import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  overviewLastUpdate: "-",
  selectedRowForMenu: {},
  menuPosition: null,
  salesByAgentData: []
};

export const salesByAgentSlice = createSlice({
  name: "SalesByAgent",
  initialState,
  reducers: {
    setOverviewLastUpdate: (state, action) => {
      state.lastUpdate = action.payload;
    },
    setSelectedRowForMenu: (state, action) => {
      state.selectedRowForMenu = action.payload;
    },
    setMenuPosition: (state, action) => {
      state.menuPosition = action.payload;
    },
    setSalesByAgentData: (state, action) => {
      state.salesByAgentData = action.payload;
    },
  },
});

export const { setOverviewLastUpdate, setSelectedRowForMenu, setMenuPosition, setSalesByAgentData } =
  salesByAgentSlice.actions;

export default salesByAgentSlice.reducer;
