import React, { useEffect, useMemo } from "react";
import { useTable } from "react-table";
import { hebCOLUMNS } from "./ColumnsFiles/hebColumns";
import { engCOLUMNS } from "./ColumnsFiles/engColumns";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { TrendArrow } from "../../TrendArrow/TrendArrow";

import "./CustomersOverviewTrendsTable.css";
import { setIsOverviewGraphReady } from "../../../store/features/homePagePropsSlice";
import LoadingPage from "../../../pages/LoadingPage/LoadingPage";

export const CustomersOverviewTrendsTable = () => {
  const dispatch = useDispatch();
  const Language = useSelector(
    (state) => state.userSlice.user.metadata.Language
  );
  const customerOverviewTrendsData = useSelector(
    (state) => state.homePagePropsSlice.customerOverviewTrendsData,
    shallowEqual
  );
  const isOverviewGraphready = useSelector(
    (state) => state.homePagePropsSlice.isOverviewGraphready
  );

  const data = useMemo(
    () => customerOverviewTrendsData,
    [customerOverviewTrendsData]
  );

  const columns = useMemo(
    () =>
      Language === "hebrew"
        ? hebCOLUMNS
        : Language === "english"
        ? engCOLUMNS
        : engCOLUMNS,
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
      autoResetPage: false,
      autoResetExpanded: false,
      autoResetGroupBy: false,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false,
    });

  useEffect(() => {
    if (customerOverviewTrendsData[0]) dispatch(setIsOverviewGraphReady(true));
  }, [customerOverviewTrendsData]);

  return isOverviewGraphready ? (
    <div className="customers-overview-trends-table-container">
      <div className="customers-overview-trends-above-table">
        <div className="bold-header" style={{ fontSize: "13px" }}>
          {Language === "hebrew"
            ? "מגמות"
            : Language === "english"
            ? "Trends"
            : "Trends"}
        </div>
      </div>

      <div
        id="customers-overview-trends-table"
        className="customers-overview-trends-table-div"
      >
        <table {...getTableProps()} className="customers-overview-trends-table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    if (["Group", "Customer"]?.includes(cell.column.id)) {
                      return (
                        <td {...cell.getCellProps()}>
                          <TrendArrow trend={cell.value} />
                        </td>
                      );
                    }
                    return (
                      <td
                        style={{ textAlign: "left", paddingLeft: "0.6rem" }}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  ) : (
    <div className="loading-overlay">
      <LoadingPage />
    </div>
  );
};
