import React from "react";
import "./CustomerOverviewNameID.css";
import { shallowEqual, useSelector } from "react-redux";

export const CustomerOverviewNameID = () => {
  const selectedCustomer = useSelector(
    (state) => state.homePagePropsSlice.selectedCustomer,
    shallowEqual
  );
  const customerNameForGraph = useSelector(
    (state) => state.homePagePropsSlice.customerNameForGraph
  );
  const Language = useSelector(
    (state) => state.userSlice.user.metadata.Language,
    shallowEqual
  );
  const customerID = selectedCustomer?.CustomerID;
  return (
    <div className="name-id-div" style={{textAlign:Language === 'hebrew'? 'right': 'left'}}>
      <div className="bold-header">{customerID}</div>
      <span> {customerNameForGraph}</span>
    </div>
  );
};
