import TextColumnFilter from "../../../Filters/TextColumnFilter";
import NumberColumnFilter from "../../../Filters/NumberColumnFilter";
import { sortTypes } from "../../../../utils/sort";
import CheckedColumnFilter from "../../../Filters/CheckedColumnFilter";
import DateColumnFilter from "../../../Filters/DateColumnFilter";


export const hebCOLUMNS = [
  {
    Header: "מספר לקוח",
    accessor: "CustomerID",
    filter: "text",
    Filter: TextColumnFilter,
    canFilter: true,
  },
  {
    Header: "שם לקוח",
    accessor: "customerName",
    filter: "text",
    Filter: TextColumnFilter,
    canFilter: true,
  },
  {
    Header: "סוכן",
    accessor: "salesAgent",
    filter: "checked",
    Filter: CheckedColumnFilter,
    canFilter: true,
  },
  {
    Header: "₪",
    accessor: "periodValue",
    filter: "number",
    Filter: NumberColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: "יחידות",
    accessor: "Units",
    filter: "number",
    Filter: NumberColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: "רכישה אחרונה",
    accessor: "LastOrderDate",
    filter: "date",
    Filter: DateColumnFilter,
    canFilter: true,
    sortType: sortTypes.lastOrderDate,
  },
//   {
//     Header: "הזדמנויות",
//     accessor: "opportunities",
//     filter: "checked",
//     Filter: CheckedColumnFilter,
//     canFilter: true,
//     // sortType: sortTypes.new,
//     disableSortBy: true,
//   },
];
