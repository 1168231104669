import React from "react";
import { Menu, MenuItem } from "@material-ui/core";
import NestedMenuItem from "material-ui-nested-menu-item";
import { dictionary } from "../../../constants/dictionary";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import axios from "axios";
import domain from "../../../config/domain";
import { setTrendsData } from "../../../store/features/homePagePropsSlice";
import { getRowsCsv, goToCustomerPage } from "../../../utils/helpers";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import pdfUtils from "../../../utils/pdf";
import { CSVLink } from "react-csv";
import { setOverviewMenuPosition } from "../../../store/features/customersOverviewSlice";
import { setRevNavigatorTableFilters } from "../../../store/features/tableFiltersSlice";
import { setRevNavigatorTableSorts } from "../../../store/features/tableSortsSlice";
import { setRevNavTableData } from "../../../store/features/revNavigatorSlice";
import { setCustomersOverviewTableData } from "../../../store/features/dataSlice";
import { reqAuth } from "../../../constants/reqAuth";

const tableName = "CustomersOverview";

export default function RevNavigatorMenu({
  tableRef,
  rows,
  csvHeaders,
  revNavFilters,
  revNavSorts,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const Language = useSelector(
    (state) => state.userSlice.user.metadata.Language
  );
  const InsightingCustomerID = useSelector(
    (state) => state.userSlice.user.metadata.InsightingCustomerID
  );
  const trendsData = useSelector(
    (state) => state.homePagePropsSlice.trendsData,
    shallowEqual
  );
  const customersOverviewTableData = useSelector(
    (state) => state.dataSlice.customersOverviewTableData
  );
  const allKeyOpportunitiesForCustomerPage = useSelector(
    (state) => state.dataSlice.allKeyOpportunitiesForCustomerPage,
    shallowEqual
  );
  const revNavTableData = useSelector(
    (state) => state.revNavigatorSlice.revNavTableData,
    shallowEqual
  );
  const overviewMenuPosition = useSelector(
    (state) => state.customersOverviewSlice.overviewMenuPosition,
    shallowEqual
  );
  const selectedRowForMenu = useSelector(
    (state) => state.customersOverviewSlice.selectedRowForMenu,
    shallowEqual
  );
  const revNavTableMode = useSelector(
    (state) => state.revNavigatorSlice.revNavTableMode
  );

  const handlePriorityClick = async (e) => {
    const newPriority = e.target.id;
    dispatch(setOverviewMenuPosition(null));

    const { CustomerID } = selectedRowForMenu;
    const updatedTrendsData = trendsData.map((item) => {
      if (item.CustomerID === CustomerID) {
        return {
          ...item,
          priority: newPriority,
          Priority: newPriority,
        };
      }
      return item;
    });
    const updatedcustomersOverviewTableData = customersOverviewTableData.map(
      (item) => {
        if (item.CustomerID === CustomerID) {
          return {
            ...item,
            priority: newPriority,
            Priority: newPriority,
          };
        }
        return item;
      }
    );
    const updatedRevNavData = revNavTableData.map((item) => {
      if (item.CustomerID === CustomerID) {
        return {
          ...item,
          priority: newPriority,
          Priority: newPriority,
        };
      }
      return item;
    });

    dispatch(setTrendsData(updatedTrendsData));
    dispatch(setRevNavTableData(updatedRevNavData));
    dispatch(setCustomersOverviewTableData(updatedcustomersOverviewTableData));
    await axios.request({
      method: "POST",
      url: `${domain}/${tableName}/priorityRowQuery/`,
      data: {
        CustomerID,
        InsightingCustomerID,
        Priority: newPriority,
      },
      headers: { authorization: reqAuth },
    });
  };

  const handleCustomerClick = () => {
    dispatch(setOverviewMenuPosition(null));
    // console.log(revNavFilters);
    dispatch(setRevNavigatorTableFilters(revNavFilters));
    dispatch(setRevNavigatorTableSorts(revNavSorts));

    try {
      const { customerName, salesAgent, CustomerID } = selectedRowForMenu;
      goToCustomerPage(
        CustomerID,
        InsightingCustomerID,
        customerName,
        salesAgent,
        Language,
        allKeyOpportunitiesForCustomerPage,
        history
      );
    } catch (e) {
      console.log(e.stack);
    }
  };

  const handleDetailsClick = () => {
    dispatch(setOverviewMenuPosition(null));
    const { ItemCatalogNumber, CurrStock, ItemName } = selectedRowForMenu;
    const path = history.location.pathname;
    history.push(path.endsWith("/") ? "details" : "revNav/details", {
      InsightingCustomerID,
      ItemCatalogNumber,
      ItemName,
      CurrStock,
    });
  };

  const handlePDFExportToClick = () => {
    // pdfUtils.exportPdfFile("#customers-overview-table", "customers_overview");
    pdfUtils.download(
      tableRef.current,
      dictionary[Language]?.RevNavigatorTable.tableTitle2[revNavTableMode]
    );
    dispatch(setOverviewMenuPosition(null));
  };

  return (
    <Menu
      // style={{ direction: Language === "hebrew" ? "rtl" : "ltr" }}
      open={!!overviewMenuPosition}
      onClose={() => {
        dispatch(setOverviewMenuPosition(null));
      }}
      anchorReference="anchorPosition"
      anchorPosition={overviewMenuPosition}
    >
      <MenuItem
        onClick={
          ["DG", "DL"]?.includes(revNavTableMode)
            ? handleDetailsClick
            : handleCustomerClick
        }
      >
        {["DG", "DL"]?.includes(revNavTableMode)
          ? dictionary[Language]?.CustomersOverviewTable.menuOptions.detailsPage
          : dictionary[Language]?.CustomersOverviewTable.menuOptions
              .customerPage}
      </MenuItem>
      <NestedMenuItem
        label={
          dictionary[Language]?.CustomersOverviewTable.menuOptions.priority
        }
        parentMenuOpen={!!overviewMenuPosition}
      >
        <MenuItem id="1" onClick={handlePriorityClick}>
          {dictionary[Language]?.CustomersOverviewTable.menuOptions.high}
        </MenuItem>
        <MenuItem id="0" onClick={handlePriorityClick}>
          {dictionary[Language]?.CustomersOverviewTable.menuOptions.regular}
        </MenuItem>
        <MenuItem id="-1" onClick={handlePriorityClick}>
          {dictionary[Language]?.CustomersOverviewTable.menuOptions.low}
        </MenuItem>
      </NestedMenuItem>
      <div className="menu-separator-div"></div>
      <NestedMenuItem
        label={
          dictionary[Language]?.CustomersOverviewTable.menuOptions.exportTo
        }
        parentMenuOpen={!!overviewMenuPosition}
      >
        <MenuItem id="csv">
          <CSVLink
            target="_blank"
            data={getRowsCsv(rows, csvHeaders, Language) || []}
            filename={`${dictionary[Language]?.RevNavigatorTable.tableTitle2[revNavTableMode]}.csv`}
            style={{ color: "black" }}
            onClick={() => {
              dispatch(setOverviewMenuPosition(null));
            }}
          >
            CSV
          </CSVLink>
        </MenuItem>
        <MenuItem
          id="pdf"
          onClick={
            (rows?.length < 200 ? true : false) ? handlePDFExportToClick : null
          }
          style={{
            color: (rows?.length < 200 ? true : false) ? "black" : "grey",
          }}
        >
          PDF
        </MenuItem>
      </NestedMenuItem>
    </Menu>
  );
}
