import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  latestProductPurchasedTableFilters: [],
  latestProductPurchasedAnalyzeTableFilters: [],
  latestCustomersKeyOpportunitiesFilters: [],
};

export const tableFiltersSlice = createSlice({
  name: "tableLatestFilters",
  initialState,
  reducers: {
    setProductPurchasedTableFilters: (state, action) => {
      state.latestProductPurchasedTableFilters = action.payload;
    },
    setProductPurchasedAnalyzeTableFilters: (state, action) => {
      state.latestProductPurchasedAnalyzeTableFilters = action.payload;
    },
    setCustomersKeyOpportunitiesFilters: (state, action) => {
      state.latestCustomersKeyOpportunitiesFilters = action.payload;
    },
  },
});

export const {
  setProductPurchasedTableFilters,
  setProductPurchasedAnalyzeTableFilters,
  setCustomersKeyOpportunitiesFilters,
} = tableFiltersSlice.actions;

export default tableFiltersSlice.reducer;
