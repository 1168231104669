import map from "lodash/map";
import uniq from "lodash/uniq";
import trim from "lodash/trim";
import { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import flattenDeep from "lodash/flattenDeep";
import Button from "@material-ui/core/Button";
import isEmpty from "lodash/isEmpty";
import { dictionary } from "../../constants/dictionary";

const CheckedColumnFilter = ({ column, labelsMap = {} }) => {
  const userSlice = useSelector((state) => state.userSlice, shallowEqual);
  const {
    user: {
      metadata: { Language },
    },
  } = userSlice;
  const [checked, setChecked] = useState({});
  const { id, preFilteredRows, setFilter } = column;

  const rows = uniq(map(map(preFilteredRows, "original"), id)).map((item) =>
    String(item)
  );
  const _rows = uniq(
    flattenDeep(rows.map((row) => row?.split(/\s?\|\s?/g).map(trim)))
  );

  const toggleCheckbox = (label) => {
    setChecked((prevChecked) => {
      return {
        ...prevChecked,
        [label]: !prevChecked[label],
      };
    });
  };

  const applyFilter = (e) => {
    if (!isEmpty(checked)) {
      const _checked = { ...checked };
      for (let key in _checked) {
        if (!_checked[key]) {
          delete _checked[key];
        }
      }
      setFilter(_checked);
    }
    e.preventDefault();
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxHeight: "10rem",
          overflowY: "auto",
        }}
      >
        {_rows.map((label, i) => (
          <label style={{ padding: "3px" }} key={`${label}-${i}`}>
            <input
              style={{ margin: "0.4rem" }}
              type="checkbox"
              checked={!!checked[label]}
              onChange={() => toggleCheckbox(label)}
            />
            {isEmpty(labelsMap) ? label : labelsMap[label]}
          </label>
        ))}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "0.5rem",
        }}
      >
        {" "}
        <Button
          size="small"
          type="submit"
          color="primary"
          disabled={isEmpty(checked)}
          variant="contained"
          onClick={applyFilter}
          className="filter-picker-apply-button"
        >
          {dictionary[Language].hierarchyBox.apply}
        </Button>
      </div>
    </>
  );
};

export default CheckedColumnFilter;
