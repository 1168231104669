import React from "react";
import { Menu, MenuItem } from "@material-ui/core";
import NestedMenuItem from "material-ui-nested-menu-item";
import { dictionary } from "../../../constants/dictionary";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import axios from "axios";
import domain from "../../../config/domain";
import {
  setComparisonValue,
  setCustomerNameForGraph,
  setLoadingSpinner,
  setSelectedCustomer,
  setTrendsData,
} from "../../../store/features/homePagePropsSlice";
import {
  getMonthForHeader,
  getRowsCsv,
  goToCustomerPage,
} from "../../../utils/helpers";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { currencyMap } from "../../../constants/currency-map";
import pdfUtils from "../../../utils/pdf";
import { CSVLink } from "react-csv";
import { setOverviewMenuPosition } from "../../../store/features/customersOverviewSlice";
import { setCustomerTrendsFilters } from "../../../store/features/tableFiltersSlice";
import { setCustomerTrendsSorts } from "../../../store/features/tableSortsSlice";
import { setRevNavTableData } from "../../../store/features/revNavigatorSlice";
import { setCustomersOverviewTableData } from "../../../store/features/dataSlice";
import { reqAuth } from "../../../constants/reqAuth";

const tableName = "CustomersOverview";
const SalesHeadersMap = {
  english: {
    yearAgo: `Sales ${getMonthForHeader("currentMonthYearAgo")}`,
    lastMonth: `Sales ${getMonthForHeader("lastMonth")}`,
    avgP3M: `Avg ${getMonthForHeader("threeMonthsAgo")}-${getMonthForHeader(
      "lastMonth"
    )}`,
    avgPurchaseP3M: `Avg Purchase ${getMonthForHeader(
      "threeMonthsAgo"
    )}-${getMonthForHeader("lastMonth")}`,
  },
  hebrew: {
    yearAgo: `מכירות ${getMonthForHeader("currentMonthYearAgo")}`,
    lastMonth: `מכירות ${getMonthForHeader("lastMonth")}`,
    avgP3M: `ממוצע ${getMonthForHeader("lastMonth")}-${getMonthForHeader(
      "threeMonthsAgo"
    )}`,
    avgPurchaseP3M: `ממוצע רכישות ${getMonthForHeader(
      "lastMonth"
    )}-${getMonthForHeader("threeMonthsAgo")}`,
  },
};

export default function CustomersOverviewMenu({
  groupedHeader,
  tableRef,
  rows,
  headerGroups,
  csvHeaders,
  overviewFilters,
  overviewSorts,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const Language = useSelector(
    (state) => state.userSlice.user.metadata.Language
  );
  const InsightingCustomerID = useSelector(
    (state) => state.userSlice.user.metadata.InsightingCustomerID
  );
  const Currency = useSelector(
    (state) => state.userSlice.user.metadata.Currency
  );
  const trendsData = useSelector(
    (state) => state.homePagePropsSlice.trendsData,
    shallowEqual
  );
  const customersOverviewTableData = useSelector(
    (state) => state.dataSlice.customersOverviewTableData
  );
  const allKeyOpportunitiesForCustomerPage = useSelector(
    (state) => state.dataSlice.allKeyOpportunitiesForCustomerPage,
    shallowEqual
  );
  const comparisonValue = useSelector(
    (state) => state.homePagePropsSlice.comparisonValue
  );
  const overviewMenuPosition = useSelector(
    (state) => state.customersOverviewSlice.overviewMenuPosition,
    shallowEqual
  );
  const selectedRowForMenu = useSelector(
    (state) => state.customersOverviewSlice.selectedRowForMenu,
    shallowEqual
  );
  const revNavTableData = useSelector(
    (state) => state.revNavigatorSlice.revNavTableData,
    shallowEqual
  );

  const handlePriorityClick = async (e) => {
    const newPriority = e.target.id;
    dispatch(setOverviewMenuPosition(null));
    const { CustomerID } = selectedRowForMenu;
    const updatedTrendsData = trendsData.map((item) => {
      if (item.CustomerID === CustomerID) {
        return {
          ...item,
          priority: newPriority,
          Priority: newPriority,
        };
      }
      return item;
    });
    const updatedcustomersOverviewTableData = customersOverviewTableData.map(
      (item) => {
        if (item.CustomerID === CustomerID) {
          return {
            ...item,
            priority: newPriority,
            Priority: newPriority,
          };
        }
        return item;
      }
    );
    const updatedRevNavData = revNavTableData.map((item) => {
      if (item.CustomerID === CustomerID) {
        return {
          ...item,
          priority: newPriority,
          Priority: newPriority,
        };
      }
      return item;
    });

    dispatch(setTrendsData(updatedTrendsData));
    dispatch(setRevNavTableData(updatedRevNavData));
    dispatch(setCustomersOverviewTableData(updatedcustomersOverviewTableData));
    await axios.request({
      method: "POST",
      url: `${domain}/${tableName}/priorityRowQuery/`,
      data: {
        CustomerID,
        InsightingCustomerID,
        Priority: newPriority,
      },
      headers: { authorization: reqAuth },
    });
  };

  const handleCustomerClick = async (e) => {
    dispatch(setOverviewMenuPosition(null));
    dispatch(setCustomerTrendsFilters(overviewFilters));
    dispatch(setCustomerTrendsSorts(overviewSorts));

    try {
      const { CustomerName, AgentName, CustomerID } = selectedRowForMenu;
      goToCustomerPage(
        CustomerID,
        InsightingCustomerID,
        CustomerName,
        AgentName,
        Language,
        allKeyOpportunitiesForCustomerPage,
        history
      );
    } catch (e) {
      console.log(e.stack);
    }
  };

  const handleComparisonClick = (e) => {
    if (e.target.id !== comparisonValue) {
      groupedHeader.current.children[5].innerText =
        SalesHeadersMap[Language][e.target.id || e.target.parentElement.id] +
        " " +
        currencyMap[Currency];
      dispatch(setComparisonValue(e.target.id || e.target.parentElement.id));
      dispatch(setTrendsData([]));
    }
    dispatch(setOverviewMenuPosition(null));
  };

  const handlePDFExportToClick = () => {
    // pdfUtils.exportPdfFile("#customers-overview-table", "customers_overview");
    pdfUtils.download(tableRef.current, "customers-overview");
    dispatch(setOverviewMenuPosition(null));
  };

  const handleShowGraphClick = (e) => {
    // if (selectedCustomer !== original) {
    dispatch(setLoadingSpinner(true));
    dispatch(setSelectedCustomer(selectedRowForMenu));
    dispatch(setCustomerNameForGraph(selectedRowForMenu.CustomerName));
    dispatch(setOverviewMenuPosition(null));
    // } else {
    //   dispatch(setOverviewMenuPosition(null));
    // }
  };

  return (
    <Menu
      // style={{ direction: Language === "hebrew" ? "rtl" : "ltr" }}
      open={!!overviewMenuPosition}
      onClose={() => {
        dispatch(setOverviewMenuPosition(null));
      }}
      anchorReference="anchorPosition"
      anchorPosition={overviewMenuPosition}
    >
      <NestedMenuItem
        label={
          dictionary[Language]?.CustomersOverviewTable.menuOptions
            .calcProgressVs
        }
        parentMenuOpen={!!overviewMenuPosition}
      >
        <MenuItem
          style={{ direction: Language === "hebrew" ? "rtl" : "ltr" }}
          id="lastMonth"
          onClick={(e) => handleComparisonClick(e, headerGroups)}
        >
          <div>
            <span
              id="lastMonth"
              className={`context-menu-checkmark ${
                comparisonValue === "lastMonth"
                  ? "checkmark-on"
                  : "checkmark-off"
              }`}
            >
              &#10003;
            </span>
            <span id="lastMonth">
              {
                dictionary[Language]?.CustomersOverviewTable.menuOptions
                  .lastMonth
              }
            </span>
          </div>
        </MenuItem>
        <MenuItem
          style={{ direction: Language === "hebrew" ? "rtl" : "ltr" }}
          id="yearAgo"
          onClick={(e) => handleComparisonClick(e, headerGroups)}
        >
          <div>
            <span
              id="yearAgo"
              className={`context-menu-checkmark ${
                comparisonValue === "yearAgo" ? "checkmark-on" : "checkmark-off"
              }`}
            >
              &#10003;
            </span>
            <span id="yearAgo">
              {dictionary[Language]?.CustomersOverviewTable.menuOptions.yearAgo}
            </span>
          </div>
        </MenuItem>
        <MenuItem
          style={{ direction: Language === "hebrew" ? "rtl" : "ltr" }}
          id="avgP3M"
          onClick={(e) => handleComparisonClick(e, headerGroups)}
        >
          <div>
            <span
              id="avgP3M"
              className={`context-menu-checkmark ${
                comparisonValue === "avgP3M" ? "checkmark-on" : "checkmark-off"
              }`}
            >
              &#10003;
            </span>
            <span id="avgP3M">
              {dictionary[Language]?.CustomersOverviewTable.menuOptions.avgP3M}
            </span>{" "}
          </div>
        </MenuItem>
        <MenuItem
          style={{ direction: Language === "hebrew" ? "rtl" : "ltr" }}
          id="avgPurchaseP3M"
          onClick={(e) => handleComparisonClick(e, headerGroups)}
        >
          <div>
            <span
              id="avgPurchaseP3M"
              className={`context-menu-checkmark ${
                comparisonValue === "avgPurchaseP3M"
                  ? "checkmark-on"
                  : "checkmark-off"
              }`}
            >
              &#10003;
            </span>
            <span id="avgPurchaseP3M">
              {
                dictionary[Language]?.CustomersOverviewTable.menuOptions
                  .avgPurchaseP3M
              }
            </span>
          </div>
        </MenuItem>
      </NestedMenuItem>
      <div className="menu-separator-div"></div>
      <MenuItem onClick={handleShowGraphClick}>
        {dictionary[Language]?.CustomersOverviewTable.menuOptions.showGraph}
      </MenuItem>
      <MenuItem onClick={handleCustomerClick}>
        {dictionary[Language]?.CustomersOverviewTable.menuOptions.customerPage}
      </MenuItem>
      <NestedMenuItem
        label={
          dictionary[Language]?.CustomersOverviewTable.menuOptions.priority
        }
        parentMenuOpen={!!overviewMenuPosition}
      >
        <MenuItem id="1" onClick={handlePriorityClick}>
          {dictionary[Language]?.CustomersOverviewTable.menuOptions.high}
        </MenuItem>
        <MenuItem id="0" onClick={handlePriorityClick}>
          {dictionary[Language]?.CustomersOverviewTable.menuOptions.regular}
        </MenuItem>
        <MenuItem id="-1" onClick={handlePriorityClick}>
          {dictionary[Language]?.CustomersOverviewTable.menuOptions.low}
        </MenuItem>
      </NestedMenuItem>
      <div className="menu-separator-div"></div>
      <NestedMenuItem
        label={
          dictionary[Language]?.CustomersOverviewTable.menuOptions.exportTo
        }
        parentMenuOpen={!!overviewMenuPosition}
      >
        <MenuItem id="csv">
          <CSVLink
            target="_blank"
            data={
              getRowsCsv(rows, csvHeaders, Language, "customersOverview") || []
            }
            filename={`customers_overview.csv`}
            style={{ color: "black" }}
            onClick={() => {
              dispatch(setOverviewMenuPosition(null));
            }}
          >
            CSV
          </CSVLink>
        </MenuItem>
        <MenuItem
          id="pdf"
          onClick={
            (rows?.length < 200 ? true : false) ? handlePDFExportToClick : null
          }
          style={{
            color: (rows?.length < 200 ? true : false) ? "black" : "grey",
          }}
        >
          PDF
        </MenuItem>
      </NestedMenuItem>
    </Menu>
  );
}
