import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  resolution: {
    device: "desktop",
    width: window.innerWidth,
  },
  staticAppDate: null,
  showCustomerPageFiltersAndSorts: false,
  prevCustomerID: "",
  custKeyOppsLastUpdate: null,
  prodPurchasedLastUpdate: null,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setResolution: (state, action) => {
      state.resolution = action.payload;
    },
    setStaticAppDate: (state, action) => {
      state.staticAppDate = action.payload;
    },
    setShowCustomerPageFiltersAndSorts: (state, action) => {
      state.showCustomerPageFiltersAndSorts = action.payload;
    },
    setPrevCustomerID: (state, action) => {
      state.prevCustomerID = action.payload;
    },
    setCustKeyOppsLastUpdate: (state, action) => {
      state.custKeyOppsLastUpdate = action.payload;
    },
    setProdPurchasedLastUpdate: (state, action) => {
      state.prodPurchasedLastUpdate = action.payload;
    },
  },
});

export const {
  setResolution,
  setStaticAppDate,
  setShowCustomerPageFiltersAndSorts,
  setPrevCustomerID,
  setCustKeyOppsLastUpdate,
  setProdPurchasedLastUpdate,
} = globalSlice.actions;

export default globalSlice.reducer;
