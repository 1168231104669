import axios from "axios";
import domain from "../config/domain";
import { reqAuth } from "../constants/reqAuth";

async function getSalesByCustomerByItemData(InsightingCustomerID, reqDate) {
  return await axios
    .request({
      method: "POST",
      url: `${domain}/SalesByCustomerByItem_Main/InsightingCustomerID/${InsightingCustomerID}`,
      headers: { authorization: reqAuth },
      data: { reqDate },
    })
    .then((r) => {
      return r.data;
    })
    .catch(console.error);
}

async function getSalesByCustomerByItemDataByCustomerID(
  CustomerID,
  InsightingCustomerID,
  // Years = [],
  // CustomerIDsList = [],
  // ItemCatNumbers = [],
  reqDate
) {
  return axios.request({
    method: "POST",
    url: `${domain}/customerPage/SalesByCustomerByItem_Main`,
    headers: { authorization: reqAuth },
    data: {
      CustomerID,
      InsightingCustomerID,
      // Years,
      // CustomerIDsList,
      // ItemCatNumbers,
      reqDate,
    },
  });
}

async function getSalesByCustomerData(InsightingCustomerID, reqDate) {
  return await axios
    .request({
      method: "POST",
      url: `${domain}/SalesByCustomer_Main/InsightingCustomerID/${InsightingCustomerID}`,
      headers: { authorization: reqAuth },
      data: { reqDate },
    })
    .then((r) => {
      return r.data;
    })
    .catch(console.error);
}
const salesService = {
  getSalesByCustomerByItemData,
  getSalesByCustomerByItemDataByCustomerID,
  getSalesByCustomerData,
};

export default salesService;
