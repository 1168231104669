import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import { grey } from "@mui/material/colors";
import CloseIcon from "@material-ui/icons/Close";
import CardContent from "@material-ui/core/CardContent";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { dictionary } from "../../constants/dictionary";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import {
  setAgentsFilterApplyToggle,
  setIsAllAgentsSelected,
  setIsPrepRows,
  setListOfSelectedAgents,
  setRevNavCheckedAgents,
  setRevNavGraphData,
  setRevNavSubmittedAgents,
} from "../../store/features/revNavigatorSlice";
import { deepEqualObjects, getRevNavCoordinates } from "../../utils/helpers";
import { Typography } from "@material-ui/core";
import { setRevNavigatorTableFilters } from "../../store/features/tableFiltersSlice";
import { setRevNavigatorTableSorts } from "../../store/features/tableSortsSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  card: {
    top: "2.5rem",
    left: "-4rem",
    zIndex: 6,
    width: 210,
    // height: 330,
    // display: "flex",
    position: "absolute",
    // flexDirection: "column",
    boxShadow: `0 0 5px ${grey[500]}`,
    fontSize: 12,
    [theme.breakpoints.down("xs")]: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: "auto",
      height: "fit-content",
    },
  },
  formControl: {
    width: 140,
  },
  filtersCloseIcon: {
    opacity: 0.82,
    cursor: "pointer",
    fontSize: 18,
    transition: "opacity 0.4s",
    "&:hover": {
      opacity: 1,
    },
  },
  font: {
    fontSize: 13,
  },
  cardcontent: {
    "&:last-child": {
      paddingBottom: 0,
    },
  },
}));

export default function AgentsPicker({
  visible = false,
  setVisibility = () => {},
  uniqueAgentIDs = [],
  // setAgentsFilterApplyToggle,
}) {
  const classes = useStyles();
  const userSlice = useSelector((state) => state.userSlice, shallowEqual);
  const revNavResData = useSelector(
    (state) => state.revNavigatorSlice.revNavResData,
    shallowEqual
  );
  const revNavSelectedPeriod = useSelector(
    (state) => state.revNavigatorSlice.revNavSelectedPeriod,
    shallowEqual
  );
  const revNavCheckedAgents = useSelector(
    (state) => state.revNavigatorSlice.revNavCheckedAgents,
    shallowEqual
  );
  const revNavSubmittedAgents = useSelector(
    (state) => state.revNavigatorSlice.revNavSubmittedAgents,
    shallowEqual
  );
  const revNavAgentsMap = useSelector(
    (state) => state.revNavigatorSlice.revNavAgentsMap,
    shallowEqual
  );
  const revNavTableMode = useSelector(
    (state) => state.revNavigatorSlice.revNavTableMode
  );
  const listOfSelectedAgents = useSelector(
    (state) => state.revNavigatorSlice.listOfSelectedAgents,
    shallowEqual
  );
  const dispatch = useDispatch();

  const {
    user: {
      metadata: { Language, UserType },
    },
  } = userSlice;

  const handleApplyClick = (e) => {
    e.preventDefault();
    setVisibility(!visible);
    dispatch(setIsPrepRows(true));
    const areAllSelected = uniqueAgentIDs.every(
      (agent) => revNavCheckedAgents[agent]
    );

    let checkedArr = Object.keys(revNavCheckedAgents)?.filter(
      (key) => revNavCheckedAgents[key] === true
    );

    const areEqual =
      checkedArr.every((element) => listOfSelectedAgents?.includes(element)) &&
      listOfSelectedAgents.every((element) => checkedArr?.includes(element));

    if (!areEqual) {
      dispatch(
        setListOfSelectedAgents(
          Object.keys(revNavCheckedAgents)?.filter(
            (key) => revNavCheckedAgents[key] === true
          )
        )
      );
      dispatch(setRevNavSubmittedAgents(revNavCheckedAgents));
      dispatch(setAgentsFilterApplyToggle(true));
      dispatch(
        setRevNavGraphData(
          getRevNavCoordinates(
            UserType,
            revNavResData,
            revNavSelectedPeriod,
            revNavCheckedAgents,
            uniqueAgentIDs,
            Language,
            revNavTableMode
          )
        )
      );
      dispatch(setIsAllAgentsSelected(areAllSelected));
      dispatch(setRevNavigatorTableFilters([]));
      dispatch(setRevNavigatorTableSorts([]));
    }
    // setVisibility(!visible);
  };

  const toggleCheckbox = (label) => {
    dispatch(
      setRevNavCheckedAgents({
        ...revNavCheckedAgents,
        [label]: !revNavCheckedAgents[label],
      })
    );
  };

  // Handler for "Select/Deselect All" functionality
  const toggleSelectAll = () => {
    // Determine if all agents are currently selected
    const areAllSelected = uniqueAgentIDs.every(
      (agent) => revNavCheckedAgents[agent]
    );

    // If all are selected, deselect all. Otherwise, select all.
    const newCheckedState = uniqueAgentIDs.reduce((acc, agent) => {
      acc[agent] = !areAllSelected; // Set all to true if not all are selected, otherwise set to false
      return acc;
    }, {});
    dispatch(setRevNavCheckedAgents(newCheckedState));
    // dispatch(setIsAllAgentsSelected(true));
  };

  
  return (
    <form name="filter-picker" className={classes.root}>
      {!visible ? null : (
        <Card
          className={classes.card}
          style={{
            direction: Language === "hebrew" ? "rtl" : "ltr",
            left: Language === "hebrew" ? "-4rem" : "-6.7rem",
          }}
        >
          <CardContent
            className={classes.cardContent}
            style={{ paddingBottom: 16 }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography>
                {dictionary[Language]?.revNav_MainGraph.agentsSelector.title}
              </Typography>
              <CloseIcon
                className={classes.filtersCloseIcon}
                onClick={() => {
                  setVisibility(!visible);
                }}
              />
            </Box>
            <div className="agents-list-container">
              <div>
                <label>
                  <input
                    style={{ margin: "0.4rem" }}
                    type="checkbox"
                    checked={
                      Object.keys(revNavCheckedAgents)?.length ===
                        uniqueAgentIDs?.length &&
                      Object.values(revNavCheckedAgents).every(
                        (value) => value === true
                      )
                    }
                    onChange={toggleSelectAll}
                  />
                  {
                    dictionary[Language]?.revNav_MainGraph.agentsSelector
                      .selectAll
                  }
                </label>
              </div>
              <div className="rev-nav-agents-for-picker">
                {revNavAgentsMap.map((label) => {
                  return (
                    <label style={{}} key={`${label.agentID}`}>
                      <input
                        style={{ margin: "0.4rem" }}
                        type="checkbox"
                        checked={!!revNavCheckedAgents[label.agentID]}
                        onChange={() => toggleCheckbox(label.agentID)}
                      />
                      {label.agentName}
                    </label>
                  );
                })}
                {/* {uniqueAgentIDs.map((label) => {
                  return (
                    <label style={{}} key={`${label}`}>
                      <input
                        style={{ margin: "0.4rem" }}
                        type="checkbox"
                        checked={!!revNavCheckedAgents[label]}
                        onChange={() => toggleCheckbox(label)}
                      />
                      {label}
                    </label>
                  );
                })} */}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent:
                  Language === "hebrew" ? "flex-end" : "flex-start",
              }}
            >
              <Button
                size="small"
                type="submit"
                color="primary"
                variant="contained"
                onClick={handleApplyClick}
                disabled={
                  !Object.values(revNavCheckedAgents)?.includes(true) ||
                  deepEqualObjects(revNavCheckedAgents, revNavSubmittedAgents)
                }
              >
                {dictionary[Language]?.hierarchyBox.apply}
              </Button>
            </div>
          </CardContent>
        </Card>
      )}
    </form>
  );
}
