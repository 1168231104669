import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { dictionary } from "../../../constants/dictionary";
import { getLastUpdate, usDateFormat } from "../../../utils/helpers";

export default function SalesReviewAboveTable({data}) {
  const Language = useSelector(
    (state) => state.userSlice.user.metadata.Language
  );
  const DateFormat = useSelector(
    (state) => state.userSlice.user.metadata.DateFormat
  );
  const [lastUpdate, setLastUpdate] = useState(null);
  useEffect(
    function onLastUpdate() {
      if (data?.length > 0) {
        setLastUpdate(getLastUpdate(data));
      }
    },
    [data]
  );
  return (
    <div
      className="sales-review-above-table"
      style={{
        direction: `${Language === "hebrew" ? "rtl" : "ltr"}`,
        marginRight: "3rem",
      }}
    >
      <h4 className="table-header">
        {dictionary[Language]?.salesReviewTable?.tableTitle}
      </h4>
      <span className="last-update">
        {dictionary[Language].lastUpdate} :{" "}
        {DateFormat === "us" ? usDateFormat(lastUpdate) : lastUpdate}
      </span>
    </div>
  );
}
