import numberUtils from "./number";

const probsObj = {
  New: "a",
  Low: "b",
  Med: "c",
  High: "d",
  חדש: "a",
  נמוכה: "b",
  בינונית: "c",
  גבוהה: "d",
};

export const sortTypes = {
  number(rowA, rowB, id) {
    let a = numberUtils.toNumber(rowA?.original[id]);
    let b = numberUtils.toNumber(rowB?.original[id]);
    if (isNaN(a) || a === null) a = -3000000;
    if (isNaN(b) || b === null) b = -3000000;
    if (a < b) return -1;
    if (b < a) return 1;
    return 0;
  },
  trendsProgress(rowA, rowB) {
    let a = rowA?.original.progressExpectation;
    let b = rowB?.original.progressExpectation;
    if (isNaN(a)) a = -3000000;
    if (isNaN(b)) b = -3000000;
    if (a < b) return -1;
    if (b < a) return 1;
    return 0;
  },
  new(rowA, rowB) {
    const a = new Date(
      rowA?.original.CreatedDate?.split("_").reverse().join("-")
    );
    const b = new Date(
      rowB?.original.CreatedDate?.split("_").reverse().join("-")
    );
    if (a < b) return -1;
    if (b < a) return 1;
    return 0;
  },
  lastOrder(rowA, rowB) {
    let a = new Date(rowA?.original.LastOrder?.split("_").reverse().join("-"));
    let b = new Date(rowB?.original.LastOrder?.split("_").reverse().join("-"));
    if (a === "Invalid Date") a = new Date("2002-11");
    if (b === "Invalid Date") b = new Date("2002-11");
    if (a < b) return -1;
    if (b < a) return 1;
    return 0;
  },
  lastOrderDate(rowA, rowB) {
    let a;
    let b;
    if (rowA?.original.LastOrderDate !== "-") {
      let formatValueA = rowA?.original.LastOrderDate?.split("/");
      let secondDigitA = formatValueA[1][1] ? formatValueA[1][1] : "";
      formatValueA[1] = formatValueA[1][0]?.replace("0", "");
      formatValueA[1] += secondDigitA;
      let secondDigitA2 = formatValueA[0][1] ? formatValueA[0][1] : "";
      formatValueA[0] = formatValueA[0][0]?.replace("0", "");
      formatValueA[0] += secondDigitA2;
      formatValueA = formatValueA.reverse().join("-");
      a = new Date(formatValueA);
    } else a = new Date("2002-11-11");

    if (rowB?.original.LastOrderDate !== "-") {
      let formatValueB = rowB?.original.LastOrderDate?.split("/");
      let secondDigitB = formatValueB[1][1] ? formatValueB[1][1] : "";
      formatValueB[1] = formatValueB[1][0]?.replace("0", "");
      formatValueB[1] += secondDigitB;
      let secondDigitB2 = formatValueB[0][1] ? formatValueB[0][1] : "";
      formatValueB[0] = formatValueB[0][0]?.replace("0", "");
      formatValueB[0] += secondDigitB2;
      formatValueB = formatValueB?.reverse().join("-");
      b = new Date(formatValueB);
    } else b = new Date("2002-11-11");

    if (a === "Invalid Date") a = new Date("2002-11-11");
    if (b === "Invalid Date") b = new Date("2002-11-11");
    if (a < b) return -1;
    if (b < a) return 1;
    return 0;
  },
  probability(rowA, rowB) {
    let a = probsObj[rowA?.original?.Probability];
    let b = probsObj[rowB?.original?.Probability];
    if (a < b) return -1;
    if (b < a) return 1;
    return 0;
  },
};
