import keys from "lodash/keys";
import isEmpty from "lodash/isEmpty";
import Box from "@material-ui/core/Box";
import isBoolean from "lodash/isBoolean";
import Chip from "@material-ui/core/Chip";
import { grey } from "@mui/material/colors";
import React, { useEffect, useCallback, useState } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import { FILTER_SEPARATOR } from "../../utils/filters";
import {
  prioritiesMapForKeyOpp,
  urgencyMapForTrends,
} from "../../constants/filtersText-map";
import { operators as textOperators } from "./TextColumnFilter";
import { hebOperators as textHebOperators } from "./TextColumnFilter";
import { engOperators as textEngOperators } from "./TextColumnFilter";
import { operators as numberOperators } from "./NumberColumnFilter";
import { shallowEqual, useSelector } from "react-redux";

const findNumberOperator = (operatorId) =>
  numberOperators.find(({ id }) => operatorId === id)?.label;

const useStyles = makeStyles(() => ({
  filter: {
    fontSize: 11,
    padding: 5,
    color: grey[50],
    margin: "2.5px 5px",
    "& .MuiChip-deleteIconColorPrimary": {
      color: grey[50],
    },
  },
  filterIcon: {
    color:"#75a9f9",
    cursor: "pointer",
  },
  filtersCloseIcon: {
    opacity: 0.82,
    marginBottom: 12,
    cursor: "pointer",
    transition: "opacity 0.4s",
    "&:hover": {
      opacity: 1,
    },
  },
}));

export default function FiltersList({
  style = {},
  filters = [],
  headers = [],
  setFilterPickerVisible = () => {},
}) {
  const classes = useStyles();
  const [languageTextOperators, setLanguageTextOperators] =
    useState(textOperators);
  const userSlice = useSelector((state) => state.userSlice, shallowEqual);
  const {
    user: {
      metadata: { DateFormat, Language },
    },
  } = userSlice;

  const findTextOperator = (operatorId) =>
    languageTextOperators.find(({ id }) => operatorId === id)?.shortLabel;

  const getColumnByAccessor = useCallback(
    (columnAccessor) => {
      return headers.find((col) => col.canFilter && col.id === columnAccessor);
    },
    [headers]
  );

  const clearFilter = useCallback(
    (columnAccessor, value = "") => {
      const column = getColumnByAccessor(columnAccessor);
      if (column && column.canFilter && column.setFilter) {
        column.setFilter(value);
      }
    },
    [headers]
  );

  useEffect(
    function onFiltersChanged() {
      setFilterPickerVisible(false);
    },
    [filters]
  );

  useEffect(() => {
    setLanguageTextOperators(
      Language === "english"
        ? textEngOperators
        : Language === "hebrew"
        ? textHebOperators
        : textOperators
    );
  }, [Language]);

  return (
    <>
      <FilterAltIcon
        // color="primary"
        className={classes?.filterIcon}
        onClick={() => {
          setFilterPickerVisible(true);
        }}
      />
      {/* think about unique keys */}
      {!isEmpty(filters) && (
        <Box display="flex" flexWrap="wrap" style={style}>
          {filters.map(({ id, value }, index) => {
            const column = getColumnByAccessor(id);
            if (column?.filter === "checked") {
              return keys(value).map((label) => {
                return (
                  <Chip
                    key={++index}
                    size="small"
                    color="primary"
                    className={classes?.filter}
                    onDelete={() =>
                      clearFilter(
                        id,
                        keys(value)
                          ?.filter((l) => l !== label)
                          .reduce((acc, l) => ({ ...acc, [l]: true }), {})
                      )
                    }
                    label={
                      <div>
                        {column.Header} is{" "}
                        <strong>
                          {id === "Priority"
                            ? prioritiesMapForKeyOpp[label]
                            : id === "urgency"
                            ? urgencyMapForTrends[Language][label]
                            : label}
                        </strong>
                      </div>
                    }
                  />
                );
              });
            }
            if (isBoolean(value)) {
              return (
                <Chip
                  key={index + 100}
                  size="small"
                  color="primary"
                  className={classes?.filter}
                  onDelete={() => clearFilter(id)}
                  label={
                    <div>
                      {column.Header} <strong>{value ? "On" : "Off"}</strong>
                    </div>
                  }
                />
              );
            }
            if (
              !isEmpty(value) &&
              typeof value !== "object" &&
              value !== null
            ) {
              let operator;
              if (value?.includes(FILTER_SEPARATOR)) {
                const [action, val] = value?.split(FILTER_SEPARATOR);
                if (column?.filter === "text") {
                  operator = findTextOperator(action);
                  value = val;
                } else if (column?.filter === "number") {
                  operator = findNumberOperator(action);
                  value = val;
                } else if (column?.filter === "date") {
                  operator = findNumberOperator(action);
                  if (DateFormat === "us") {
                    const euFormat = val?.split("-").reverse();
                    if (parseInt(euFormat[1]) < 10 && euFormat[1]?.includes("0"))
                      euFormat[1] = euFormat[1].replace("0", "");
                    if (parseInt(euFormat[0]) < 10 && euFormat[0]?.includes("0"))
                      euFormat[0] = euFormat[0].replace("0", "");
                    const usFormat = [
                      euFormat[1],
                      euFormat[0],
                      euFormat[2],
                    ].join("/");
                    value = usFormat;
                  } else value = val?.split("-").reverse().join("/");
                }
              }
              return (
                <Chip
                  key={index}
                  size="small"
                  color="primary"
                  className={classes?.filter}
                  onDelete={() => clearFilter(id)}
                  label={
                    <div>
                      {column?.Header} {operator && <strong>{operator}</strong>}{" "}
                      {value}
                    </div>
                  }
                />
              );
            }
            return <div />;
          })}
        </Box>
      )}
    </>
  );
}
