import TextColumnFilter from "../../../Filters/TextColumnFilter";
import NumberColumnFilter from "../../../Filters/NumberColumnFilter";
import CheckedColumnFilter from "../../../Filters/CheckedColumnFilter";

import { sortTypes } from "../../../../utils/sort";
import PercentageColumnFilter from "../../../Filters/PercentageColumnFilter";

export const hebCOLUMNS = [
  {
    Header: "שם מוצר / וריאנט",
    accessor: "ProductName",
    filter: "text",
    Filter: TextColumnFilter,
    canFilter: true,
  },
  {
    Header: 'מק"ט',
    accessor: "Code",
    filter: "text",
    Filter: TextColumnFilter,
    canFilter: true,
  },
  {
    Header: "₪",
    accessor: "Value",
    filter: "number",
    Filter: NumberColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: "יחידות",
    accessor: "PotentialValueInUnits",
    filter: "number",
    Filter: NumberColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: "סוג הזדמנות",
    accessor: "InsightType",
    filter: "checked",
    Filter: CheckedColumnFilter,
    canFilter: true,
    // sortType: sortTypes.new,
    disableSortBy: true,
  },
  {
    Header: "*התקדמות %",
    accessor: "Progress",
    filter: "number",
    Filter: PercentageColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: "מידע מרכזי",
    accessor: "KeyData",
    filter: "text",
    Filter: TextColumnFilter,
    canFilter: true,
  },
];
