import React from "react";
import LoginButton from "../../components/AuthButtons/LoginButton";
import "./LoginPage.css";
import insightingLogo from "../../images/insighting-LOGO-2 (1).png";

function LoginPage() {
  return (
    <>
      <div className="login-page">
        <div className="login-greet">Welcome to Insighting</div>
        <div className="login-button">
          <LoginButton />
        </div>
      </div>
      <img
        className="insighting-logo"
        src={insightingLogo}
        alt="Insighting Logo"
      />
    </>
  );
}

export default LoginPage;
