import React from "react";
import { dictionary } from "../../../constants/dictionary";
import { useSelector } from "react-redux";

const TitleBar = () => {
  const Language = useSelector(
    (state) => state.userSlice.user.metadata.Language
  );
  return (
    <div className="title-bar reports-page-title">
      <div>{dictionary[Language]?.reportsGeneratorPage.title}</div>
      <div className="title-bar-buttons">
        <button style={{ borderBottom: "3px solid black" }}>
          {" "}
          {dictionary[Language]?.reportsGeneratorPage.generateReport}
        </button>
        <button disabled>
          {" "}
          {dictionary[Language]?.reportsGeneratorPage.scheduledReport}
        </button>
      </div>
    </div>
  );
};

export default TitleBar;
