import Box from "@material-ui/core/Box";
import React, { useState, useMemo, useRef } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTable, useSortBy, useFilters } from "react-table";
import { hebCOLUMNS } from "./ColumnsFiles/hebColumns";
import { engCOLUMNS } from "./ColumnsFiles/engColumns";
import FiltersList from "../../Filters/FiltersList";
import { filterTypes, getColumnByAccessor } from "../../../utils/filters";
import FilterPicker from "../../Filters/FilterPicker";
import ListAltIcon from "@mui/icons-material/ListAlt";

import { AdditionalData } from "../../AdditionalData/AdditionalData";
import { insightsMap } from "../../../constants/insights-map";
import OnGoingLight from "../../OnGoingLight/OnGoingLight";
import { dictionary } from "../../../constants/dictionary";
import {
  setCurrencyForTableHeaders,
  usDateFormat,
  getRowsCsv,
  isSorted,
} from "../../../utils/helpers";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import numberUtils from "../../../utils/number";
import { setCustomersKeyOpportunitiesFilters } from "../../../store/features/tableLatestFiltersSlice";
import { setCustomersKeyOpportunitiesSorts } from "../../../store/features/tableLatestSortsSlice";
import { isEmpty } from "lodash";
import { Menu, MenuItem } from "@material-ui/core";
import NestedMenuItem from "material-ui-nested-menu-item";
import { CSVLink } from "react-csv";
import pdfUtils from "../../../utils/pdf";
import "./CustomerKeyOpportunitiesTable.css";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  tableFilters: {
    marginLeft: 20,
    marginBottom: "0.5rem",
    position: "relative",
  },
  filterIcon: {
    marginLeft: 20,
    cursor: "pointer",
  },
  filtersCloseIcon: {
    opacity: 0.82,
    marginBottom: 12,
    cursor: "pointer",
    transition: "opacity 0.4s",
    "&:hover": {
      opacity: 1,
    },
  },
  export: {
    marginLeft: 15,
    marginBottom: 10,
  },
}));

const todaysDate = moment(moment().format("DD/MM/YYYY"), "D/M/YYYY");

export const CustomerKeyOpportunitiesTable = ({
  customerData,
  salesByItem,
  setCoordinates,
  setProductName,
  CustomerName,
  CustomerID,
  AgentName,
}) => {
  const classes = useStyles();
  const [filterPickerVisible, setFilterPickerVisible] = useState(null);
  const [menuPosition, setMenuPosition] = useState(null);
  const [pdfAvailable, setPdfAvailable] = useState(false);
  const history = useHistory();
  const tableRef = useRef(null);

  const dispatch = useDispatch();

  const data = useMemo(() => customerData, [customerData]);

  const userSlice = useSelector((state) => state.userSlice, shallowEqual);
  const custKeyOppsLastUpdate = useSelector(
    (state) => state.globalSlice.custKeyOppsLastUpdate,
    shallowEqual
  );
  const customersKeyOpportunitiesFilters = useSelector(
    (state) => state.tableFiltersSlice.customersKeyOpportunitiesFilters,
    shallowEqual
  );
  const showCustomerPageFilters = useSelector(
    (state) => state.tableFiltersSlice.showCustomerPageFilters,
    shallowEqual
  );
  const customersKeyOpportunitiesSorts = useSelector(
    (state) => state.tableSortsSlice.customersKeyOpportunitiesSorts,
    shallowEqual
  );
  const latestCustomersKeyOpportunitiesSorts = useSelector(
    (state) => state.tableLatestSortsSlice.latestCustomersKeyOpportunitiesSorts,
    shallowEqual
  );
  const latestCustomersKeyOpportunitiesFilters = useSelector(
    (state) =>
      state.tableLatestFiltersSlice.latestCustomersKeyOpportunitiesFilters,
    shallowEqual
  );

  const {
    user: {
      metadata: { Currency, DateFormat, Language },
    },
  } = userSlice;

  const handlePDFExportToClick = () => {
    pdfUtils.download(tableRef.current, `${CustomerID}-key-opportunities`);
    setMenuPosition(null);
  };

  const handleRightClick = (e, rowsLength) => {
    setPdfAvailable(rowsLength < 200 ? true : false);
    if (menuPosition) {
      return;
    }
    e.preventDefault();
    setMenuPosition({
      top: e.clientY,
      left: e.clientX,
    });
  };

  const handleInsight5Click = ({ original: { ItemVariant, Param4 } }) => {
    history.push("customer/details", {
      CustomerName,
      ItemVariant,
      InsightType: "5",
      Param4,
    });
  };

  const handleInsight3Click = (row) => {
    const { original } = row;
    let { ProductName, Param3, Param5, Code, ItemVariant } = original;
    Param3 =
      Param3?.length > 0
        ? Param3?.replace(/[\[\]']+/g, "")
            ?.split(",")
            ?.map((item) => item?.trim())
        : false;

    Param5 =
      Param5?.length > 2
        ? Param5?.replace(/NO__/g, "")
            ?.replaceAll("]", "")
            .substring(2, Param5?.length)
            ?.split(", [")
            .map((item) => {
              let parts = item?.split(",");
              let customerCode = parts[0]
                ?.replaceAll("'", "")
                ?.replaceAll('"', "")
                ?.trim();
              let customerName = parts[1]
                ?.replaceAll("'", "")
                ?.replaceAll('"', "")
                ?.trim();
              let value = numberUtils.numberWithCommas(
                parseInt(parts[2]?.trim())
              );

              return {
                customerCode: customerCode,
                customerName: customerName,
                value: value,
              };
            })
        : [];
    history.push("customer/details", {
      Param3,
      Param5,
      ProductName,
      Code,
      CustomerName,
      CustomerID,
      AgentName,
      InsightType: "3",
      ItemVariant,
    });
  };

  const columns = useMemo(
    () =>
      Language === "hebrew"
        ? setCurrencyForTableHeaders(Currency, hebCOLUMNS, Language)
        : Language === "english"
        ? setCurrencyForTableHeaders(Currency, engCOLUMNS, Language)
        : setCurrencyForTableHeaders(Currency, engCOLUMNS, Language),
    [customerData]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setAllFilters,
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      // initialState: {
      //   sortBy: customersKeyOpportunitiesSorts,
      //   filters: [],
      // },
      autoResetPage: false,
      autoResetExpanded: false,
      autoResetGroupBy: false,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false,
    },
    useFilters,
    useSortBy
  );

  useEffect(
    function onSortsChanged() {
      dispatch(setCustomersKeyOpportunitiesSorts(state.sortBy));
    },
    [state.sortBy, dispatch]
  );

  useEffect(
    function onFiltersChanged() {
      dispatch(setCustomersKeyOpportunitiesFilters(state.filters));
    },
    [state.filters, dispatch]
  );

  useEffect(
    function resetAllSorts() {
      if (
        !showCustomerPageFilters &&
        !isEmpty(customersKeyOpportunitiesSorts)
      ) {
        customersKeyOpportunitiesSorts?.forEach(({ id, value }) => {
          const column = getColumnByAccessor(id, headerGroups[0].headers);
          if (column && column.canSort && column.toggleSortBy) {
            column.toggleSortBy(id);
          }
        });
      } else if (!isEmpty(latestCustomersKeyOpportunitiesSorts)) {
        latestCustomersKeyOpportunitiesSorts?.forEach(({ id, value }) => {
          const column = getColumnByAccessor(id, headerGroups[0].headers);
          if (column && column.canSort && column.toggleSortBy) {
            column.toggleSortBy(id);
          }
        });
      }
    },
    [CustomerID]
  );

  useEffect(
    function applyFilters() {
      if (
        !showCustomerPageFilters &&
        !isEmpty(customersKeyOpportunitiesFilters)
      ) {
        customersKeyOpportunitiesFilters?.forEach(({ id, value }) => {
          const column = getColumnByAccessor(id, headerGroups[0]?.headers);
          if (column && column.canFilter && column.setFilter) {
            column.setFilter(value);
          }
        });
      } else if (
        !showCustomerPageFilters &&
        isEmpty(customersKeyOpportunitiesFilters)
      ) {
        setAllFilters([]);
      } else if (
        showCustomerPageFilters &&
        !isEmpty(latestCustomersKeyOpportunitiesFilters)
      ) {
        latestCustomersKeyOpportunitiesFilters?.forEach(({ id, value }) => {
          const column = getColumnByAccessor(id, headerGroups[0]?.headers);
          if (column && column.canFilter && column.setFilter) {
            column.setFilter(value);
          }
        });
      }
    },
    [data]
  );

  return (
    <>
      <div
        className="customer-key-opportunities-above-table"
        style={{
          direction: `${Language === "hebrew" ? "rtl" : "ltr"}`,
          marginRight: "1.5rem",
        }}
      >
        <h4>
          {dictionary[Language]?.customerKeyOpportunitiesTable?.tableTitle}
        </h4>
        {custKeyOppsLastUpdate && (
          <span className="last-update" style={{ marginRight: "0.5rem" }}>
            {dictionary[Language].lastUpdate} :{" "}
            {DateFormat === "us"
              ? usDateFormat(custKeyOppsLastUpdate)
              : custKeyOppsLastUpdate}
          </span>
        )}
      </div>

      <Box
        display="flex"
        alignItems="center"
        className={classes.tableFilters}
        style={{
          direction: `${Language === "hebrew" ? "rtl" : "ltr"}`,
          marginRight: "1rem",
          marginTop: "0.5rem",
        }}
      >
        <Box display="flex" alignItems="center">
          <FiltersList
            filters={state.filters}
            headers={headerGroups[0].headers}
            setFilterPickerVisible={setFilterPickerVisible}
          />
          <FilterPicker
            columns={columns}
            visible={filterPickerVisible}
            headers={headerGroups[0].headers}
            setVisibility={setFilterPickerVisible}
          />
        </Box>
      </Box>

      <div
        style={{ direction: Language === "hebrew" ? "rtl" : "ltr" }}
        id="customer-key-opportunities-table"
        className="customer-key-opportunities-table-div"
      >
        <table
          ref={tableRef}
          {...getTableProps()}
          className="customer-key-opportunities-table"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>{isSorted(column)}</span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps}>
            <Menu
              open={!!menuPosition}
              onClose={() => {
                setMenuPosition(null);
              }}
              anchorReference="anchorPosition"
              anchorPosition={menuPosition}
            >
              <NestedMenuItem
                label={
                  dictionary[Language]?.salesReviewTable.menuOptions.exportTo
                }
                parentMenuOpen={!!menuPosition}
              >
                <MenuItem id="csv">
                  <CSVLink
                    target="_blank"
                    data={getRowsCsv(rows, columns)}
                    filename={`customer_key_opportunities.csv`}
                    style={{ color: "black" }}
                    onClick={() => {
                      setMenuPosition(null);
                    }}
                  >
                    CSV
                  </CSVLink>
                </MenuItem>
                <MenuItem
                  id="pdf"
                  onClick={pdfAvailable ? handlePDFExportToClick : null}
                  style={{ color: pdfAvailable ? "black" : "grey" }}
                >
                  PDF
                </MenuItem>
              </NestedMenuItem>
            </Menu>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  onContextMenu={(e) => handleRightClick(e, rows?.length)}
                >
                  {row.cells.map((cell) => {
                    if (cell.column.id === "ProductName") {
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{ direction: "ltr" }}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    }
                    if (
                      (cell.column.Header === "Key Data" ||
                        cell.column.Header === "מידע מרכזי") &&
                      (cell.row.values.InsightType?.split("|")[0] ===
                        insightsMap[Language]?.Insight5 ||
                        cell.row.values.InsightType?.split("|")[0] ===
                          insightsMap[Language]?.Insight5During)
                    ) {
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            // border:"none"
                          }}
                        >
                          <ListAltIcon
                            style={{
                              cursor: "pointer",
                              marginRight: "0.5rem",
                              color: "#73aafc",
                            }}
                            onClick={() => handleInsight5Click(row)}
                            fontSize="small"
                          />
                          {cell.render("Cell")}
                        </td>
                      );
                    }
                    if (
                      (cell.column.Header === "Key Data" ||
                        cell.column.Header === "מידע מרכזי") &&
                      (cell.row.values.InsightType?.split("|")[0] ===
                        insightsMap[Language]?.Insight1 ||
                        cell.row.values.InsightType?.split("|")[0] ===
                          insightsMap[Language]?.Insight1During)
                    ) {
                      return (
                        <td {...cell.getCellProps()}>
                          <AdditionalData
                            cell={cell}
                            salesByItem={salesByItem}
                            setCoordinates={setCoordinates}
                            setProductName={setProductName}
                          />
                        </td>
                      );
                    }
                    if (
                      (cell.column.Header === "Key Data" ||
                        cell.column.Header === "מידע מרכזי") &&
                      cell.row.values.InsightType?.split("|")[0] ===
                        insightsMap[Language]?.Insight3
                    ) {
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            // border:"none"
                          }}
                        >
                          <ListAltIcon
                            style={{
                              cursor: "pointer",
                              marginRight: "0.5rem",
                              color: "#73aafc",
                            }}
                            onClick={() => handleInsight3Click(cell.row)}
                            fontSize="small"
                          />
                          {cell.render("Cell")}
                        </td>
                      );
                    }
                    if (
                      cell.column.Header === "Insight Type" ||
                      cell.column.Header === "סוג הזדמנות"
                    ) {
                      return (
                        <td {...cell.getCellProps()} style={{}}>
                          <span style={{ margin: "0.2rem 0 0 0.5rem" }}>
                            {cell.value?.split("|")[0]}
                          </span>
                          <OnGoingLight
                            row={row}
                            todaysDate={todaysDate}
                            language={Language}
                          />
                        </td>
                      );
                    }
                    if (
                      cell.column.Header === "Progress* %" ||
                      cell.column.Header === "*התקדמות %"
                    ) {
                      if (isNaN(parseFloat(cell.value))) {
                        return (
                          <td key={cell.column.id} {...cell.getCellProps()}>
                            -
                          </td>
                        );
                      } else {
                        let progressValue = parseInt(cell.value);
                        let percentage = Math.min(
                          Math.max(progressValue, 0),
                          999
                        ); // Cap negative values at 0
                        const displayPercentage = Math.min(percentage, 100); // Cap displayed percentage at 100

                        const progressBarWidth = `${displayPercentage}%`;

                        let backgroundColor =
                          displayPercentage > 70
                            ? "#24b474"
                            : displayPercentage > 25
                            ? "#ffc408"
                            : displayPercentage > 0
                            ? "#fd6767"
                            : "transparent";

                        return (
                          <td
                            key={cell.column.id}
                            {...cell.getCellProps()}
                            className="overview-progress-cell"
                            style={{
                              width: "7rem",
                              border: "1px solid #ddd",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  border: "1px solid #D1D1D1",
                                  height: "1rem",
                                  borderRadius: "50px",
                                  width: "100%",
                                  marginLeft: "5px",
                                  position: "relative",
                                }}
                              >
                                <div
                                  style={{
                                    width: progressBarWidth,
                                    height: "100%",
                                    backgroundColor: backgroundColor,
                                    borderRadius: "50px",
                                    position: "absolute",
                                    left: 0,
                                    top: 0,
                                  }}
                                />
                              </div>
                              <span style={{ margin: "0 5px" }}>
                                {percentage}%
                              </span>
                            </div>
                          </td>
                        );
                      }
                    }
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="progress-note">
        * progress is calculated including orders
      </div>
    </>
  );
};
