import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SimilarCustomersSalesTable } from "../../components/Tables/SimilarCustomersSalesTable/SimilarCustomersSalesTable";
import { Subtitle } from "../../components/Subtitle/Subtitle";
import "./CustomerDetailsPage.css";
import { PurchaseHistoryTable } from "../../components/Tables/PurchaseHistoryTable/PurchaseHistoryTable";
import { usDateFormat } from "../../utils/helpers";
import numberUtils from "../../utils/number";

const detailsIntro = (Language, period, CustomerName) => {
  if (Language === "hebrew")
    return `רכישות של לקוחות דומים ל ${CustomerName} בין ${period[0]} ל ${period[1]}`;
  if (Language === "english")
    return `Purchases of similar customers to ${CustomerName} between ${period[0]} to ${period[1]}`;
  else return null;
};

const date = new Date();
const currentYear = date.getFullYear().toString();
const lastYear = (date.getFullYear() - 1).toString();
const currentMonth = date.getMonth() + 1;

export const CustomerDetailsPage = () => {
  const {
    userSlice: {
      user: { metadata },
    },
    dataSlice: { productPurchasedTableDataInStore },
  } = useSelector((state) => state);
  let { Language, DateFormat } = metadata;
  const [purchasedHistoryData, setPurchasedHistoryData] = useState([]);

  const history = useHistory();
  const {
    Param3,
    Param4,
    Param5,
    ProductName,
    Code,
    InsightType,
    CustomerName,
    ItemVariant,
  } = history.location.state;

  useEffect(
    function setDataForPurchaseHistory() {
      if (InsightType === "5" && productPurchasedTableDataInStore?.length > 0) {
        const codesWithSelectedVarID = Array.from(
          new Set(
            productPurchasedTableDataInStore
              ?.filter(
                (i) =>
                  ((Param4.length && Param4.includes(i.ItemCatalogNumber)) ||
                    i.VarID === ItemVariant) &&
                  (i.YearOfData === lastYear || i.YearOfData === currentYear)
              )
              .map((i) => i.ItemCatalogNumber)
          )
        );
        const relevantGroupItems = productPurchasedTableDataInStore?.filter(
          (i) => codesWithSelectedVarID?.includes(i.ItemCatalogNumber)
        );
        const currentYearItems = relevantGroupItems?.filter(
          (i) => i.YearOfData === currentYear
        );
        const lastYearItems = relevantGroupItems?.filter(
          (i) => i.YearOfData === lastYear
        );

        const getCurrentMonthSales = (saleReview) => {
          if (saleReview)
            return parseInt(saleReview[`Sales_${currentMonth}`]) || 0;
          else return "-";
        };

        const getPeriodResult = (months, saleReview, lastYearSaleReview) => {
          let count = 0;
          let sum = 0;
          if (currentMonth === 1) {
            while (count < months) {
              if (
                !lastYearSaleReview &&
                isNaN(parseInt(lastYearSaleReview[`Sales_${12 - (count + 1)}`]))
              ) {
                sum += 0;
                count++;
              } else {
                sum += parseInt(
                  lastYearSaleReview[`Sales_${12 - (count + 1)}`]
                );
                count++;
              }
            }
            return sum;
          } else {
            while (currentMonth - (1 + count) > 0 && count < months) {
              if (
                !saleReview ||
                isNaN(
                  parseInt(saleReview?.[`Sales_${currentMonth - (1 + count)}`])
                )
              ) {
                sum += 0;
                count++;
              } else {
                sum += parseInt(
                  saleReview?.[`Sales_${currentMonth - (1 + count)}`]
                );
                count++;
              }
            }
            if (count === months) {
              return sum;
            } else {
              count = months - count;
              while (count > 0 && count < 12) {
                if (
                  !lastYearSaleReview ||
                  isNaN(
                    parseInt(lastYearSaleReview?.[`Sales_${12 - count + 1}`])
                  )
                ) {
                  sum += 0;
                  count++;
                } else {
                  sum += parseInt(
                    lastYearSaleReview?.[`Sales_${12 - count + 1}`]
                  );
                  count--;
                }
              }
              return sum;
            }
          }
        };

        if (relevantGroupItems?.length > 0) {
          const dataForGroupHistory = [];
          const existingCodes = new Set();

          relevantGroupItems?.forEach((i) => {
            const saleReview =
              i.YearOfData === currentYear
                ? i
                : currentYearItems.find(
                    (item) => item.ItemCatalogNumber === i.ItemCatalogNumber
                  );
            const lastYearSaleReview =
              i.YearOfData === lastYear
                ? i
                : lastYearItems.find(
                    (item) => item.ItemCatalogNumber === i.ItemCatalogNumber
                  );

            if (!existingCodes.has(i.ItemCatalogNumber)) {
              const p3m = getPeriodResult(3, saleReview, lastYearSaleReview);
              const p6m = getPeriodResult(6, saleReview, lastYearSaleReview);
              const p6mToP3m = isNaN(p6m - p3m) ? "-" : p6m - p3m;
              const lastOrderDate = saleReview?.LastOrderDate
                ? saleReview?.LastOrderDate?.replaceAll("_", "/")
                : lastYearSaleReview?.LastOrderDate
                ? lastYearSaleReview?.LastOrderDate?.replaceAll("_", "/")
                : "-";
              const obj = {
                productName: saleReview ? saleReview.ItemName : i.ItemName,
                code: i.ItemCatalogNumber,
                currentMonth: numberUtils.numberWithCommas(
                  getCurrentMonthSales(saleReview)
                ),
                p3m: p3m !== 0 ? numberUtils.numberWithCommas(p3m) : "-",
                p6mToP3m:
                  p6mToP3m !== 0 ? numberUtils.numberWithCommas(p6mToP3m) : "-",
                changeBetweenPeriods:
                  p3m !== 0 && p6mToP3m !== 0
                    ? ((p3m / p6mToP3m - 1) * 100).toFixed(1) + "%"
                    : p3m === 0 && p6mToP3m !== 0
                    ? -100.0 + "%"
                    : "-",
                LastOrderDate:
                  DateFormat === "us"
                    ? usDateFormat(lastOrderDate)
                    : lastOrderDate,
                Probability: saleReview?.Probability
                  ? saleReview?.Probability
                  : lastYearSaleReview?.Probability
                  ? lastYearSaleReview?.Probability
                  : "-",
              };

              dataForGroupHistory.push(obj);
              existingCodes.add(i.ItemCatalogNumber);
            }
          });

          setPurchasedHistoryData(
            dataForGroupHistory?.filter(
              (i) =>
                i.p3m !== "-" || i.p6mToP3m !== "-" || i.currentMonth !== "-"
            )
          );
        }
      }
    },
    [InsightType, productPurchasedTableDataInStore]
  );

  return (
    <div>
      <Subtitle />
      {InsightType === "3" ? (
        <>
          {!Param5?.length >= 1 && Param3 ? (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "30%",
                transform: "translate(0, -50%)",
                padding: "10px",
              }}
            >
              {Language === "hebrew" ? "אין מידע זמין" : "No Data Available"}
            </div>
          ) : (
            <div
              className="details-page"
              style={{
                alignItems: Language === "hebrew" ? "flex-end" : "flex-start",
              }}
            >
              <h4>
                {ProductName} , {Code}
              </h4>
              <div
                style={{
                  direction: `${Language === "hebrew" ? "rtl" : "ltr"}`,
                }}
              >
                {detailsIntro(Language, Param3, CustomerName)}
              </div>
              <SimilarCustomersSalesTable similarCustomersData={Param5} />
            </div>
          )}
        </>
      ) : InsightType === "5" ? (
        purchasedHistoryData?.length === 0 ? (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "30%",
              transform: "translate(0, -50%)",
              padding: "10px",
            }}
          >
            {Language === "hebrew" ? "אין מידע זמין" : "No Data Available"}
          </div>
        ) : (
          <div
            style={{
              direction: `${Language === "hebrew" ? "rtl" : "ltr"}`,
            }}
          >
            <PurchaseHistoryTable
              purchasedHistoryData={purchasedHistoryData}
              CustomerName={CustomerName}
              ItemVariant={ItemVariant}
            />
          </div>
        )
      ) : (
        <div> 404 </div>
      )}
    </div>
  );
};
