import React, {  useMemo, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useFilters, useSortBy, useTable } from "react-table";
import {
  getRowsCsv,
  isSorted,
  setCurrencyForTableHeaders,
} from "../../../utils/helpers";
import { engCOLUMNS } from "./ColumnsFiles/engColumns";
import { hebCOLUMNS } from "./ColumnsFiles/hebColumns";
import { filterTypes } from "../../../utils/filters";
import { Box } from "@material-ui/core";
import ExportTableMenu from "../../Export/ExportTableMenu";
import FiltersList from "../../Filters/FiltersList";
import FilterPicker from "../../Filters/FilterPicker";
import "./SimilarCustomersSalesTable.css";

export const SimilarCustomersSalesTable = ({ similarCustomersData }) => {
  const [filterPickerVisible, setFilterPickerVisible] = useState(null);
  const userSlice = useSelector(state => state.userSlice,shallowEqual)

  const {
    user: {
      metadata: { Currency, Language },
    },
  } = userSlice;
  const columns = useMemo(
    () =>
      Language === "hebrew"
        ? setCurrencyForTableHeaders(Currency, hebCOLUMNS, Language)
        : Language === "english"
        ? setCurrencyForTableHeaders(Currency, engCOLUMNS, Language)
        : setCurrencyForTableHeaders(Currency, engCOLUMNS, Language),
    [Currency, Language]
  );

  const data = useMemo(() => similarCustomersData, [similarCustomersData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: {
        sortBy: [{ id: "value", desc: true }],
      },
      autoResetPage: false,
      autoResetExpanded: false,
      autoResetGroupBy: false,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false,
    },
    useFilters,
    useSortBy
  );

  //   useEffect(
  //     function onFiltersChanged() {
  //       dispatch(setProductPurchasedTableFilters(state.filters));
  //     },
  //     [state.filters, dispatch]
  //   );

  //   useEffect(
  //     function applyFilters() {
  //       if (!isEmpty(productPurchasedTableFilters)) {
  //         productPurchasedTableFilters?.forEach(({ id, value }) => {
  //           const column = getColumnByAccessor(id, headerGroups[0]?.headers);
  //           if (column && column.canFilter && column.setFilter) {
  //             column.setFilter(value);
  //           }
  //         });
  //       }
  //     },
  //     [location, data, headerGroups, productPurchasedTableFilters]
  //   );

  return (
    <div>
      <Box
        display="flex"
        alignitems="center"
        marginTop="1rem"
        style={{ direction: `${Language === "hebrew" ? "rtl" : "ltr"}` }}
      >
        <ExportTableMenu
          csv={{
            data: getRowsCsv(rows, columns),
            file: "similar_customers",
          }}
          pdf={{
            el: "#similar-customers-sales-table",
            file: "similar_customers",
          }}
        />
        <FiltersList
          filters={state.filters}
          headers={headerGroups[0].headers}
          setFilterPickerVisible={setFilterPickerVisible}
        />
        <FilterPicker
          columns={columns}
          visible={filterPickerVisible}
          headers={headerGroups[0].headers}
          setVisibility={setFilterPickerVisible}
        />
      </Box>
      <div
        id="similar-customers-sales-table"
        className="similar-customers-sales-table-div"
        style={{
          direction: `${Language === "hebrew" ? "rtl" : "ltr"}`,
        }}
      >
        <table {...getTableProps()} className="similar-customers-sales-table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <span> {isSorted(column)}</span>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} style={{ direction: "ltr" }}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
