import React from "react";
import { dictionary } from "../../../constants/dictionary";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function ReportDetailsBottomButtons() {
  const Language = useSelector(
    (state) => state.userSlice.user.metadata.Language
  );
  const history = useHistory();

  return (
    <div className="bottom-buttons-container">
      <button className="clear-all-button " onClick={() => history.goBack()}>
        {dictionary[Language]?.reportsGeneratorPage.backToEdit}
      </button>
      {/* <div className="bottom-buttons ">
        <button
          style={{
            background: false ? "#D8D8D8" : "black",
          }}
        >
          {dictionary[Language]?.reportsGeneratorPage.generate}
        </button>
      </div> */}
    </div>
  );
}

export default ReportDetailsBottomButtons;
