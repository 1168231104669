import Box from "@material-ui/core/Box";
import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useFilters, useSortBy, useTable } from "react-table";

import { filterTypes } from "../../../utils/filters";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

import {
  setCurrencyForTableHeaders,
  isSorted,
  usDateFormat,
} from "../../../utils/helpers";
import { engCOLUMNS } from "./ColumnsFiles/engColumns";
import { hebCOLUMNS } from "./ColumnsFiles/hebColumns";
import "./RevNavigatorDetailsTable.css";

import numberUtils from "../../../utils/number";
import {
  setOverviewMenuPosition,
  setSelectedRowForMenu,
} from "../../../store/features/customersOverviewSlice";
import {
  setItemByCustTableData,
  setRevNavTableMode,
} from "../../../store/features/revNavigatorSlice";
import { dictionary } from "../../../constants/dictionary";
import { LoadingAnimation } from "../../LoadingAnimation/LoadingAnimation";
import RevNavigatorDetailsAboveTable from "./RevNavigatorDetailsAboveTable";
import RevNavigatorDetailsFilters from "./RevNavigatorDetailsFilters";
import RevNavigatorDetailsMenu from "./RevNavigatorDetailsMenu";

function RevNavigatorDetailsTable({ ItemCatalogNumber, ItemName, CurrStock }) {
  const staticAppDate = useSelector((state) => state.globalSlice.staticAppDate);
  const userSlice = useSelector((state) => state.userSlice, shallowEqual);
  const revNavSelectedPeriod = useSelector(
    (state) => state.revNavigatorSlice.revNavSelectedPeriod,
    shallowEqual
  );
  const uniqueAgentIDs = useSelector(
    (state) => state.revNavigatorSlice.uniqueAgentIDs,
    shallowEqual
  );
  const itemByCustRes = useSelector(
    (state) => state.revNavigatorSlice.itemByCustRes,
    shallowEqual
  );
  const itemByCustTableData = useSelector(
    (state) => state.revNavigatorSlice.itemByCustTableData,
    shallowEqual
  );
  const revNavAgentsMapByAgentID = useSelector(
    (state) => state.revNavigatorSlice.revNavAgentsMapByAgentID,
    shallowEqual
  );
  const listOfSelectedAgents = useSelector(
    (state) => state.revNavigatorSlice.listOfSelectedAgents,
    shallowEqual
  );
  const isAllAgentsSelected = useSelector(
    (state) => state.revNavigatorSlice.isAllAgentsSelected,
    shallowEqual
  );
  let revNavTableMode = useSelector(
    (state) => state.revNavigatorSlice.revNavTableMode
  );

  const {
    user: {
      metadata: { Currency, Language, DateFormat },
    },
  } = userSlice;

  const dispatch = useDispatch();

  const columns = useMemo(() => {
    return Language === "hebrew"
      ? setCurrencyForTableHeaders(Currency, hebCOLUMNS, Language)
      : Language === "english"
      ? setCurrencyForTableHeaders(Currency, engCOLUMNS, Language)
      : setCurrencyForTableHeaders(Currency, engCOLUMNS, Language);
  }, [Currency, Language]);

  const data = useMemo(() => {
    return itemByCustTableData;
  }, [itemByCustTableData]);

  const groupedHeader = useRef(null);
  const tableRef = useRef(null);
  const [validAgents, setValidAgents] = useState([]);

  //   function getAgentNameById(agentID) {
  //     const agent = revNavAgentsMap.find((agent) => agent.agentID === agentID);
  //     return agent ? agent.agentName : null;
  //   }

  //   function renderInsightTypeCell(row, cell) {
  //     return (
  //       <td
  //         key={cell.column.id}
  //         style={{ textAlign: Language === "hebrew" ? "end" : "start" }}
  //         {...cell.getCellProps()}
  //       >
  //         <InsightTypesLogos row={row} language={Language} />
  //         <OnGoingLight row={row} todaysDate={todaysDate} language={Language} />
  //       </td>
  //     );
  //   }

  function renderLastOrderCell(row, cell) {
    return (
      <td key={cell.column.id} {...cell.getCellProps()}>
        {DateFormat === "us" ? usDateFormat(cell.value) : cell.render("Cell")}
      </td>
    );
  }

  function renderLTRValueCell(row, cell) {
    return (
      <td
        key={cell.column.id}
        {...cell.getCellProps()}
        style={{ direction: "ltr", width: "9rem" }}
      >
        {cell.render("Cell")}
      </td>
    );
  }

  const handleRightClick = (e, row) => {
    const { original } = row;
    dispatch(setSelectedRowForMenu(original));
    dispatch(
      setOverviewMenuPosition({
        top: e.clientY,
        left: e.clientX,
      })
    );
    e.preventDefault();
    e.stopPropagation();
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      //   initialState: {
      //     sortBy: revNavigatorDetailsTableSorts,
      //     filters: revNavigatorDetailsTableFilters,
      //   },
      autoResetPage: false,
      autoResetExpanded: false,
      autoResetGroupBy: false,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false,
    },
    useFilters,
    useSortBy
  );

  useEffect(() => {
    if (itemByCustRes) {
      let agentsListToFilterBy = isAllAgentsSelected
        ? uniqueAgentIDs
        : listOfSelectedAgents;
      setValidAgents(agentsListToFilterBy);
      agentsListToFilterBy =
        agentsListToFilterBy.length === 0
          ? JSON.parse(sessionStorage.getItem("agentsListToFilterBy"))
          : agentsListToFilterBy;

      sessionStorage.setItem(
        "agentsListToFilterBy",
        JSON.stringify(agentsListToFilterBy)
      );

      if (revNavTableMode === "L") {
        revNavTableMode = sessionStorage.getItem(
          "revNavTableMode",
          revNavTableMode
        );
      } else {
        sessionStorage.setItem("revNavTableMode", revNavTableMode);
      }

      let tableData = itemByCustRes?.filter((i) =>
        agentsListToFilterBy?.includes(i.AgentID)
      );
      tableData = tableData.map((i) => {
        let newItem = { ...i };
        if (newItem[revNavSelectedPeriod]?.includes("[")) {
          newItem[revNavSelectedPeriod] = newItem[revNavSelectedPeriod]
            ?.slice(1, -1) // Remove the brackets
            .split(",") // Split the string into an array
            .map((item) => item.trim()); // Trim each item in the array
        }
        return newItem;
      });
      tableData = tableData?.filter(
        (i) =>
          i[revNavSelectedPeriod] &&
          i[revNavSelectedPeriod][2] === revNavTableMode
      );
      tableData = tableData.map((i) => ({
        CustomerID: i.CustomerID,
        customerName: i.CustomerName,
        salesAgent: revNavAgentsMapByAgentID[i.AgentID],
        periodValue: isNaN(i[revNavSelectedPeriod][1])
          ? "-"
          : numberUtils.numberWithCommas(parseInt(i[revNavSelectedPeriod][1])),
        Units: isNaN(i[revNavSelectedPeriod][0])
          ? "-"
          : numberUtils.numberWithCommas(parseInt(i[revNavSelectedPeriod][0])),
        LastOrderDate: i.LastPurchaseDate?.replaceAll("_", "/"),
        opportunities: "TBD",
      }));

      dispatch(setRevNavTableMode(revNavTableMode));
      dispatch(setItemByCustTableData(tableData));
    }
  }, [itemByCustRes]);

  return validAgents?.length === 0 && itemByCustTableData?.length === 0 ? (
    <LoadingAnimation />
  ) : (
    <div
      // key={JSON.stringify(revNavigatorTableFilters)}
      style={{
        marginLeft: "0.5rem",
        direction: Language === "hebrew" ? "rtl" : "ltr",
        // width: "75%",
      }}
    >
      <RevNavigatorDetailsAboveTable
        rows={rows}
        ItemCatalogNumber={ItemCatalogNumber}
        ItemName={ItemName}
        CurrStock={CurrStock}
      />
      <Box
        display="flex"
        alignItems="center"
        style={{
          direction: `${Language === "hebrew" ? "rtl" : "ltr"}`,
          marginRight: "3.2rem",
        }}
      >
        <Box display="flex" alignItems="center" style={{ marginLeft: 10 }}>
          {/* <CustomersOverviewGroupFilter
            displayAddGroupFilter={displayAddGroupFilter}
            setDisplayGroupFilter={setDisplayGroupFilter}
          /> */}
          <RevNavigatorDetailsFilters
            filters={state.filters}
            headers={headerGroups[0].headers?.filter((i) => i.id !== "details")}
            columns={columns}
          />
        </Box>
      </Box>
      <div
        ref={tableRef}
        id="customers-overview-table"
        className="customers-overview-table-div"
        style={{ width: "75vw", maxHeight: "75vh" }}
      >
        <table
          {...getTableProps()}
          className="customers-overview-table"
          style={{ width: "99%" }}
        >
          <thead>
            {headerGroups.map((headerGroup) => {
              return (
                <tr {...headerGroup.getHeaderGroupProps()} ref={groupedHeader}>
                  {headerGroup.headers.map((column) => {
                    return (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        id={column.id}
                      >
                        {column.render("Header")}
                        <span>{isSorted(column)}</span>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          {data?.length === 0 ? (
            <div style={{ textAlign: "center", padding: "1rem 0rem" }}>
              {dictionary?.[Language]?.salesReviewTable.noData}
            </div>
          ) : (
            <tbody {...getTableBodyProps}>
              <RevNavigatorDetailsMenu
                tableRef={tableRef}
                rows={rows}
                headerGroups={headerGroups}
                csvHeaders={columns}
                revNavFilters={state.filters}
                revNavSorts={state.sortBy}
              />

              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    key={row.id}
                    {...row.getRowProps()}
                    onContextMenu={(e) => handleRightClick(e, row)}
                  >
                    {row.cells.map((cell) => {
                      const renderDefaultCell = () => (
                        <td key={cell.column.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );

                      switch (cell.column.id) {
                        case "periodValue":
                        case "Units":
                          return renderLTRValueCell(row, cell);
                        case "LastOrderDate":
                          return renderLastOrderCell(row, cell);
                        default:
                          return renderDefaultCell(row, cell);
                      }
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
}
export default memo(RevNavigatorDetailsTable);
