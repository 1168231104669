const date = new Date();

export const dictionary = {
  hebrew: {
    agent: "סוכנ/ת",
    lastUpdate: "עודכן לאחרונה",
    navHeaders: {
      backButton: "חזור",
      greet: (given_name) => `${given_name} שלום`,
      searchPlaceHolder: "הקלד שם לקוח או מספר לקוח לקבלת סקירה מלאה",
    },
    dropdownOptions: {
      logout: "התנתק",
      support: "עזרה",
      home: "בית",
    },
    keyOpportunitiesTable: {
      tableTitle: "הזדמנויות מרכזיות",
      tableHeaders: {
        customerCode: "מספר לקוח",
        customerName: "שם לקוח",
        salesPerson: "סוכן",
        insightType: "סוג ההזדמנות",
        value: "שווי מוערך",
        progress: "סטטוס התקדמות",
        priority: "חשיבות",
      },
      menuOptions: {
        customerPage: "...דף לקוח",
        detailsPage: "...פרטים",
        priority: "שנה עדיפות ל",
        exportTo: "יצוא ל",
        high: "גבוהה",
        regular: "רגילה",
        low: "מבוטל",
      },
      noData: "המערכת בתהליך ניתוח הזדמנויות חדשות... צפי לסיום ב2 לחודש",
      noData2: "אין מידע זמין",
    },
    opportunitiesAnalysisByAgent: {
      graphTitle: "הזדמנויות לפי סוכן",
    },
    salesReviewTable: {
      tableTitle: "סקירת מכירות",
      menuOptions: {
        period: "תקופה",
        thisMonth: "חודש נוכחי",
        lastMonth: "חודש קודם",
        yearToDate: "מתחילת השנה",
        exportTo: "יצוא ל",
      },
      noData: "אין מידע זמין",
    },
    customerKeyOpportunitiesTable: {
      tableTitle: "הזדמנויות מרכזיות",
    },
    purchaseHistoryTable: {
      subtitle: "היסטוריית מכירות לפי מוצר",
    },
    productPurchasedTable: {
      tableTitle: "מכירות לפי מוצר",
      probabilityOptions: {
        New: "חדש",
        Low: "נמוכה",
        Med: "בינונית",
        High: "גבוהה",
        NA: "-",
      },
      periodButtons: {
        thisMonth: "חודש נוכחי",
        lastMonth: "חודש שעבר",
        yearToDate: "שנה נוכחית",
      },
      analyze: "ניתוח",
    },
    productSalesGraph: {
      graphTitle: "מכירות",
    },
    CustomerSalesOverviewGraph: {
      graphTitle: "סך מכירות לפי חודש",
    },
    CustomersOverviewTable: {
      tableTitle: "סקירת לקוחות",
      tableHeaders: {
        customerCode: "מספר לקוח",
        customerName: "שם לקוח",
        salesPerson: "סוכן",
        lastOrder: "הזמנה אחרונה",
        trend: "מגמה",
        gix: "GIX",
        progressExpectation: "",
        urgency: "דחיפות",
        priority: "עדיפות",
        high: "גבוהה",
        regular: "רגילה",
        low: "נמוכה",
      },
      menuOptions: {
        trendPeriod: "תקופת מגמה",
        current: "נוכחי",
        lastYear: "שנה קודמת",
        calcProgressVs: "התקדמות ביחס ל",
        lastMonth: "חודש קודם",
        yearAgo: "שנה קודמת",
        avgP3M: "ממוצע 3 חודשים",
        avgPurchaseP3M: "ממוצע רכישות 3 חודשים",
        showGraph: "הצג גרף",
        customerPage: "...דף לקוח",
        detailsPage: "...פרטים",
        priority: "שנה עדיפות ל",
        high: "גבוהה",
        regular: "רגילה",
        low: "נמוכה",
        exportTo: "יצוא ל",
      },
      filterOptions: {
        trendCustomer: "טרנד - לקוח",
        trendGroup: "טרנד - קבוצה",
        salesCurrentPeriod: "מכירות - תקופה נוכחית",
        salesPreviousPeriod: "מכירות - תקופה קודמת",
        salesProgress: "מכירות - התקדמות",
      },
      urgencyOptions: {
        high: "גבוה",
        medium: "בינוני",
        low: "נמוך",
      },
      sumsText: {
        numberOfCustomers: "לקוחות",
        progress: "התקדמות",
        openOrders: "הזמנות פתוחות",
        currentMonth: `מכירות ${date.getMonth() + 1}/${
          date.getFullYear() % 2000
        }`,
      },
    },
    growthAnalysisGraph: {
      title: "ניתוח השינוי במכירות ביחס לתקופה מקבילה",
      periodSelection: {
        P3M: "ממוצע 3 חוד׳",
      },
      legend: {
        BaseSales: "שינוי במכירות בסיס",
        DistributionGains: "הרחבת הפצה לקוחות קיימים",
        DistributionLosses: "צמצום פריטים לקוחות קיימים",
        LostCustomers: "לקוחות שלא קנו",
        NewCustomers: "לקוחות חדשים/חוזרים",
        Total: "סה״כ צמיחה",
      },
    },
    opportunityOptions: {
      insight1Agg: "צמצום פריטים",
      insight2Agg: "אין הזמנות",
      insight3Agg: "פריטים מומלצים",
      insight4DuringAgg: "מכירות נמוכות",
      insight5Agg: "צמצום קטגוריות",
    },
    CustomersOverviewGraph: {
      graphTitle: "מכירות 12 חודשים בהשוואה לשנה קודמת",
    },
    opportunityValueSplit: {
      graphTitle: "התפלגות הזדמנויות",
    },
    customerOverviewSalesForecast: {
      title: "תחזית מכירות חודש נוכחי",
      purchaseProbabilityTitle: "סבירות לרכישה ",
      forecastTitle: "תחזית",
    },
    reportsTable: {
      tableTitle: "דו״ח הזדמנויות",
    },
    analyzeBox: {
      title: "ניתוח",
      dimension: "מכירות לפי",
      units: "יחידות",
      reportingPeriod: "תקופת ניתוח",
      clickHere: "לחץ כאן",
      compareTo: "השוואה ל",
      apply: "",
    },
    hierarchyBox: {
      title: "סינון קבוצות",
      subtitle: "בחרו את התנאים לקבוצת הסינון",
      apply: "בצע",
      and: "וגם",
      or: "או",
      addCondition: "הוספת תנאי",
      hierarchyPlaceHolder: "בחר קבוצה",
      hierarchyValuesPlaceHolder: "בחר ערכים לקבוצה",
    },
    filterPicker: {
      filterRows: "סינון שורות",
      column: "עמודה",
      operator: "אופרטור",
      value: "ערך",
      contains: "מכיל",
      notContains: "לא מכיל",
      equals: "שווה ל",
      startsWith: "מתחיל ב",
      endsWith: "מסתיים ב",
      addFilter: "הוסף סינון...",
    },
    greenDiv: {
      customersWith: "לקוחות עם",
      salesPotential: "פוטנציאל מכירות",
      activeInsightsWorth: "הזדמנויות זמינות בשווי",
    },
    hierarchyButton: {
      addFilterText: "סנן לפי קבוצה",
      removeFilterText: "מסונן לפי קבוצה",
    },
    revNav_MainGraph: {
      legend: {
        increase: "עלייה",
        decrease: "ירידה",
        total: "סה״כ",
      },
      agentsSelector: {
        placeHolder: "סוכנים",
        title: "בחר סוכנים",
        selectAll: "בחר הכל",
      },
      preSelectorText: {
        timeFrame: "תקופת זמן",
        for: "עבור",
      },
    },
    RevNavigatorTable: {
      stock: "מלאי:",
      tableTitle: "לקוחות שלא קנו",
      tableTitle2: {
        L: "לקוחות שלא קנו",
        N: "לקוחות חדשים/חוזרים",
        B: "שינוי במכירות בסיס",
        DG: "הרחבת הפצה לקוחות קיימים",
        DL: "צמצום פריטים לקוחות קיימים",
        Total: "סה״כ צמיחה",
      },
      sumsText: {
        products: "מוצרים",
        customers: "לקוחות",
      },
    },
    reportsGeneratorPage: {
      title: "דוחות",
      report: "דוח",
      reportTypes: {
        byCustomer: "לפי לקוח",
        byCustomerItem: "לפי לקוח / מוצר",
        byItem: "לפי מוצר",
        byItemAgent: "לפי מוצר / סוכן",
        byAgent: "לפי סוכן",
      },
      reportTypesArray: [
        "לפי לקוח",
        "לפי לקוח / מוצר",
        "לפי מוצר",
        "לפי מוצר / סוכן",
        "לפי סוכן",
      ],
      // generateReportInstruction: ``,
      generateReport: "יצירת דוח",
      scheduledReport: "",
      preGeneratingMessage: "בחר סוג דוח מהסרגל הצדדי כדי להתחיל",
      clearAll: "נקה הכל",
      // saveReport: "שמירת דוח",
      backToEdit: "חזרה לעריכה",
      additionalInfoPlaceHolder: "בחר",
      generate: "יצירת דוח",
      predefinedReport: "דוחות מוגדרים מראש",
      dataType: "סוג נתונים",
      reportSettings: "הגדרות דוח",
      reportFiltersHeaders: {
        reportPeriod: "תקופה",
        valuesToDisplay: "סוגי נתונים",
        additionalInfo: "מסננים נוספים",
      },
      comparePeriod: "תקופת השוואה",
      reportPeriodShowBy: {
        total: "סה״כ",
        year: "שנה",
        month: "חודש",
        showBy: "קבץ לפי :",
      },
      reportPeriodSelectPrompt: "בחר",
      reportValuesToDisplay: {
        valueSales: "כספי",
        units: "יחידות",
        uniqueElements: "מספר ערכים ייחודיים",
      },
      additionalInfoSelector: [
        { optionKey: "AgentIDs", optionText: "סוכן" },
        { optionKey: "CustomerIDs", optionText: "לקוח" },
        { optionKey: "ItemCatNumbers", optionText: "מוצר" },
        { optionKey: "CustomerHierarchies", optionText: "קבוצת לקוחות" },
      ],
      additionalInfoSideBarHeaders: {
        agents: "סוכנים",
        customers: "לקוחות",
        items: "מוצרים",
        customersGroups: "קבוצות לקוחות",
      },
      loadingMessageText: "אנחנו מכינים את הדוח – זה עשוי לקחת עד 60 שניות",
    },
  },

  english: {
    agent: "Agent",
    lastUpdate: "Last Update",
    navHeaders: {
      backButton: "Back",
      greet: (given_name) => `Hi  ${given_name}`,
      searchPlaceHolder: "Type customer name or number for a complete overview",
    },
    dropdownOptions: {
      logout: "Logout",
      support: "Support",
      home: "Home",
    },
    keyOpportunitiesTable: {
      tableTitle: "Key Opportunities",
      tableHeaders: {
        customerCode: "Customer Code",
        customerName: "Customer Name",
        salesPerson: "Sales Person",
        insightType: "Insight Type",
        value: "Value",
        progress: "Progress",
        priority: "Priority",
      },
      menuOptions: {
        customerPage: "Customer Page...",
        detailsPage: "Details...",
        priority: "Set Priority to",
        exportTo: "Export to",
        high: "High",
        regular: "Regular",
        low: "Discard",
      },
      noData:
        "Analyzing opportunities is in progress... Estimated completion by the 2nd of this month",
      noData2: "No data available",
    },
    opportunitiesAnalysisByAgent: {
      graphTitle: "Opportunities Analysis by agent",
    },
    salesReviewTable: {
      tableTitle: "Sales Review",
      menuOptions: {
        period: "Period",
        thisMonth: "This Month",
        lastMonth: "Last Month",
        yearToDate: "Year to Date",
        exportTo: "Export to",
      },
      noData: "No data available",
    },
    customerKeyOpportunitiesTable: {
      tableTitle: "Key Opportunities",
    },
    purchaseHistoryTable: {
      subtitle: "Purchase history by product",
    },
    productPurchasedTable: {
      tableTitle: "Product Purchased Table",
      probabilityOptions: {
        New: "New",
        Low: "Low",
        Med: "Med",
        High: "High",
        NA: "-",
      },
      periodButtons: {
        thisMonth: "This Month",
        lastMonth: "Last Month",
        yearToDate: "Year to Date",
      },
      analyze: "Analyze",
    },
    productSalesGraph: {
      graphTitle: "Sales",
    },
    CustomerSalesOverviewGraph: {
      graphTitle: "Total Sales by month in",
    },
    CustomersOverviewTable: {
      tableTitle: "Customers Overview",
      tableHeaders: {
        customerCode: "Customer Code",
        customerName: "CustomerName",
        salesPerson: "Sales Agent",
        lastOrder: "Last Order",
        trend: "Trend",
        gix: "GIX",
        progressExpectation: "",
        urgency: "דחיפות",
        priority: "עדיפות",
      },
      menuOptions: {
        trendPeriod: "Trend Period",
        current: "Current",
        lastYear: "Last Year",
        calcProgressVs: "Calc Progress vs",
        lastMonth: "Last Month",
        yearAgo: "Year Ago",
        avgP3M: "Avg P3M",
        avgPurchaseP3M: "Avg Purchase P3M",
        showGraph: "Show Graph",
        customerPage: "Customer Page...",
        detailsPage: "Details...",
        priority: "Set Priority to",
        high: "High",
        regular: "Regular",
        low: "Low",
        exportTo: "Export to",
      },
      filterOptions: {
        trendCustomer: "Trend - Customer",
        trendGroup: "Trend - Group",
        salesCurrentPeriod: "Sales - Current Period",
        salesPreviousPeriod: "Sales - Previous Period",
        salesProgress: "Sales - Progress",
      },
      urgencyOptions: {
        high: "HIGH",
        medium: "MEDIUM",
        low: "LOW",
      },
      sumsText: {
        products: "Products",
        numberOfCustomers: "Customers",
        progress: "Progress",
        openOrders: "Open orders",
        currentMonth: `Sales ${date.getMonth() + 1}/${
          date.getFullYear() % 2000
        }`,
      },
    },
    growthAnalysisGraph: {
      title: "Growth Analysis",
      periodSelection: {
        P3M: "P3M",
      },
      legend: {
        BaseSales: "Base Sales",
        DistributionGains: "Distribution Gains",
        DistributionLosses: "Distribution Losses",
        LostCustomers: "Lost Customers",
        NewCustomers: "New/Returning Customers",
        Total: "Total Growth",
      },
    },
    opportunityOptions: {
      insight1Agg: "Reducing items",
      insight2Agg: "No orders",
      insight3Agg: "Upsell",
      insight4DuringAgg: "Low sales",
      insight5Agg: "Reducing category",
    },
    CustomersOverviewGraph: {
      graphTitle: "Rolling 12 months sales, vs. YA and group",
    },
    opportunityValueSplit: {
      graphTitle: "Opportunity Value Split",
    },
    customerOverviewSalesForecast: {
      title: "Current Month Sales Forecast",
      purchaseProbabilityTitle: "Purchase Probability",
      forecastTitle: "Forecast",
    },
    reportsTable: {
      tableTitle: "Opportunities Report",
    },
    analyzeBox: {
      title: "Analyze",
      dimension: "Sales by",
      units: "Units",
      reportingPeriod: "Analyze Period",
      clickHere: "Click Here",
      compareTo: "Compare to",
      apply: "Apply",
    },
    hierarchyBox: {
      title: "Filter by group",
      subtitle: "Select the relevant conditions for this group",
      and: "AND",
      or: "OR",
      addCondition: "Add Condition",
      hierarchyPlaceHolder: "Select Group",
      hierarchyValuesPlaceHolder: "Select Group Values",
      apply: "Apply",
      selectConditions: "Select Conditions",
    },
    filterPicker: {
      filterRows: "Filter Rows",
      column: "Column",
      operator: "Operator",
      value: "Value",
      contains: "Contains",
      notContains: "Not Contains",
      equals: "Equals",
      startsWith: "Starts With",
      endsWith: "Ends With",
      addFilter: "Add filter...",
    },
    greenDiv: {
      customersWith: "customers with",
      salesPotential: "sales potential",
      activeInsightsWorth: "Active insights worth",
    },
    hierarchyButton: {
      addFilterText: "Filter by group",
      removeFilterText: "Filtered by group",
    },
    revNav_MainGraph: {
      legend: {
        increase: "Increase",
        decrease: "Decrease",
        total: "Total",
      },
      agentsSelector: {
        placeHolder: "Agents",
        title: "Select Agents",
        selectAll: "Select All",
      },
      preSelectorText: {
        timeFrame: "Time Frame",
        for: "For",
      },
    },
    RevNavigatorTable: {
      stock: "Stock: ",
      tableTitle: "Lost Customers",
      tableTitle2: {
        B: "Base Sales",
        DG: "Distribution Gains",
        DL: "Distribution Losses",
        L: "Lost Customers",
        N: "New/Returning Customers",
        Total: "Total Growth",
      },
      sumsText: {
        products: "Products",
        customers: "Customers",
      },
    },
    reportsGeneratorPage: {
      title: "Reports",
      report: "Report",
      reportTypes: {
        byCustomer: "By customer",
        byCustomerItem: "By customer / item",
        byItem: "By item",
        byItemAgent: "By item / agent",
        byAgent: "By agent",
      },
      reportTypesArray: [
        "By customer",
        "By customer / item",
        "By item",
        "By item / agent",
        "By agent",
      ],
      // generateReportInstruction: ``,
      generateReport: "Generate report",
      scheduledReport: "",
      preGeneratingMessage:
        "Select a report type from the sidebar to start generating a report",
      clearAll: "Clear all",
      // saveReport: "Save Report",
      backToEdit: "Back to edit",
      additionalInfoPlaceHolder: "Select",
      generate: "Generate",
      predefinedReport: "Predefined report",
      dataType: "Data type",
      reportSettings: "Report settings",
      reportFiltersHeaders: {
        reportPeriod: "Report Period",
        valuesToDisplay: "Data types",
        additionalInfo: "Additional filters",
      },
      comparePeriod: "Compare period",
      reportPeriodShowBy: {
        total: "Total",
        year: "Year",
        month: "Month",
        showBy: "Group by :",
      },
      reportPeriodSelectPrompt: "Select",
      reportValuesToDisplay: {
        valueSales: "Monetary",
        units: "Units",
        uniqueElements: "Number of unique elements",
      },
      additionalInfoSelector: [
        { optionKey: "AgentIDs", optionText: "Agent" },
        { optionKey: "CustomerIDs", optionText: "Customer" },
        { optionKey: "ItemCatNumbers", optionText: "Item" },
        { optionKey: "CustomerHierarchies", optionText: "Customer group" },
      ],
      additionalInfoSideBarHeaders: {
        agents: "Agents",
        customers: "Customers",
        items: "Items",
        customersGroups: "Customer Groups",
      },
      loadingMessageText:
        "We're preparing your report – this may take up to 60 seconds.",
    },
  },
};
