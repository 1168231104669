import moment from "moment";
import React from "react";
import "./OnGoingLight.css";

function OnGoingLight({ row, language, todaysDate }) {
  const { CreatedDate } = row?.original;
  const isOnGoingInsight = (createdDate) => {
    const dayToCompare = moment(createdDate?.split("_").join("/"), "D/M/YYYY");
    return todaysDate.diff(dayToCompare, "days") < 3 ? true : false;
  };
  // const { Language } = JSON.parse(sessionStorage.getItem("userMetadata"));

  return (
    <span
      className={`${
        isOnGoingInsight(CreatedDate)
          ? "onGoingLight-div-on"
          : "onGoingLight-div-off"
      }`}
    >
      {language === "hebrew" ? "חדש" : language === "english" ? "new" : "new"}
    </span>
  );
}

export default OnGoingLight;
