import moment from "moment";

function sortDates(dates) {
  return dates?.length >= 1
    ? dates
        .map((date) => date?.replace("\r", ""))
        ?.sort((a, b) => moment(b) - moment(a))[0]
        ?.split("-")
        .reverse()
        .join("/")
        .replace(/^0+/, "")
        .replace(/\/0+/g, "/")
    : "not provided";
}
function currentMonth() {
  const staticAppDate =
    process.env.REACT_APP_NODE_ENV === "ir-development"
      ? process.env.REACT_APP_DEMO_DATE
      : false;

  const date = staticAppDate ? new Date(staticAppDate) : new Date();

  const month = date.getMonth() + 1;
  return `${month}_${date.getFullYear()}`;
}
function formatDateView(date) {
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString()?.slice(-2);
  return `${month}/${year}`;
}
function formatDateProcess(date) {
  const month = (date.getMonth() + 1).toString();
  const year = date.getFullYear().toString();
  return `${month}-${year}`;
}
const dateUtils = {
  sortDates,
  currentMonth,
  formatDateView,
  formatDateProcess
};

export default dateUtils;
