import NumberColumnFilter from "../../../Filters/NumberColumnFilter";
import CheckedColumnFilter from "../../../Filters/CheckedColumnFilter";

import { sortTypes } from "../../../../utils/sort";

export const hebGROUPED_COLUMNS = [
  {
    Header: "סוכן",
    accessor: "SalesAgent",
    filter: "checked",
    Filter: CheckedColumnFilter,
    canFilter: true,
  },
  {
    Header: "מכירות ביחס ליעדים",
    columns: [
      {
        Header: "₪ יעד",
        accessor: "Target",
        filter: "number",
        Filter: NumberColumnFilter,
        canFilter: true,
        sortType: sortTypes.number,
      },
      {
        Header: "₪ מכירות",
        accessor: "Sales",
        filter: "number",
        Filter: NumberColumnFilter,
        canFilter: true,
        sortType: sortTypes.number,
      },
      {
        Header: "אינדקס",
        accessor: "SalesIx",
        // // Filter: ColumnFilter
        filter: "number",
        Filter: NumberColumnFilter,
        canFilter: true,
        sortType: sortTypes.number,
      },
      {
        Header: "₪ אומדן מכירות",
        accessor: "SalesExt",
        filter: "number",
        Filter: NumberColumnFilter,
        canFilter: true,
        sortType: sortTypes.number,
      },
      {
        Header: "אינדקס",
        accessor: "SalesExtIx",
        // // Filter: ColumnFilter
        filter: "number",
        Filter: NumberColumnFilter,
        canFilter: true,
        sortType: sortTypes.number,
      },
      {
        Header: "₪ הזמנות פתוחות",
        accessor: "OpenOrders",
        filter: "number",
        Filter: NumberColumnFilter,
        canFilter: true,
        sortType: sortTypes.number,
      },
      {
        Header: '₪ סה"כ הזמנות+מכירות',
        accessor: "SalesPlusOrders",
        filter: "number",
        Filter: NumberColumnFilter,
        canFilter: true,
        sortType: sortTypes.number,
      },
    ],
  },
  {
    Header: "הזדמנויות בחודש הנוכחי",
    columns: [
      {
        Header: "מומשו",
        accessor: "Achieved",
        filter: "number",
        Filter: NumberColumnFilter,
        canFilter: true,
        sortType: sortTypes.number,
      },
      {
        Header: "לא מומשו",
        accessor: "NotAchieved",
        filter: "number",
        Filter: NumberColumnFilter,
        canFilter: true,
        sortType: sortTypes.number,
      },
    ],
  },
  {
    Header: "מגמות בחודש הקודם",
    columns: [
      {
        Header: "שנה קודמת",
        accessor: "YA",
        filter: "number",
        Filter: NumberColumnFilter,
        canFilter: true,
        sortType: sortTypes.number,
      },
      {
        Header: "P3M",
        accessor: "P3M",
        filter: "number",
        Filter: NumberColumnFilter,
        canFilter: true,
        sortType: sortTypes.number,
      },
      {
        Header: "P6M",
        accessor: "P6M",
        filter: "number",
        Filter: NumberColumnFilter,
        canFilter: true,
        sortType: sortTypes.number,
      },
      {
        Header: "P12M",
        accessor: "P12M",
        filter: "number",
        Filter: NumberColumnFilter,
        canFilter: true,
        sortType: sortTypes.number,
      },
    ],
  },
];
