import axios from "axios";
import domain from "../config/domain";
import { reqAuth } from "../constants/reqAuth";

async function getIndexes(tableName, InsightingCustomerID, reqDate) {
  // reqDate = '2023-03-19T22:00:00.000Z'
  return axios
    .request({
      method: "POST",
      url: `${domain}/${tableName}/getIndexes`,
      headers: { authorization: reqAuth },
      data: { InsightingCustomerID, reqDate },
    })
    .then((r) => {
      return r.data;
    })
    .catch(console.error);
}

const tableIndexes = {
  getIndexes,
};

export default tableIndexes;
