import React from "react";
import { Menu, MenuItem } from "@material-ui/core";
import NestedMenuItem from "material-ui-nested-menu-item";
import { dictionary } from "../../../constants/dictionary";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getRowsCsv } from "../../../utils/helpers";
import pdfUtils from "../../../utils/pdf";
import { CSVLink } from "react-csv";
import { setOverviewMenuPosition } from "../../../store/features/customersOverviewSlice";

export default function RevNavigatorDetailsMenu({
  tableRef,
  rows,
  csvHeaders,
}) {
  const dispatch = useDispatch();
  const Language = useSelector(
    (state) => state.userSlice.user.metadata.Language
  );
  const overviewMenuPosition = useSelector(
    (state) => state.customersOverviewSlice.overviewMenuPosition,
    shallowEqual
  );
  const revNavTableMode = useSelector(
    (state) => state.revNavigatorSlice.revNavTableMode
  );

  const handlePDFExportToClick = () => {
    // pdfUtils.exportPdfFile("#customers-overview-table", "customers_overview");
    pdfUtils.download(
      tableRef.current,
      dictionary[Language]?.RevNavigatorTable.tableTitle2[revNavTableMode]
    );
    dispatch(setOverviewMenuPosition(null));
  };

  return (
    <Menu
      // style={{ direction: Language === "hebrew" ? "rtl" : "ltr" }}
      open={!!overviewMenuPosition}
      onClose={() => {
        dispatch(setOverviewMenuPosition(null));
      }}
      anchorReference="anchorPosition"
      anchorPosition={overviewMenuPosition}
    >
      <NestedMenuItem
        label={
          dictionary[Language]?.CustomersOverviewTable.menuOptions.exportTo
        }
        parentMenuOpen={!!overviewMenuPosition}
      >
        <MenuItem id="csv">
          <CSVLink
            target="_blank"
            data={getRowsCsv(rows, csvHeaders, Language) || []}
            filename={`${dictionary[Language]?.RevNavigatorTable.tableTitle2[revNavTableMode]}.csv`}
            style={{ color: "black" }}
            onClick={() => {
              dispatch(setOverviewMenuPosition(null));
            }}
          >
            CSV
          </CSVLink>
        </MenuItem>
        <MenuItem
          id="pdf"
          onClick={
            (rows?.length < 200 ? true : false) ? handlePDFExportToClick : null
          }
          style={{
            color: (rows?.length < 200 ? true : false) ? "black" : "grey",
          }}
        >
          PDF
        </MenuItem>
      </NestedMenuItem>
    </Menu>
  );
}
