import { createSlice } from "@reduxjs/toolkit";
let staticAppDate;
if (process.env.REACT_APP_NODE_ENV === "ir-development")
  staticAppDate = process.env.REACT_APP_DEMO_DATE;

const date = staticAppDate ? new Date(staticAppDate) : new Date();
let sixMonthsAgo = date;
sixMonthsAgo.setMonth(date.getMonth() - 6);
const sixMonthsAgoMonth = sixMonthsAgo.getMonth() + 1;
const sixMonthsAgoDay = sixMonthsAgo.getDate();
const sixMonthsAgoYear = sixMonthsAgo.getFullYear();

const goodFormat = `${sixMonthsAgoYear}-${sixMonthsAgoMonth}-${sixMonthsAgoDay}`;
// lastOrder.setFilter("greater--/--" + goodFormat);
const initialState = {
  customerTrendsFilters: [
    { id: "lastOrder", value: "greater--/--" + goodFormat },
  ],
  customerTrendsForexitingPageFilters: [],
  keyOpportunitiesTableFilters: [],
  salesByCustomerByItemData: [],
  salesReviewTableFilters: [],
  productPurchasedTableFilters: [],
  reportsTableFilters: [],
  customersKeyOpportunitiesFilters: [],
  purchaseHistoryTableFilters: [],
  showCustomerPageFilters: false,
  customersGroupFilter: [],
  hierarchyFilterLogicOperators: [],
  hierFilterData: [],
  revNavigatorTableFilters: [],
  revNavigatorDetailsTableFilters: [],
  showHierFilteredData: false,
};
// useEffect(function onLoadSetFilters() {
//   if (!history.location.state?.pathName) {
//     const lastOrder = getColumnByAccessor(
//       "lastOrder",
//       headerGroups[0].headers
//     );
//     if (lastOrder && lastOrder.canFilter && lastOrder.setFilter) {
//       let sixMonthsAgo = date;
//       sixMonthsAgo.setMonth(date.getMonth() - 6);
//       const sixMonthsAgoMonth = sixMonthsAgo.getMonth() + 1;
//       const sixMonthsAgoDay = sixMonthsAgo.getDate();
//       const sixMonthsAgoYear = sixMonthsAgo.getFullYear();

//       const goodFormat = `${sixMonthsAgoYear}-${sixMonthsAgoMonth}-${sixMonthsAgoDay}`;
//       lastOrder.setFilter("greater--/--" + goodFormat);
//     }
//   }
// }, []);
export const tableFiltersSlice = createSlice({
  name: "tableFilters",
  initialState,
  reducers: {
    setCustomerTrendsFilters: (state, action) => {
      state.customerTrendsFilters = action.payload;
    },
    setCustomerTrendsForexitingPageFilters: (state, action) => {
      state.customerTrendsForexitingPageFilters = action.payload;
    },
    setKeyOpportunitiesTableFilters: (state, action) => {
      state.keyOpportunitiesTableFilters = action.payload;
    },
    addKeyOpportunitiesTableFilters: (state, action) => {
      const { id, value, replace = false } = action.payload;
      const filter = state.keyOpportunitiesTableFilters.find(
        ({ id: _id }) => _id === id
      );
      if (filter) {
        state.keyOpportunitiesTableFilters =
          state.keyOpportunitiesTableFilters.map((item) => {
            if (item.id === id) {
              return {
                id,
                value: replace ? value : { ...item.value, ...value },
              };
            }
            return item;
          });
      } else {
        state.keyOpportunitiesTableFilters.push(action.payload);
      }
    },
    setSalesReviewTableFilters: (state, action) => {
      state.salesReviewTableFilters = action.payload;
    },
    setPurchaseHistoryTableFilters: (state, action) => {
      state.purchaseHistoryTable = action.payload;
    },
    // setProductPurchasedTableFilters: (state, action) => {
    //   state.productPurchasedTableFilters = action.payload;
    // },
    setReportsTableFilters: (state, action) => {
      state.reportsTableFilters = action.payload;
    },
    // setCustomersKeyOpportunitiesFilters: (state, action) => {
    //   state.customersKeyOpportunitiesFilters = action.payload;
    // },
    setShowCustomerPageFilters: (state, action) => {
      state.showCustomerPageFilters = action.payload;
    },
    setCustomersGroupFilter: (state, action) => {
      state.customersGroupFilter = action.payload;
    },
    setHierarchyFilterLogicOperators: (state, action) => {
      state.hierarchyFilterLogicOperators = action.payload;
    },
    setRevNavigatorTableFilters: (state, action) => {
      state.revNavigatorTableFilters = action.payload;
    },
    setRevNavigatorDetailsTableFilters: (state, action) => {
      state.revNavigatorDetailsTableFilters = action.payload;
    },
    setHierFilterData: (state, action) => {
      state.hierFilterData = action.payload;
    },
    setShowHierFilteredData: (state, action) => {
      state.showHierFilteredData = action.payload;
    },
  },
});

export const {
  setCustomerTrendsFilters,
  setCustomerTrendsForexitingPageFilters,
  setKeyOpportunitiesTableFilters,
  addKeyOpportunitiesTableFilters,
  setPurchaseHistoryTableFilters,
  setSalesReviewTableFilters,
  // setProductPurchasedTableFilters,
  setReportsTableFilters,
  // setCustomersKeyOpportunitiesFilters,
  setShowCustomerPageFilters,
  setCustomersGroupFilter,
  setHierarchyFilterLogicOperators,
  setHierFilterData,
  setRevNavigatorTableFilters,
  setRevNavigatorDetailsTableFilters,
  setShowHierFilteredData,
} = tableFiltersSlice.actions;

export default tableFiltersSlice.reducer;
