import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useFilters, useSortBy, useTable } from "react-table";
import { setKeyOpportunitiesTableData } from "../../../store/features/dataSlice";
import { setKeyOpportunitiesTableSorts } from "../../../store/features/tableSortsSlice";
import { setKeyOpportunitiesTableFilters } from "../../../store/features/tableFiltersSlice";
import { filterTypes, getColumnByAccessor } from "../../../utils/filters";
import AgentCard from "../../AgentCard";
import FilterPicker from "../../Filters/FilterPicker";
import FiltersList from "../../Filters/FiltersList";
import InsightTypesLogos from "../../InsightTypesLogos/InsightTypesLogos";
import { PriorityButton } from "../../PriorityButton/PriorityButton";

import { Menu, MenuItem } from "@material-ui/core";
import axios from "axios";
import _ from "lodash";
import NestedMenuItem from "material-ui-nested-menu-item";
import { CSVLink } from "react-csv";
import { dictionary } from "../../../constants/dictionary";
import domain from "../../../config/domain";
import {
  setCurrencyForTableHeaders,
  usDateFormat,
  goToCustomerPage,
  getLastUpdate,
  getRowsCsv,
  isSorted,
} from "../../../utils/helpers";
import pdfUtils from "../../../utils/pdf";
import OnGoingLight from "../../OnGoingLight/OnGoingLight";
import { engCOLUMNS } from "./ColumnsFiles/engColumns";
import { hebCOLUMNS } from "./ColumnsFiles/hebColumns";
import "./keyOpportunitiesTable.css";
import { LoadingAnimation } from "../../LoadingAnimation/LoadingAnimation";
import { setCustKeyOppsLastUpdate } from "../../../store/features/globalSlice";
import moment from "moment";
import { reqAuth } from "../../../constants/reqAuth";
// import { aggInsightsMap } from "../../../constants/insights-map";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: "1rem",
    marginBottom: 20,
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
      width: "100%",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  tableFilters: {
    marginLeft: 20,
    marginBottom: 12,
    position: "relative",
  },
  filterIcon: {
    marginLeft: 20,
    cursor: "pointer",
  },
  filtersCloseIcon: {
    opacity: 0.82,
    marginBottom: 12,
    cursor: "pointer",
    transition: "opacity 0.4s",
    "&:hover": {
      opacity: 1,
    },
  },
  export: {
    marginLeft: 15,
    marginBottom: 10,
  },
}));

const noOppsTd = {
  height: "25vh",
  textAlign: "center",
  marginTop: "2rem",
  background: "#EFEDED",
  border: "none",
  width: "30vw",
};

function getServiceDaysArr(serviceDaysString) {
  return serviceDaysString?.replace("[", "").replace("]", "")?.split(",");
}

const weekDays = {
  english: {
    1: "Sun",
    2: "Mon",
    3: "Tue",
    4: "Wed",
    5: "Thu",
    6: "Fri",
    7: "Sat",
    8: "Sun",
    9: "Mon",
    10: "Tue",
    11: "Wed",
    12: "Thu",
    13: "Fri",
  },
  hebrew: {
    1: "א",
    2: "ב",
    3: "ג",
    4: "ד",
    5: "ה",
    6: "ו",
    7: "ש",
    8: "א",
    9: "ב",
    10: "ג",
    11: "ד",
    12: "ה",
    13: "ו",
  },
};

const tableName = "KeyOpportunities";
const todaysDate = moment(moment().format("DD/MM/YYYY"), "D/M/YYYY");

function KeyOpportunitiesTable({ setAgentDayData }) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const userSlice = useSelector((state) => state.userSlice, shallowEqual);
  const globalSlice = useSelector((state) => state.globalSlice, shallowEqual);
  const keyOpportunitiesTableData = useSelector(
    (state) => state.dataSlice.keyOpportunitiesTableData,
    shallowEqual
  );
  const customersForNavbarData = useSelector(
    (state) => state.dataSlice.customersForNavbarData,
    shallowEqual
  );
  const allKeyOpportunitiesForCustomerPage = useSelector(
    (state) => state.dataSlice.allKeyOpportunitiesForCustomerPage,
    shallowEqual
  );
  const keyOpportunitiesDisplayType = useSelector(
    (state) => state.dataSlice.keyOpportunitiesDisplayType
  );
  const keyOpportunitiesTableFilters = useSelector(
    (state) => state.tableFiltersSlice.keyOpportunitiesTableFilters,
    shallowEqual
  );
  const keyOpportunitiesTableSorts = useSelector(
    (state) => state.tableSortsSlice.keyOpportunitiesTableSorts,
    shallowEqual
  );

  const {
    InsightingCustomerID: insightingCustomerID,
    UserType: userType,
    WorkWeekFirstDay,
  } = userSlice.user.metadata;
  const { resolution, staticAppDate } = globalSlice;

  const date = staticAppDate ? new Date(staticAppDate) : new Date();

  const [filterPickerVisible, setFilterPickerVisible] = useState(null);

  const [activeDays, setActiveDays] = useState([]);
  const [pickedDay, setPickedDay] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [menuPosition, setMenuPosition] = useState(null);
  const [selectedRow, setSelectedRow] = useState({});
  const [lastUpdate, setLastUpdate] = useState(null);
  const [pdfAvailable, setPdfAvailable] = useState(false);

  const daysContainerElement = useRef(null);
  const tableRef = useRef(null);

  const isMobile = resolution.device === "mobile";

  const {
    user: {
      metadata: { Currency, AgentID, Language, DateFormat },
    },
  } = userSlice;

  const columns = useMemo(
    () =>
      Language === "hebrew"
        ? setCurrencyForTableHeaders(Currency, hebCOLUMNS, Language)
        : Language === "english"
        ? setCurrencyForTableHeaders(Currency, engCOLUMNS, Language)
        : setCurrencyForTableHeaders(Currency, engCOLUMNS, Language),
    []
  );

  const handleRightClick = (e, row, rowsLength) => {
    setPdfAvailable(rowsLength < 200 ? true : false);
    if (menuPosition) {
      return;
    }
    setSelectedRow(row);
    e.preventDefault();
    setMenuPosition({
      top: e.clientY,
      left: e.clientX,
    });
  };

  const handlePDFExportToClick = () => {
    // pdfUtils.exportPdfFile("#key-opportunities-table", "key_opportunities");
    pdfUtils.download(tableRef.current, "key-opportunities");
    setMenuPosition(null);
  };

  const handleCustomerClick = async () => {
    try {
      const { CustomerName, AgentName, CustomerID } = selectedRow?.original;
      goToCustomerPage(
        CustomerID,
        insightingCustomerID,
        CustomerName,
        AgentName,
        Language,
        allKeyOpportunitiesForCustomerPage,
        history
      );
    } catch (error) {
      console.log(error.message);
      console.log(error.stack);
    }
  };
  const handleAgentCardClick = async (row) => {
    try {
      const { CustomerName, AgentName, CustomerID } = row?.original;
      goToCustomerPage(
        CustomerID,
        insightingCustomerID,
        CustomerName,
        AgentName,
        Language,
        allKeyOpportunitiesForCustomerPage,
        history
      );
    } catch (error) {
      console.log(error.message);
      console.log(error.stack);
    }
  };

  const handlePriorityClick = async (e) => {
    const newPriority = e.target.id;
    setMenuPosition(null);
    const { CustomerID } = selectedRow?.original;
    let foundIndex = keyOpportunitiesTableData.findIndex(
      (item) => item.CustomerID === CustomerID
    );
    const updatedItem = {
      ...keyOpportunitiesTableData[foundIndex],
      Priority: newPriority,
    };
    const updatedData = [...keyOpportunitiesTableData];
    updatedData.splice(foundIndex, 1, updatedItem);

    dispatch(setKeyOpportunitiesTableData(updatedData));

    await axios.request({
      method: "POST",
      url: `${domain}/${tableName}/priorityRowQuery/`,
      data: {
        CustomerID,
        InsightingCustomerID: insightingCustomerID,
        Priority: newPriority,
      },
      headers: { authorization: reqAuth },
    });
  };

  // const columns = useMemo(() => COLUMNS, []); // eslint-disable-next-line
  function createTableData(day) {
    return keyOpportunitiesTableData
      .map((item) => ({
        ...item,
        ServiceDays: getServiceDaysArr(
          customersForNavbarData.find(
            (customerData) => customerData.id === item.CustomerID
          )?.serviceDays
        ),
      }))
      ?.filter((item) =>
        item.ServiceDays?.includes(
          _.findKey(
            weekDays[Language],
            (value) => value === weekDays[Language][day]
          )
        )
      );
  }

  const data = useMemo(
    () =>
      userType === "agent" && isMobile && tableData?.length > 0
        ? tableData
        : keyOpportunitiesTableData,
    [tableData, keyOpportunitiesTableData]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: {
        sortBy: keyOpportunitiesTableSorts,
      },
      autoResetPage: false,
      autoResetExpanded: false,
      autoResetGroupBy: false,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false,
    },
    useFilters,
    useSortBy
  );

  const handleDayClick = (e, day) => {
    if (parseInt(WorkWeekFirstDay) === 1) {
      setPickedDay([day]);
      setActiveDays((prev) =>
        prev?.includes(day)
          ? prev?.filter((item) => item !== day)
          : [...prev, day]
      );
    }
    if (parseInt(WorkWeekFirstDay) === 2) {
      day = (day - 1).toString();
      setPickedDay([day]);
      setActiveDays((prev) =>
        prev?.includes(day)
          ? prev?.filter((item) => item !== day)
          : [...prev, day]
      );
    }
  };

  useEffect(() => {
    setAgentDayData(tableData?.filter((item) => item.AgentID === AgentID));
  }, [tableData]);

  useEffect(() => {
    if (
      isEmpty(tableData) &&
      !isEmpty(keyOpportunitiesTableData) &&
      !isEmpty(customersForNavbarData)
    ) {
      const day =
        date.getDay() + 1 > parseInt(WorkWeekFirstDay) + 4
          ? WorkWeekFirstDay
          : date.getDay() + 1;
      if (parseInt(WorkWeekFirstDay) === 1) {
        setPickedDay([day.toString()]);
        setActiveDays([day.toString()]);
        setTableData(createTableData(day.toString()));
        // setAgentDayData(tableData?.filter((item) => item.AgentID === AgentID))
      }
      if (parseInt(WorkWeekFirstDay) === 2) {
        setPickedDay([(day - 1).toString()]);
        setActiveDays([(day - 1).toString()]);
        setTableData(createTableData((day - 1).toString()));
        // setAgentDayData(tableData?.filter((item) => item.AgentID === AgentID))
      }
    }
  }, [keyOpportunitiesTableData, customersForNavbarData]);

  useEffect(
    function onFiltersChanged() {
      dispatch(setKeyOpportunitiesTableFilters(state.filters));
    },
    [state.filters]
  );

  useEffect(
    function onLastUpdate() {
      if (data?.length > 0) {
        let updateDate = getLastUpdate(data);
        setLastUpdate(updateDate);
        dispatch(setCustKeyOppsLastUpdate(updateDate));
      }
    },
    [data]
  );

  useEffect(
    function onDayChanged() {
      const activeDaysData = [];
      activeDays?.forEach((day) => {
        activeDaysData.push(...createTableData(day));
      });

      if (
        userType === "agent" &&
        isMobile &&
        daysContainerElement.current.childNodes?.length > 0
      ) {
        for (let i = 0; i < 5; i++) {
          daysContainerElement.current.childNodes[i].style.backgroundColor =
            activeDays?.includes((i + 1).toString()) ? "#aedd94" : "white";
          daysContainerElement.current.childNodes[i].style.color =
            activeDays?.includes((i + 1).toString()) ? "white" : "black";
        }
      }
      setTableData(_.uniqWith(activeDaysData, isEqual));
      setAgentDayData(tableData?.filter((item) => item.AgentID === AgentID));
    },
    [pickedDay]
    // [pickedDay, setActiveDays]
  );

  useEffect(
    function onSortsChanged() {
      dispatch(setKeyOpportunitiesTableSorts(state.sortBy));
    },
    [state.sortBy, dispatch]
  );

  useEffect(
    function onTableFiltersChanged() {
      if (
        !isEmpty(keyOpportunitiesTableFilters) &&
        !isEqual(keyOpportunitiesTableFilters, state.filters)
      ) {
        keyOpportunitiesTableFilters?.forEach(({ id, value }) => {
          const column = getColumnByAccessor(id, headerGroups[0].headers);
          if (column && column.canFilter && column.setFilter) {
            column.setFilter(value);
          }
        });
      }
    },
    [keyOpportunitiesTableFilters]
  );

  useEffect(
    function applyFilters() {
      if (!isEmpty(keyOpportunitiesTableFilters)) {
        keyOpportunitiesTableFilters?.forEach(({ id, value }) => {
          const column = getColumnByAccessor(id, headerGroups[0].headers);
          if (column && column.canFilter && column.setFilter) {
            column.setFilter(value);
          }
        });
      }
    },
    [location, data]
  );

  useEffect(function onLoadSetFilters() {
    if (!history.location.state?.pathName) {
      const priorityColumn = getColumnByAccessor(
        "Priority",
        headerGroups[0].headers
      );
      if (
        priorityColumn &&
        priorityColumn.canFilter &&
        priorityColumn.setFilter
      ) {
        priorityColumn.setFilter({ 0: true, 1: true });
      }
      const salesPersonColumn = getColumnByAccessor(
        "AgentName",
        headerGroups[0].headers
      );

      if (
        userType === "agent" &&
        salesPersonColumn &&
        salesPersonColumn.canFilter &&
        salesPersonColumn.setFilter
      ) {
        const found = data.find((item) => item.AgentID === AgentID);
        if (found)
          salesPersonColumn.setFilter({
            [found?.AgentName]: true,
          }); // filter by agent
      }
    }
  }, []);

  return (
    <div className={classes.root}>
      {(userType === "manager" ||
        userType === "teamleader" ||
        (userType === "agent" && !isMobile)) && (
        <div
          className="key-opportunities-above-table"
          style={{
            direction: `${Language === "hebrew" ? "rtl" : "ltr"}`,
            marginRight: "1.5rem",
          }}
        >
          <h4>{dictionary[Language]?.keyOpportunitiesTable?.tableTitle}</h4>
          {lastUpdate && (
            <span className="last-update" style={{ marginRight: "0.5rem" }}>
              {dictionary[Language].lastUpdate} :{" "}
              {DateFormat === "us" ? usDateFormat(lastUpdate) : lastUpdate}
            </span>
          )}
        </div>
      )}
      {/* days bar */}
      {userType === "agent" && isMobile && (
        <ul className="week-list" ref={daysContainerElement}>
          {Object.keys(weekDays[Language])
            ?.slice(
              parseInt(WorkWeekFirstDay) - 1,
              parseInt(WorkWeekFirstDay) + 4
            )
            .map((day) => (
              <li
                key={day}
                className="day-list"
                onClick={(e) => handleDayClick(e, day)}
              >
                <div className="day-box">
                  <div className="day-date">{weekDays[Language][day]}</div>
                </div>
              </li>
            ))}
        </ul>
      )}
      <div className={classes.tableFilters}>
        <Box
          display="flex"
          alignItems="center"
          style={{
            direction: `${Language === "hebrew" ? "rtl" : "ltr"}`,
            marginRight: "1.2rem",
          }}
        >
          <FiltersList
            filters={state.filters}
            style={{ maxWidth: 560 }}
            headers={headerGroups[0].headers}
            setFilterPickerVisible={setFilterPickerVisible}
          />
          <FilterPicker
            columns={columns}
            visible={filterPickerVisible}
            headers={headerGroups[0].headers}
            setVisibility={setFilterPickerVisible}
          />
        </Box>
      </div>

      {(userType === "manager" ||
        userType === "teamleader" ||
        (userType === "agent" && !isMobile)) && (
        <div
          ref={tableRef}
          id="key-opportunities-table"
          className={`${!rows?.length ? "no-opps-background" : ""}`}
          style={{
            maxHeight: "78vh",
            overflowY: "auto",
            marginRight: "1rem",
            marginTop: "1rem",
          }}
        >
          <table {...getTableProps()} className="key-opportunities-table">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className="key-opportunities-headers"
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <span>{isSorted(column)}</span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              {...getTableBodyProps()}
              className={`${!rows?.length ? "no-opps-background" : ""}`}
            >
              <Menu
                open={!!menuPosition}
                onClose={() => {
                  setMenuPosition(null);
                }}
                anchorReference="anchorPosition"
                anchorPosition={menuPosition}
              >
                <MenuItem onClick={handleCustomerClick}>
                  {
                    dictionary[Language]?.keyOpportunitiesTable.menuOptions
                      .customerPage
                  }
                </MenuItem>
                <NestedMenuItem
                  label={
                    dictionary[Language]?.keyOpportunitiesTable.menuOptions
                      .priority
                  }
                  parentMenuOpen={!!menuPosition}
                >
                  <MenuItem id="1" onClick={handlePriorityClick}>
                    {
                      dictionary[Language]?.keyOpportunitiesTable.menuOptions
                        .high
                    }
                  </MenuItem>
                  <MenuItem id="0" onClick={handlePriorityClick}>
                    {
                      dictionary[Language]?.keyOpportunitiesTable.menuOptions
                        .regular
                    }
                  </MenuItem>
                  <MenuItem id="-1" onClick={handlePriorityClick}>
                    {
                      dictionary[Language]?.keyOpportunitiesTable.menuOptions
                        .low
                    }
                  </MenuItem>
                </NestedMenuItem>
                <div className="menu-separator-div"></div>
                <NestedMenuItem
                  label={
                    dictionary[Language]?.keyOpportunitiesTable.menuOptions
                      .exportTo
                  }
                  parentMenuOpen={!!menuPosition}
                >
                  <MenuItem id="csv">
                    <CSVLink
                      target="_blank"
                      data={getRowsCsv(rows, columns)}
                      filename={`key_opportunities.csv`}
                      style={{ color: "black" }}
                      onClick={() => {
                        setMenuPosition(null);
                      }}
                    >
                      CSV
                    </CSVLink>
                  </MenuItem>
                  <MenuItem
                    id="pdf"
                    onClick={pdfAvailable ? handlePDFExportToClick : null}
                    style={{ color: pdfAvailable ? "black" : "grey" }}
                  >
                    PDF
                  </MenuItem>
                </NestedMenuItem>
              </Menu>
              {keyOpportunitiesDisplayType === "spinner" ? (
                <LoadingAnimation />
              ) : keyOpportunitiesDisplayType === "tableData" ? (
                rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      onContextMenu={(e) =>
                        handleRightClick(e, row, rows?.length)
                      }
                    >
                      {row.cells.map((cell) => {
                        if (
                          cell.column.Header === "Priority" ||
                          cell.column.Header === "עדיפות"
                        ) {
                          return (
                            <td {...cell.getCellProps()}>
                              <PriorityButton
                                row={row}
                                userType={userType}
                                status={cell.value}
                              />
                            </td>
                          );
                        }

                        if (
                          cell.column.Header === "Type" ||
                          cell.column.Header === "סוג ההזדמנות"
                        ) {
                          return (
                            <td
                              style={{ textAlign: "start" }}
                              {...cell.getCellProps()}
                            >
                              <InsightTypesLogos
                                row={row}
                                language={Language}
                              />
                              <OnGoingLight
                                row={row}
                                todaysDate={todaysDate}
                                language={Language}
                              />
                            </td>
                          );
                        }

                        if (
                          cell.column.Header === "Progress" ||
                          cell.column.Header === "התקדמות"
                        ) {
                          if (isNaN(parseFloat(cell.value))) {
                            return (
                              <td key={cell.column.id} {...cell.getCellProps()}>
                                -
                              </td>
                            );
                          } else {
                            let progressValue = parseInt(cell.value);
                            let percentage = Math.min(
                              Math.max(progressValue, 0),
                              999
                            );
                            const displayPercentage = Math.min(percentage, 100); // Cap displayed percentage at 100

                            const progressBarWidth = `${displayPercentage}%`;

                            let backgroundColor =
                              displayPercentage > 70
                                ? "#24b474"
                                : displayPercentage > 25
                                ? "#ffc408"
                                : displayPercentage > 0
                                ? "#fd6767"
                                : "transparent";

                            return (
                              <td
                                key={cell.column.id}
                                {...cell.getCellProps()}
                                className="overview-progress-cell"
                                style={{
                                  width: "7rem",
                                  border: "1px solid #ddd",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      border: "1px solid #D1D1D1",
                                      height: "1rem",
                                      borderRadius: "50px",
                                      width: "100%",
                                      marginLeft: "5px",
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: progressBarWidth,
                                        height: "100%",
                                        backgroundColor: backgroundColor,
                                        borderRadius: "50px",
                                        position: "absolute",
                                        left: 0,
                                        top: 0,
                                      }}
                                    />
                                  </div>
                                  <span style={{ margin: "0 5px" }}>
                                    {percentage}%
                                  </span>
                                </div>
                              </td>
                            );
                          }
                        }

                        return (
                          <td
                            {...cell.getCellProps()}
                            // onClick={() => handleRowClick(row)}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td style={noOppsTd}>
                    {date.getDate() > 2
                      ? dictionary[Language].keyOpportunitiesTable.noData2
                      : dictionary[Language].keyOpportunitiesTable.noData}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
      {/* agent mobile view */}
      {userType === "agent" && isMobile && (
        <>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <div key={row.id}>
                <AgentCard
                  row={row}
                  onCardClick={() => handleAgentCardClick(row)}
                />
              </div>
            );
          })}
        </>
      )}
    </div>
  );
}

export default memo(KeyOpportunitiesTable);
