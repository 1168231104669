import React, { useState } from "react";
import { dictionary } from "../../../constants/dictionary";
import svgs from "../../../utils/svgs";
import { useDispatch, useSelector } from "react-redux";
import {
  resetReportFilters,
  setReportCategory,
  setReportFilters,
} from "../../../store/features/reportsGeneratorPageSlice";

const Sidebar = () => {
  const Language = useSelector(
    (state) => state.userSlice.user.metadata.Language
  );
  const reportCategory = useSelector(
    (state) => state.reportsGeneratorPageSlice.reportCategory
  );
  const dispatch = useDispatch();

  const handleReportCategoryClick = (e) => {
    if (reportCategory !== e.target.id) {
      const categoryId = e.target.id;
      dispatch(resetReportFilters());
      dispatch(setReportCategory(categoryId));
    }
  };

  const generateReportCategoryID = (buttonText) =>
    Object.entries(dictionary[Language]?.reportsGeneratorPage.reportTypes).find(
      ([key, value]) => value === buttonText
    )?.[0];

  return (
    <div className="sidebar">
      <div className="predefined-report-title">
        {svgs.predefinedReportSVG}
        <span style={{ margin: "0px 3px", fontSize: "14px" }}>
          {dictionary[Language]?.reportsGeneratorPage.predefinedReport}
        </span>
      </div>
      {dictionary[Language]?.reportsGeneratorPage.reportTypesArray.map(
        (buttonText, index) => {
          const categoryId = generateReportCategoryID(buttonText);
          return (
            <div
              key={index}
              className={`sidebar-button ${
                reportCategory === categoryId ? "selected" : ""
              }`}
              style={{ textAlign: Language === "english" ? "left" : "right" }}
              id={categoryId}
              onClick={handleReportCategoryClick}
            >
              {buttonText}
            </div>
          );
        }
      )}
    </div>
  );
};

export default Sidebar;
