import Select from "react-select";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import React, { useState, useEffect, useMemo } from "react";
import Dropdown2 from "../Dropdown2/Dropdown2";
import { dictionary } from "../../constants/dictionary";
import insightingLogo from "../../images/insighting-LOGO-5.png";
import insightingSmallLogo from "../../images/small-white_logo.png";
import SupportPopup2 from "../SupportPopup2/SupportPopup2";
import { goToCustomerPage } from "../../utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import "./Navbar.css";
import { setShowCustomerPageFilters } from "../../store/features/tableFiltersSlice";
import { setProductPurchasedAnalyzeTableFilters } from "../../store/features/tableLatestFiltersSlice";
import { setIsOverviewGraphReady } from "../../store/features/homePagePropsSlice";

function Navbar() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectValue, setSelectValue] = useState("");

  const [showMenu, setShowMenu] = useState(false);

  const customersForNavbarData = useSelector(
    (state) => state.dataSlice.customersForNavbarData,
    shallowEqual
  );
  const allKeyOpportunitiesForCustomerPage = useSelector(
    (state) => state.dataSlice.allKeyOpportunitiesForCustomerPage,
    shallowEqual
  );
  const keyOpportunitiesDisplayType = useSelector(
    (state) => state.dataSlice.keyOpportunitiesDisplayType
  );
  const userSlice = useSelector((state) => state.userSlice, shallowEqual);
  const globalSlice = useSelector((state) => state.globalSlice, shallowEqual);

  const { resolution } = globalSlice;
  const { user } = userSlice;
  const {
    InsightingCustomerID,
    InsightingCustomerName,
    Language,
    AgentPermission,
    AgentID,
    UserType,
    TeamAgentsIDs,
  } = user.metadata;

  const isMobile = resolution.device === "mobile";
  const isMobileTablet = resolution.device === "mobile-tablet";

  const autoCompleteOptions = useMemo(() => {
    if (AgentPermission === "agentonly" && UserType === "agent") {
      return customersForNavbarData?.filter((item) => item.AgentID === AgentID);
    } else if (AgentPermission === "teamonly" && UserType === "teamleader") {
      return customersForNavbarData?.filter((item) =>
        [...TeamAgentsIDs?.split("+"), AgentID]?.includes(item.AgentID)
      );
    } else {
      return customersForNavbarData;
    }
  }, [customersForNavbarData]);

  const customSelectStyles = {
    menu: (provided, state) => ({
      ...provided,
      color: state.selectProps.menuColor,
      width: isMobile || isMobileTablet ? 180 : 600,
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "7px 15px",
      borderBottom: "1px dotted #eee",
      color: state.isselectInput ? "#000" : "#000",
      backgroundColor: state.isselectInput ? "#eee" : "#fff",
    }),
    control: (control) => ({
      ...control,
      height: 28,
      color: "#fff",
      borderRadius: 20,
      minHeight: "initial",
      width: isMobile || isMobileTablet ? 180 : 600,
    }),
    placeholder: (placeholder) => ({
      ...placeholder,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }),
    indicatorsContainer: (indicatorsContainer) => ({
      ...indicatorsContainer,
      height: 28,
    }),
  };

  const togglerHandler = () => setShowMenu(!showMenu);

  const handleGoBack = () => {
    if (history.location.pathname === "/customer/details")
      dispatch(setShowCustomerPageFilters(true));
    if (history.location.pathname !== "/") history.goBack();
    return;
  };

  const handleGoHome = () => {
    setSelectValue("");
    if (history.location.pathname !== "/") {
      dispatch(setIsOverviewGraphReady(true));
      // console.log(history.location);
      if (history.location.pathname.toLowerCase()?.includes("revnav"))
        window.location.href = "/";
      else history.push("/", { pathName: history.location.pathname });
    }
  };

  const onCustomerSearchChange = async (CustomerID) => {
    setSelectValue(autoCompleteOptions.find(({ id }) => id === CustomerID));
  };

  useEffect(
    function onSelectValueChanged() {
      if (selectValue && keyOpportunitiesDisplayType !== "spinner") {
        const { id: CustomerID, label: CustomerName, AgentName } = selectValue;
        (async () => {
          try {
            goToCustomerPage(
              CustomerID,
              InsightingCustomerID,
              CustomerName,
              AgentName,
              Language,
              allKeyOpportunitiesForCustomerPage,
              history
            );
            dispatch(setShowCustomerPageFilters(false));
            dispatch(setProductPurchasedAnalyzeTableFilters([]));
            setSelectValue("");
          } catch (error) {
            console.log(error.message);
            console.log(error.stack);
          }
        })();
      }
    },
    [selectValue]
  );

  return (
    <div className="navbar sticky-nav">
      <ul>
        {resolution.width > 350 && (
          <li className="logo">
            <img
              onClick={handleGoHome}
              src={
                resolution.width < 720 ? insightingSmallLogo : insightingLogo
              }
              alt="Insighting Logo"
            />
          </li>
        )}
        <li className="back-button">
          <div onClick={handleGoBack}>
            <FontAwesomeIcon icon={faChevronLeft} />
            {!isMobile && (
              <span>{dictionary[Language]?.navHeaders?.backButton}</span>
            )}
          </div>
        </li>
        <li
          className="search-bar"
          title={
            selectValue
              ? selectValue.label
              : dictionary[Language]?.navHeaders?.searchPlaceHolder
          }
        >
          <Select
            value={selectValue}
            styles={customSelectStyles}
            options={autoCompleteOptions}
            onChange={({ id }) => onCustomerSearchChange(id)}
            placeholder={dictionary[Language]?.navHeaders?.searchPlaceHolder}
          />
        </li>
        {!(isMobile || isMobileTablet) && (
          <li className="insighting-customer-name">{InsightingCustomerName}</li>
        )}
        {!(isMobile || isMobileTablet) && (
          <SupportPopup2 togglerHandler={togglerHandler} />
        )}
        {!isMobile && (
          <li className="greet">
            <div>{user.metadata.UserFullName}</div>
          </li>
        )}

        <Dropdown2 togglerHandler={togglerHandler} />
      </ul>
    </div>
  );
}

export default Navbar;
