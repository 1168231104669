import Box from "@material-ui/core/Box";
import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useFilters, useSortBy, useTable } from "react-table";

import { filterTypes, getColumnByAccessor } from "../../../utils/filters";

import {
  getMonthForHeader,
  setCurrencyForTableHeaders,
  isSorted,
  getUrgencyValue,
} from "../../../utils/helpers";
import { PriorityButton } from "../../PriorityButton/PriorityButton";
import { engCOLUMNS } from "./ColumnsFiles/engColumns";
import { hebCOLUMNS } from "./ColumnsFiles/hebColumns";
import "./CustomersOverviewTable.css";

import { currencyMap } from "../../../constants/currency-map";
import InsightTypesLogos from "../../InsightTypesLogos/InsightTypesLogos";
import OnGoingLight from "../../OnGoingLight/OnGoingLight";
import moment from "moment";
import momentBusinessDays from "moment-business-days";
import { aggInsightsMap } from "../../../constants/insights-map";
import numberUtils from "../../../utils/number";
import {
  setCustomerNameForGraph,
  setLoadingSpinner,
  setSelectedCustomer,
  setTrendsData,
} from "../../../store/features/homePagePropsSlice";
import { setCustomersOverviewTableData } from "../../../store/features/dataSlice";
import {
  setClickedRow,
  setLastComparisonValue,
  setOverviewMenuPosition,
  setSelectedRowForMenu,
} from "../../../store/features/customersOverviewSlice";
import CustomersOverviewAboveTable from "./CustomersOverviewAboveTable";
import CustomersOverviewMenu from "./CustomersOverviewMenu";
import CustomersOverviewGroupFilter from "./CustomersOverviewGroupFilter";
import CustomersOverviewFilters from "./CustomersOverviewFilters";
import { urgencyColorsPalette } from "../../../constants/urgencyColorsPalette";
import { setCustomerTrendsFilters } from "../../../store/features/tableFiltersSlice";

function getComparisonValue(item, comparisonValue) {
  return comparisonValue === "avgP3M"
    ? item.SalesP3M_Avg
    : comparisonValue === "avgPurchaseP3M"
    ? item.SalesP3M_Avg_purchase_only
    : comparisonValue === "lastMonth"
    ? item.SalesLastMonth
    : comparisonValue === "yearAgo"
    ? item.SalesYA
    : null;
}
function getCustomersOpportunitiesData(
  customerID,
  keyOpportunitiesTableDataCopy
) {
  const found = keyOpportunitiesTableDataCopy.find(
    (opp) => opp.CustomerID === customerID
  );

  if (found)
    return {
      OriginalInsightType: found.OriginalInsightType,
      AggPotentialValue: found.AggPotentialValue?.replaceAll(",", ""),
      AchievedValue: found.AchievedValue?.replaceAll(",", ""),
      CalcProgress: found.CalcProgress,
    };
  else return false;
}

function calcOverviewProgress(achieved, divisor) {
  if (achieved > 0 && divisor <= 0) {
    return 100;
  } else if (achieved / divisor === Infinity) {
    return 100;
  } else if (achieved / divisor === -Infinity || achieved / divisor < 0) {
    return 0;
  } else {
    return (achieved / divisor) * 100;
  }
}

function getUrgency(
  customerSize,
  calcProgress,
  expectedValue,
  avgMinMax,
  achievedValue,
  customersOpportunitiesData
) {
  /*
  customerSize:
  1-small
  3-medium
  5-big

  retrun(#):
  1 - No (NEW)
  3 - Low
  6 - Medium
  9 - High
  */

  let daysProgress = businessDaysPassed(); // 1/2/3/4
  let urgencyValue = 1;
  if (parseInt(customerSize) === 5) {
    if (
      customersOpportunitiesData.OriginalInsightType?.some((insight) =>
        insight?.includes("Insight2")
      ) ||
      customersOpportunitiesData.OriginalInsightType?.some((insight) =>
        insight?.includes("Insight4")
      )
    ) {
      if (
        customersOpportunitiesData.CalcProgress <
        progMultiplier.bigH[daysProgress]
      )
        urgencyValue = Math.max(urgencyValue, 9);
      if (
        progMultiplier.bigH[daysProgress] <=
          customersOpportunitiesData.CalcProgress &&
        customersOpportunitiesData.CalcProgress <
          progMultiplier.bigM[daysProgress]
      )
        urgencyValue = Math.max(urgencyValue, 6);
      else urgencyValue = Math.max(urgencyValue, 1);
    }
    // CALC FOR THE CONDITION BELOW SAME AS ABOVE SO I COMBINED THEM
    // else if (customersOpportunitiesData.OriginalInsightType?.includes("insight4")) {}
    if (
      customersOpportunitiesData.OriginalInsightType?.some((insight) =>
        insight?.includes("Insight1")
      )
    ) {
      if (
        customersOpportunitiesData.AggPotentialValue -
          customersOpportunitiesData.AchievedValue >
        avgMinMax * 0.2
      ) {
        urgencyValue = Math.max(urgencyValue, 9);
      } else urgencyValue = Math.max(urgencyValue, 1);
    } else {
      if (achievedValue < progMultiplier.bigH[daysProgress] * avgMinMax)
        urgencyValue = Math.max(urgencyValue, 6);
      if (
        progMultiplier.bigH[daysProgress] * avgMinMax <= achievedValue &&
        achievedValue < progMultiplier.bigM[daysProgress] * avgMinMax
      )
        urgencyValue = Math.max(urgencyValue, 3);
      else {
        urgencyValue = Math.max(urgencyValue, 1);
      }
    }
  } else if (parseInt(customerSize) === 3) {
    if (
      customersOpportunitiesData.OriginalInsightType?.some((insight) =>
        insight?.includes("Insight2")
      )
    ) {
      if (
        customersOpportunitiesData.CalcProgress <
        progMultiplier.medM[daysProgress]
      )
        urgencyValue = Math.max(urgencyValue, 6);
      if (
        progMultiplier.medM[daysProgress] <=
          customersOpportunitiesData.CalcProgress &&
        customersOpportunitiesData.CalcProgress <
          progMultiplier.medL[daysProgress]
      )
        urgencyValue = Math.max(urgencyValue, 3);
      else urgencyValue = Math.max(urgencyValue, 1);
    }
    if (
      customersOpportunitiesData.OriginalInsightType?.some((insight) =>
        insight?.includes("Insight4")
      )
    ) {
      if (
        customersOpportunitiesData.CalcProgress <
        progMultiplier.medM[daysProgress]
      )
        urgencyValue = Math.max(urgencyValue, 6);
      else urgencyValue = Math.max(urgencyValue, 1);
    }
    if (
      customersOpportunitiesData.OriginalInsightType?.some((insight) =>
        insight?.includes("Insight1")
      )
    ) {
      if (
        customersOpportunitiesData.AggPotentialValue -
          customersOpportunitiesData.AchievedValue >
        avgMinMax * 0.35
      )
        urgencyValue = Math.max(urgencyValue, 6);
      else urgencyValue = Math.max(urgencyValue, 1);
    } else {
      if (achievedValue < progMultiplier.medL[daysProgress] * avgMinMax)
        urgencyValue = Math.max(urgencyValue, 3);
      else {
        urgencyValue = Math.max(urgencyValue, 1);
      }
    }
  } else if (parseInt(customerSize) === 1) {
    if (
      customersOpportunitiesData.OriginalInsightType?.some((insight) =>
        insight?.includes("Insight2")
      )
    ) {
      if (parseFloat(calcProgress) === 0)
        urgencyValue = Math.max(urgencyValue, 3);
      else {
        urgencyValue = Math.max(urgencyValue, 1);
      }
    } else urgencyValue = Math.max(urgencyValue, 1);
  } else {
    urgencyValue = Math.max(urgencyValue, 1);
  }
  return urgencyValue;
}

function businessDaysPassed() {
  if (momentBusinessDays().businessDaysIntoMonth() < 6) return 1;
  else if (momentBusinessDays().businessDaysIntoMonth() < 11) return 2;
  else if (momentBusinessDays().businessDaysIntoMonth() < 16) return 3;
  else if (momentBusinessDays().businessDaysIntoMonth() >= 16) return 4;
}

const progMultiplier = {
  bigH: {
    1: 0.1,
    2: 0.15,
    3: 0.3,
    4: 0.45,
  },
  bigM: {
    1: 0.17,
    2: 0.3,
    3: 0.45,
    4: 0.65,
  },
  medM: {
    1: 0.1,
    2: 0.15,
    3: 0.3,
    4: 0.4,
  },
  medL: {
    1: 0.17,
    2: 0.3,
    3: 0.45,
    4: 0.65,
  },
};
const { round } = Math;
const { numberWithCommas } = numberUtils;
const todaysDate = moment(moment().format("DD/MM/YYYY"), "D/M/YYYY");

function CustomersOverviewTable() {
  const staticAppDate = useSelector((state) => state.globalSlice.staticAppDate);
  const userSlice = useSelector((state) => state.userSlice, shallowEqual);
  const keyOpportunitiesTableDataCopy = useSelector(
    (state) => state.dataSlice.keyOpportunitiesTableDataCopy,
    shallowEqual
  );
  const customersOverviewData = useSelector(
    (state) => state.dataSlice.customersOverviewData,
    shallowEqual
  );
  const salesByCustomer_MainData = useSelector(
    (state) => state.dataSlice.salesByCustomer_MainData,
    shallowEqual
  );
  const keyOpportunitiesDisplayType = useSelector(
    (state) => state.dataSlice.keyOpportunitiesDisplayType
  );
  const customerTrendsFilters = useSelector(
    (state) => state.tableFiltersSlice.customerTrendsFilters,
    shallowEqual
  );
  const customerTrendsSorts = useSelector(
    (state) => state.tableSortsSlice.customerTrendsSorts,
    shallowEqual
  );
  const trendsData = useSelector(
    (state) => state.homePagePropsSlice.trendsData,
    shallowEqual
  );
  const comparisonValue = useSelector(
    (state) => state.homePagePropsSlice.comparisonValue
  );
  const lastComparisonValue = useSelector(
    (state) => state.customersOverviewSlice.lastComparisonValue
  );
  const date = staticAppDate ? new Date(staticAppDate) : new Date();
  
  let sixMonthsAgo = new Date(date);
  sixMonthsAgo.setMonth(date.getMonth() - 6);
  const sixMonthsAgoMonth = sixMonthsAgo.getMonth() + 1;
  const sixMonthsAgoDay = sixMonthsAgo.getDate();
  const sixMonthsAgoYear = sixMonthsAgo.getFullYear();

  const goodFormat = `${sixMonthsAgoYear}-${sixMonthsAgoMonth}-${sixMonthsAgoDay}`;
  const {
    user: {
      metadata: {
        Currency,
        Language,
        UserType,
        InsightingCustomerID,
        AgentPermission,
        AgentID,
        TeamAgentsIDs,
        // CustHierValues,
      },
    },
  } = userSlice;

  const dispatch = useDispatch();

  const SalesHeadersMap = {
    english: {
      yearAgo: `Sales ${getMonthForHeader("currentMonthYearAgo")}`,
      lastMonth: `Sales ${getMonthForHeader("lastMonth")}`,
      avgP3M: `Avg ${getMonthForHeader("threeMonthsAgo")}-${getMonthForHeader(
        "lastMonth"
      )}`,
      avgPurchaseP3M: `Avg Purchase ${getMonthForHeader(
        "threeMonthsAgo"
      )}-${getMonthForHeader("lastMonth")}`,
    },
    hebrew: {
      yearAgo: `מכירות ${getMonthForHeader("currentMonthYearAgo")}`,
      lastMonth: `מכירות ${getMonthForHeader("lastMonth")}`,
      avgP3M: `ממוצע ${getMonthForHeader("lastMonth")}-${getMonthForHeader(
        "threeMonthsAgo"
      )}`,
      avgPurchaseP3M: `ממוצע רכישות ${getMonthForHeader(
        "lastMonth"
      )}-${getMonthForHeader("threeMonthsAgo")}`,
    },
  };

  const columns = useMemo(() => {
    return (
      Language === "hebrew"
        ? setCurrencyForTableHeaders(Currency, hebCOLUMNS, Language)
        : Language === "english"
        ? setCurrencyForTableHeaders(Currency, engCOLUMNS, Language)
        : setCurrencyForTableHeaders(Currency, engCOLUMNS, Language)
    ).map((item) => {
      return item.accessor === "comparisonValue"
        ? {
            ...item,
            Header:
              SalesHeadersMap[Language][comparisonValue] +
              " " +
              currencyMap[Currency],
          }
        : item;
    });
  }, [Currency, Language, comparisonValue]);

  const data = useMemo(() => {
    return trendsData;
  }, [trendsData]);

  const groupedHeader = useRef(null);
  const tableRef = useRef(null);
  const [displayAddGroupFilter, setDisplayGroupFilter] = useState(true);

  const handleRightClick = (e, row) => {
    const { original } = row;
    dispatch(setSelectedRowForMenu(original));
    dispatch(
      setOverviewMenuPosition({
        top: e.clientY,
        left: e.clientX,
      })
    );
    e.preventDefault();
    e.stopPropagation();
  };

  const handleShowGraphLeftClick = (e, row) => {
    const { original } = row;
    dispatch(setClickedRow(Math.random()));
    // if (selectedCustomer !== original) {
    dispatch(setLoadingSpinner(true));
    dispatch(setSelectedCustomer(original));
    dispatch(setCustomerNameForGraph(original.CustomerName));
    // }
    e.stopPropagation();
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: {
        sortBy: customerTrendsSorts,
        filters: customerTrendsFilters,
      },
      autoResetPage: false,
      autoResetExpanded: false,
      autoResetGroupBy: false,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false,
    },
    useFilters,
    useSortBy
  );

  useEffect(async () => {
    async function trends() {
      let customersOverviewDataRes;

      if (AgentPermission === "agentonly")
        customersOverviewDataRes = customersOverviewData?.Items?.filter(
          (item) => item.AgentID === AgentID
        );
      else if (UserType === "teamleader")
        customersOverviewDataRes = customersOverviewData?.Items?.filter((obj) =>
          TeamAgentsIDs?.split("+")?.includes(obj.AgentID)
        );
      else customersOverviewDataRes = customersOverviewData?.Items;

      const TrendsForTable = customersOverviewDataRes
        ?.filter((item) => item.CustomerID)
        .map((item) => {
          let case1 =
            parseInt(item.LastOrder?.split("_")[1]) === date.getFullYear();
          let case2 =
            parseInt(item.LastOrder?.split("_")[0]) === date.getMonth() + 1;

          let lastOrderIsCurrentMonth = case1 && case2;

          let lastOrder = item.LastOrder;

          let customersOpportunitiesData = getCustomersOpportunitiesData(
            item.CustomerID,
            keyOpportunitiesTableDataCopy
          );
          return {
            ...item,
            customerCode: item.CustomerID,
            customerName: item.CustomerName,
            salesAgent: item.AgentName,
            lastOrder: lastOrder?.split("_").join("/") || "-",
            expectedComparisonTermValue: getComparisonValue(
              item,
              comparisonValue
            ),
            progressExpectation: calcOverviewProgress(
              parseFloat(
                date.getDate() === 1
                  ? 0
                  : !lastOrderIsCurrentMonth
                  ? 0
                  : item.AchievedValue
              ),
              getComparisonValue(item, comparisonValue)
            ),
            achieved:
              date.getDate() === 1
                ? 0
                : !lastOrderIsCurrentMonth
                ? 0
                : round(item.AchievedValue).toLocaleString() === "NaN"
                ? "-"
                : numberWithCommas(round(item.AchievedValue)),
            comparisonValue:
              round(
                getComparisonValue(item, comparisonValue)
              ).toLocaleString() === "NaN"
                ? "-"
                : numberWithCommas(
                    round(getComparisonValue(item, comparisonValue))
                  ),
            InsightType:
              keyOpportunitiesTableDataCopy.find(
                (opp) => opp.CustomerID === item.CustomerID
              )?.InsightType || aggInsightsMap[Language].None,
            OriginalInsightType:
              customersOpportunitiesData?.OriginalInsightType,
            CreatedDate: keyOpportunitiesTableDataCopy.find(
              (opp) => opp.CustomerID === item.CustomerID
            )?.CreatedDate,
            // urgency: 3,
            urgency: customersOpportunitiesData
              ? getUrgency(
                  item.Param4,
                  item.CalcProgress,
                  item.ExpectedValue,
                  item.Param2,
                  item.AchievedValue,
                  customersOpportunitiesData
                  // item.CustomerID
                )
              : 1,
            priority: item.Priority,
            UpdateDate: item.UpdateDate,
            openOrders: !isNaN(item.OrdersValueCurrMonth)
              ? numberWithCommas(round(item.OrdersValueCurrMonth))
              : 0,
            custHierValues: item.CustHierValues?.slice(1, -1)?.split("~"),
            // lastComparisonValue: comparisonValue,
          };
        });
      dispatch(setLastComparisonValue(comparisonValue));
      dispatch(setCustomersOverviewTableData(TrendsForTable));
      dispatch(setTrendsData(TrendsForTable));
      sessionStorage.setItem(
        "sessionTrendsData",
        JSON.stringify(
          TrendsForTable.map((i) => ({
            InsightType: i.InsightType,
            OriginalInsightType: i.OriginalInsightType,
            urgency: i.urgency,
            LastOrder: i.LastOrder,
            Priority: i.Priority,
            CustomerID: i.CustomerID,
            CustomerName: i.CustomerName,
            AgentName: i.AgentName,
            AgentID: i.AgentID,
            CreatedDate: i.CreatedDate,
            UpdateDate: i.UpdateDate,
          }))
        )
      );
      return;
    }
    if (
      salesByCustomer_MainData.Items &&
      keyOpportunitiesDisplayType !== "spinner" &&
      customersOverviewData.Items &&
      (trendsData?.length === 0 || comparisonValue !== lastComparisonValue)
    ) {
      trends();
    }
  }, [
    InsightingCustomerID,
    salesByCustomer_MainData,
    comparisonValue,
    keyOpportunitiesTableDataCopy,
    customersOverviewData,
  ]);

  useEffect(() => {
    return () => {
      dispatch(setCustomerTrendsFilters(state.filters));
    };
  }, [state.filters]);


  return (
    <div
      style={{
        marginLeft: "0.5rem",
        direction: Language === "hebrew" ? "rtl" : "ltr",
      }}
    >
      <CustomersOverviewAboveTable rows={rows} />
      <Box
        display="flex"
        alignItems="center"
        style={{
          direction: `${Language === "hebrew" ? "rtl" : "ltr"}`,
          marginRight: "3.2rem",
        }}
      >
        <Box display="flex" alignItems="center" style={{ marginLeft: 10 }}>
          <CustomersOverviewGroupFilter
            displayAddGroupFilter={displayAddGroupFilter}
            setDisplayGroupFilter={setDisplayGroupFilter}
          />
          <CustomersOverviewFilters
            filters={state.filters}
            headers={headerGroups[0].headers.map((item) => {
              return item.id === "comparisonValue"
                ? {
                    ...item,
                    Header:
                      SalesHeadersMap[Language][comparisonValue] +
                      " " +
                      currencyMap[Currency],
                  }
                : item;
            })}
            columns={columns}
          />
        </Box>
      </Box>
      <div
        ref={tableRef}
        id="customers-overview-table"
        className="customers-overview-table-div"
      >
        <table {...getTableProps()} className="customers-overview-table">
          <thead>
            {headerGroups.map((headerGroup) => {
              return (
                <tr {...headerGroup.getHeaderGroupProps()} ref={groupedHeader}>
                  {headerGroup.headers.map((column) => {
                    return (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        id={column.id}
                      >
                        {column.render("Header")}
                        <span>{isSorted(column)}</span>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps}>
            <CustomersOverviewMenu
              groupedHeader={groupedHeader}
              tableRef={tableRef}
              rows={rows}
              headerGroups={headerGroups}
              csvHeaders={columns}
              // csvHeaders={Array.from(
              //   groupedHeader?.current?.children || []
              // ).map((row) => ({
              //   Header: row.childNodes[0].data,
              //   accessor: row.id,
              // }))}
              overviewFilters={state.filters}
              overviewSorts={state.sortBy}
            />
            {rows.map((row) => {
              // console.log("-----preparing customerSoverview row-----");
              prepareRow(row);
              return (
                <tr
                  key={row.id}
                  {...row.getRowProps()}
                  onContextMenu={(e) => handleRightClick(e, row)}
                  onClick={(e) => handleShowGraphLeftClick(e, row)}
                >
                  {row.cells.map((cell) => {
                    const renderDefaultCell = () => (
                      <td key={cell.column.id} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    );

                    function renderProgressExpectationCell() {
                      if (isNaN(parseFloat(cell.value))) {
                        return (
                          <td key={cell.column.id} {...cell.getCellProps()}>
                            -
                          </td>
                        );
                      } else {
                        let progressValue = parseInt(cell.value);
                        let percentage = Math.min(
                          Math.max(progressValue, 0),
                          999
                        );
                        const displayPercentage = Math.min(percentage, 100);

                        const progressBarWidth = `${displayPercentage}%`;

                        let backgroundColor =
                          displayPercentage > 70
                            ? "#24b474"
                            : displayPercentage > 25
                            ? "#ffc408"
                            : displayPercentage > 0
                            ? "#fd6767"
                            : "transparent";

                        return (
                          <td
                            key={cell.column.id}
                            {...cell.getCellProps()}
                            className="overview-progress-cell"
                            style={{
                              width: "7rem",
                              border: "1px solid #ddd",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{
                                  border: "1px solid #D1D1D1",
                                  height: "1rem",
                                  borderRadius: "50px",
                                  width: "100%",
                                  marginLeft: "5px",
                                  position: "relative",
                                }}
                              >
                                <div
                                  style={{
                                    width: progressBarWidth,
                                    height: "100%",
                                    backgroundColor: backgroundColor,
                                    borderRadius: "50px",
                                    position: "absolute",
                                    left: 0,
                                    top: 0,
                                  }}
                                />
                              </div>
                              <span style={{ margin: "0 5px" }}>
                                {percentage}%
                              </span>
                            </div>
                          </td>
                        );
                      }
                    }

                    function renderInsightTypeCell() {
                      return (
                        <td
                          key={cell.column.id}
                          style={{ textAlign: "start" }}
                          {...cell.getCellProps()}
                        >
                          <InsightTypesLogos row={row} language={Language} />
                          <OnGoingLight
                            row={row}
                            todaysDate={todaysDate}
                            language={Language}
                          />
                        </td>
                      );
                    }

                    function renderComparisonOpenOrdersCell() {
                      return (
                        <td
                          key={cell.column.id}
                          style={{ maxWidth: "7rem" }}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    }

                    function renderUrgencyCell() {
                      return (
                        <td
                          key={cell.column.id}
                          style={{ width: "2.5rem", height: "2rem" }}
                        >
                          <span
                            style={{
                              color:
                                urgencyColorsPalette.text[
                                  getUrgencyValue(cell.value)
                                ],
                              backgroundColor:
                                urgencyColorsPalette.background[
                                  getUrgencyValue(cell.value)
                                ],
                              borderRadius: "5px",
                              fontSize: "11px",
                              fontWeight: "bold",
                              padding: "0.3rem",
                            }}
                          >
                            {getUrgencyValue(cell.value)}
                          </span>
                        </td>
                      );
                    }

                    function renderPriorityCell() {
                      return (
                        <td key={cell.column.id} {...cell.getCellProps()}>
                          <PriorityButton
                            row={row}
                            userType={UserType}
                            status={cell.value}
                          />
                        </td>
                      );
                    }

                    switch (cell.column.id) {
                      case "progressExpectation":
                        return renderProgressExpectationCell();
                      case "InsightType":
                        return renderInsightTypeCell();
                      case "comparisonValue":
                      case "openOrders":
                        return renderComparisonOpenOrdersCell();
                      case "urgency":
                        return renderUrgencyCell();
                      case "priority":
                        return renderPriorityCell();
                      default:
                        return renderDefaultCell();
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default memo(CustomersOverviewTable);
