export const labelTextMap = {
  hebrew: {
    BaseSales:
      "שינוי במכירות מלקוחות קיימים שרכשו את אותם מוצרים בין שני התקופות",
    NewCustomers: "הכנסה שנוצרה הלקוחות חדשים או מלקוחות שחזרו לרכוש",
    LostCustomers:
      "הכנסה שאבדה מלקוחות שלא ביצעו רכישה בתקופה הנוכחית אך רכשו בתקופה הקודמת",
    DistributionGains:
      "הכנסה שהתקבלה ממוצרים שנרכשו על ידי לקוחות פעילים בתקופה הנוכחית, שלא נרכשו בתקופה הקודמת",
    DistributionLosses:
      "הכנסה שאבדה ממוצרים שלא נרכשו על ידי לקוחות פעילים בתקופה הנוכחית, למרות שנרכשו בעבר",
    Total: "סך השינוי בין התקופות",
  },
  english: {
    BaseSales:
      "Change in sales from existing customers purchasing the same products between two periods",
    NewCustomers:
      "Revenue gained from first-time purchases or customers resuming purchases",
    LostCustomers:
      "Revenue lost from customers who did not purchase in the current period",
    DistributionGains:
      "Sales gained from active customers buying items they didn't purchase in the past period",
    DistributionLosses:
      "Sales lost from active customers who stopped purchasing items they bought in the previous period",
    Total: "Total change in sales between the two compared periods",
  },
};
