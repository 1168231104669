import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reportCategory: "",
  reportFilters: {
    Type: "",
    ReportName: "",
    InsightingCustomerID: null,
    Period1: ["", []],
    Period2: ["", []],
    aggregationType: "Total",
    AgentIDs: [],
    CustomerIDs: [],
    ItemCatNumbers: [],
    AgentList: [],
    CustomerList: [],
    ItemList: [],
    ValuesToDisplay: [],
    CustomersHierarchies: {},
    groups: [], // Add the groups array here
  },
  startReportDate: null,
  endReportDate: null,
  startComparedDate: null,
  endComparedDate: null,
  comparePeriodCheckbox: false,
  fetchingSpinner: false,
  reportDetailsResData: [],
  itemCatNumbers: [],
  isSelectionPending: false,
  groups: [],
  selectedOption1: null,
  selectedCategory: null,
  selectedOptions2: [],
};

export const reportsGeneratorPageSlice = createSlice({
  name: "reportsGeneratorPageSlice",
  initialState,
  reducers: {
    setReportCategory: (state, action) => {
      state.reportCategory = action.payload;
    },
    setIsSelectionPending: (state, action) => {
      state.isSelectionPending = action.payload;
    },
    setGroups: (state, action) => {
      state.groups = action.payload;
    },
    setSelectedOption1: (state, action) => {
      state.selectedOption1 = action.payload;
    },
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    setSelectedOptions2: (state, action) => {
      state.selectedOptions2 = action.payload;
    },
    setReportFilters: (state, action) => {
      state.reportFilters = action.payload;
    },
    updateReportFilters: (state, action) => {
      state.reportFilters = {
        ...state.reportFilters,
        ...action.payload, // Merge the new filters with the existing filters
      };
    },
    setStartReportDate: (state, action) => {
      state.startReportDate = action.payload
        ? action.payload.toISOString()
        : null;
    },
    setEndReportDate: (state, action) => {
      state.endReportDate = action.payload
        ? action.payload.toISOString()
        : null;
    },
    setStartComparedDate: (state, action) => {
      state.startComparedDate = action.payload
        ? action.payload.toISOString()
        : null;
    },
    setEndComparedDate: (state, action) => {
      state.endComparedDate = action.payload
        ? action.payload.toISOString()
        : null;
    },
    resetReportFilters: (state) => {
      state.reportFilters = initialState.reportFilters;
      state.startReportDate = null;
      state.endReportDate = null;
      state.startComparedDate = null;
      state.endComparedDate = null;
      state.comparePeriodCheckbox = false;
      state.isSelectionPending = false;
      state.groups = [];
      state.selectedOption1 = null;
      state.selectedCategory = null;
      state.selectedOptions2 = [];
    },
    setComparePeriodCheckbox: (state, action) => {
      state.comparePeriodCheckbox = action.payload;
    },
    setFetchingSpinner: (state, action) => {
      state.fetchingSpinner = action.payload;
    },
    setReportDetailsResData: (state, action) => {
      state.reportDetailsResData = action.payload;
    },
    setItemCatNumbers: (state, action) => {
      state.itemCatNumbers = action.payload;
    },
  },
});

export const {
  setReportCategory,
  setReportFilters,
  updateReportFilters,
  setStartReportDate,
  setEndReportDate,
  setStartComparedDate,
  setEndComparedDate,
  resetReportFilters,
  setComparePeriodCheckbox,
  setFetchingSpinner,
  setReportDetailsResData,
  setItemCatNumbers,
  setGroups,
  setIsSelectionPending,
  setSelectedCategory,
  setSelectedOption1,
  setSelectedOptions2,
} = reportsGeneratorPageSlice.actions;

export default reportsGeneratorPageSlice.reducer;
