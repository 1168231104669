const local = "http://localhost:8080";
const irDev = "https://ir-devapi.insighting-app.io";
const prod = "https://api.insighting-app.io";
const dev = "https://devapi.insighting-app.io";

const domain =
  process.env.NODE_ENV === "development"
    ? local
    : process.env.REACT_APP_NODE_ENV === "ir-development"
    ? irDev
    : process.env.REACT_APP_NODE_ENV === "production"
    ? prod
    : dev;
export default domain;
