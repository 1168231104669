import React from "react";
import "./ReportsGeneratorPage.css";
import TitleBar from "./ReportsGeneratorComponents/TitleBar";
import Sidebar from "./ReportsGeneratorComponents/SideBar";
import MainContent from "./ReportsGeneratorComponents/MainContent";
import { useSelector } from "react-redux";

const ReportsGeneratorPage = () => {
  const Language = useSelector(
    (state) => state.userSlice.user.metadata.Language
  );

  return (
    <div
      className="app-container"
      style={{ direction: Language === "hebrew" ? "rtl" : "ltr" }}
    >
      <TitleBar />
      <div className="content-container">
        <Sidebar />
        <MainContent />
      </div>
    </div>
  );
};

export default ReportsGeneratorPage;
