import React from "react";
import { Menu, MenuItem } from "@material-ui/core";
import NestedMenuItem from "material-ui-nested-menu-item";
import { CSVLink } from "react-csv";
import { dictionary } from "../../../constants/dictionary";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getRowsCsv } from "../../../utils/helpers";
import pdfUtils from "../../../utils/pdf";
import { setMenuPosition } from "../../../store/features/salesByAgentSlice";

export default function SalesReviewMenu({
  tableRef,
  salesPeriod,
  setSalesPeriod,
  rows,
  filterableColumns,
}) {
  const dispatch = useDispatch();
  const Language = useSelector(
    (state) => state.userSlice.user.metadata.Language
  );
  const menuPosition = useSelector(
    (state) => state.salesByAgentSlice.menuPosition,
    shallowEqual
  );

  const handlePDFExportToClick = () => {
    pdfUtils.download(tableRef.current, "sales-by-agent");
    dispatch(setMenuPosition(null));
  };

  const handlePeriodClick = (e) => {
    setSalesPeriod(e.target.id);
    dispatch(setMenuPosition(null));
  };

  return (
    <Menu
      open={!!menuPosition}
      onClose={() => {
        dispatch(setMenuPosition(null));
      }}
      anchorReference="anchorPosition"
      anchorPosition={menuPosition}
    >
      <NestedMenuItem
        label={dictionary[Language]?.salesReviewTable.menuOptions.period}
        parentMenuOpen={!!menuPosition}
      >
        <MenuItem id="thisMonth" onClick={handlePeriodClick}>
          <div className="context-menu-option">
            <span
              className={`context-menu-checkmark ${
                salesPeriod === "thisMonth" ? "checkmark-on" : "checkmark-off"
              }`}
            >
              &#10003;
            </span>
            <span id="thisMonth">
              {dictionary[Language]?.salesReviewTable.menuOptions.thisMonth}
            </span>
          </div>
        </MenuItem>
        <MenuItem id="lastMonth" onClick={handlePeriodClick}>
          <div className="context-menu-option">
            <span
              className={`context-menu-checkmark ${
                salesPeriod === "lastMonth" ? "checkmark-on" : "checkmark-off"
              }`}
            >
              &#10003;
            </span>
            <span id="lastMonth">
              {dictionary[Language]?.salesReviewTable.menuOptions.lastMonth}
            </span>
          </div>
        </MenuItem>
        <MenuItem id="yearToDate" onClick={handlePeriodClick}>
          <div className="context-menu-option">
            <span
              className={`context-menu-checkmark ${
                salesPeriod === "yearToDate" ? "checkmark-on" : "checkmark-off"
              }`}
            >
              &#10003;
            </span>
            <span id="yearToDate">
              {dictionary[Language]?.salesReviewTable.menuOptions.yearToDate}
            </span>
          </div>
        </MenuItem>
      </NestedMenuItem>
      <div className="menu-separator-div"></div>
      <NestedMenuItem
        label={dictionary[Language]?.salesReviewTable.menuOptions.exportTo}
        parentMenuOpen={!!menuPosition}
      >
        <MenuItem id="csv">
          <CSVLink
            target="_blank"
            data={getRowsCsv(rows, filterableColumns)}
            filename={`sales_overview.csv`}
            style={{ color: "black" }}
            onClick={() => {
              dispatch(setMenuPosition(null));
            }}
          >
            CSV
          </CSVLink>
        </MenuItem>
        <MenuItem id="pdf" onClick={handlePDFExportToClick}>
          PDF
        </MenuItem>
      </NestedMenuItem>
    </Menu>
  );
}
