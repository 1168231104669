import React from "react";
import svgs from "../../../../utils/svgs";
import { dictionary } from "../../../../constants/dictionary";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setReportFilters } from "../../../../store/features/reportsGeneratorPageSlice";

function ReportDisplayOptionsFilter() {
  const dispatch = useDispatch();
  const Language = useSelector(
    (state) => state.userSlice.user.metadata.Language
  );
  const reportFilters = useSelector(
    (state) => state.reportsGeneratorPageSlice.reportFilters,
    shallowEqual
  );

  const valueOptionText =
    dictionary[Language]?.reportsGeneratorPage.reportValuesToDisplay.valueSales;

  const unitsOptionText =
    dictionary[Language]?.reportsGeneratorPage.reportValuesToDisplay.units;

  const uniqueElementsOptionText =
    dictionary[Language]?.reportsGeneratorPage.reportValuesToDisplay
      .uniqueElements;

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    if (checked) {
      dispatch(
        setReportFilters({
          ...reportFilters,
          ValuesToDisplay: [...reportFilters.ValuesToDisplay, id],
        })
      );
    } else {
      dispatch(
        setReportFilters({
          ...reportFilters,
          ValuesToDisplay: reportFilters.ValuesToDisplay?.filter(
            (i) => i !== id
          ),
        })
      );
    }
  };

  return (
    <div className="report-filter-box">
      <div className="report-filter-header">
        <div className="report-svgs">{svgs.tableSVG}</div>
        <div>
          {
            dictionary[Language]?.reportsGeneratorPage.reportFiltersHeaders
              .valuesToDisplay
          }
        </div>
        <div className="report-filter-header-mandatory">*</div>
      </div>
      <div className="report-specific-filter-box">
        <div className="report-specific-filter-box-details">
          <div>
            <input
              type="checkbox"
              name=""
              id="valueSales"
              checked={reportFilters.ValuesToDisplay?.includes("valueSales")}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="valueSales"> {valueOptionText}</label>
          </div>
          <div style={{ padding: "3rem 0" }}>
            <input
              type="checkbox"
              name=""
              id="units"
              checked={reportFilters.ValuesToDisplay?.includes("units")}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="units"> {unitsOptionText}</label>
          </div>
          <div>
            <input
              type="checkbox"
              name=""
              id="uniqueElements"
              checked={reportFilters.ValuesToDisplay?.includes(
                "uniqueElements"
              )}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="uniqueElements"> {uniqueElementsOptionText}</label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportDisplayOptionsFilter;
