import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import { grey } from "@mui/material/colors";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import CardContent from "@material-ui/core/CardContent";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { dictionary } from "../../constants/dictionary";
import { shallowEqual, useSelector } from "react-redux";
import HierarchySelectors from "../HierarchySelectors/HierarchySelectors";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  card: {
    top: -10,
    zIndex: 6,
    width: 630,
    height: 420,
    display: "flex",
    position: "absolute",
    flexDirection: "column",
    boxShadow: `0 0 5px ${grey[500]}`,
    fontSize: 12,
    [theme.breakpoints.down("xs")]: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: "auto",
      height: "fit-content",
    },
  },
  formControl: {
    width: 140,
  },
  filtersCloseIcon: {
    opacity: 0.82,
    cursor: "pointer",
    fontSize: 18,
    transition: "opacity 0.4s",
    "&:hover": {
      opacity: 1,
    },
  },
  font: {
    fontSize: 13,
  },
  cardContent: {},
}));

export default function HierarchyPicker({
  visible = false,
  setVisibility = () => {},
}) {
  const classes = useStyles();
  const userSlice = useSelector((state) => state.userSlice, shallowEqual);
  const {
    user: {
      metadata: { Language, Currency },
    },
  } = userSlice;

  return (
    <form name="filter-picker" className={classes.root}>
      {!visible ? null : (
        <Card
          className={classes.card}
          style={{ direction: Language === "hebrew" ? "rtl" : "ltr" }}
        >
          <CardContent className={classes.cardContent}>
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Typography gutterBottom variant="body2">
                {dictionary[Language].hierarchyBox.title}
              </Typography>
              <CloseIcon
                className={classes.filtersCloseIcon}
                onClick={() => setVisibility(false)}
              />
            </Box>
            <Typography gutterBottom variant="body2">
              {dictionary[Language].hierarchyBox.subtitle}
            </Typography>

            <HierarchySelectors
              setVisibility={setVisibility}
            />
            <FormControl className={classes.filtersCloseIcon}></FormControl>
          </CardContent>
        </Card>
      )}
    </form>
  );
}
