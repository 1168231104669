import NumberColumnFilter from "../../../Filters/NumberColumnFilter";
import CheckedColumnFilter from "../../../Filters/CheckedColumnFilter";

import { sortTypes } from "../../../../utils/sort";

export const engGROUPED_COLUMNS = [
  {
    Header: "Sales Agent",
    accessor: "SalesAgent",
    filter: "checked",
    Filter: CheckedColumnFilter,
    canFilter: true,
  },
  {
    Header: "Target Sales Vs. Target Sales Ext.",
    columns: [
      {
        Header: "Target $",
        accessor: "Target",
        filter: "number",
        Filter: NumberColumnFilter,
        canFilter: true,
        sortType: sortTypes.number,
      },
      {
        Header: "Sales $",
        accessor: "Sales",
        filter: "number",
        Filter: NumberColumnFilter,
        canFilter: true,
        sortType: sortTypes.number,
      },
      {
        Header: "IX",
        accessor: "SalesIx",
        // // Filter: ColumnFilter
        filter: "number",
        Filter: NumberColumnFilter,
        canFilter: true,
        sortType: sortTypes.number,
      },
      {
        Header: "Sales Ext. $",
        accessor: "SalesExt",
        filter: "number",
        Filter: NumberColumnFilter,
        canFilter: true,
        sortType: sortTypes.number,
      },
      {
        Header: "IX",
        accessor: "SalesExtIx",
        // // Filter: ColumnFilter
      },
      {
        Header: "Open Orders $",
        accessor: "OpenOrders",
        filter: "number",
        Filter: NumberColumnFilter,
        canFilter: true,
        sortType: sortTypes.number,
      },
      {
        Header: "Sales+Orders $",
        accessor: "SalesPlusOrders",
        filter: "number",
        Filter: NumberColumnFilter,
        canFilter: true,
        sortType: sortTypes.number,
      },
    ],
  },
  {
    Header: "Current Month Opportunities",
    columns: [
      {
        Header: "Achieved",
        accessor: "Achieved",
        filter: "number",
        Filter: NumberColumnFilter,
        canFilter: true,
        sortType: sortTypes.number,
      },
      {
        Header: "Not Achieved",
        accessor: "NotAchieved",
        filter: "number",
        Filter: NumberColumnFilter,
        canFilter: true,
        sortType: sortTypes.number,
      },
    ],
  },
  {
    Header: "Last Month Trends",
    columns: [
      {
        Header: "YA",
        accessor: "YA",
        filter: "number",
        Filter: NumberColumnFilter,
        canFilter: true,
        sortType: sortTypes.number,
      },
      {
        Header: "P3M",
        accessor: "P3M",
        filter: "number",
        Filter: NumberColumnFilter,
        canFilter: true,
        sortType: sortTypes.number,
      },
      {
        Header: "P6M",
        accessor: "P6M",
        filter: "number",
        Filter: NumberColumnFilter,
        canFilter: true,
        sortType: sortTypes.number,
      },
      {
        Header: "P12M",
        accessor: "P12M",
        filter: "number",
        Filter: NumberColumnFilter,
        canFilter: true,
        sortType: sortTypes.number,
      },
    ],
  },
];
