import React from "react";
import "./CustomerSalesOverviewGraph.css";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";
import helpers from "../../../utils/helpers";
import { currencyMap } from "../../../constants/currency-map";
import { dictionary } from "../../../constants/dictionary";

export const CustomerSalesOverviewGraph = ({
  coordinates,
  productPurchasedData,
  customerName,
}) => {
  const staticAppDate = useSelector((state) => state.globalSlice.staticAppDate);
  const date = staticAppDate ? new Date(staticAppDate) : new Date();
  const { usDateFormat, getLastUpdate } = helpers;
  const lastUpdate = false;
  // const lastUpdate = (() => {
  //   if (productPurchasedData?.length > 0) {
  //     getLastUpdate(productPurchasedData);
  //   }
  // })();

  let { Currency, DateFormat, Language } = JSON.parse(
    sessionStorage.getItem("userMetadata")
  );

  const data = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: date.getFullYear() - 1,
        type: "bar",
        backgroundColor: "rgba(248, 191, 19, 1)",
        borderColor: "rgba(248, 191, 19, 1)",
        data: coordinates[1],
        lineTension: 0.6,
      },
      {
        label: date.getFullYear(),
        type: "bar",
        borderColor: "rgba(68, 115, 195, 1)",
        backgroundColor: "rgba(68, 115, 195, 1)",
        data: coordinates[0],
      },
    ],
  };
  let options = {
    animation: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        grid: {
          display: false,
        },
      },
      x: {
        grid: {
          display: true,
        },
      },
    },
  };

  return (
    <div className="customer-sales-overview-graph-div">
      <div
        style={{
          direction: `${Language === "hebrew" ? "rtl" : "ltr"}`,
        }}
      >
        <h4 className="customer-sales-overview-graph-title">
          {customerName}{" "}
          {dictionary[Language].CustomerSalesOverviewGraph.graphTitle}{" "}
          {currencyMap[Currency]}
        </h4>
        {lastUpdate && (
          <span className="last-update">
            {dictionary[Language].lastUpdate} :{" "}
            {DateFormat === "us" ? usDateFormat(lastUpdate) : lastUpdate}
          </span>
        )}
      </div>
      <Bar
        data={data}
        options={options}
        className="customer-sales-overview-scatter"
      />
    </div>
  );
};
