import TextColumnFilter from "../../../Filters/TextColumnFilter";
import NumberColumnFilter from "../../../Filters/NumberColumnFilter";
import CheckedColumnFilter from "../../../Filters/CheckedColumnFilter";

import { sortTypes } from "../../../../utils/sort";
import { prioritiesMapForKeyOpp } from "../../../../constants/filtersText-map";
import PercentageColumnFilter from "../../../Filters/PercentageColumnFilter";

export const engCOLUMNS = [
  {
    Header: "Customer Name",
    Footer: "Customer Name",
    accessor: "CustomerName",
    filter: "text",
    Filter: TextColumnFilter,
    canFilter: true,
  },
  {
    Header: "Customer Code",
    Footer: "Customer Code",
    accessor: "CustomerID",
    filter: "text",
    Filter: TextColumnFilter,
    canFilter: true,
  },
  {
    Header: "Sales Person",
    Footer: "Sales Person",
    accessor: "AgentName",
    filter: "checked",
    Filter: CheckedColumnFilter,
    canFilter: true,
  },
  {
    Header: "Type",
    Footer: "Insight Type",
    accessor: "InsightType",
    filter: "checked",
    Filter: CheckedColumnFilter,
    canFilter: true,
    // sortType: sortTypes.new,
    disableSortBy: true,
  },
  {
    Header: "$",
    Footer: "Value",
    accessor: "AggPotentialValue",
    filter: "number",
    Filter: NumberColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: "Progress",
    Footer: "Progress",
    accessor: "CalcProgress",
    filter: "number",
    Filter: PercentageColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: "Priority",
    Footer: "Priority",
    accessor: "Priority",
    filter: "checked",
    Filter: ({ column }) => (
      <CheckedColumnFilter column={column} labelsMap={prioritiesMapForKeyOpp} />
    ),
    canFilter: true,
  },
];
