import { shallowEqual, useDispatch, useSelector } from "react-redux";
import List from "@material-ui/core/List";
import MuiDrawer from "@material-ui/core/Drawer";
import React, { useState } from "react";
import MuiListItem from "@material-ui/core/ListItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import LogoutIcon from "@mui/icons-material/Logout";
import HelpIcon from "@material-ui/icons/Help";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import HomeIcon from "@mui/icons-material/Home";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { useHistory } from "react-router-dom";
import {
  setIsOverviewGraphReady,
  setLoadingSpinner,
} from "../../store/features/homePagePropsSlice";
import whiteDots from "../../images/dots_white.png";
import insightingLogo from "../../images/insighting-LOGO-5.png";
import insightingLogo2 from "../../images/insighting-LOGO-2 (1).png";

import "./Dropdown2.css";
import { useAuth0 } from "@auth0/auth0-react";

const useDrawerStyles = makeStyles(() => ({
  header: {
    width: "100%",
    paddingBottom: 12,
    textAlign: "center",
    borderBottom: "1px dashed black",
  },
}));

const Drawer = withStyles({
  paper: {
    width: "50%",
    color: "black",
    backgroundColor: "#cbe8ba",
    fontWeight: 100,
  },
})(MuiDrawer);

const Drawer2 = withStyles({
  paper: {
    width: "17%",
    height: "285px", //325px
    color: "black",
    backgroundColor: "#cbe8ba",
    fontWeight: 100,
    borderRadius: "18px",
    marginTop: "2.8rem",
  },
})(MuiDrawer);
const ListItem = withStyles({
  root: {
    marginTop: 0,
    paddingTop: 6,
    marginBottom: 0,
    paddingBottom: 6,
    textAlign: "right",
  },
})(MuiListItem);
const ListItem2 = withStyles({
  root: {
    marginTop: 0,
    paddingTop: 6,
    marginBottom: 0,
    paddingBottom: 6,
    textAlign: "left",
  },
})(MuiListItem);

const menu = [
  { text: "Support", Icon: HelpIcon },
  { text: "Account", Icon: AccountCircleIcon },
  { text: "Logout", Icon: LogoutIcon },
];
const menu2 = [
  { text: "Home", Icon: HomeOutlinedIcon },
  { text: "Revenue Navigator", Icon: QueryStatsIcon },
  // { text: "Reports Generator", Icon: AssessmentOutlinedIcon },
  { text: "Opportunities", Icon: TipsAndUpdatesOutlinedIcon },
  { text: "Logout", Icon: LogoutIcon },
];

function MobileDrawer({
  active = false,
  deactivate = () => {},
  onListItemClick = {},
}) {
  const { logout } = useAuth0();
  const history = useHistory();

  const classes = useDrawerStyles();

  const clickHandlers = {
    ...onListItemClick,
    Account: () => history.push("/"),
    Logout: logout,
  };

  return (
    <Drawer open={active} anchor="right" onClose={deactivate}>
      <div role="presentation">
        <List>
          <ListItem>
            <div className={classes.header}>
              <img src={insightingLogo} alt="logo" width="120" />
            </div>
          </ListItem>
          {menu.map(({ text, Icon }) => (
            <ListItem
              button
              // key={text}
              onClick={clickHandlers[text]}
            >
              <ListItemText primary={text} />
              <ListItemIcon style={{ minWidth: "initial", marginLeft: 5 }}>
                <Icon style={{ fill: "black", fontSize: 28 }} />
              </ListItemIcon>
            </ListItem>
          ))}
        </List>
      </div>
    </Drawer>
  );
}

function DesktopDrawer({
  active = false,
  deactivate = () => {},
  onListItemClick = {},
}) {
  const ShowGrowthAnalysis_Flag = useSelector(
    (state) => state.userSlice.user.metadata.ShowGrowthAnalysis_Flag
  );
  const { logout } = useAuth0();
  const history = useHistory();
  const dispatch = useDispatch();

  const classes = useDrawerStyles();

  const clickHandlers = {
    ...onListItemClick,
    "Reports Generator": () => {
      deactivate();
      history.push("/reportsPage");
    },
    Opportunities: () => {
      deactivate();
      history.push("/opportunities");
    },
    Home: () => {
      deactivate();
      dispatch(setIsOverviewGraphReady(true));
      history.push("/");
    },
    "Revenue Navigator": () => {
      if (ShowGrowthAnalysis_Flag === "true") {
        history.push("/revNav");
        deactivate();
      }
      // window.location.href = "/revNav"
    },
    Support: () => {
      deactivate();
    },
    Account: () => history.push("/"),
    Logout: logout,
  };

  return (
    <Drawer2 open={active} anchor="right" onClose={deactivate}>
      <div role="presentation">
        <List>
          <ListItem2>
            <div className={classes.header}>
              <img src={insightingLogo2} alt="logo" width={150} />
            </div>
          </ListItem2>
          {menu2.map(({ text, Icon }) => (
            <ListItem2
              button
              key={text}
              onClick={clickHandlers[text]}
              style={{
                color:
                  text === "Revenue Navigator" &&
                  ShowGrowthAnalysis_Flag !== "true"
                    ? "grey"
                    : "black",
              }}
            >
              <ListItemText primary={text} />
              <ListItemIcon style={{ minWidth: "initial", marginLeft: 5 }}>
                <Icon
                  style={{
                    fill: text === "Revenue Navigator" ? "black" : "black",
                    fontSize: 28,
                  }}
                />
              </ListItemIcon>
            </ListItem2>
          ))}
        </List>
      </div>
    </Drawer2>
  );
}

export default function Dropdown2() {
  const [isDrawerActive, setIsDrawerActive] = useState(false);
  const resolution = useSelector(
    (state) => state.globalSlice.resolution,
    shallowEqual
  );

  const isMobile = resolution.device === "mobile";

  return (
    <div className="container">
      <div className="menu-container">
        <img
          src={whiteDots}
          alt="menu button"
          className="white-dots"
          onClick={() => {
            setIsDrawerActive(!isDrawerActive);
          }}
        />
        {isMobile && (
          <MobileDrawer
            active={isDrawerActive}
            // onListItemClick={{ Support: setOpened }}
            deactivate={() => setIsDrawerActive(false)}
          />
        )}
        {!isMobile && (
          <DesktopDrawer
            active={isDrawerActive}
            onListItemClick={{}}
            deactivate={() => setIsDrawerActive(false)}
          />
        )}
      </div>
    </div>
  );
}
