import React, { memo, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { dictionary } from "../../constants/dictionary";
import { GrowthAnalysisGraph } from "../Graphs/GrowthAnalysisGraph";
import { currencyMap } from "../../constants/currency-map";
import "./GrowthAnalysis.css";
import revNavigatorService from "../../services/revNavigator";
import {
  setProcessedPeriods,
  setRevNavAgentsMap,
  setRevNavAgentsMapByAgentID,
  setRevNavCheckedAgents,
  setRevNavGraphData,
  setRevNavResData,
  setUniqueAgentIDs,
} from "../../store/features/revNavigatorSlice";
import { getRevNavCoordinates } from "../../utils/helpers";

function GrowthAnalysis() {
  const dispatch = useDispatch();
  const metadata = useSelector(
    (state) => state.userSlice.user.metadata,
    shallowEqual
  );
  const revNavResData = useSelector(
    (state) => state.revNavigatorSlice.revNavResData,
    shallowEqual
  );
  const revNavCheckedAgents = useSelector(
    (state) => state.revNavigatorSlice.revNavCheckedAgents,
    shallowEqual
  );
  const uniqueAgentIDs = useSelector(
    (state) => state.revNavigatorSlice.uniqueAgentIDs,
    shallowEqual
  );
  const revNavGraphData = useSelector(
    (state) => state.revNavigatorSlice.revNavGraphData,
    shallowEqual
  );
  const revNavSelectedPeriod = useSelector(
    (state) => state.revNavigatorSlice.revNavSelectedPeriod
  );
  const revNavTableMode = useSelector(
    (state) => state.revNavigatorSlice.revNavTableMode
  );
  const processedPeriods = useSelector(
    (state) => state.revNavigatorSlice.processedPeriods
  );
  const staticAppDate = useSelector((state) => state.globalSlice.staticAppDate);
  const date = staticAppDate ? new Date(staticAppDate) : new Date();
  // const userDate = useSelector((state) => state.globalSlice.userDate);
  // const dateStringForReq = staticAppDate ? staticAppDate : userDate;

  let {
    InsightingCustomerID,
    Language,
    Currency,
    ManagerID,
    UserType,
    AgentPermission,
    AgentID,
    TeamAgentsIDs,
  } = metadata;

  const [growthAnalysisData, setGrowthAnalysisData] = useState({});
  const [subtitle, setSubtitle] = useState("");

  useEffect(() => {
    async function getRevNavResData() {
      try {
        ManagerID = ManagerID?.split("+");
        let Items = [];
        if (UserType === "teamleader" && AgentPermission === "teamonly") {
          const agents = TeamAgentsIDs?.split("+");
          const response =
            await revNavigatorService.getRevNavigator_Main_DataByTeamLeader(
              agents,
              InsightingCustomerID,
              date
            );
          if (response) Items.push(...response);
        } else if (UserType === "agent" && AgentPermission === "agentonly") {
          const response =
            await revNavigatorService.getRevNavigator_Main_DataByAgent(
              AgentID,
              InsightingCustomerID,
              date
            );
          if (response) {
            Items.push(...response);
          }
        } else {
          //manager or agent or team leader with no restrictions
          const response =
            await revNavigatorService.getRevNavigator_Main_DataByManager(
              InsightingCustomerID,
              date
            );

          if (response) Items.push(...response);
        }
        if (Items?.length > 0) {
          let agentsMap = [];
          let uniqueAgentsList = Items.reduce((acc, item) => {
            if (
              item.AgentID &&
              !acc?.includes(item.AgentID) &&
              item.AgentID !== "Total"
            ) {
              acc.push(item.AgentID);
              agentsMap.push({
                agentID: item.AgentID,
                agentName: item.AgentName,
              });
            }
            return acc;
          }, []);
          dispatch(setRevNavResData(Items));
          dispatch(setRevNavAgentsMap(agentsMap));
          dispatch(
            setRevNavAgentsMapByAgentID(
              agentsMap.reduce((acc, agent) => {
                acc[agent.agentID] = agent.agentName;
                return acc;
              }, {})
            )
          );
          dispatch(
            setUniqueAgentIDs(
              uniqueAgentsList
              // .map((agent) => ({
              //   name: agent,
              //   code: agent,
              //   value: agent,
              //   label: agent,
              //   isSelected: false,
              // }))
            )
          );
          if (UserType === "agent" && AgentPermission !== "agentonly") {
            dispatch(
              setRevNavCheckedAgents(
                uniqueAgentsList.reduce((acc, current) => {
                  acc[current] = current === AgentID ? true : false;
                  return acc;
                }, {})
              )
            );
          } else {
            dispatch(
              setRevNavCheckedAgents(
                uniqueAgentsList.reduce((acc, current) => {
                  acc[current] = true;
                  return acc;
                }, {})
              )
            );
          }
        }
      } catch (error) {
        console.error("Error fetching RevNavigator_Main data:", error);
      }
    }
    getRevNavResData();
    // getGrowthAnalysisData();
  }, []);

  useEffect(
    function createGraphDataPoints() {
      if (revNavResData?.length > 0 && uniqueAgentIDs?.length > 0) {
        const processedPeriodsMap = {};
        const spacesAndBrackets = /[\[\]\s]/g;

        revNavResData?.forEach((item) => {
          const period = item.Period;
          const firstPeriod = item.FirstPeriod?.replace(
            spacesAndBrackets,
            ""
          ).replace(",", "-");
          const secondPeriod = item.SecondPeriod?.replace(
            spacesAndBrackets,
            ""
          ).replace(",", "-");
          if (
            period &&
            !processedPeriodsMap[period] &&
            [
              "LastMonth",
              "LastMonth_YA",
              "P2M",
              "P2M_YA",
              "P3M",
              "P3M_YA",
            ]?.includes(period)
          ) {
            processedPeriodsMap[period] = {
              Period: period,
              FirstPeriod: firstPeriod,
              SecondPeriod: secondPeriod,
            };
          }
        });

        dispatch(setProcessedPeriods(Object.values(processedPeriodsMap)));
        const graphCoordinates = getRevNavCoordinates(
          UserType,
          revNavResData,
          revNavSelectedPeriod,
          revNavCheckedAgents,
          uniqueAgentIDs,
          Language,
          revNavTableMode
        );
        dispatch(setRevNavGraphData(graphCoordinates));
        // console.log(graphCoordinates);
        setGrowthAnalysisData({
          ...graphCoordinates[0],
          vs: {
            english: "vs.",
            hebrew: "לעומת",
          },
          firstPeriod: graphCoordinates[0].firstPeriod
            ?.slice(1, -1)
            ?.replace(",", " -"),
          secondPeriod: graphCoordinates[0].secondPeriod
            ?.slice(1, -1)
            ?.replace(",", " -"),
        });
      }
    },
    [revNavSelectedPeriod, uniqueAgentIDs]
  );

  useEffect(
    function createGraphSubtitle() {
      if (Object.keys(growthAnalysisData)?.length > 0) {
        let {
          vs,
          BaseSales,
          DistributionGains,
          DistributionLosses,
          LostCustomers,
          NewCustomers,
          Total,
          firstPeriod,
          secondPeriod,
        } = growthAnalysisData;
        const foundPeriod = processedPeriods?.find(
          (i) => i.Period === revNavTableMode
        );
        setSubtitle(
          foundPeriod
            ? `${foundPeriod.FirstPeriod} ${vs[Language]} ${foundPeriod.SecondPeriod}, ${currencyMap[Currency]}`
            : `${firstPeriod} ${vs[Language]} ${secondPeriod}, ${currencyMap[Currency]}`
        );
        BaseSales = Math.round(BaseSales);
        DistributionGains = Math.round(DistributionGains);
        DistributionLosses = Math.round(DistributionLosses);
        LostCustomers = Math.round(LostCustomers);
        NewCustomers = Math.round(NewCustomers);
        Total = Math.round(Total);

        // setGraphData([
        //   {
        //     labelText: labelTextMap[Language]["BaseSales"],
        //     name: dictionary[Language].growthAnalysisGraph?.legend.BaseSales,
        //     value: [0, BaseSales],
        //     label: kFormat(BaseSales),
        //   },
        //   {
        //     labelText: labelTextMap[Language]["DistributionGains"],
        //     name: dictionary[Language].growthAnalysisGraph?.legend
        //       .DistributionGains,
        //     value: [BaseSales, BaseSales + DistributionGains],
        //     label: kFormat(DistributionGains),
        //   },
        //   {
        //     labelText: labelTextMap[Language]["DistributionLosses"],
        //     name: dictionary[Language].growthAnalysisGraph?.legend
        //       .DistributionLosses,
        //     value: [
        //       BaseSales + DistributionGains,
        //       BaseSales + DistributionGains + DistributionLosses,
        //     ],
        //     label: kFormat(DistributionLosses),
        //   },
        //   {
        //     labelText: labelTextMap[Language]["NewCustomers"],
        //     name: dictionary[Language].growthAnalysisGraph?.legend.NewCustomers,
        //     value: [
        //       BaseSales + DistributionGains + DistributionLosses,
        //       BaseSales + DistributionGains + DistributionLosses + NewCustomers,
        //     ],
        //     label: kFormat(NewCustomers),
        //   },
        //   {
        //     labelText: labelTextMap[Language]["LostCustomers"],
        //     name: dictionary[Language].growthAnalysisGraph?.legend
        //       .LostCustomers,
        //     value: [
        //       BaseSales + DistributionGains + DistributionLosses + NewCustomers,
        //       BaseSales +
        //         DistributionGains +
        //         DistributionLosses +
        //         NewCustomers +
        //         LostCustomers,
        //     ],
        //     label: kFormat(LostCustomers),
        //   },
        //   {
        //     labelText: labelTextMap[Language]["Total"],
        //     name: dictionary[Language].growthAnalysisGraph?.legend.Total,
        //     value: [
        //       0,
        //       BaseSales +
        //         DistributionGains +
        //         DistributionLosses +
        //         NewCustomers +
        //         LostCustomers,
        //     ],
        //     label: kFormat(
        //       BaseSales +
        //         DistributionGains +
        //         DistributionLosses +
        //         NewCustomers +
        //         LostCustomers
        //     ),
        //   },
        // ]);
      }
    },
    [growthAnalysisData]
  );

  return (
    <div className="growth-analysis-div">
      <div
        className="growth-analysis-title"
        style={{
          alignSelf: `flex-${Language === "hebrew" ? "end" : "start"}`,
          marginRight: "2.5rem",
          marginLeft: "1rem",
        }}
      >
        <h4>{dictionary[Language].growthAnalysisGraph?.title}</h4>
      </div>
      <div
        style={{
          direction: `${Language === "hebrew" ? "rtl" : "ltr"}`,
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        {subtitle}
      </div>
      <GrowthAnalysisGraph data={revNavGraphData} />
    </div>
  );
}
export default memo(GrowthAnalysis);
