import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import reportsGeneratorService from "../../services/reportsGenerator";
import "./ReportsGeneratorDetailsPage.css";
// import "../ReportsGeneratorPage/ReportsGeneratorPage.css";
import ReportDetailsTitleBar from "./ReportsGeneratorDetailsComponents/ReportDetailsTitleBar";
import ReportDetailsMainContent from "./ReportsGeneratorDetailsComponents/ReportDetailsMainContent";
import ReportDetailsSideBar from "./ReportsGeneratorDetailsComponents/ReportDetailsSideBar";
import { dictionary } from "../../constants/dictionary";
import {
  setFetchingSpinner,
  setReportDetailsResData,
} from "../../store/features/reportsGeneratorPageSlice";
import { setSalesByAgentData } from "../../store/features/salesByAgentSlice";

function ReportsGeneratorPageDetails() {
  const Language = useSelector(
    (state) => state.userSlice.user.metadata.Language
  );
  const InsightingCustomerID = useSelector(
    (state) => state.userSlice.user.metadata.InsightingCustomerID
  );
  const AgentID = useSelector((state) => state.userSlice.user.metadata.AgentID);
  const reportFilters = useSelector(
    (state) => state.reportsGeneratorPageSlice.reportFilters,
    shallowEqual
  );
  const reportCategory = useSelector(
    (state) => state.reportsGeneratorPageSlice.reportCategory,
    shallowEqual
  );
  const comparePeriodCheckbox = useSelector(
    (state) => state.reportsGeneratorPageSlice.comparePeriodCheckbox,
    shallowEqual
  );
  const salesByAgentData = useSelector(
    (state) => state.salesByAgentSlice.salesByAgentData,
    shallowEqual
  );

  const dispatch = useDispatch();
  function transformReportFilters() {
    const aggregationTypeMapping = {
      Month: "ByMonth",
      Total: "AggregatedPeriod",
      Year: "AggregatedPeriodByYear",
    };

    const transformPeriod = (period, aggregationType) => {
      const periodName = aggregationTypeMapping[aggregationType];
      const [name, dates] = period;
      const formattedDates = dates.map((date) => {
        const d = new Date(date);
        return `${d.getMonth() + 1}_${d.getFullYear()}`;
      });

      return [periodName, formattedDates];
    };

    const transformValuesToDisplay = (values) => {
      const valueMapping = {
        uniqueElements: "CountOfUniqueElements",
        valueSales: "Amount",
        units: "Units",
      };
      return values.map((value) => valueMapping[value] || value);
    };

    const getAgentsList = () => {
      const listOfAgents = [];

      if (!salesByAgentData?.length) {
        dispatch(
          setSalesByAgentData(
            JSON.parse(sessionStorage.getItem("salesByAgentData"))
          )
        );
        listOfAgents.push(
          ...JSON.parse(sessionStorage.getItem("salesByAgentData")).map(
            (agent) => agent.AgentID
          )
        );
      } else {
        listOfAgents.push(...salesByAgentData.map((agent) => agent.AgentID));
      }

      return reportFilters.AgentIDs.length
        ? reportFilters.AgentIDs
        : listOfAgents;
      // return [
      //   "00",
      //   "31",
      //   "23",
      //   "13",
      //   "37",
      //   "15",
      //   "35",
      //   "34",
      //   "03",
      //   "21",
      //   "36",
      //   "10",
      //   "12",
      //   "14",
      //   "38",
      //   "05",
      //   "19",
      //   "28",
      //   "06",
      //   "11",
      //   "29",
      //   "09",
      //   "07",
      //   "08",
      //   "24",
      //   "-1",
      //   "32",
      //   "025",
      //   "17",
      //   "16",
      //   "04",
      //   "26",
      //   "01",
      //   "20",
      //   "18",
      //   "22",
      //   "27",
      //   "02",
      //   "39",
      // ];

      // .length
      // ? listOfAgents
      // : ["Shloime Spitzer", "Shmuli", "YY Fulda", "Yaacov", "Zvi"];
    };

    const transformedFilters = {
      InsightingCustomerID: InsightingCustomerID,
      AgentIDs: getAgentsList(),
      Period1: transformPeriod(
        reportFilters.Period1,
        reportFilters.aggregationType
      ),
      ValuesToDisplay: transformValuesToDisplay(reportFilters.ValuesToDisplay),
    };

    // console.log(aggregationTypeMapping[aggregationType]);

    // console.log(aggregationType);

    // console.log(reportFilters.aggregationType);

    // console.log(
    //   transformPeriod(reportFilters.Period1, reportFilters.aggregationType)
    // );

    if (
      comparePeriodCheckbox &&
      reportFilters.Period2 &&
      reportFilters.Period2[1].length
    ) {
      transformedFilters.Period2 = transformPeriod(
        reportFilters.Period2,
        reportFilters.aggregationType
      );
    }

    if (reportFilters.CustomerIDs && reportFilters.CustomerIDs.length) {
      transformedFilters.CustomerIDs = reportFilters.CustomerIDs;
    }
    //  else {
    //   transformedFilters.CustomerIDs = ["CustomerIDTest"];
    // }

    if (reportFilters.ItemCatNumbers && reportFilters.ItemCatNumbers.length) {
      transformedFilters.ItemCatNumbers = reportFilters.ItemCatNumbers;
    }

    if (
      reportFilters.CustomersHierarchies &&
      Object.keys(reportFilters.CustomersHierarchies).length
    ) {
      transformedFilters.CustomersHierarchies =
        reportFilters.CustomersHierarchies;
    }

    return transformedFilters;
  }

  useEffect(() => {
    async function getReportsServerData() {
      try {
        dispatch(setFetchingSpinner(true)); // Set spinner to true before API calls

        let response;
        switch (reportCategory) {
          case "byCustomer":
            response = await reportsGeneratorService.getReportByCustomer(
              transformReportFilters(),
              AgentID
            );
            // await reportsGeneratorService.getReportFile(
            //   "",
            //   InsightingCustomerID,
            //   AgentID,
            //   `${InsightingCustomerID}_${AgentID}_reportOut.json`
            // );

            dispatch(setReportDetailsResData(response.data.Items));
            break;
          case "byCustomerItem":
            response = await reportsGeneratorService.getReportByCustomerByItem(
              transformReportFilters(),
              AgentID
            );
            dispatch(setReportDetailsResData(response.data.Items));
            break;
          case "byItem":
            response = await reportsGeneratorService.getReportByItem(
              transformReportFilters(),
              AgentID
            );
            dispatch(setReportDetailsResData(response.data.Items));
            break;
          case "byItemAgent":
            response = await reportsGeneratorService.getReportByItemByAgent(
              transformReportFilters(),
              AgentID
            );
            dispatch(setReportDetailsResData(response.data.Items));
            break;
          case "byAgent":
            response = await reportsGeneratorService.getReportByAgent(
              transformReportFilters(),
              AgentID
            );
            dispatch(setReportDetailsResData(response.data.Items));
            break;
          default:
            console.error("Invalid reportCategory");
            dispatch(setFetchingSpinner(false)); // Set spinner to false on invalid category

            return;
        }
        // Handle response data here
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error here
      } finally {
        dispatch(setFetchingSpinner(false)); // Set spinner to false after API calls
      }
    }

    getReportsServerData();
  }, [reportCategory]); // Add any dependencies here

  return (
    <div
      className="app-container"
      style={{ direction: Language === "hebrew" ? "rtl" : "ltr" }}
    >
      <ReportDetailsTitleBar />
      <div className="content-container">
        <ReportDetailsSideBar />
        <ReportDetailsMainContent />
      </div>
    </div>
  );
}

export default ReportsGeneratorPageDetails;
