import React from "react";
import svgs from "../../../utils/svgs";
import { dictionary } from "../../../constants/dictionary";
import { useSelector } from "react-redux";

function PreGeneratingView() {
    const Language = useSelector(
        (state) => state.userSlice.user.metadata.Language
      );
  return (
    <div className="centered-content">
      <div>{svgs.preGeneratingSVG}</div>
      <p>{dictionary[Language]?.reportsGeneratorPage.preGeneratingMessage}</p>
    </div>
  );
}

export default PreGeneratingView;
