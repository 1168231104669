import React, { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import revNavigatorService from "../../services/revNavigator";
import { useDispatch, useSelector } from "react-redux";
import { setItemByCustRes } from "../../store/features/revNavigatorSlice";
import RevNavigatorDetailsTable from "../../components/Tables/RevNavigatorDetailsTable/RevNavigatorDetailsTable";
import { setRevNavigatorTableSorts } from "../../store/features/tableSortsSlice";

export default function RevNavigatorDetailsPage() {
  const history = useHistory();
  const {
    // InsightingCustomerID,
    ItemCatalogNumber,
    ItemName,
    CurrStock,
    sorts,
  } = history.location.state;
  const staticAppDate = useSelector((state) => state.globalSlice.staticAppDate);
  const date = staticAppDate ? new Date(staticAppDate) : new Date();
  const dispatch = useDispatch();
  let {
    AgentPermission,
    TeamAgentsIDs,
    AgentID,
    InsightingCustomerID,
    UserType,
  } = JSON.parse(sessionStorage.getItem("userMetadata"));

  useEffect(async () => {
    let Items = [];
    let response = null;
    if (UserType === "teamleader" && AgentPermission === "teamonly") {
      const agents = TeamAgentsIDs?.split("+");
      response =
        await revNavigatorService.getRevNavData_ItemsData_ByItemByCustByItemCatalogNumberByTeamLeader(
          agents,
          ItemCatalogNumber,
          InsightingCustomerID,
          date
        );
    } else if (UserType === "agent" && AgentPermission === "agentonly") {
      response =
        await revNavigatorService.getRevNavData_ItemsData_ByItemByCustByItemCatalogNumberByAgent(
          AgentID,
          ItemCatalogNumber,
          InsightingCustomerID,
          date
        );
    } else {
      response =
        await revNavigatorService.getRevNavData_ItemsData_ByItemByCustByItemCatalogNumberByManager(
          ItemCatalogNumber,
          InsightingCustomerID,
          date
        );
    }    
    dispatch(setItemByCustRes(response.data.data.Items));
    dispatch(setRevNavigatorTableSorts(sorts));
  }, []);

  return (
    <div>
      <RevNavigatorDetailsTable
        ItemCatalogNumber={ItemCatalogNumber}
        ItemName={ItemName}
        CurrStock={CurrStock}
      />
    </div>
  );
}
