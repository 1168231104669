import TextColumnFilter from "../../../Filters/TextColumnFilter";
import NumberColumnFilter from "../../../Filters/NumberColumnFilter";
import { sortTypes } from "../../../../utils/sort";
import CheckedColumnFilter from "../../../Filters/CheckedColumnFilter";
import DateColumnFilter from "../../../Filters/DateColumnFilter";
import PercentageColumnFilter from "../../../Filters/PercentageColumnFilter";

export const engCOLUMNS = [
  {
    Header: "Product Name",
    accessor: "ProductName",
    filter: "text",
    Filter: TextColumnFilter,
    canFilter: true,
  },
  {
    Header: "Code",
    accessor: "Code",
    filter: "text",
    Filter: TextColumnFilter,
    canFilter: true,
  },
  {
    Header: "$",
    accessor: "Value",
    filter: "number",
    Filter: NumberColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: "report date",
    accessor: "reportPeriod",
    filter: "number",
    Filter: NumberColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: "compared date",
    accessor: "compareToPeriod",
    filter: "number",
    Filter: NumberColumnFilter,
    canFilter: false,
    sortType: sortTypes.number,
  },
  {
    Header: "% Change",
    accessor: "changeBetweenPeriods",
    filter: "number",
    Filter: PercentageColumnFilter,
    canFilter: false,
    sortType: sortTypes.number,
  },
  {
    Header: "Sales %",
    accessor: "Sales",
    filter: "number",
    Filter: NumberColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: "Quantity",
    accessor: "Units",
    filter: "number",
    Filter: NumberColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: "Last Purchase",
    accessor: "LastOrderDate",
    filter: "date",
    Filter: DateColumnFilter,
    canFilter: true,
    sortType: sortTypes.lastOrderDate,
    // disableSortBy: true
  },
  {
    Header: "Open Orders $",
    accessor: "OpenOrders",
    filter: "number",
    Filter: NumberColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: "Purchase Probability",
    accessor: "Probability",
    filter: "checked",
    Filter: CheckedColumnFilter,
    canFilter: true,
    sortType: sortTypes.probability,
  },
];
