import trim from "lodash/trim";
import some from "lodash/some";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import lowerCase from "lodash/lowerCase";

import numberUtils from "./number";

export const FILTER_SEPARATOR = "--/--";

export const filterTypes = {
  text: (rows, id, filterValue) => {
    const [action, value] = filterValue?.split(FILTER_SEPARATOR);
    if (!value) {
      return rows;
    }
    const _value = lowerCase(value);
    switch (action) {
      case "equals":
        return rows?.filter((row) => lowerCase(row.values[id]) === _value);
      case "contains":
        return rows?.filter((row) => lowerCase(row.values[id])?.includes(_value));
      case "notContains":
        return rows?.filter(
          (row) => !lowerCase(row.values[id])?.includes(_value)
        );
      case "startsWith":
        return rows?.filter((row) =>
          lowerCase(row.values[id]).startsWith(_value)
        );
      case "endsWith":
        return rows?.filter((row) => lowerCase(row.values[id]).endsWith(_value));
      default:
        return rows;
    }
  },

  number: (rows, id, filterValue) => {
    const [action, value] = filterValue?.split(FILTER_SEPARATOR);
    if (!value) {
      return rows;
    }
    switch (action) {
      case "equals":
        return rows?.filter(
          (row) => numberUtils.toNumber(row.values[id]) === parseFloat(value)
        );
      case "greater":
        return rows?.filter(
          (row) =>
            numberUtils.toNumber(row.values[id]) > parseFloat(value) &&
            numberUtils.toNumber(row.values[id]) < 100000000
        );
      case "lower":
        return rows?.filter(
          (row) => numberUtils.toNumber(row.values[id]) < parseFloat(value)
        );
      default:
        return rows;
    }
  },

  boolean: (rows, id, filterValue) => {
    if (isNil(filterValue) || filterValue === "") {
      return rows;
    }
    switch (filterValue) {
      case true:
        return rows?.filter((row) => row.values[id] === "1");
      case false:
        return rows?.filter((row) => row.values[id] === "0");
      default:
        return rows;
    }
  },

  checked: (rows, id, filterValue) => {
    if (isEmpty(filterValue)) {
      return rows;
    }
    return rows?.filter((row) => {
      const options = String(row.values[id])
        ?.split(/\s?\|\s?/g)
        .map(trim);
      return some(options, (option) => !!filterValue[option]);
    });
  },

  date: (rows, id, filterValue) => {
    const [action, value] = filterValue?.split(FILTER_SEPARATOR);
    if (!value) {
      return rows;
    }
    switch (action) {
      case "equals":
        let formatValue = value?.split("-").reverse();
        formatValue[1] =
          formatValue[1][0]?.replace("0", "") + formatValue[1][1];
        formatValue[0] =
          formatValue[0][0]?.replace("0", "") + formatValue[0][1];
        formatValue = formatValue?.reverse().join("-");
        return rows?.filter(
          (row) =>
            Date.parse(row?.original[id]?.split("/").reverse().join("-")) ===
            parseInt(Date.parse(formatValue))
        );
      case "greater":
        return rows?.filter(
          (row) =>
            Date.parse(row?.original[id]?.split("/").reverse().join("-")) >
            Date.parse(value)
        );
      case "lower":
        return rows?.filter(
          (row) =>
            Date.parse(row?.original[id]?.split("/").reverse().join("-")) <
            Date.parse(value)
        );
      default:
        return rows;
    }
  },
};

export const getColumnByAccessor = (columnAccessor, headers = []) => {
  return headers?.find((col) => col.canFilter && col.id === columnAccessor);
};
