import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  revNavGraphData: [],
  revNavResData: [],
  revNavTableData: [],
  revNavItemsByItemData: [],
  revNavSelectedPeriod: "LastMonth",
  revNavCheckedAgents: {},
  revNavSubmittedAgents: {},
  uniqueAgentIDs: [],
  revNavAgentsMap: [],
  revNavAgentsMapByAgentID: [],
  agentsFilterApplyToggle: false,
  listOfSelectedAgents: [],
  isAllAgentsSelected: true,
  revNavTableMode: "L",
  isPrepRows: false,
  itemByCustRes: null,
  itemByCustTableData: [],
  processedPeriods: [],
};

export const revNavigatorSlice = createSlice({
  name: "revNavigator",
  initialState,
  reducers: {
    setRevNavGraphData: (state, action) => {
      state.revNavGraphData = action.payload;
    },
    setRevNavResData: (state, action) => {
      state.revNavResData = action.payload;
    },
    setRevNavTableData: (state, action) => {
      state.revNavTableData = action.payload;
    },
    setRevNavItemsByItemData: (state, action) => {
      state.revNavItemsByItemData = action.payload;
    },
    setRevNavSelectedPeriod: (state, action) => {
      state.revNavSelectedPeriod = action.payload;
    },
    setRevNavCheckedAgents: (state, action) => {
      state.revNavCheckedAgents = action.payload;
    },
    setRevNavSubmittedAgents: (state, action) => {
      state.revNavSubmittedAgents = action.payload;
    },
    setAgentsFilterApplyToggle: (state, action) => {
      state.agentsFilterApplyToggle = action.payload;
    },
    setIsAllAgentsSelected: (state, action) => {
      state.isAllAgentsSelected = action.payload;
    },
    setListOfSelectedAgents: (state, action) => {
      state.listOfSelectedAgents = action.payload;
    },
    setUniqueAgentIDs: (state, action) => {
      state.uniqueAgentIDs = action.payload;
    },
    setRevNavAgentsMap: (state, action) => {
      state.revNavAgentsMap = action.payload;
    },
    setRevNavAgentsMapByAgentID: (state, action) => {
      state.revNavAgentsMapByAgentID = action.payload;
    },
    setRevNavTableMode: (state, action) => {
      state.revNavTableMode = action.payload;
    },
    setIsPrepRows: (state, action) => {
      state.isPrepRows = action.payload;
    },
    setItemByCustRes: (state, action) => {
      state.itemByCustRes = action.payload;
    },
    setItemByCustTableData: (state, action) => {
      state.itemByCustTableData = action.payload;
    },
    setProcessedPeriods: (state, action) => {
      state.processedPeriods = action.payload;
    },
  },
});

export const {
  setRevNavGraphData,
  setRevNavResData,
  setRevNavTableData,
  setRevNavItemsByItemData,
  setRevNavSelectedPeriod,
  setRevNavCheckedAgents,
  setRevNavSubmittedAgents,
  setUniqueAgentIDs,
  setRevNavAgentsMap,
  setRevNavAgentsMapByAgentID,
  setAgentsFilterApplyToggle,
  setIsAllAgentsSelected,
  setListOfSelectedAgents,
  setRevNavTableMode,
  setIsPrepRows,
  setItemByCustRes,
  setItemByCustTableData,
  setProcessedPeriods,
} = revNavigatorSlice.actions;

export default revNavigatorSlice.reducer;
