import TextColumnFilter from "../../../Filters/TextColumnFilter";
import NumberColumnFilter from "../../../Filters/NumberColumnFilter";
import CheckedColumnFilter from "../../../Filters/CheckedColumnFilter";

import { sortTypes } from "../../../../utils/sort";
// import { getMonthForHeader } from "../../../utils/helpers";
import {
  prioritiesMapForTrends,
  urgencyMapForTrends,
} from "../../../../constants/filtersText-map";
import DateColumnFilter from "../../../Filters/DateColumnFilter";
import PercentageColumnFilter from "../../../Filters/PercentageColumnFilter";
const staticAppDate =
  process.env.REACT_APP_NODE_ENV === "ir-development"
    ? process.env.REACT_APP_DEMO_DATE
    : false;

const date = staticAppDate ? new Date(staticAppDate) : new Date();
const lastMonth =
  date.getMonth() !== 0
    ? `${date.getMonth()}/${date.getFullYear().toString()?.slice(2)}`
    : `12/${(date.getFullYear() - 1).toString()?.slice(2)}`;
const currentMonth = `${date.getMonth() + 1}/${date
  .getFullYear()
  .toString()
  ?.slice(2)}`;

export const hebCOLUMNS = [
  {
    Header: "שם לקוח",
    accessor: "customerName",
    filter: "text",
    Filter: TextColumnFilter,
    canFilter: true,
  },
  {
    Header: "מספר לקוח",
    accessor: "customerCode",
    filter: "text",
    Filter: TextColumnFilter,
    canFilter: true,
  },
  {
    Header: "סוכן",
    accessor: "salesAgent",
    filter: "checked",
    Filter: CheckedColumnFilter,
    canFilter: true,
  },
  {
    Header: "רכישה אחרונה",
    accessor: "lastOrder",
    filter: "date",
    Filter: DateColumnFilter,
    canFilter: true,
    sortType: sortTypes.lastOrder,
  },
  // {
  //   Header: `מגמה : ${getMonthForHeader("lastMonth")}-${getMonthForHeader(
  //     "twoMonthsAgo"
  //   )} לעומת ${getMonthForHeader("threeMonthsAgo")}-${getMonthForHeader(
  //     "fourMonthsAgo"
  //   )}`,
  //   columns: [
  // {
  //   Header: "לקוח",
  //   accessor: "trend",
  //   filter: "number", // filter: "checked"
  //   Filter: NumberColumnFilter,
  //   canFilter: true,
  //   sortType: sortTypes.number,
  // },
  // {
  //   Header: "קבוצה",
  //   accessor: "gix",
  //   filter: "number", // filter: "checked"
  //   Filter: NumberColumnFilter,
  //   canFilter: true,
  //   sortType: sortTypes.number,
  // },
  //   ],
  // },
  // {
  // Header: `רכישות (₪): ${getMonthForHeader(
  //   "currentMonth"
  // )} לעומת   ${getMonthForHeader("lastMonth")}`,
  // columns: [
  {
    Header: "מכירות " + currentMonth + " ₪",
    accessor: "achieved",
    filter: "number", // filter: "checked"
    Filter: NumberColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: "מכירות " + lastMonth + " ₪",
    accessor: "comparisonValue",
    filter: "number", // filter: "checked"
    Filter: NumberColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: "התקדמות מכירות",
    accessor: "progressExpectation",
    filter: "number", // filter: "checked"
    Filter: PercentageColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  //   ],
  // },
  {
    Header: "הזמנות פתוחות ₪",
    accessor: "openOrders",
    filter: "number",
    Filter: NumberColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: "הזדמנויות",
    accessor: "InsightType",
    filter: "checked",
    // Filter: ({ column }) => (
    //   <CheckedColumnFilter
    //     column={column}
    //     labelsMap={opportunitiesMapForCustomersOverview.hebrew}
    //   />
    // ),
    Filter: CheckedColumnFilter,
    canFilter: true,
    // sortType: sortTypes.new,
    disableSortBy: true,
  },
  {
    Header: "דחיפות",
    accessor: "urgency",
    // filter: "number",
    filter: "checked",
    Filter: ({ column }) => (
      <CheckedColumnFilter
        column={column}
        labelsMap={urgencyMapForTrends.hebrew}
      />
    ),
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: "עדיפות",
    accessor: "priority",
    filter: "checked", // filter: "checked"
    Filter: ({ column }) => (
      <CheckedColumnFilter column={column} labelsMap={prioritiesMapForTrends} />
    ),
    canFilter: true,
    maxWidth: 70,
    minWidth: 50,
    width: 60,
  },
];
