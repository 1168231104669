import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  keyOpportunitiesTableData: [],
  fetchedRevNav_CustomersData: [],
  keyOpportunitiesTableDataCopy: [],
  customersForNavbarData: [],
  customersWithInsightType4Opportunities: [],
  salesByCustomerByItem_MainData: [],
  salesByCustomer_MainData: [],
  allKeyOpportunitiesForCustomerPage: [],
  keyOpportunitiesDisplayType: "spinner",
  productPurchasedTableDataInStore: [],
  customersOverviewData: [],
  customersOverviewTableData: [],
  customersOverviewAppliedSort: [],
  salesByAgent: [],
  productsAnalyzeDimension: "value",
  productsAnalyzeDates: [],
  productPurchasedTableMode: "regular",
  // lastCustomerVisited: {},
};

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setKeyOpportunitiesTableData: (state, action) => {
      state.keyOpportunitiesTableData = action.payload;
    },
    setFetchedRevNav_CustomersData: (state, action) => {
      state.fetchedRevNav_CustomersData = action.payload;
    },
    setKeyOpportunitiesTableDataCopy: (state, action) => {
      state.keyOpportunitiesTableDataCopy = action.payload;
    },
    setKeyOpportunitiesTablePriority: (state, action) => {
      const {
        InsightingCustomerId,
        CustID_InsightType_Month_ItemCatNum_ItemVar,
        Priority,
      } = action.payload;
      const tableRow = state.keyOpportunitiesTableData
        ?.filter((row) => row.InsightingCustomerId === InsightingCustomerId)
        .find(
          (row) =>
            row.CustID_InsightType_Month_ItemCatNum_ItemVar ===
            CustID_InsightType_Month_ItemCatNum_ItemVar
        );
      if (tableRow) {
        tableRow.Priority = Priority;
      }
    },
    setCustomersForNavbarData: (state, action) => {
      state.customersForNavbarData = action.payload;
    },
    setSalesByCustomer_MainData: (state, action) => {
      state.salesByCustomer_MainData = action.payload;
    },
    setAllKeyOpportunitiesForCustomerPage: (state, action) => {
      state.allKeyOpportunitiesForCustomerPage = action.payload;
    },
    setKeyOpportunitiesDisplayType: (state, action) => {
      state.keyOpportunitiesDisplayType = action.payload;
    },
    setProductPurchasedTableDataInStore: (state, action) => {
      state.productPurchasedTableDataInStore = action.payload;
    },
    setCustomersOverviewData: (state, action) => {
      state.customersOverviewData = action.payload;
    },
    setCustomersOverviewTableData: (state, action) => {
      state.customersOverviewTableData = action.payload;
    },
    setSalesByAgent: (state, action) => {
      state.salesByAgent = action.payload;
    },
    setProductsAnalyzeDimension: (state, action) => {
      state.productsAnalyzeDimension = action.payload;
    },
    setProductsAnalyzeDates: (state, action) => {
      state.productsAnalyzeDates = action.payload;
    },
    setProductPurchasedTableMode: (state, action) => {
      state.productPurchasedTableMode = action.payload;
    },
    // setLastCustomerVisited: (state, action) => {
    //   state.lastCustomerVisited = action.payload;
    // },
  },
});

export const {
  setKeyOpportunitiesTableData,
  setFetchedRevNav_CustomersData,
  setKeyOpportunitiesTableDataCopy,
  setKeyOpportunitiesTablePriority,
  setCustomersForNavbarData,
  setSalesByCustomer_MainData,
  setAllKeyOpportunitiesForCustomerPage,
  setKeyOpportunitiesDisplayType,
  setProductPurchasedTableDataInStore,
  setCustomersOverviewData,
  setCustomersOverviewTableData,
  setSalesByAgent,
  setProductsAnalyzeDimension,
  setProductsAnalyzeDates,
  setProductPurchasedTableMode,
  // setLastCustomerVisited
} = dataSlice.actions;

export default dataSlice.reducer;
