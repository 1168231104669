import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import pdfMake from "pdfmake";
import whiteSpace from "../images/separator-white-space.png";

const exportPdf = async (domEl, name = "pdf_file") => {
  domEl.style.maxHeight = "initial";

  const width = parseFloat(window.getComputedStyle(domEl).width);
  const height =
    parseFloat(window.getComputedStyle(domEl).height) +
    window.innerHeight -
    120;

  const canvas = await html2canvas(domEl, { y: 0, width, height });

  const imgData = canvas.toDataURL("image/png", 1.0);
  const doc = (() => {
    if (canvas.width > canvas.height) {
      return new jsPDF("l", "mm", "a4", true);
    }
    return new jsPDF("p", "mm", "a4", true);
  })();

  const docWidth = doc.internal.pageSize.getWidth();
  const docHeight = doc.internal.pageSize.getHeight();

  doc.addImage(imgData, "PNG", 0, 0, docWidth, docHeight);

  doc.save(`${name}.pdf`);

  window.setTimeout(() => {
    domEl.removeAttribute("style");
  }, 0);
};

const exportPdfFile = (domElId, fileName = "pdf_file") => {
  const domEl = document.querySelector(domElId);
  pdfUtils.exportPdf(domEl, fileName);
};

const download = async (tableRef, tableName = "pdf_file") => {
  tableRef.style.maxHeight = "initial";
  const divToPrint = tableRef;
  html2canvas(divToPrint).then(async (canvas) => {
    const imgData = await canvas.toDataURL("image/png", 1.0);
    const imgWidth = 190;
    const pageHeight = 290;
    const imgHeight = (canvas.height * imgWidth) / canvas.width - 10;
    let heightLeft = imgHeight;
    const doc = new jsPDF("pt", "mm", undefined, false);
    let position = 0;
    doc.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight, "alias", "SLOW");
    heightLeft -= pageHeight;
    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(
        imgData,
        "PNG",
        10,
        position + 10,
        imgWidth,
        imgHeight,
        "alias",
        "SLOW"
      );
      doc.addImage(whiteSpace, "PNG", 10, 0, imgWidth, 10, "alias2", "SLOW");
      heightLeft -= pageHeight;
    }
    await doc.save(`${tableName}.pdf`);

    window.setTimeout(() => {
      tableRef.removeAttribute("style");
    }, 0);
  });
};

const pdfUtils = {
  exportPdf,
  exportPdfFile,
  download,
};

export default pdfUtils;
