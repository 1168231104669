import TextColumnFilter from "../../../Filters/TextColumnFilter";
import NumberColumnFilter from "../../../Filters/NumberColumnFilter";
import { sortTypes } from "../../../../utils/sort";
import CheckedColumnFilter from "../../../Filters/CheckedColumnFilter";
import DateColumnFilter from "../../../Filters/DateColumnFilter";
import PercentageColumnFilter from "../../../Filters/PercentageColumnFilter";
import {
  prioritiesMapForTrends,
  urgencyMapForTrends,
} from "../../../../constants/filtersText-map";

export const hebCOLUMNS = [
  {
    Header: "מספר לקוח",
    accessor: "CustomerID",
    filter: "text",
    Filter: TextColumnFilter,
    canFilter: true,
  },
  {
    Header: "שם לקוח",
    accessor: "customerName",
    filter: "text",
    Filter: TextColumnFilter,
    canFilter: true,
  },
  {
    Header: "סוכן",
    accessor: "salesAgent",
    filter: "checked",
    Filter: CheckedColumnFilter,
    canFilter: true,
  },
  {
    Header: "שם מוצר",
    accessor: "ItemName",
    filter: "text",
    Filter: TextColumnFilter,
    canFilter: true,
  },
  {
    Header: "מק״ט",
    accessor: "ItemCatalogNumber",
    filter: "text",
    Filter: TextColumnFilter,
    canFilter: true,
  },
  {
    Header: "קטגוריה",
    accessor: "ItemCategory",
    filter: "text",
    Filter: TextColumnFilter,
    canFilter: true,
  },
  {
    Header: "₪",
    accessor: "periodValue",
    filter: "number",
    Filter: NumberColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: "רכישה אחרונה",
    accessor: "LastOrderDate",
    filter: "date",
    Filter: DateColumnFilter,
    canFilter: true,
    sortType: sortTypes.lastOrderDate,
  },
  {
    Header: "הזדמנויות",
    accessor: "opportunities",
    filter: "checked",
    // Filter: ({ column }) => (
    //   <CheckedColumnFilter
    //     column={column}
    //     labelsMap={opportunitiesMapForCustomersOverview.hebrew}
    //   />
    // ),
    Filter: CheckedColumnFilter,
    canFilter: true,
    // sortType: sortTypes.new,
    disableSortBy: true,
  },
  {
    Header: "דחיפות",
    accessor: "urgency",
    // filter: "number",
    filter: "checked",
    Filter: ({ column }) => (
      <CheckedColumnFilter
        column={column}
        labelsMap={urgencyMapForTrends.hebrew}
      />
    ),
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: "עדיפות",
    accessor: "priority",
    filter: "checked", // filter: "checked"
    Filter: ({ column }) => (
      <CheckedColumnFilter column={column} labelsMap={prioritiesMapForTrends} />
    ),
    canFilter: true,
    maxWidth: 70,
    minWidth: 50,
    width: 60,
  },
  {
    Header: "יחידות",
    accessor: "Units",
    filter: "number",
    Filter: NumberColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: "מספר לקוחות",
    accessor: "NumOfCustomers",
    filter: "number",
    Filter: NumberColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: "מלאי",
    accessor: "CurrStock",
    filter: "number",
    Filter: NumberColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: "פרטים",
    accessor: "details",
    // filter: "number",
    // filter: "checked",
    // Filter: ({ column }) => (
    //   <CheckedColumnFilter
    //     column={column}
    //     labelsMap={urgencyMapForTrends.hebrew}
    //   />
    // ),
    // canFilter: false,
    // sortType: sortTypes.number,
  },
];
