import React from "react";
import { dictionary } from "../../constants/dictionary";
import "./InsightTypesLogos.css";

export default function InsightTypesLogos({ row, language }) {
  // const { Language } = JSON.parse(sessionStorage.getItem("userMetadata"));
  let originalInsights = row?.original.OriginalInsightType;
  let logos = (
    <>
      <>
        {originalInsights?.includes("Insight2_agg") ||
        originalInsights?.includes("Insight2During_agg") ? (
          ""
        ) : originalInsights?.includes("Insight1_agg") ||
          originalInsights?.includes("Insight1During_agg") ? (
          <span className="type-one-agg insight-tag">
            {dictionary[language]?.opportunityOptions.insight1Agg}
          </span>
        ) : (
          ""
        )}
      </>
      <>
        {originalInsights?.includes("Insight2_agg") ||
        originalInsights?.includes("Insight2During_agg") ? (
          <span className="type-two-agg insight-tag">
            {dictionary[language]?.opportunityOptions.insight2Agg}
          </span>
        ) : (
          ""
        )}
      </>
      <>
        {originalInsights?.includes("Insight2_agg") ||
        originalInsights?.includes("Insight2During_agg") ? (
          ""
        ) : originalInsights?.includes("Insight3_agg") ? (
          <span className="type-three-agg insight-tag">
            {dictionary[language]?.opportunityOptions.insight3Agg}
          </span>
        ) : (
          ""
        )}
      </>
      <>
        {originalInsights?.includes("Insight2_agg") ||
        originalInsights?.includes("Insight2During_agg") ? (
          ""
        ) : originalInsights?.includes("Insight4During_agg") &&
          (!originalInsights?.includes("Insight1During_agg") ||
            !originalInsights?.includes("Insight5During_agg")) ? (
          <span className="type-four-agg insight-tag">
            {dictionary[language]?.opportunityOptions.insight4DuringAgg}
          </span>
        ) : (
          ""
        )}
      </>
      <>
        {originalInsights?.includes("Insight2_agg") ||
        originalInsights?.includes("Insight2During_agg") ? (
          ""
        ) : originalInsights?.includes("Insight5_agg") ||
          originalInsights?.includes("Insight5During_agg") ? (
          <span className="type-five-agg insight-tag">
            {dictionary[language]?.opportunityOptions.insight5Agg}
          </span>
        ) : (
          ""
        )}
      </>
    </>
  );

  return <span className="logos">{logos}</span>;
}
