import React, { memo, useEffect } from "react";
import { CustomerOverviewNameID } from "../CustomerOverviewNameID/CustomerOverviewNameID";
import CustomersOverviewGraph from "../Graphs/CustomersOverviewGraph/CustomersOverviewGraph";
import { CustomerOverviewSalesForecast } from "../CustomerOverviewSalesForecast/CustomerOverviewSalesForecast";
import { CustomersOverviewTrendsTable } from "../Tables/CustomersOverviewTrendsTable/CustomersOverviewTrendsTable";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import dateUtils from "../../utils/date";
import {
  setCustomerNameForGraph,
  setCustomerOverviewTrendsData,
  setSelectedCustomer,
  setTrendsCoordinates,
} from "../../store/features/homePagePropsSlice";
import { isNumber } from "lodash";

const staticAppDate =
  process.env.REACT_APP_NODE_ENV === "ir-development"
    ? process.env.REACT_APP_DEMO_DATE
    : false;

const date = staticAppDate ? new Date(staticAppDate) : new Date();
const { formatDateView } = dateUtils;

let months = [];
let monthsRequired = 11;

const baseDate = staticAppDate ? new Date(staticAppDate) : new Date();
baseDate.setDate(15);

for (let i = monthsRequired; i >= 0; i--) {
  const newDate = new Date(baseDate);

  // Calculate the target month and year considering year change
  let targetMonth = baseDate.getMonth() - i;
  let targetYear = baseDate.getFullYear();

  // Adjust the year if the month subtraction went beyond the current year
  while (targetMonth < 0) {
    targetMonth += 12; // Move to the previous year
    targetYear--;
  }

  newDate.setMonth(targetMonth);
  newDate.setFullYear(targetYear);

  const month = (newDate.getMonth() + 1).toString(); //.padStart(2, "0");
  const year = newDate.getFullYear().toString()?.slice(-2);
  months.push(`${month}/${year}`);
}

const actualMonths = months
  ?.filter((month) => month?.includes(date.getFullYear() % 2000))
  .map((item) => item?.split("/")[0]);
const yearAgoMonths = months
  ?.filter((month) => month?.includes((date.getFullYear() % 2000) - 1))
  .map((item) => item?.split("/")[0]);

function salesByYear(salesByCustomer_MainData) {
  const { Items } = salesByCustomer_MainData;
  const currentYearSales = Items?.filter(
    (item) => parseInt(item.YearOfData) === date.getFullYear()
  );
  const lastYearSales = Items?.filter(
    (item) => parseInt(item.YearOfData) === date.getFullYear() - 1
  );
  const twoYearsAgoSales = Items?.filter(
    (item) => parseInt(item.YearOfData) === date.getFullYear() - 2
  );

  return { currentYearSales, lastYearSales, twoYearsAgoSales };
}

function getYearAgoCoordinates({ CustomerID }, salesByCustomer_MainData) {
  const { twoYearsAgoSales, lastYearSales } = salesByYear(
    salesByCustomer_MainData
  );
  const arr = [];

  yearAgoMonths?.forEach((month) =>
    arr.push(
      parseInt(
        twoYearsAgoSales.find((sale) => sale.CustomerID === CustomerID)?.[
          `Sales_${month}`
        ]
      ) || 0
    )
  );

  actualMonths?.forEach((month) =>
    arr.push(
      parseInt(
        lastYearSales.find((sale) => sale.CustomerID === CustomerID)?.[
          `Sales_${month}`
        ]
      ) || 0
    )
  );

  return arr;
}

function normalizedGroupValues(arrays) {
  const divArr = Array(12).fill(0);
  let sumArr = Array(12).fill(0);
  for (let i = 0; i < 12; i++) {
    arrays?.forEach((arr) => {
      let num = parseInt(arr[i]);
      if (isNumber(num) && num !== 0 && !isNaN(num)) {
        divArr[i]++;
        sumArr[i] += arr[i];
      }
    });
  }

  const normalizedArr = sumArr.map(
    (item, index) => parseInt(item / divArr[index]) || 0
  );

  return normalizedArr;
}

function getActualCoordinates({ CustomerID }, salesByCustomer_MainData) {
  const { currentYearSales, lastYearSales } = salesByYear(
    salesByCustomer_MainData
  );
  const arr = [];

  yearAgoMonths?.forEach((month) =>
    arr.push(
      parseInt(
        lastYearSales?.find((sale) => sale.CustomerID === CustomerID)?.[
          `Sales_${month}`
        ]
      ) || 0
    )
  );

  actualMonths?.forEach((month) =>
    arr.push(
      parseInt(
        currentYearSales?.find((sale) => sale.CustomerID === CustomerID)?.[
          `Sales_${month}`
        ]
      ) || 0
    )
  );

  return arr;
}

let arr2 = [];

function getGroupCoordinates(
  { GroupCustomerIDs, GroupID },
  customersOverviewData,
  salesByCustomer_MainData
) {
  const found = arr2?.find((item) => item.GroupID === GroupID);

  if (found) {
    return found.arr;
  } else {
    if (
      (GroupCustomerIDs?.includes("[") && GroupCustomerIDs[1] !== "]") ||
      GroupCustomerIDs?.toLowerCase() === "has_group"
    ) {
      const groupArr = customersOverviewData.Items?.filter(
        (item) => item.GroupID === GroupID
      ).map((item) => item.CustomerID);

      let arr = [];
      groupArr?.forEach(
        (item) =>
          arr.push(
            getActualCoordinates({ CustomerID: item }, salesByCustomer_MainData)
          ) || 0
      );

      arr2.push({ GroupID: GroupID, arr: arr });
      return arr;
    } else {
      return [];
    }
  }
}

function CustomerOverviewDetails() {
  const salesByCustomer_MainData = useSelector(
    (state) => state.dataSlice.salesByCustomer_MainData,
    shallowEqual
  );
  const customersOverviewData = useSelector(
    (state) => state.dataSlice.customersOverviewData,
    shallowEqual
  );
  const Language = useSelector(
    (state) => state.userSlice.user.metadata.Language,
  );
  const customerNameForGraph = useSelector(
    (state) => state.homePagePropsSlice.customerNameForGraph
  );
  const selectedCustomer = useSelector(
    (state) => state.homePagePropsSlice.selectedCustomer,
    shallowEqual
  );
  const trendsData = useSelector(
    (state) => state.homePagePropsSlice.trendsData,
    shallowEqual
  );
  const clickedRow = useSelector(
    (state) => state.customersOverviewSlice.clickedRow
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (trendsData?.length > 0 && !customerNameForGraph) {
      dispatch(setCustomerNameForGraph(trendsData[0]?.CustomerName));
      dispatch(setSelectedCustomer(trendsData[0] || {}));
    }
  }, [trendsData]);

  useEffect(() => {
    if (salesByCustomer_MainData?.Items && selectedCustomer) {
      dispatch(
        setTrendsCoordinates({
          actual: getActualCoordinates(
            selectedCustomer,
            salesByCustomer_MainData
          ),
          yearAgo: getYearAgoCoordinates(
            selectedCustomer,
            salesByCustomer_MainData
          ),
          group: normalizedGroupValues(
            getGroupCoordinates(
              selectedCustomer,
              customersOverviewData,
              salesByCustomer_MainData
            )
          ),
          expected: [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            selectedCustomer.ExpectedValue,
          ],
        })
      );

      const monthsAgo1 = new Date(date);
      monthsAgo1.setMonth(date.getMonth() - 1);
      const monthsAgo2 = new Date(date);
      monthsAgo2.setMonth(date.getMonth() - 2);
      const monthsAgo3 = new Date(date);
      monthsAgo3.setMonth(date.getMonth() - 3);
      const monthsAgo4 = new Date(date);
      monthsAgo4.setMonth(date.getMonth() - 4);
      const monthsAgo14 = new Date(date);
      monthsAgo14.setMonth(date.getMonth() - 14);
      const monthsAgo13 = new Date(date);
      monthsAgo13.setMonth(date.getMonth() - 13);
      const separator = Language === "hebrew" ? "לעומת" : "vs";

      dispatch(
        setCustomerOverviewTrendsData([
          {
            TimeFrame: `${formatDateView(monthsAgo2)} - ${formatDateView(
              monthsAgo1
            )} ${separator} ${formatDateView(monthsAgo4)} - ${formatDateView(
              monthsAgo3
            )}`,
            Customer: selectedCustomer.TrendShort,
            Group: selectedCustomer.TrendShort_GIX,
          },
          {
            TimeFrame: `${formatDateView(monthsAgo2)} - ${formatDateView(
              monthsAgo1
            )} ${separator} ${formatDateView(monthsAgo14)} - ${formatDateView(
              monthsAgo13
            )}`,
            Customer: selectedCustomer.TrendYA,
            Group: selectedCustomer.TrendYA_GIX,
          },
        ])
      );
    }
  }, [selectedCustomer, clickedRow]);

  return (
    <div className="customers-overview-bottom" style={{direction:Language === 'hebrew'? 'rtl': 'ltr'}}>
      <CustomerOverviewNameID />
      <CustomersOverviewGraph />
      <div className="overview-forecast-trends-div">
        <CustomerOverviewSalesForecast />
        <CustomersOverviewTrendsTable />
      </div>
    </div>
  );
}
export default memo(CustomerOverviewDetails);
