import React, { useState } from "react";
import svgs from "../../../../utils/svgs";
import { makeStyles } from "@material-ui/core";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { dictionary } from "../../../../constants/dictionary";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { subMonths } from "date-fns";
import {
  setEndComparedDate,
  setEndReportDate,
  setReportFilters,
  setStartComparedDate,
  setStartReportDate,
  setComparePeriodCheckbox,
} from "../../../../store/features/reportsGeneratorPageSlice";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: 140,
    margin: "8px",
    border: "1px solid #E8E8E8",
  },
  font: {
    fontSize: 13,
  },
}));

function ReportPeriodsFilter() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const reportFilters = useSelector(
    (state) => state.reportsGeneratorPageSlice.reportFilters,
    shallowEqual
  );
  const startReportDate = useSelector(
    (state) => state.reportsGeneratorPageSlice.startReportDate
  );
  const endReportDate = useSelector(
    (state) => state.reportsGeneratorPageSlice.endReportDate
  );
  const startComparedDate = useSelector(
    (state) => state.reportsGeneratorPageSlice.startComparedDate
  );
  const endComparedDate = useSelector(
    (state) => state.reportsGeneratorPageSlice.endComparedDate
  );
  const comparePeriodCheckbox = useSelector(
    (state) => state.reportsGeneratorPageSlice.comparePeriodCheckbox
  );
  const Language = useSelector(
    (state) => state.userSlice.user.metadata.Language
  );
  const comparePeriodText =
    dictionary[Language]?.reportsGeneratorPage.comparePeriod;
  const totalText =
    dictionary[Language]?.reportsGeneratorPage.reportPeriodShowBy.total;
  const yearText =
    dictionary[Language]?.reportsGeneratorPage.reportPeriodShowBy.year;
  const monthText =
    dictionary[Language]?.reportsGeneratorPage.reportPeriodShowBy.month;

  const changeCheckbox = () => {
    dispatch(setComparePeriodCheckbox(!comparePeriodCheckbox));
  };

  const changeReportDate = (dates) => {
    const [start, end] = dates;
    dispatch(setStartReportDate(start));
    dispatch(setEndReportDate(end));
    dispatch(
      setReportFilters({
        ...reportFilters,
        Period1: [
          reportFilters.Period1?.[0] || "",
          [start?.toISOString(), end?.toISOString()],
        ],
      })
    );
  };

  const changeComparedDate = (dates) => {
    const [start, end] = dates;
    dispatch(setStartComparedDate(start));
    dispatch(setEndComparedDate(end));
    dispatch(
      setReportFilters({
        ...reportFilters,
        Period2: [
          reportFilters.Period2?.[0] || "",
          [start?.toISOString(), end?.toISOString()],
        ],
      })
    );
  };

  const handleRadioButtonChange = (e) => {

    dispatch(
      setReportFilters({
        ...reportFilters,
        aggregationType: e.target.id,
      })
    );
  };

  return (
    <div className="report-filter-box">
      <div className="report-filter-header">
        <div className="report-svgs">{svgs.calendarSVG}</div>
        <div>
          {
            dictionary[Language]?.reportsGeneratorPage.reportFiltersHeaders
              .reportPeriod
          }
        </div>
        <div className="report-filter-header-mandatory">*</div>
      </div>
      <div className="report-specific-filter-box">
        <div className="report-specific-filter-box-details">
          <div style={{ display: "flex", alignItems: "center" }}>
            <span>{svgs.miniCalendarSVG}</span>
            <DatePicker
              className={classes.formControl}
              calendarClassName="calendar-control"
              selected={startReportDate ? new Date(startReportDate) : null}
              onChange={changeReportDate}
              selectsRange
              startDate={startReportDate ? new Date(startReportDate) : null}
              endDate={endReportDate ? new Date(endReportDate) : null}
              dateFormat="M/yyyy"
              showMonthYearPicker
              minDate={subMonths(new Date(), 24)}
              maxDate={subMonths(new Date(), 0)}
              placeholderText={
                dictionary[Language]?.reportsGeneratorPage
                  .reportPeriodSelectPrompt
              }
            />
          </div>
          <div style={{ padding: "1.5rem 0rem 0.5rem 0rem" }}>
            <input
              type="checkbox"
              name=""
              id={comparePeriodText}
              onClick={changeCheckbox}
              checked={comparePeriodCheckbox}
            />
            <label htmlFor={comparePeriodText}> {comparePeriodText}</label>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span>
              {comparePeriodCheckbox
                ? svgs.miniCalendarSVG
                : svgs.miniCalendarDisabledSVG}
            </span>

            <DatePicker
              className={classes.formControl}
              calendarClassName="calendar-control"
              selected={startComparedDate ? new Date(startComparedDate) : null}
              onChange={changeComparedDate}
              selectsRange
              startDate={startComparedDate ? new Date(startComparedDate) : null}
              endDate={endComparedDate ? new Date(endComparedDate) : null}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              placeholderText={
                dictionary[Language]?.reportsGeneratorPage
                  .reportPeriodSelectPrompt
              }
              minDate={subMonths(new Date(), 24)}
              maxDate={subMonths(new Date(), 0)}
              onCalendarOpen={() => dispatch(setComparePeriodCheckbox(true))}
            />
          </div>

          <div style={{ padding: "1.5rem 0rem" }}>
            {
              dictionary[Language]?.reportsGeneratorPage.reportPeriodShowBy
                .showBy
            }{" "}
            <span className="report-filter-header-mandatory">*</span>
          </div>
          <div className="report-filter-show-by-radios">
            <div>
              <input
                type="radio"
                name="reportPeriodShowBy"
                id={"Total"}
                checked={reportFilters.aggregationType === "Total"}
                onChange={handleRadioButtonChange}
              />
              <label htmlFor={"Total"}> {totalText}</label>
            </div>
            <div>
              <input
                type="radio"
                name="reportPeriodShowBy"
                id={"Year"}
                checked={reportFilters.aggregationType === "Year"}
                onChange={handleRadioButtonChange}
              />
              <label htmlFor={"Year"}> {yearText}</label>
            </div>
            <div>
              <input
                type="radio"
                name="reportPeriodShowBy"
                id={"Month"}
                checked={reportFilters.aggregationType === "Month"}
                onChange={handleRadioButtonChange}
              />
              <label htmlFor={"Month"}> {monthText}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportPeriodsFilter;
