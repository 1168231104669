import React from "react";
import insightingLogo2 from "../../images/insighting-LOGO-2-cropped.png";
import "./LoadingPage.css";

export default function LoadingPage() {
  return (
    <div className="loading-progress-container">
      <img src={insightingLogo2} alt="logo" width={335} />
      <div>We are working for you, it will take us a few sec</div>
      <div className="loading-progress">
        <div className="loading-progress-value"></div>
      </div>
    </div>
  );
}
