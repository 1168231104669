import React from 'react'

function Support() {
    return (
        <div>
            Support Page
        </div>
    )
}

export default Support
