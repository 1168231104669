import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clickedRow: 555, // rand
  overviewLastUpdate: "-",
  selectedRowForMenu: {},
  overviewMenuPosition: null,
  lastComparisonValue: "",
  lastUpdateDate: "",
  flattenArrayOfHierachyValues: [],
};

export const customersOverviewSlice = createSlice({
  name: "customersOverview",
  initialState,
  reducers: {
    setClickedRow: (state, action) => {
      state.clickedRow = action.payload;
    },
    setOverviewLastUpdate: (state, action) => {
      state.lastUpdate = action.payload;
    },
    setSelectedRowForMenu: (state, action) => {
      state.selectedRowForMenu = action.payload;
    },
    setOverviewMenuPosition: (state, action) => {
      state.overviewMenuPosition = action.payload;
    },
    setLastComparisonValue: (state, action) => {
      state.lastComparisonValue = action.payload;
    },
    setLastUpdateDate: (state, action) => {
      state.lastUpdateDate = action.payload;
    },
    setFlattenArrayOfHierachyValues: (state, action) => {
      state.flattenArrayOfHierachyValues = action.payload;
    },
  },
});

export const {
  setClickedRow,
  setOverviewLastUpdate,
  setSelectedRowForMenu,
  setOverviewMenuPosition,
  setLastComparisonValue,
  setFlattenArrayOfHierachyValues,
} = customersOverviewSlice.actions;

export default customersOverviewSlice.reducer;
