import axios from "axios";
import domain from "../config/domain";
import { reqAuth } from "../constants/reqAuth";

async function getRevNavigator_Main_DataByManager(
  InsightingCustomerID,
  reqDate
) {
  return axios
    .request({
      method: "POST",
      url: `${domain}/RevNavigator_Main/InsightingCustomerID/${InsightingCustomerID}`,
      headers: { authorization: reqAuth },
      data: { reqDate },
    })
    .then((r) => {
      return r.data.Items;
    })
    .catch(console.error);
}

async function getRevNavigator_Main_DataByAgent(
  AgentID,
  InsightingCustomerID,
  reqDate
) {
  return axios
    .request({
      method: "POST",
      url: `${domain}/RevNavigator_Main/by-agent`,
      headers: { authorization: reqAuth },
      data: { reqDate, AgentID, InsightingCustomerID },
    })
    .then((r) => r.data)
    .catch(console.error);
}

async function getRevNavigator_Main_DataByTeamLeader(
  agents,
  InsightingCustomerID,
  reqDate
) {
  return axios
    .request({
      method: "POST",
      url: `${domain}/RevNavigator_Main/by-team-leader`,
      headers: { authorization: reqAuth },
      data: { reqDate, agents, InsightingCustomerID },
    })
    .then((r) => r.data)
    .catch(console.error);
}

async function getRevNavigator_CustomersData_DataByManager(
  InsightingCustomerID,
  reqDate
) {
  return axios
    .request({
      method: "POST",
      url: `${domain}/RevNavigator_CustomersData/InsightingCustomerID/${InsightingCustomerID}`,
      headers: { authorization: reqAuth },
      data: { reqDate },
    })
    .then((r) => {
      return r.data.Items;
    })
    .catch(console.error);
}

async function getRevNavigator_CustomersData_DataByAgent(
  AgentID,
  InsightingCustomerID,
  reqDate
) {
  return axios
    .request({
      method: "POST",
      url: `${domain}/RevNavigator_CustomersData/by-agent`,
      headers: { authorization: reqAuth },
      data: { reqDate, AgentID, InsightingCustomerID },
    })
    .then((r) => r.data)
    .catch(console.error);
}

async function getRevNavigator_CustomersData_DataByTeamLeader(
  agents,
  InsightingCustomerID,
  reqDate
) {
  return axios
    .request({
      method: "POST",
      url: `${domain}/RevNavigator_CustomersData/by-team-leader`,
      headers: { authorization: reqAuth },
      data: { reqDate, agents, InsightingCustomerID },
    })
    .then((r) => r.data)
    .catch(console.error);
}
async function getRevNavigator_ItemsData_ByItem_DataByManager(
  InsightingCustomerID,
  reqDate
) {
  return axios
    .request({
      method: "POST",
      url: `${domain}/RevNavigator_ItemsData_ByItem/InsightingCustomerID/${InsightingCustomerID}`,
      headers: { authorization: reqAuth },
      data: { reqDate },
    })
    .then((r) => {
      return r.data.Items;
    })
    .catch(console.error);
}

async function getRevNavigator_ItemsData_ByItem_DataByAgent(
  AgentID,
  InsightingCustomerID,
  reqDate
) {
  return axios
    .request({
      method: "POST",
      url: `${domain}/RevNavigator_ItemsData_ByItem/by-agent`,
      headers: { authorization: reqAuth },
      data: { reqDate, AgentID, InsightingCustomerID },
    })
    .then((r) => r.data)
    .catch(console.error);
}

async function getRevNavigator_ItemsData_ByItem_DataByTeamLeader(
  agents,
  InsightingCustomerID,
  reqDate
) {
  return axios
    .request({
      method: "POST",
      url: `${domain}/RevNavigator_ItemsData_ByItem/by-team-leader`,
      headers: { authorization: reqAuth },
      data: { reqDate, agents, InsightingCustomerID },
    })
    .then((r) => r.data)
    .catch(console.error);
}

async function getRevNavData_ItemsData_ByItemByCustByItemCatalogNumberByManager(
  ItemCatalogNumber,
  InsightingCustomerID,
  reqDate
) {
  return axios.request({
    method: "POST",
    url: `${domain}/customerPage/RevNavData_ItemsData_ByItemByCust`,
    headers: { authorization: reqAuth },
    data: { reqDate, ItemCatalogNumber, InsightingCustomerID, mode: "manager" },
  });
}
async function getRevNavData_ItemsData_ByItemByCustByItemCatalogNumberByTeamLeader(
  agents,
  ItemCatalogNumber,
  InsightingCustomerID,
  reqDate
) {
  return axios.request({
    method: "POST",
    url: `${domain}/customerPage/RevNavData_ItemsData_ByItemByCust`,
    headers: { authorization: reqAuth },
    data: {
      reqDate,
      ItemCatalogNumber,
      InsightingCustomerID,
      agents,
      mode: "teamleader",
    },
  });
}
async function getRevNavData_ItemsData_ByItemByCustByItemCatalogNumberByAgent(
  AgentID,
  ItemCatalogNumber,
  InsightingCustomerID,
  reqDate
) {
  return axios.request({
    method: "POST",
    url: `${domain}/customerPage/RevNavData_ItemsData_ByItemByCust`,
    headers: { authorization: reqAuth },
    data: {
      reqDate,
      ItemCatalogNumber,
      InsightingCustomerID,
      AgentID,
      mode: "agent",
    },
  });
}

const revNavigatorService = {
  getRevNavigator_Main_DataByManager,
  getRevNavigator_Main_DataByAgent,
  getRevNavigator_Main_DataByTeamLeader,
  getRevNavigator_CustomersData_DataByManager,
  getRevNavigator_CustomersData_DataByAgent,
  getRevNavigator_CustomersData_DataByTeamLeader,
  getRevNavigator_ItemsData_ByItem_DataByManager,
  getRevNavigator_ItemsData_ByItem_DataByAgent,
  getRevNavigator_ItemsData_ByItem_DataByTeamLeader,
  getRevNavData_ItemsData_ByItemByCustByItemCatalogNumberByManager,
  getRevNavData_ItemsData_ByItemByCustByItemCatalogNumberByTeamLeader,
  getRevNavData_ItemsData_ByItemByCustByItemCatalogNumberByAgent,
};

export default revNavigatorService;
