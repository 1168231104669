import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customerTrendsSorts: [
    { id: "urgency", desc: true },
    { id: "achieved", desc: true },
  ],
  keyOpportunitiesTableSorts: [{ id: "AggPotentialValue", desc: true }],
  salesReviewTableSorts: [{ id: "Sales", desc: true }],
  productPurchasedTableSorts: [{ id: "Value", desc: true }],
  reportsTableSorts: [],
  purchaseHistoryTableSorts: [{ id: "p3m", desc: true }],
  customersKeyOpportunitiesSorts: [{ id: "Value", desc: true }],
  revNavigatorTableSorts: [],
  revNavigatorDetailsTableSorts: [],
};

export const tableSortsSlice = createSlice({
  name: "tableSorts",
  initialState,
  reducers: {
    setCustomerTrendsSorts: (state, action) => {
      state.customerTrendsSorts = action.payload;
    },
    setKeyOpportunitiesTableSorts: (state, action) => {
      state.keyOpportunitiesTableSorts = action.payload;
    },
    setSalesReviewTableSorts: (state, action) => {
      state.salesReviewTableSorts = action.payload;
    },
    // setProductPurchasedTableSorts: (state, action) => {
    //   state.productPurchasedTableSorts = action.payload;
    // },
    setReportsTableSorts: (state, action) => {
      state.reportsTableSorts = action.payload;
    },
    setPurchaseHistoryTableSorts: (state, action) => {
      state.purchaseHistoryTable = action.payload;
    },
    setRevNavigatorTableSorts: (state, action) => {
      state.revNavigatorTableSorts = action.payload;
    },
    setRevNavigatorDetailsTableSorts: (state, action) => {
      state.revNavigatorDetailsTableSorts = action.payload;
    },
    // setCustomersKeyOpportunitiesSorts: (state, action) => {
    //   state.customersKeyOpportunitiesSorts = action.payload;
    // },
  },
});

export const {
  setCustomerTrendsSorts,
  setKeyOpportunitiesTableSorts,
  setSalesReviewTableSorts,
  setPurchaseHistoryTableSorts,
  // setProductPurchasedTableSorts,
  setReportsTableSorts,
  setRevNavigatorTableSorts,
  // setCustomersKeyOpportunitiesSorts,
  setRevNavigatorDetailsTableSorts,
} = tableSortsSlice.actions;

export default tableSortsSlice.reducer;
