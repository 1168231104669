import React, { useMemo } from "react";
import { scaleLinear } from "d3-scale";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  XAxis,
  Cell,
  ResponsiveContainer,
} from "recharts";

import "./styles.css";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  setRevNavGraphData,
  setRevNavTableMode,
} from "../../../store/features/revNavigatorSlice";

const engCharacters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

function findTableModeByValue(legend, value) {
  const keys = [];
  for (const [key, val] of Object.entries(legend)) {
    if (val === value) {
      keys.push(key);
    }
  }
  return keys;
}

const legend = {
  english: {
    B: "Base Sales",
    DG: "Distribution Gains",
    DL: "Distribution Losses",
    L: "Lost Customers",
    N: "New/Returning Customers",
    Total: "Total Growth",
  },
  hebrew: {
    B: "שינוי במכירות בסיס",
    DG: "הרחבת הפצה לקוחות קיימים",
    DL: "צמצום פריטים לקוחות קיימים",
    L: "לקוחות שלא קנו",
    N: "לקוחות חדשים/חוזרים",
    Total: "סה״כ צמיחה",
  },
};
const indexMap = {
  B: 0,
  DG: 1,
  DL: 2,
  N: 3,
  L: 4,
};

function barColor(barData, index) {
  if (index === 5) return "#e5d3e5";
  else if (barData.label?.includes("-")) {
    if (!barData.selected) return "#f8cdcd";
    else return "#FF7C80";
  } else {
    if (!barData.selected) return "#e0ecd5";
    else return "#A9D18E";
  }
  // return index === 5
  //   ? "#e5d3e5"
  //   : barData.label?.includes("-")
  //   ? "#f8cdcd"
  //   : "#e0ecd5";
}

export const RevNavigatorGraph = ({}) => {
  const revNavGraphData = useSelector(
    (state) => state.revNavigatorSlice.revNavGraphData,
    shallowEqual
  );
  const revNavTableMode = useSelector(
    (state) => state.revNavigatorSlice.revNavTableMode
  );

  const graphData = useMemo(() => {
    return revNavGraphData;
  }, [revNavGraphData]);

  const dispatch = useDispatch();

  const handleBarClick = (e) => {
    const { tableMode, barIndex } = e.payload;
    if (
      tableMode !== revNavTableMode &&
      ["L", "N", "DG", "DL"]?.includes(tableMode)
    ) {
      dispatch(setRevNavTableMode(tableMode));
      dispatch(
        setRevNavGraphData(
          revNavGraphData.map((i) => ({
            ...i,
            selected: i.barIndex === barIndex ? true : false,
          }))
        )
      );
    }
  };

  const CustomTick = (props) => {
    const { x, y, payload } = props;
    let language = engCharacters?.includes(payload.value[0])
      ? "english"
      : "hebrew";
    const handleLabelClick = (e) => {
      const tableMode = e.currentTarget.id;
      const barIndex = indexMap[tableMode];
      if (
        tableMode !== revNavTableMode &&
        ["L", "N", "DG", "DL"]?.includes(tableMode)
      ) {
        dispatch(setRevNavTableMode(tableMode));
        dispatch(
          setRevNavGraphData(
            revNavGraphData.map((i) => ({
              ...i,
              selected: i.barIndex === barIndex ? true : false,
            }))
          )
        );
      }
    };

    return (
      <g
        id={findTableModeByValue(legend[language], payload.value)}
        name={payload.value}
        onClick={handleLabelClick}
      >
        <foreignObject x={x - 75} y={y} width={150} height={100}>
          <div
            style={{
              // width:"max-content",
              fontSize: "12.5px",
              fontWeight: "500",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ width: "max-content" }}>{payload.value}</div>
            {[1, 2, 3, 4]?.includes(payload.index) && (
              <div
                style={{
                  border: [1, 2, 3, 4]?.includes(payload.index)
                    ? "black 1px solid"
                    : "lightgray 1px solid",
                  color: [1, 2, 3, 4]?.includes(payload.index)
                    ? "black"
                    : "lightgray",
                  padding: 2,
                  width: "max-content",
                  cursor: [1, 2, 3, 4]?.includes(payload.index)
                    ? "pointer"
                    : "not-allowed",
                }}
              >
                {engCharacters?.includes(payload.value[0]) ? "Details" : "פירוט"}{" "}
              </div>
            )}
          </div>
        </foreignObject>
      </g>
    );
  };
  return (
    <ResponsiveContainer height={200}>
      <BarChart
        data={graphData}
        margin={{
          top: 20,
        }}
      >
        {/* <Legend
          layout="horizontal"
          verticalAlign="top"
          align={Language === "hebrew" ? "left" : "right"}
          payload={[
            {
              id: "increase",
              value: dictionary[Language]?.revNav_MainGraph.legend.increase,
              type: "Square",
              color: "rgb(169, 209, 142)",
            },
            {
              id: "decrease",
              value: dictionary[Language]?.revNav_MainGraph.legend.decrease,
              type: "Square",
              color: "rgb(255, 124, 128)",
            },
            {
              id: "total",
              value: dictionary[Language]?.revNav_MainGraph.legend.total,
              type: "Square",
              color: "rgb(200, 162, 200)",
            },
          ]}
        />{" "} */}
        <CartesianGrid
          strokeDasharray="5 5"
          vertical={false}
          horizontal={true}
          horizontalCoordinatesGenerator={(args) => {
            let hPoints = [];
            const totalLines = Math.ceil(args.offset.height / 70);
            const hScale = scaleLinear()
              .range([args.offset.top, args.height - args.offset.bottom])
              .domain([0, totalLines]);

            for (let i = 0; i <= totalLines; i++) {
              hPoints = [...hPoints, hScale(i)];
            }

            return hPoints;
          }}
        />
        <XAxis
          dataKey="name"
          height={55}
          // tick={{ fontSize: 14 }}
          tick={<CustomTick />}
          // interval={0}
        />
        {/* <Tooltip
          cursor={true}
          separator=""
          formatter={(value, name, props) => {
            return [props.payload.labelText, ""];
          }}
        /> */}

        <Bar
          dataKey="value"
          maxBarSize={180}
          onClick={handleBarClick}
          isAnimationActive={false}
        >
          {graphData.map((entry, index) => {
            return (
              <React.Fragment key={index}>
                <Cell
                  style={{
                    cursor: [1, 2, 3, 4]?.includes(index) ? "pointer" : "",
                  }}
                  key={`cell-${index}`}
                  fill={barColor(graphData[index], index)}
                />
                <LabelList dataKey="label" fill="black" fontWeight="100" />
              </React.Fragment>
            );
          })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};
