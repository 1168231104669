import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  test: 0,
  test2: 0,
};

export const testSlice = createSlice({
  name: "testSlice",
  initialState,
  reducers: {
    setTest: (state, action) => {
      state.test = action.payload;
    },
    setTest2: (state, action) => {
      state.test2 = action.payload;
    },
  },
});

export const { setTest, setTest2 } = testSlice.actions;

export default testSlice.reducer;
