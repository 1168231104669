import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import { grey } from "@mui/material/colors";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import CardContent from "@material-ui/core/CardContent";
import { useState, useEffect, useCallback } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { getColumnByAccessor } from "../../utils/filters";
import { dictionary } from "../../constants/dictionary";
import { shallowEqual, useSelector } from "react-redux";
import DateMonthsColumnFilter from "./DateMonthsColumnFilter";
import { currencyMap } from "../../constants/currency-map";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  card: {
    top: -10,
    left: 10,
    zIndex: 6,
    width: 180,
    display: "flex",
    position: "absolute",
    flexDirection: "column",
    boxShadow: `0 0 5px ${grey[500]}`,
    overflow: "visible",
    fontSize: 12,
    [theme.breakpoints.down("xs")]: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: "auto",
      height: "fit-content",
    },
  },
  formControl: {
    width: 140,
  },
  filtersCloseIcon: {
    opacity: 0.82,
    cursor: "pointer",
    fontSize: 18,
    transition: "opacity 0.4s",
    "&:hover": {
      opacity: 1,
    },
  },
  font: {
    fontSize: 13,
  },
}));

export default function AnalyzePicker({
  columns = [],
  headers = [],
  visible = false,
  setVisibility = () => {},
}) {
  const classes = useStyles();
  const [filterByColumn, setFilterByColumn] = useState(columns[0].accessor);
  const [analyzeDimension, setAnalyzeDimension] = useState("value");
  const userSlice = useSelector((state) => state.userSlice, shallowEqual);

  const {
    user: {
      metadata: { Language, Currency },
    },
  } = userSlice;

  const clearFilter = useCallback(
    (columnAccessor) => {
      const column = getColumnByAccessor(columnAccessor, headers);
      if (column && column.canFilter && column.setFilter) {
        column.setFilter("");
      }
    },
    [headers]
  );

  const clearAllFilters = useCallback(() => {
    headers?.forEach((column) => {
      clearFilter(column.id);
    });
  }, [headers]);

  useEffect(
    function onClearFilters() {
      if (!filterByColumn) {
        clearAllFilters();
      }
    },
    [filterByColumn]
  );

  return (
    <form name="filter-picker" className={classes.root}>
      {!visible || !filterByColumn ? (
        <Typography
          variant="caption"
          color="textSecondary"
          onClick={() => setVisibility(true)}
        >
        </Typography>
      ) : (
        <Card
          className={classes.card}
          style={{ direction: Language === "hebrew" ? "rtl" : "ltr" }}
        >
          <CardContent>
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
              marginBottom={1}
            >
              <Typography gutterBottom variant="body2">
                {dictionary[Language].analyzeBox.title}
              </Typography>
              <CloseIcon
                className={classes.filtersCloseIcon}
                onClick={() => setVisibility(false)}
              />
            </Box>
            <Box className={classes.formControl}>
              <div style={{ marginBottom: 5 }}>
                {" "}
                {dictionary[Language].analyzeBox.dimension}
              </div>
              <div
                onClick={(e) => {
                  setAnalyzeDimension(e.target.className);
                }}
                style={{
                  display: "flex",
                  fontSize: "0.6rem",
                  border: "#c0c0c0 solid 0.9px",
                  cursor: "pointer",
                  borderRadius: "5px",
                }}
              >
                <span
                  className="units"
                  style={{
                    flexBasis: "50%",
                    textAlign: "center",
                    padding: " 0.25rem",
                    background:
                      analyzeDimension === "units" ? "#a8c6fa" : "#f2faff",
                    borderTopLeftRadius: "4px",
                    borderBottomLeftRadius: "4px",
                  }}
                >
                  {dictionary[Language].analyzeBox.units}
                </span>
                <span
                  className="value"
                  style={{
                    flexBasis: "50%",
                    textAlign: "center",
                    padding: " 0.25rem",
                    background:
                      analyzeDimension === "value" ? "#a8c6fa" : "#f2faff",
                    borderTopRightRadius: "4px",
                    borderBottomRightRadius: "4px",
                  }}
                >
                  {currencyMap[Currency]}
                </span>
              </div>
            </Box>
            <DateMonthsColumnFilter
              column={columns}
              setVisibility={setVisibility}
              analyzeDimension={analyzeDimension}
            />
            <FormControl className={classes.filtersCloseIcon}></FormControl>
          </CardContent>
        </Card>
      )}
    </form>
  );
}
