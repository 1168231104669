import TextColumnFilter from "../../../Filters/TextColumnFilter";
import NumberColumnFilter from "../../../Filters/NumberColumnFilter";
import CheckedColumnFilter from "../../../Filters/CheckedColumnFilter";

import { sortTypes } from "../../../../utils/sort";
import PercentageColumnFilter from "../../../Filters/PercentageColumnFilter";

export const engGROUPED_COLUMNS = [
  {
    Header: "Customer Details",
    columns: [
      {
        Header: "Customer Code",
        Footer: "Customer Code",
        accessor: "CustomerID",
        filter: "text",
        Filter: TextColumnFilter,
        canFilter: true,
      },
      {
        Header: "Customer Name",
        Footer: "Customer Name",
        accessor: "CustomerName",
        filter: "text",
        Filter: TextColumnFilter,
        canFilter: true,
      },
      {
        Header: "Sales Person",
        Footer: "Sales Person",
        accessor: "AgentName",
        filter: "checked",
        Filter: CheckedColumnFilter,
        canFilter: true,
      },
      {
        Header: "All insights value",
        Footer: "Value",
        accessor: "AggPotentialValue",
        filter: "number",
        Filter: NumberColumnFilter,
        canFilter: true,
        sortType: sortTypes.number,
      },
    ],
  },
  {
    Header: "Opportunities Details",
    columns: [
      {
        Header: "Code",
        accessor: "Code",
        filter: "text",
        Filter: TextColumnFilter,
        canFilter: true,
      },
      {
        Header: "Product/Variant",
        accessor: "ProductName",
        filter: "text",
        Filter: TextColumnFilter,
        canFilter: true,
      },
      {
        Header: "Insight type",
        accessor: "InsightType",
        filter: "checked",
        Filter: CheckedColumnFilter,
        canFilter: true,
      },
      {
        Header: "Insight value",
        accessor: "Value",
        filter: "number",
        Filter: NumberColumnFilter,
        canFilter: true,
        sortType: sortTypes.number,
      },
      {
        Header: "Key Data",
        accessor: "KeyData",
        filter: "text",
        Filter: TextColumnFilter,
        canFilter: true,
      },
      {
        Header: "Progress",
        accessor: "Progress",
        filter: "number",
        Filter: PercentageColumnFilter,
        canFilter: true,
        sortType: sortTypes.number,
      },
    ],
  },
];
