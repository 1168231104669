import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  latestCustomersKeyOpportunitiesSorts: [],
  latestProductPurchasedTableSorts: [],
};

export const tableLatestSortsSlice = createSlice({
  name: "tableLatestSorts",
  initialState,
  reducers: {
    setProductPurchasedTableSorts: (state, action) => {
      state.latestProductPurchasedTableSorts = action.payload;
    },
    setCustomersKeyOpportunitiesSorts: (state, action) => {
      state.latestCustomersKeyOpportunitiesSorts = action.payload;
    },
  },
});

export const {
  setProductPurchasedTableSorts,
  setCustomersKeyOpportunitiesSorts,
} = tableLatestSortsSlice.actions;

export default tableLatestSortsSlice.reducer;
