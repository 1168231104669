import React, { useEffect, useState } from "react";
import { dictionary } from "../../../constants/dictionary";
import { shallowEqual, useSelector } from "react-redux";
import RevNavigatorDetailsSumsRow from "./RevNavigatorDetailsSumsRow";

export default function RevNavigatorDetailsAboveTable({
  rows,
  ItemCatalogNumber,
  ItemName,
  CurrStock,
}) {
  const Language = useSelector(
    (state) => state.userSlice.user.metadata.Language
  );
  const revNavTableMode = useSelector(
    (state) => state.revNavigatorSlice.revNavTableMode
  );
  const revNavSelectedPeriod = useSelector(
    (state) => state.revNavigatorSlice.revNavSelectedPeriod
  );
  const processedPeriods = useSelector(
    (state) => state.revNavigatorSlice.processedPeriods
  );

  const [periodDates, setPeriodDates] = useState([]);

  useEffect(() => {
    if (processedPeriods.length > 0) {
      const desiredPeriod = processedPeriods.find(
        (i) => i.Period === revNavSelectedPeriod
      );

      setPeriodDates(
        `${desiredPeriod.FirstPeriod}${Language === "hebrew" ? " לעומת " : " vs "}${
          desiredPeriod.SecondPeriod
        }`
      );
    }
  }, [processedPeriods]);

  return (
    <div
      style={{
        direction: `${Language === "hebrew" ? "rtl" : "ltr"}`,
        display: "flex",
        alignItems: "baseline",
      }}
    >
      <div
        className="customers-trends-above-table"
        style={{
          marginRight: "3rem",
        }}
      >
        <div style={{ display: "flex" }}>
          <h4 className="table-header">
            {
              dictionary[Language]?.RevNavigatorTable.tableTitle2[
                revNavTableMode
              ]
            }
          </h4>{" "}
          <span>{periodDates}</span>
        </div>
        <div style={{ marginLeft: "0.5rem" }}>
          <span>{`${ItemName}, ${ItemCatalogNumber}. ${" "} `}</span>
          <span style={{ margin: "0 1rem" }}>
            {`${dictionary[Language]?.RevNavigatorTable.stock} ${CurrStock}`}
          </span>
        </div>
      </div>
      <RevNavigatorDetailsSumsRow rows={rows} />
    </div>
  );
}
