import axios from "axios";
import domain from "../config/domain";
import { reqAuth } from "../constants/reqAuth";

async function getKeyOpportunitiesTableData(
  ManagerID,
  InsightingCustomerID,
  reqDate
) {
  return axios
    .request({
      method: "POST",
      url: `${domain}/KeyOpportunities/by-manager`,
      headers: { authorization: reqAuth },
      data: { ManagerID, InsightingCustomerID, reqDate },
    })
    .then((r) => r.data)
    .catch(console.error);
}
async function getKeyOpportunitiesTableDataByAgent(
  AgentID,
  InsightingCustomerID,
  reqDate
) {
  return axios
    .request({
      method: "POST",
      url: `${domain}/KeyOpportunities/by-agent`,
      headers: { authorization: reqAuth },
      data: { AgentID, InsightingCustomerID, reqDate },
    })
    .then((r) => r.data)
    .catch(console.error);
}
async function getKeyOpportunitiesTableDataByTeamLeader(
  agents,
  InsightingCustomerID,
  reqDate
) {
  return axios
    .request({
      method: "POST",
      url: `${domain}/KeyOpportunities/by-team-leader`,
      headers: { authorization: reqAuth },
      data: { agents, InsightingCustomerID, reqDate },
    })
    .then((r) => r.data)
    .catch(console.error);
}
const opportunitiesService = {
  getKeyOpportunitiesTableData,
  getKeyOpportunitiesTableDataByAgent,
  getKeyOpportunitiesTableDataByTeamLeader,
};

export default opportunitiesService;
