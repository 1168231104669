import React, { useMemo } from "react";
import { scaleLinear } from "d3-scale";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  XAxis,
  Cell,
  ResponsiveContainer,
} from "recharts";

import "./styles.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

function barColor(data, index) {
  return index === 5
    ? "#C8A2C8"
    : data[index].label?.includes("-")
    ? "#FF7C80"
    : "#A9D18E";
}

export const GrowthAnalysisGraph = ({ data }) => {
  const graphData = useMemo(() => {
    return data;
  }, [data]);
  const history = useHistory();
  return (
    <ResponsiveContainer width="96%" height={340}>
      <BarChart
        data={graphData}
        margin={{
          top: 20,
        }}
        style={{ cursor: "pointer" }}
        onClick={() => history.push("/revnav")}
      >
        <CartesianGrid
          strokeDasharray="5 5"
          vertical={false}
          horizontal={true}
          horizontalCoordinatesGenerator={(args) => {
            let hPoints = [];
            const totalLines = Math.ceil(args.offset.height / 70);
            const hScale = scaleLinear()
              .range([args.offset.top, args.height - args.offset.bottom])
              .domain([0, totalLines]);

            for (let i = 0; i <= totalLines; i++) {
              hPoints = [...hPoints, hScale(i)];
            }

            return hPoints;
          }}
        />
        <XAxis dataKey="name" height={20} />
        <Bar dataKey="value" maxBarSize={180} isAnimationActive={false}>
          {graphData.map((entry, index) => (
            <React.Fragment key={index}>
              <Cell key={`cell-${index}`} fill={barColor(graphData, index)} />
              <LabelList dataKey="label" fill="black" fontWeight="100" />
            </React.Fragment>
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};
