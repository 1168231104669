import React from "react";
import { useSelector } from "react-redux";
import { dictionary } from "../../../constants/dictionary";
import numberUtils from "../../../utils/number";
import { currencyMap } from "../../../constants/currency-map";

export default function RevNavigatorSumsRow(rows) {
  const Language = useSelector(
    (state) => state.userSlice.user.metadata.Language
  );
  const Currency = useSelector(
    (state) => state.userSlice.user.metadata.Currency
  );
  const revNavTableMode = useSelector(
    (state) => state.revNavigatorSlice.revNavTableMode
  );
  const numberOfCustomers = rows?.rows?.length;
  const periodValueSum = rows.rows.reduce(
    (sum, row) =>
      sum +
      (typeof row?.original.periodValue === "string"
        ? parseInt(row?.original.periodValue?.replaceAll(",", ""))
        : typeof row?.original.periodValue === "number"
        ? parseInt(row?.original.periodValue)
        : 0),
    0
  );
  const periodUnitsSum = rows.rows.reduce(
    (sum, row) =>
      sum +
      (typeof row?.original?.Units === "string"
        ? parseInt(row?.original?.Units?.replaceAll(",", ""))
        : typeof row?.original?.Units === "number"
        ? parseInt(row?.original?.Units)
        : 0),
    0
  );

  return (
    <div
      style={{
        direction: `${Language === "hebrew" ? "rtl" : "ltr"}`,
        margin: "0.5rem",
      }}
    >
      <div
        className="rev-navigator-table-sums-list"
        style={
          {
            // width: ["DL", "DG"]?.includes(revNavTableMode) ? "16rem" : "12.5rem",
          }
        }
      >
        <div className="customers-overview-table-sums-tag">
          <div className="sum-value">{numberOfCustomers}</div>
          <div className="sum-header">
            {dictionary[Language]?.RevNavigatorTable.sumsText.customers}
            {/* {["DL", "DG"]?.includes(revNavTableMode)
              ? dictionary[Language]?.RevNavigatorTable.sumsText.products
              : dictionary[Language]?.RevNavigatorTable.sumsText.customers} */}
          </div>
        </div>
        <div className="hierarchy-separator"></div>
        <div className="customers-overview-table-sums-tag">
          <div className="sum-value">
            {/* {currencyMap[Currency]} */}
            {numberUtils.mFormat(periodValueSum)}
          </div>
          <div className="sum-header">{currencyMap[Currency]}</div>
        </div>

        <div className="hierarchy-separator"></div>
        <div className="customers-overview-table-sums-tag">
          <div className="sum-value">
            {/* {currencyMap[Currency]} */}
            {["DL", "DG"]?.includes(revNavTableMode)
              ? numberUtils.mFormat(periodUnitsSum)
              : "-"}
          </div>
          <div className="sum-header">
            {dictionary[Language]?.analyzeBox.units}
          </div>
        </div>
      </div>
    </div>
  );
}
