import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { dictionary } from "../../../constants/dictionary";
import { setTrendsData } from "../../../store/features/homePagePropsSlice";
import {
  setHierFilterData,
  setShowHierFilteredData,
} from "../../../store/features/tableFiltersSlice";
import { Box } from "@material-ui/core";
import HierarchyPicker from "../../Filters/HierarchyPicker";
import { isEmpty } from "lodash";
import { Tooltip } from "react-tooltip";
import { setFlattenArrayOfHierachyValues } from "../../../store/features/customersOverviewSlice";

export default function CustomersOverviewGroupFilter({
  displayAddGroupFilter,
  setDisplayGroupFilter,
}) {
  const dispatch = useDispatch();
  const Language = useSelector(
    (state) => state.userSlice.user.metadata.Language
  );
  const CustHierNames = useSelector(
    (state) => state.userSlice.user.metadata.CustHierNames
  );
  const customersOverviewTableData = useSelector(
    (state) => state.dataSlice.customersOverviewTableData,
    shallowEqual
  );
  const showHierFilteredData = useSelector(
    (state) => state.tableFiltersSlice.showHierFilteredData
  );
  const hierFilterData = useSelector(
    (state) => state.tableFiltersSlice.hierFilterData,
    shallowEqual
  );

  const [hierarchyMode, setHierarchyMode] = useState(false);
  const [hierarchyButtonVisible, setHierarchyButtonVisible] = useState(null);

  const handleRemoveCustomerGroupFilter = () => {
    dispatch(setTrendsData(customersOverviewTableData));
    dispatch(setHierFilterData([]));
    dispatch(setShowHierFilteredData(false));
  };

  useEffect(() => {
    const arrayOfHierarchyValues = customersOverviewTableData.map(
      (i) => i.custHierValues
    );

    const flattenArrayOfHierachyValues = Array.from({ length: 8 }, (_, j) => {
      const uniqueSet = new Set();

      return arrayOfHierarchyValues.reduce((acc, item) => {
        const value = item?.[j];

        if (value !== undefined && !uniqueSet.has(value)) {
          acc.push(value);
          uniqueSet.add(value);
        }

        return acc;
      }, []);
    });

    sessionStorage.setItem(
      "flattenArrayOfHierachyValues",
      JSON.stringify(flattenArrayOfHierachyValues)
    );
    dispatch(setFlattenArrayOfHierachyValues(flattenArrayOfHierachyValues));
  }, [customersOverviewTableData]);

  useEffect(() => {
    if (showHierFilteredData) {
      const firstAndIndex = hierFilterData.findIndex(
        (i) => i.logicID === "AND"
      );
      const firstOrIndex = hierFilterData.findIndex((i) => i.logicID === "OR");

      if (hierFilterData?.length > 0 && hierFilterData[0]) {
        const andsFiltersData = [
          ...hierFilterData?.filter((i) => i.logicID === "AND"),
          hierFilterData[firstAndIndex - 1],
        ];

        const orsFiltersData = hierFilterData?.filter((i, index, array) => {
          if (i.logicID === "OR") {
            const nextLogicID = array[index + 1]?.logicID;
            return nextLogicID !== "AND" || typeof nextLogicID === "undefined";
          }
          return false;
        });

        if (firstOrIndex === 1 || hierFilterData?.length === 1)
          orsFiltersData.push(hierFilterData[0]);

        const hierAndsFilterArr = andsFiltersData
          ?.filter((i) => i?.selectedOption1 !== null)
          .map((i) => ({
            selection: i?.selectedOptions2?.map((i) => i.label),
            selectedHierIndex: i?.selectedOption1.index,
          }));

        const hierOrsFilterArr = orsFiltersData
          ?.filter((i) => i?.selectedOption1 !== null)
          .map((i) => ({
            selection: i?.selectedOptions2?.map((i) => i.label),
            selectedHierIndex: i?.selectedOption1.index,
          }));

        const passedAndsConditionsData = [
          ...customersOverviewTableData?.filter((cust) => {
            return (
              (!hierAndsFilterArr[0] ||
                hierAndsFilterArr[0]?.selection?.includes(
                  cust.custHierValues?.[hierAndsFilterArr[0].selectedHierIndex]
                )) &&
              (!hierAndsFilterArr[1] ||
                hierAndsFilterArr[1]?.selection?.includes(
                  cust.custHierValues?.[hierAndsFilterArr[1].selectedHierIndex]
                )) &&
              (!hierAndsFilterArr[2] ||
                hierAndsFilterArr[2]?.selection?.includes(
                  cust.custHierValues?.[hierAndsFilterArr[2].selectedHierIndex]
                )) &&
              (!hierAndsFilterArr[3] ||
                hierAndsFilterArr[3]?.selection?.includes(
                  cust.custHierValues?.[hierAndsFilterArr[3].selectedHierIndex]
                )) &&
              (!hierAndsFilterArr[4] ||
                hierAndsFilterArr[4]?.selection?.includes(
                  cust.custHierValues?.[hierAndsFilterArr[4].selectedHierIndex]
                )) &&
              (!hierAndsFilterArr[5] ||
                hierAndsFilterArr[5]?.selection?.includes(
                  cust.custHierValues?.[hierAndsFilterArr[5].selectedHierIndex]
                )) &&
              (!hierAndsFilterArr[6] ||
                hierAndsFilterArr[6]?.selection?.includes(
                  cust.custHierValues?.[hierAndsFilterArr[6].selectedHierIndex]
                )) &&
              (!hierAndsFilterArr[7] ||
                hierAndsFilterArr[7]?.selection?.includes(
                  cust.custHierValues?.[hierAndsFilterArr[7].selectedHierIndex]
                )) &&
              (!hierAndsFilterArr[8] ||
                hierAndsFilterArr[8]?.selection?.includes(
                  cust.custHierValues?.[hierAndsFilterArr[8].selectedHierIndex]
                ))
            );
          }),
        ];

        const passedOrsConditionsData = [];
        for (let i of hierOrsFilterArr) {
          let tempPassed = customersOverviewTableData?.filter((cust) =>
            i.selection?.includes(cust.custHierValues?.[i.selectedHierIndex])
          );
          if (!isEmpty(tempPassed)) passedOrsConditionsData.push(...tempPassed);
        }

        dispatch(
          setTrendsData([
            ...new Set([
              ...passedAndsConditionsData,
              ...passedOrsConditionsData,
            ]),
          ])
        );
      }
    }
  }, [showHierFilteredData]);

  useEffect(() => {
    setDisplayGroupFilter(
      !isEmpty(hierFilterData) && hierFilterData[0] ? false : true
    );
  }, [hierFilterData]);

  return (
    <>
      <Box className="hierarchy-div">
        {displayAddGroupFilter ? (
          <div
            className="hierarchy-button"
            style={{
              cursor: CustHierNames?.length > 2 ? "pointer" : "not-allowed",
              color: CustHierNames?.length > 2 ? "black" : "grey",
              width: Language === "hebrew" ? "6.6rem" : "7.5rem",
            }}
            onClick={(e) => {
              if (CustHierNames?.length > 2) {
                setHierarchyMode(!hierarchyMode);
                setHierarchyButtonVisible(true);
              }
            }}
          >
            <span
              className="hierarchy-filter-delete-button"
              style={{ color: "black", background: "transparent" }}
              onClick={handleRemoveCustomerGroupFilter}
            >
              +{" "}
            </span>
            {dictionary[Language].hierarchyButton.addFilterText}
          </div>
        ) : (
          <div
            className="cancel-applied-hierarchy-filter"
            data-tooltip-id="my-tooltip-children-multiline"
            style={{ zIndex: "7" }}
          >
            <Tooltip
              opacity={1}
              border={"1px solid grey"}
              id="my-tooltip-children-multiline"
              place="bottom-start"
              style={{
                background: "white",
                color: "black",
                boxShadow: "1px 1px 1px 1px grey",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  fontSize: "13px",
                }}
              >
                {hierFilterData?.map((hierItem) => (
                  <span
                    style={
                      hierFilterData?.length > 1
                        ? {
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "baseline",
                          }
                        : {}
                    }
                  >
                    <span
                      className="hierarchy-filter-chosen-logic"
                      style={
                        hierItem.logicID === "AND"
                          ? {
                              color: "#d36efb",
                              background: "#f6deff",
                              display: "block",
                              margin: "3px 0px",
                            }
                          : hierItem.logicID === "OR"
                          ? {
                              color: "#FBA16E",
                              background: "#FFF2DE",
                              display: "block",
                              margin: "3px 0px",
                            }
                          : {
                              margin: "15px 0px 10px 0px",
                            }
                      }
                    >
                      {hierItem.logicValue}{" "}
                    </span>
                    <span style={{ margin: "0 10px" }}>
                      {hierItem.selectedOption1.value + ":"}{" "}
                    </span>
                    <span>
                      {hierItem?.selectedOptions2?.map((hierValue) => (
                        <span className="hierarchy-chosen-option">
                          {hierValue.value}
                        </span>
                      ))}
                    </span>
                  </span>
                ))}
              </div>
            </Tooltip>

            {dictionary[Language].hierarchyButton.removeFilterText}
            <span
              className="hierarchy-filter-delete-button"
              style={{ color: "black", background: "#c0c0c0" }}
              onClick={handleRemoveCustomerGroupFilter}
            >
              x{" "}
            </span>
          </div>
        )}
        <div
          className="hierarchy-separator"
          style={{ margin: "0 0.5rem" }}
        ></div>
      </Box>
      <Box className="customers-overview-hierarchy-box">
        <HierarchyPicker
          visible={hierarchyButtonVisible}
          setVisibility={setHierarchyButtonVisible}
        />
      </Box>
    </>
  );
}
