import React, { useState } from "react";
import FiltersList from "../../Filters/FiltersList";
import FilterPicker from "../../Filters/FilterPicker";

export default function CustomersOverviewFilters({
  filters,
  headers,
  columns,
}) {
  const [filterPickerVisible, setFilterPickerVisible] = useState(null);
  // const mappedHeaders = headers.map((header) => ({
  //   id: header.id,
  //   canFilter: header.canFilter,
  //   Header: header.Header,
  //   setFilter: header.setFilter,
  //   render: header.render,
  // }));
  return (
    <>
      <FiltersList
        filters={filters}
        headers={headers}
        setFilterPickerVisible={setFilterPickerVisible}
      />
      <FilterPicker
        columns={columns}
        visible={filterPickerVisible}
        headers={headers}
        setVisibility={setFilterPickerVisible}
      />
    </>
  );
}
