const local = "http://localhost:3002";
const dev = "https://devapi-reports.insighting-app.io";
const irDev = "https://ir-devapi.insighting-app.io";
const prod = "https://api-reports.insighting-app.io";

const reportsServerDomain =
  process.env.NODE_ENV === "development"
    ? local
    : process.env.REACT_APP_NODE_ENV === "ir-development"
    ? irDev
    : process.env.REACT_APP_NODE_ENV === "production"
    ? prod
    : dev;
export default reportsServerDomain;
