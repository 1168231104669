import React, { memo, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { dictionary } from "../../constants/dictionary";
import { RevNavigatorGraph } from "../Graphs/RevNavigatorGraph";
import "./RevNavigator.css";
import Select from "react-select";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StopIcon from "@mui/icons-material/Stop";

import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import AgentsPicker from "../Filters/AgentsPicker";
import {
  setProcessedPeriods,
  setRevNavGraphData,
  setRevNavSelectedPeriod,
} from "../../store/features/revNavigatorSlice";
import { getRevNavCoordinates } from "../../utils/helpers";

function RevNavigator() {
  const metadata = useSelector(
    (state) => state.userSlice.user.metadata,
    shallowEqual
  );
  const revNavResData = useSelector(
    (state) => state.revNavigatorSlice.revNavResData,
    shallowEqual
  );
  const revNavSelectedPeriod = useSelector(
    (state) => state.revNavigatorSlice.revNavSelectedPeriod
  );
  const revNavCheckedAgents = useSelector(
    (state) => state.revNavigatorSlice.revNavCheckedAgents,
    shallowEqual
  );
  const uniqueAgentIDs = useSelector(
    (state) => state.revNavigatorSlice.uniqueAgentIDs,
    shallowEqual
  );
  const revNavTableMode = useSelector(
    (state) => state.revNavigatorSlice.revNavTableMode
  );
  const processedPeriods = useSelector(
    (state) => state.revNavigatorSlice.processedPeriods
  );

  const dispatch = useDispatch();
  let { Language, UserType } = metadata;

  // const [processedPeriods, setProcessedPeriods] = useState([]);
  // const [uniqueAgentIDs, setUniqueAgentIDs] = useState([]);
  const [agentsPickerVisible, setAgentsPickerVisible] = useState(null);

  const handlePeriodChange = (selectedOption) => {
    dispatch(setRevNavSelectedPeriod(selectedOption.value));
  };

  useEffect(
    function createGraphDataPoints() {
      if (revNavResData?.length > 0 && uniqueAgentIDs?.length > 0) {
        const processedPeriodsMap = {};
        const spacesAndBrackets = /[\[\]\s]/g;

        revNavResData?.forEach((item) => {
          const period = item.Period;
          const firstPeriod = item.FirstPeriod?.replace(
            spacesAndBrackets,
            ""
          ).replace(",", "-");
          const secondPeriod = item.SecondPeriod?.replace(
            spacesAndBrackets,
            ""
          ).replace(",", "-");
          if (
            period &&
            !processedPeriodsMap[period] &&
            [
              "LastMonth",
              "LastMonth_YA",
              "P2M",
              "P2M_YA",
              "P3M",
              "P3M_YA",
            ]?.includes(period)
          ) {
            processedPeriodsMap[period] = {
              Period: period,
              FirstPeriod: firstPeriod,
              SecondPeriod: secondPeriod,
            };
          }
        });
        dispatch(setProcessedPeriods(Object.values(processedPeriodsMap)));
        dispatch(
          setRevNavGraphData(
            getRevNavCoordinates(
              UserType,
              revNavResData,
              revNavSelectedPeriod,
              revNavCheckedAgents,
              uniqueAgentIDs,
              Language,
              revNavTableMode
            )
          )
        );
        // }
      }
    },
    [revNavSelectedPeriod, uniqueAgentIDs]
  );
  return (
    <div style={{ margin: "0rem 2rem" }}>
      <div
        style={{
          display: "flex",
          direction: Language === "hebrew" ? "rtl" : "ltr",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {dictionary[Language]?.revNav_MainGraph.preSelectorText.timeFrame}
          <Select
            // value={selectValue}
            // styles={customSelectStyles}
            styles={{
              control: (base) => ({
                ...base,
                margin: "8px",
                width: 280,
              }),
              menuPortal: (base) => ({
                ...base,
                zIndex: 7,
                fontSize: 12,
                // direction: Language === "hebrew" ? "rtl" : "ltr",
              }),
            }}
            isSearchable={false}
            options={processedPeriods?.map((i) => ({
              value: i.Period,
              label: `${i.FirstPeriod} ${
                Language === "hebrew" ? "לעומת" : " vs "
              } ${i.SecondPeriod}
              `,
            }))}
            onChange={handlePeriodChange}
            placeholder={`${processedPeriods.find(i=> i.Period === revNavSelectedPeriod)?.FirstPeriod} ${
              Language === "hebrew" ? "לעומת" : " vs "
            } ${processedPeriods.find(i=> i.Period === revNavSelectedPeriod)?.SecondPeriod}
            `}
          />
          {dictionary[Language]?.revNav_MainGraph.preSelectorText.for}

          <div
            style={{
              // className: "revNavigator-agents-selector",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              border: "1px solid hsl(0, 0%, 80%)",
              color: "hsl(0, 0%, 80%)",
              height: "2.4rem",
              borderRadius: "3px",
              margin: "8px",
              minWidth: "7rem",
            }}
            onClick={() => setAgentsPickerVisible(!agentsPickerVisible)}
            // onClick={()=>multiSelectRef.current.show()}
          >
            <span style={{ padding: "4px", color: "black" }}>
              {
                Object.values(revNavCheckedAgents)?.filter(
                  (value) => value === true
                )?.length
              }
              {" " +
                dictionary[Language]?.revNav_MainGraph.agentsSelector
                  .placeHolder}
            </span>
            {" | "}
            <ExpandMoreIcon />
          </div>
          <AgentsPicker
            visible={agentsPickerVisible}
            setVisibility={setAgentsPickerVisible}
            uniqueAgentIDs={uniqueAgentIDs}
            // setAgentsFilterApplyToggle={setAgentsFilterApplyToggle}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>
            <StopIcon style={{ color: "#A9D18E" }} />
          </span>
          <span>{dictionary[Language]?.revNav_MainGraph.legend.increase}</span>
          <span>
            <StopIcon style={{ color: "#FF7C80" }} />
          </span>
          <span>{dictionary[Language]?.revNav_MainGraph.legend.decrease}</span>
          <span>
            <StopIcon style={{ color: "#C8A2C8" }} />
          </span>
          <span>{dictionary[Language]?.revNav_MainGraph.legend.total}</span>
        </div>
      </div>

      <RevNavigatorGraph />
    </div>
  );
}
export default memo(RevNavigator);
