import { useEffect } from "react";
import debounce from "lodash/debounce";
import { useDispatch } from "react-redux";
import { useTheme } from "@material-ui/core/styles";

import { setResolution } from "../store/features/globalSlice";

const useResolution = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(
    function onWindowSizeChange() {
      const onResize = () => {
        const { innerWidth: width, innerHeight: height, orientation } = window;
        const device = (() => {
          if (
            width < theme.breakpoints.values.sm ||
            (orientation !== 0 && height < 600&& width <1100)
          ) {
            return "mobile";
          }
          if (width < theme.breakpoints.values.md) {
            return "mobile-tablet";
          }
          if (width < theme.breakpoints.values.lg) {
            return "tablet";
          }
          return "desktop";
        })();

        dispatch(setResolution({ device, width }));
      };

      const onDebouncedResize = debounce(onResize, 400);
      window.addEventListener("resize", onDebouncedResize);

      onResize();

      return () => {
        window.removeEventListener("resize", onDebouncedResize);
      };
    },
    [dispatch]
  );
};

export default useResolution;
