import Box from "@material-ui/core/Box";
import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useFilters, useSortBy, useTable } from "react-table";
import ListAltIcon from "@mui/icons-material/ListAlt";

import { filterTypes } from "../../../utils/filters";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

import {
  setCurrencyForTableHeaders,
  isSorted,
  getUrgencyValue,
  goToCustomerPage,
  usDateFormat,
} from "../../../utils/helpers";
import { PriorityButton } from "../../PriorityButton/PriorityButton";
import { engCOLUMNS } from "./ColumnsFiles/engColumns";
import { hebCOLUMNS } from "./ColumnsFiles/hebColumns";
import "./RevNavigatorTable.css";

import InsightTypesLogos from "../../InsightTypesLogos/InsightTypesLogos";
import OnGoingLight from "../../OnGoingLight/OnGoingLight";
import moment from "moment";
import numberUtils from "../../../utils/number";
import { setTrendsData } from "../../../store/features/homePagePropsSlice";
import {
  setOverviewMenuPosition,
  setSelectedRowForMenu,
} from "../../../store/features/customersOverviewSlice";
import RevNavigatorFilters from "./RevNavigatorFilters";
import { urgencyColorsPalette } from "../../../constants/urgencyColorsPalette";
import RevNavigatorAboveTable from "./RevNavigatorAboveTable";
import RevNavigatorMenu from "./RevNavigatorMenu";
import {
  setIsPrepRows,
  setRevNavTableData,
} from "../../../store/features/revNavigatorSlice";
import { dictionary } from "../../../constants/dictionary";
import { LoadingAnimation } from "../../LoadingAnimation/LoadingAnimation";

const todaysDate = moment(moment().format("DD/MM/YYYY"), "D/M/YYYY");
const { round } = Math;
const { numberWithCommas } = numberUtils;

const DL_DG_Columns = [
  "ItemName",
  "ItemCatalogNumber",
  "ItemCategory",
  "NumOfCustomers",
  "periodValue",
  "Units",
  "NonOrderingCustomers",
  "CurrStock",
  "details",
];
const L_N_Columns = [
  "CustomerID",
  "customerName",
  "salesAgent",
  "periodValue",
  "LastOrderDate",
  "opportunities",
  "urgency",
  "priority",
  "details",
];

function RevNavigatorTable() {
  const userSlice = useSelector((state) => state.userSlice, shallowEqual);
  const revNavSelectedPeriod = useSelector(
    (state) => state.revNavigatorSlice.revNavSelectedPeriod,
    shallowEqual
  );
  const revNavTableData = useSelector(
    (state) => state.revNavigatorSlice.revNavTableData,
    shallowEqual
  );
  const revNavItemsByItemData = useSelector(
    (state) => state.revNavigatorSlice.revNavItemsByItemData,
    shallowEqual
  );
  const agentsFilterApplyToggle = useSelector(
    (state) => state.revNavigatorSlice.agentsFilterApplyToggle
  );
  const revNavTableMode = useSelector(
    (state) => state.revNavigatorSlice.revNavTableMode
  );
  const isPrepRows = useSelector((state) => state.revNavigatorSlice.isPrepRows);
  const customersOverviewData = useSelector(
    (state) => state.dataSlice.customersOverviewData,
    shallowEqual
  );
  const listOfSelectedAgents = useSelector(
    (state) => state.revNavigatorSlice.listOfSelectedAgents,
    shallowEqual
  );
  const isAllAgentsSelected = useSelector(
    (state) => state.revNavigatorSlice.isAllAgentsSelected,
    shallowEqual
  );
  const allKeyOpportunitiesForCustomerPage = useSelector(
    (state) => state.dataSlice.allKeyOpportunitiesForCustomerPage,
    shallowEqual
  );
  const fetchedRevNav_CustomersData = useSelector(
    (state) => state.dataSlice.fetchedRevNav_CustomersData,
    shallowEqual
  );
  const revNavigatorTableFilters = useSelector(
    (state) => state.tableFiltersSlice.revNavigatorTableFilters,
    shallowEqual
  );
  const revNavigatorTableSorts = useSelector(
    (state) => state.tableSortsSlice.revNavigatorTableSorts,
    shallowEqual
  );
  const trendsData = useSelector(
    (state) => state.homePagePropsSlice.trendsData,
    shallowEqual
  );
  const revNavCheckedAgents = useSelector(
    (state) => state.revNavigatorSlice.revNavCheckedAgents,
    shallowEqual
  );

  const staticAppDate = useSelector((state) => state.globalSlice.staticAppDate);
  const date = staticAppDate ? new Date(staticAppDate) : new Date();
  const history = useHistory();

  const {
    user: {
      metadata: {
        Currency,
        Language,
        UserType,
        InsightingCustomerID,
        DateFormat,
      },
    },
  } = userSlice;

  const dispatch = useDispatch();

  // const columns = useMemo(() => {
  //   return Language === "hebrew"
  //     ? setCurrencyForTableHeaders(Currency, hebCOLUMNS, Language)
  //     : Language === "english"
  //     ? setCurrencyForTableHeaders(Currency, engCOLUMNS, Language)
  //     : setCurrencyForTableHeaders(Currency, engCOLUMNS, Language);
  // }, [Currency, Language]);

  const columns = useMemo(() => {
    return ["DL", "DG"]?.includes(revNavTableMode)
      ? Language === "hebrew"
        ? setCurrencyForTableHeaders(Currency, hebCOLUMNS, Language)?.filter(
            (column) => DL_DG_Columns?.includes(column.accessor)
          )
        : Language === "english"
        ? setCurrencyForTableHeaders(Currency, engCOLUMNS, Language)?.filter(
            (column) => DL_DG_Columns?.includes(column.accessor)
          )
        : setCurrencyForTableHeaders(Currency, engCOLUMNS, Language)?.filter(
            (column) => DL_DG_Columns?.includes(column.accessor)
          )
      : Language === "hebrew"
      ? setCurrencyForTableHeaders(Currency, hebCOLUMNS, Language)?.filter(
          (column) => L_N_Columns?.includes(column.accessor)
        )
      : Language === "english"
      ? setCurrencyForTableHeaders(Currency, engCOLUMNS, Language)?.filter(
          (column) => L_N_Columns?.includes(column.accessor)
        )
      : setCurrencyForTableHeaders(Currency, engCOLUMNS, Language)?.filter(
          (column) => L_N_Columns?.includes(column.accessor)
        );
  }, [Currency, Language, revNavTableMode]);

  const data = useMemo(() => {
    return revNavTableData;
  }, [revNavTableData]);

  const groupedHeader = useRef(null);
  const tableRef = useRef(null);
  const [showSpinner, setShowSpinner] = useState(false);

  function renderInsightTypeCell(row, cell) {
    return (
      <td
        key={cell.column.id}
        style={{ textAlign: Language === "hebrew" ? "end" : "start" }}
        {...cell.getCellProps()}
      >
        <InsightTypesLogos row={row} language={Language} />
        <OnGoingLight row={row} todaysDate={todaysDate} language={Language} />
      </td>
    );
  }

  function renderGoToCustomerPage(row, cell) {
    const { CustomerID, customerName, salesAgent } = row?.original;
    return (
      <td
        style={{
          cursor: "pointer",
        }}
        key={cell.column.id}
        {...cell.getCellProps()}
        onClick={() =>
          goToCustomerPage(
            CustomerID,
            InsightingCustomerID,
            customerName,
            salesAgent,
            Language,
            allKeyOpportunitiesForCustomerPage,
            history
          )
        }
      >
        <ListAltIcon
          style={{
            cursor: "pointer",
            marginRight: "0.5rem",
            color: "#73aafc",
          }}
          fontSize="small"
        />
        {cell.render("Cell")}
      </td>
    );
  }
  function renderGoToRevNavDetailsPage(row, cell) {
    return (
      <td
        style={{
          justifyContent: "center",
        }}
        key={cell.column.id}
        {...cell.getCellProps()}
        onClick={() => {
          const { ItemCatalogNumber, CurrStock, ItemName } = row.values;
          const path = history.location.pathname;
          history.push(path.endsWith("/") ? "details" : "revNav/details", {
            InsightingCustomerID,
            ItemCatalogNumber,
            ItemName,
            CurrStock,
            sorts: state.sortBy,
          });
        }}
      >
        <ListAltIcon
          style={{
            cursor: "pointer",
            marginRight: "0.5rem",
            color: "#73aafc",
          }}
          fontSize="small"
        />
        {cell.render("Cell")}{" "}
      </td>
    );
  }

  function renderLastOrderCell(row, cell) {
    return (
      <td key={cell.column.id} {...cell.getCellProps()}>
        {DateFormat === "us" ? usDateFormat(cell.value) : cell.render("Cell")}
      </td>
    );
  }
  function renderLTRValueCell(row, cell) {
    return (
      <td
        key={cell.column.id}
        {...cell.getCellProps()}
        style={{ direction: "ltr", width: "9rem" }}
      >
        {" "}
        {numberWithCommas(cell.value)}
        {/* {cell.render("Cell")} */}
      </td>
    );
  }

  function renderUrgencyCell(row, cell) {
    return (
      <td key={cell.column.id} style={{ width: "2.5rem", height: "2rem" }}>
        <span
          style={{
            color: urgencyColorsPalette.text[getUrgencyValue(cell.value)],
            backgroundColor:
              urgencyColorsPalette.background[getUrgencyValue(cell.value)],
            borderRadius: "5px",
            fontSize: "11px",
            fontWeight: "bold",
            padding: "0.3rem",
          }}
        >
          {getUrgencyValue(cell.value)}
        </span>
      </td>
    );
  }

  function renderPriorityCell(row, cell) {
    return (
      <td key={cell.column.id} {...cell.getCellProps()}>
        <PriorityButton row={row} userType={UserType} status={cell.value} />
      </td>
    );
  }

  const handleRightClick = (e, row) => {
    const { original } = row;
    dispatch(setSelectedRowForMenu(original));
    dispatch(
      setOverviewMenuPosition({
        top: e.clientY,
        left: e.clientX,
      })
    );
    e.preventDefault();
    e.stopPropagation();
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: {
        sortBy: revNavigatorTableSorts,
        filters: revNavigatorTableFilters,
      },
      autoResetPage: false,
      autoResetExpanded: false,
      autoResetGroupBy: false,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false,
    },
    useFilters,
    useSortBy
  );

  useEffect(async () => {
    async function generateRevNavData() {
      if (revNavigatorTableFilters?.length === 0) {
        state.filters = [];
      }
      if (revNavigatorTableSorts?.length === 0) {
        state.sortBy = [];
      }
      let revNavTableDataTemp = [];
      if (["L", "N"]?.includes(revNavTableMode)) {
        revNavTableDataTemp = fetchedRevNav_CustomersData
          ?.filter(
            (i) =>
              i[revNavSelectedPeriod]?.[2]?.trim() === revNavTableMode &&
              i[revNavSelectedPeriod][0]
          )
          .map((customer) => {
            const found = trendsData.find(
              (i) => i.CustomerID === customer.CustomerID
            );
            if (found)
              return {
                priority: found.Priority,
                CustomerID: found.CustomerID,
                customerName: found.CustomerName,
                salesAgent: found.AgentName,
                AgentID: found.AgentID,
                periodValue: numberWithCommas(
                  round(customer?.[revNavSelectedPeriod]?.[0])
                ),
                progress: customer?.[revNavSelectedPeriod]?.[1],
                mode: customer?.[revNavSelectedPeriod]?.[2]?.trim(),
                LastOrderDate: found.LastOrder
                  ? found.LastOrder.split("_").join("/")
                  : "-",
                // LastOrderDate: formattedDate
                //   ? formattedDate.split("_").join("/")
                //   : "-",
                opportunities: found.InsightType,
                OriginalInsightType: found.OriginalInsightType,
                urgency: found.urgency,
                details: "",
                CreatedDate: found.CreatedDate,
              };
          });

        if (!isAllAgentsSelected) {
          revNavTableDataTemp = revNavTableDataTemp?.filter((i) =>
            listOfSelectedAgents?.includes(i.AgentID)
          );
        }
      } else if (["DG", "DL"]?.includes(revNavTableMode)) {
        revNavTableDataTemp = revNavItemsByItemData?.map((item) => {
          return {
            AgentID: item.AgentID,
            CurrStock: numberWithCommas(round(item.CurrStock)),
            ItemCatalogNumber: item.ItemCatalogNumber,
            ItemCategory: item.ItemCategory,
            ItemName: item.ItemName,
            salesAgent: item.AgentName,
            NumOfCustomers: parseInt(item[revNavSelectedPeriod]?.[0]) || 0,
            Units: round(parseFloat(item[revNavSelectedPeriod]?.[1]) || 0),
            periodValue: round(
              parseFloat(item[revNavSelectedPeriod]?.[2]) || 0
            ),
            mode: item.Type?.trim(),
            details: "",
          };
        });
        if (isAllAgentsSelected && UserType === "manager") {
          revNavTableDataTemp = revNavTableDataTemp?.filter(
            (i) => i.AgentID === "Total"
          );
        } else {
          revNavTableDataTemp = agentsFilterApplyToggle
            ? revNavTableDataTemp?.filter((i) =>
                listOfSelectedAgents?.includes(i.AgentID)
              )
            : revNavTableDataTemp;

          const combinedData = {};
          for (const item of revNavTableDataTemp) {
            const { NumOfCustomers, Units, periodValue } = item;
            const catalogNumber = item.ItemCatalogNumber;
            const mode = item.mode;
            const AgentID = item.AgentID;
            if (!(`${catalogNumber}_${AgentID}_${mode}` in combinedData)) {
              combinedData[`${catalogNumber}_${AgentID}_${mode}`] = {
                ...item,
                NumOfCustomers: NumOfCustomers,
                Units: Units,
                periodValue: periodValue,
              };
            } else {
              combinedData[
                `${catalogNumber}_${AgentID}_${mode}`
              ].NumOfCustomers += NumOfCustomers;
              combinedData[`${catalogNumber}_${AgentID}_${mode}`].Units +=
                Units;
              combinedData[`${catalogNumber}_${AgentID}_${mode}`].periodValue +=
                periodValue;
            }
          }

          revNavTableDataTemp = Object.values(combinedData)?.filter((i) => {
            return Object.keys(revNavCheckedAgents)?.includes(i.AgentID);
          });
        }
        revNavTableDataTemp = revNavTableDataTemp?.filter(
          (customer) =>
            customer?.mode === revNavTableMode && customer?.periodValue
        );
      }

      dispatch(setRevNavTableData(revNavTableDataTemp));
      dispatch(setIsPrepRows(false));
    }
    if (trendsData?.length > 0) {
      // console.time("generateRevNavData");
      generateRevNavData();
      // console.timeEnd("generateRevNavData");
    } else if (customersOverviewData.Items) {
      dispatch(
        setTrendsData(JSON.parse(sessionStorage.getItem("sessionTrendsData")))
      );
    }
  }, [
    revNavSelectedPeriod,
    listOfSelectedAgents,
    fetchedRevNav_CustomersData,
    trendsData,
    revNavTableMode,
    customersOverviewData,
  ]);

  useEffect(() => {
    // Show the spinner
    if (rows[0] && revNavTableMode !== rows[0]?.original.mode) {
      state.filters = [];
      state.sortBy = [];
    }
    let timer;
    if (rows?.length > 1) {
      setShowSpinner(true);
      timer = setTimeout(
        () => {
          setShowSpinner(false);
        },
        rows?.length > 500 ? rows.length : 500
      );
    }
    // Set a timeout to hide the spinner after 2 seconds

    // Cleanup function to clear the timeout if the component unmounts
    // or if the revNavTableMode changes again before the timeout completes
    return () => {
      clearTimeout(timer);
    };
  }, [revNavTableMode, listOfSelectedAgents]);

  return (listOfSelectedAgents?.length === 0 &&
    !isAllAgentsSelected &&
    revNavTableData?.length === 0) ||
    (listOfSelectedAgents?.length === 0 && revNavTableData?.length === 0) ||
    showSpinner ||
    (rows[0] && revNavTableMode !== rows[0]?.original.mode) || // change table mode spinner
    isPrepRows ? ( // agents selector apply
    <LoadingAnimation />
  ) : (
    <div
      style={{
        marginLeft: "0.5rem",
        direction: Language === "hebrew" ? "rtl" : "ltr",
      }}
    >
      <RevNavigatorAboveTable rows={rows} />
      <Box
        display="flex"
        alignItems="center"
        style={{
          direction: `${Language === "hebrew" ? "rtl" : "ltr"}`,
          marginRight: "3.2rem",
        }}
      >
        <Box display="flex" alignItems="center" style={{ marginLeft: 10 }}>
          {/* <CustomersOverviewGroupFilter
            displayAddGroupFilter={displayAddGroupFilter}
            setDisplayGroupFilter={setDisplayGroupFilter}
          /> */}
          <RevNavigatorFilters
            filters={state.filters}
            headers={headerGroups[0].headers?.filter((i) => i.id !== "details")}
            columns={columns}
          />
        </Box>
      </Box>
      <div
        ref={tableRef}
        id="customers-overview-table"
        className="customers-overview-table-div"
      >
        {/* {showSpinner && <LoadingAnimation />} */}

        <table {...getTableProps()} className="customers-overview-table">
          <thead>
            {headerGroups.map((headerGroup) => {
              return (
                <tr {...headerGroup.getHeaderGroupProps()} ref={groupedHeader}>
                  {headerGroup.headers.map((column) => {
                    return (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        id={column.id}
                      >
                        {column.render("Header")}
                        <span>{isSorted(column)}</span>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          {data?.length === 0 ? (
            <div style={{ textAlign: "center", padding: "1rem 0rem" }}>
              {dictionary?.[Language]?.salesReviewTable.noData}
            </div>
          ) : (
            <tbody {...getTableBodyProps}>
              <RevNavigatorMenu
                tableRef={tableRef}
                rows={rows}
                headerGroups={headerGroups}
                csvHeaders={columns}
                revNavFilters={state.filters}
                revNavSorts={state.sortBy}
              />

              {rows.map((row) => {
                // console.log("-----preparing revNavigtor table row-----");
                prepareRow(row);
                return (
                  <tr
                    key={row.id}
                    {...row.getRowProps()}
                    onContextMenu={(e) => handleRightClick(e, row)}
                  >
                    {row.cells.map((cell) => {
                      const renderDefaultCell = () => (
                        <td key={cell.column.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );

                      switch (cell.column.id) {
                        case "NumOfCustomers":
                        case "periodValue":
                        case "Units":
                        case "CurrStock":
                          return renderLTRValueCell(row, cell);
                        case "lastOrder":
                          return renderLastOrderCell(row, cell);
                        case "details":
                          return ["DL", "DG"]?.includes(revNavTableMode)
                            ? renderGoToRevNavDetailsPage(row, cell)
                            : renderGoToCustomerPage(row, cell);
                        case "opportunities":
                          return renderInsightTypeCell(row, cell);
                        case "urgency":
                          return renderUrgencyCell(row, cell);
                        case "priority":
                          return renderPriorityCell(row, cell);
                        default:
                          return renderDefaultCell(row, cell);
                      }
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
}
export default memo(RevNavigatorTable);
