import axios from "axios";
import reportsServerDomain from "../config/reportsServerDomain";
import { reqAuth } from "../constants/reqAuth";

const axiosInstance = axios.create({
  timeout: 4 * 60 * 1000, // 4 minutes
});

async function makeReportRequest(url, reportFilters, userId) {
  try {
    const {
      InsightingCustomerID,
      AgentIDs,
      ValuesToDisplay,
      CustomersHierarchies,
      Period1,
      Period2,
      CustomerIDs,
      ItemCatNumbers,
    } = reportFilters;

    const output_file_name = `${InsightingCustomerID}_${userId}_reportOut.json`;

    // First request to the report server
    const firstRequest = await axiosInstance.request({
      method: "POST",
      url: `${reportsServerDomain}/${url}`,
      headers: { authorization: reqAuth },
      data: {
        InsightingCustomerID,
        AgentIDs,
        ValuesToDisplay,
        CustomersHierarchies,
        Period1,
        Period2,
        CustomerIDs,
        ItemCatNumbers,
        output_file_name, // New mandatory field
      },
    });

    const { N_Files } = firstRequest.data;

    if (N_Files > 0) {
      // Start waiting for the file to be ready using getReportFile
      return await getReportFile(
        new Date().toISOString(),
        InsightingCustomerID,
        userId,
        output_file_name
      );
    } else {
      throw new Error("Failed to get file information from the server.");
    }
  } catch (e) {
    console.log(url);
    console.log(output_file_name);
    console.log(e);
  }
}

async function getReportFile(
  RequestDateTime,
  InsightingCustomerID,
  UserID,
  output_file_name
) {
  try {
    await new Promise((resolve) => setTimeout(resolve, 1000)); // initial 1 seconds
    const maxRetries = 80; // Allow a max of 80 retries (80 * 4s = 320s or 4 minutes)
    let retries = 0;

    while (retries < maxRetries) {
      const fileResponse = await axiosInstance.request({
        method: "POST",
        url: `${reportsServerDomain}/GetReportFile`,
        headers: { authorization: reqAuth },
        data: {
          RequestDateTime, // The current timestamp
          InsightingCustomerID, // The customer ID
          UserID, // The user ID (AgentID)
          output_file_name, // The output file name we are waiting for
        },
      });

      if (fileResponse.data === 102) {
        // File is still processing, wait before retrying
        retries += 1;
        await new Promise((resolve) => setTimeout(resolve, 4000)); // Wait for 4 seconds before retrying
      } else if (fileResponse.data) {
        // File is ready, return the file data
        return fileResponse.data;
      } else {
        throw new Error(
          `Failed to retrieve the file. Response: ${fileResponse}`
        );
      }
    }

    throw new Error(
      `Exceeded maximum attempts (${maxRetries}) while waiting for the file.`
    );
  } catch (e) {
    console.log(output_file_name);
    console.log(e);
    if (e.code === "ERR_NETWORK") {
      getReportFile(
        RequestDateTime,
        InsightingCustomerID,
        UserID,
        output_file_name
      );
    }
  }
}

async function getReportByCustomer(reportFilters, userId) {
  return makeReportRequest(
    "ReportsServer/GetReportByCustomer/ByCustomer",
    reportFilters,
    userId
  );
}

async function getReportByCustomerByItem(reportFilters, userId) {
  return makeReportRequest(
    "ReportsServer/GetReportByCustomer/ByCustomerByItem",
    reportFilters,
    userId
  );
}

async function getReportByItem(reportFilters, userId) {
  return makeReportRequest(
    "ReportsServer/GetReportByItem/ByItem",
    reportFilters,
    userId
  );
}

async function getReportByItemByAgent(reportFilters, userId) {
  return makeReportRequest(
    "ReportsServer/GetReportByItem/ByItemByAgent",
    reportFilters,
    userId
  );
}

async function getReportByAgent(reportFilters, userId) {
  return makeReportRequest(
    "ReportsServer/GetReportByAgent",
    reportFilters,
    userId
  );
}

const reportsGeneratorService = {
  getReportByCustomer,
  getReportByCustomerByItem,
  getReportByItem,
  getReportByItemByAgent,
  getReportByAgent,
  getReportFile,
};

export default reportsGeneratorService;
