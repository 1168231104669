
import MuiDrawer from "@material-ui/core/Drawer";
import React, { useState } from "react";
import {  withStyles } from "@material-ui/core/styles";

import "./SupportPopup2.css";


const Drawer2 = withStyles({
  paper: {
    width: "17%",
    height: "9%",
    fontWeight: 100,
    borderRadius: "18px",
    marginTop: "2.8rem",
    background: "#444444",
    border: "black solid 2px",
    padding: "0.5rem",
    color: "white",
  },
})(MuiDrawer);

function SupportDrawer({ active = false, deactivate = () => {} }) {
  return (
    <Drawer2 open={active} anchor="right" onClose={deactivate}>
      <div role="presentation">
        <div className="modal-wrapper2" aria-modal aria-hidden tabIndex={-1}>
          <div className="modal2">
            <p
              style={{
                borderTop: "white solid 1px",
                paddingTop: "0.5rem",
                textAlign: "center",
              }}
            >
              For support contact:{" "}
              <a className="email-address" href="mailto:support@insighting.io">
                {" "}
                support@insighting.io
              </a>
            </p>
          </div>
        </div>
      </div>
    </Drawer2>
  );
}

export default function SupportPopup2() {
  const [opened, setOpened] = useState(false);
  const [isDrawerActive, setIsDrawerActive] = useState(false);
  return (
    <div className="container">
      <div className="menu-container">
        <i
          onClick={() => setIsDrawerActive(!isDrawerActive)}
          className="fas fa-question-circle fa-2x"
        />
        <SupportDrawer
          active={isDrawerActive}
          onListItemClick={{ Support: setOpened }}
          deactivate={() => setIsDrawerActive(false)}
        />
      </div>
    </div>
  );
}
