import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { CustomerKeyOpportunitiesTable } from "../../components/Tables/CustomerKeyOpportunitiesTable/CustomerKeyOpportunitiesTable";
import { CustomerSalesOverviewGraph } from "../../components/Graphs/CustomerSalesOverviewGraph/CustomerSalesOverviewGraph";
import { ProductPurchasedTable } from "../../components/Tables/ProductPurchasedTable/ProductPurchasedTable";
import { ProductSalesGraph } from "../../components/Graphs/ProductSalesGraph/ProductSalesGraph";
import { Subtitle } from "../../components/Subtitle/Subtitle";
import numberUtils from "../../utils/number";
import { monthsBetween, usDateFormat } from "../../utils/helpers";

import salesService from "../../services/salesByCustomerByItem";
import { dictionary } from "../../constants/dictionary";
import {
  setProductPurchasedTableDataInStore,
  setProductPurchasedTableMode,
  setProductsAnalyzeDates,
  setProductsAnalyzeDimension,
} from "../../store/features/dataSlice";
import "./CustomerPage.css";
import { setProductPurchasedTableFilters } from "../../store/features/tableLatestFiltersSlice";
import { setPrevCustomerID } from "../../store/features/globalSlice";
import Footer from "../../components/Footer/Footer";

export const CustomerPage = () => {
  const { numberWithCommas } = numberUtils;
  const { InsightingCustomerID, Language, DateFormat } = JSON.parse(
    sessionStorage.getItem("userMetadata")
  );
  const { round } = Math;
  // const { userSlice } = useSelector((state) => state);
  // const {
  //   user: {
  //     metadata: { InsightingCustomerID, Language },
  //   },
  // } = userSlice;
  const history = useHistory();
  const { CustomerID, data, CustomerName, dataForSubtitle, AgentName } =
    history.location.state;
  const [productPurchasedData, setProductPurchasedData] = useState([]);
  // const [productPurchasedDataforOverallGraph, setProductPurchasedDataforOverallGraph] = useState([]);
  const [salesByItem, setSalesByItem] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  const [coordinatesOverall, setCoordinatesOverall] = useState([]);
  const [salesPeriod, setSalesPeriod] = useState("thisMonth");
  const [analyzeMode, setAnalyzeMode] = useState(false);
  const [productName, setProductName] = useState("");

  const prevCustomerID = useSelector(
    (state) => state.globalSlice.prevCustomerID
  );
  const prodPurchasedLastUpdate = useSelector(
    (state) => state.globalSlice.prodPurchasedLastUpdate
  );
  const productsAnalyzeDates = useSelector(
    (state) => state.dataSlice.productsAnalyzeDates
  );
  const productPurchasedTableMode = useSelector(
    (state) => state.dataSlice.productPurchasedTableMode
  );
  const productsAnalyzeDimension = useSelector(
    (state) => state.dataSlice.productsAnalyzeDimension,
    shallowEqual
  );
  const productPurchasedTableDataInStore = useSelector(
    (state) => state.dataSlice.productPurchasedTableDataInStore,
    shallowEqual
  );

  const staticAppDate = useSelector((state) => state.globalSlice.staticAppDate);
  const date = staticAppDate ? new Date(staticAppDate) : new Date();
  // const date = useMemo(
  //   () => (staticAppDate ? new Date(staticAppDate) : new Date()),
  //   []
  // );
  const month = date.getMonth() + 1;
  const currentYear = date.getFullYear();
  const dispatch = useDispatch();

  const getTotalSales = (Items) => {
    let thisMonthTotal = Items?.filter(
      (item) =>
        parseInt(item.YearOfData) === currentYear &&
        (item[`Sales_${month}`] > 0 || item[`Sales_${month}`] < 0)
    ).reduce((prev, curr) => prev + parseInt(curr[`Sales_${month}`]), 0);

    let lastMonthTotal =
      month > 1 // if current month is Feb - Dec
        ? Items?.filter(
            (item) =>
              parseInt(item.YearOfData) === currentYear &&
              (item[`Sales_${month - 1}`] > 0 || item[`Sales_${month - 1}`] < 0)
          ).reduce(
            (prev, curr) => prev + parseInt(curr[`Sales_${month - 1}`]),
            0
          )
        : Items?.filter(
            // in case current month is January
            (item) =>
              parseInt(item.YearOfData) === currentYear - 1 &&
              (item[`Sales_12`] > 0 || item[`Sales_12`] < 0)
          ).reduce((prev, curr) => prev + parseInt(curr[`Sales_12`]), 0);

    let yearToDateTotal = Items?.filter(
      (item) => parseInt(item.YearOfData) === currentYear
    ).reduce((prev, curr) => {
      return prev + ytdReducer(curr).ytdSales;
    }, 0);
    let lastYearTotal = Items?.filter(
      (item) => parseInt(item.YearOfData) === currentYear - 1
    ).reduce((prev, curr) => prev + lastYearReducer(curr).totalYearSales, 0);

    return { thisMonthTotal, lastMonthTotal, yearToDateTotal, lastYearTotal };
  };
  const ytdReducer = (item) => {
    let ytdUnits = 0;
    let ytdOrders = 0;
    let ytdSales = 0;
    for (const [key, value] of Object.entries(item)) {
      for (let i = 1; i <= month; i++) {
        if (key === `Sales_${i}`)
          ytdSales += isNaN(parseInt(value)) ? 0 : parseInt(value);
        if (key === `Orders_${i}`)
          ytdOrders += isNaN(parseInt(value)) ? 0 : parseInt(value);
        if (key === `SalesInUnits_${i}`)
          ytdUnits += isNaN(parseInt(value)) ? 0 : parseInt(value);
      }
    }
    return { ytdSales, ytdUnits, ytdOrders };
  };

  const lastYearReducer = (item) => {
    let totalYearUnits = 0;
    let totalYearOrders = 0;
    let totalYearSales = 0;
    if (item) {
      for (const [key, value] of Object?.entries(item)) {
        for (let i = 1; i <= 12; i++) {
          if (key === `Sales_${i}`)
            totalYearSales += isNaN(parseInt(value)) ? 0 : parseInt(value);
          if (key === `Orders_${i}`)
            totalYearOrders += isNaN(parseInt(value)) ? 0 : parseInt(value);
          if (key === `SalesInUnits_${i}`)
            totalYearUnits += isNaN(parseInt(value)) ? 0 : parseInt(value);
        }
      }
    }
    return { totalYearSales, totalYearUnits, totalYearOrders };
  };

  const setCoordinatesFunc = (salesByItem, dimension = "Sales_") => {
    let actualYearCoordinatesOverall = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let yearAgoCoordinatesOverall = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    let totalYearSalesItems = [];
    let actualYearSalesItems = [];

    salesByItem?.forEach((element) => {
      if (parseInt(element.YearOfData) === currentYear) {
        actualYearSalesItems.push(element);
        for (let i = 0; i < 12; i++) {
          if (element[`Sales_${i + 1}`]) {
            actualYearCoordinatesOverall[i] += parseInt(
              element[`Sales_${i + 1}`]
            );
          }
        }
      } else if (parseInt(element.YearOfData) === currentYear - 1) {
        totalYearSalesItems.push(element);
        for (let i = 0; i < 12; i++) {
          yearAgoCoordinatesOverall[i] += parseInt(element[`Sales_${i + 1}`]);
        }
      }
    });
    setCoordinatesOverall([
      actualYearCoordinatesOverall,
      yearAgoCoordinatesOverall,
    ]);

    let actualYearProduct = salesByItem
      ?.filter((item) => parseInt(item.YearOfData) === currentYear)
      .sort((a, b) =>
        parseInt(a[`Sales_${month}`]) < parseInt(b[`Sales_${month}`]) ? 1 : -1
      )[0];

    let yearAgoProduct = actualYearProduct
      ? salesByItem.find(
          (item) =>
            item.ItemCatalogNumber === actualYearProduct.ItemCatalogNumber &&
            parseInt(item.YearOfData) === currentYear - 1
        )
      : null;
    let actualYearCoordinates = [];
    let yearAgoCoordinates = [];
    for (let i = 1; i < 13; i++) {
      if (actualYearProduct)
        actualYearCoordinates.push(actualYearProduct[`Sales_${i}`]);
      if (yearAgoProduct) yearAgoCoordinates.push(yearAgoProduct[`Sales_${i}`]);
    }
    setProductName(actualYearProduct?.ItemName);
    setCoordinates([actualYearCoordinates, yearAgoCoordinates]);
  };

  useEffect(() => {
    async function getSalesByCustomerByItem() {
      try {
        if (CustomerID !== prevCustomerID) {
          const response =
            await salesService.getSalesByCustomerByItemDataByCustomerID(
              CustomerID,
              InsightingCustomerID,
              date
            );
          let salesByCustomerByItemByIdData = response?.data?.data?.Items.map(
            (obj) => {
              for (let key in obj) {
                obj[key] = obj[key]?.replace("\r", "");
              }
              return obj;
            }
          );

          dispatch(
            setProductPurchasedTableDataInStore(salesByCustomerByItemByIdData)
          );
          setSalesByItem(salesByCustomerByItemByIdData);
          setCoordinatesFunc(salesByCustomerByItemByIdData);
        } else {
          setSalesByItem(productPurchasedTableDataInStore);
          setCoordinatesFunc(productPurchasedTableDataInStore);
        }
      } catch (error) {
        if (error.message === "Network Error")
          setTimeout(() => getSalesByCustomerByItem(), 4000);
        else {
          console.log(error.message);
          console.log(error.stack);
        }
      }
    }
    getSalesByCustomerByItem();
  }, [CustomerID, InsightingCustomerID]);


  useEffect(
    function setUnitsDataForGraph() {
      if (
        productPurchasedTableMode === "analyze" &&
        productsAnalyzeDimension === "units"
      ) {
        let actualYearProduct = salesByItem
          ?.filter((item) => parseInt(item.YearOfData) === currentYear)
          .sort((a, b) =>
            parseInt(a[`SalesInUnits${month}`]) <
            parseInt(b[`SalesInUnits${month}`])
              ? 1
              : -1
          )[0];

        let yearAgoProduct = actualYearProduct
          ? salesByItem.find(
              (item) =>
                item.ItemCatalogNumber ===
                  actualYearProduct.ItemCatalogNumber &&
                parseInt(item.YearOfData) === currentYear - 1
            )
          : null;
        let actualYearCoordinates = [];
        let yearAgoCoordinates = [];
        for (let i = 1; i < 13; i++) {
          if (actualYearProduct)
            actualYearCoordinates.push(actualYearProduct[`SalesInUnits${i}`]);
          if (yearAgoProduct)
            yearAgoCoordinates.push(yearAgoProduct[`SalesInUnits${i}`]);
        }
        setProductName(actualYearProduct?.ItemName);
        setCoordinates([actualYearCoordinates, yearAgoCoordinates]);
      }
    },
    [productsAnalyzeDimension, productPurchasedTableMode]
  );

  useEffect(() => {
    const { thisMonthTotal, lastMonthTotal, yearToDateTotal, lastYearTotal } =
      getTotalSales(salesByItem);
    let lastYearCustomerProducts = salesByItem?.filter(
      (product) => parseInt(product.YearOfData) === currentYear - 1
    );
    let thisYearCustomerProducts = salesByItem?.filter(
      (product) =>
        parseInt(product.YearOfData) === currentYear &&
        (parseInt(product[`Sales_${month}`]) >= 1 ||
          parseInt(product[`SalesInUnits_${month}`]) >= 1)
    );

    if (productPurchasedTableMode === "analyze") {
      let [startReportDate, endReportDate] = productsAnalyzeDates[0];
      let [startComparedDate, endComparedDate] = productsAnalyzeDates[1];

      const reportPeriod = monthsBetween(
        new Date(JSON.parse(startReportDate)),
        new Date(JSON.parse(endReportDate))
      );
      const comparedPeriod = monthsBetween(
        new Date(JSON.parse(startComparedDate)),
        new Date(JSON.parse(endComparedDate))
      );
      const reportYears = Object.keys(reportPeriod);
      const comparedYears = Object.keys(comparedPeriod);
      const relevantProductsForReport = salesByItem?.filter((item) =>
        [...new Set([...reportYears, ...comparedYears])]?.includes(
          item.YearOfData
        )
      );

      const uniqueListForReport = salesByItem
        ?.filter((item) =>
          [...new Set([...reportYears, ...comparedYears])]?.includes(
            item.YearOfData
          )
        )
        .reduce((accumulator, currentValue) => {
          // Check if there is already an object in the accumulator with the same ItemCatalogNumber property
          const existingObject = accumulator.find(
            (obj) => obj.ItemCatalogNumber === currentValue.ItemCatalogNumber
          );

          // If there is no object with the same ItemCatalogNumber property, add the current object to the accumulator
          if (!existingObject) {
            accumulator.push(currentValue);
          }

          return accumulator;
        }, []);

      const getPeriodSum = (ItemCatalogNumber, dimension, period, years) => {
        let sum = 0;
        years?.forEach((year) => {
          let relevantYearItem = relevantProductsForReport.find(
            (product) =>
              product.ItemCatalogNumber === ItemCatalogNumber &&
              product.YearOfData === year
          );
          if (relevantYearItem) {
            period[year]?.forEach((month) => {
              let num = parseInt(
                relevantYearItem[
                  `${
                    dimension === "units" ? "SalesInUnits_" : "Sales_"
                  }${month}`
                ]
              );
              if (!isNaN(num)) sum += num;
            });
          }
        });

        return sum;
      };

      let dataForAnalyzeTable = uniqueListForReport
        .map((item) => {
          let reportPeriodSum = getPeriodSum(
            item.ItemCatalogNumber,
            productsAnalyzeDimension,
            reportPeriod,
            reportYears
          );
          let compareToPeriodSum = getPeriodSum(
            item.ItemCatalogNumber,
            productsAnalyzeDimension,
            comparedPeriod,
            comparedYears
          );

          let datesArr = salesByItem
            ?.filter(
              (product) =>
                product.ItemCatalogNumber === item.ItemCatalogNumber &&
                item.LastOrderDate &&
                item.LastOrderDate !== null &&
                item.LastOrderDate !== undefined &&
                item.LastOrderDate !== ""
            )
            .map(
              (item) =>
                new Date(item.LastOrderDate?.split("_").reverse().join("-"))
            );

          let mostRecentOrderDate = new Date(Math.max.apply(null, datesArr));

          const formattedDate = isNaN(mostRecentOrderDate)
            ? "-"
            : `${mostRecentOrderDate.getDate()}_${
                mostRecentOrderDate.getMonth() + 1
              }_${mostRecentOrderDate.getFullYear()}`;

          let obj = {
            Code: item.ItemCatalogNumber,
            ProductName: item.ItemName,
            // item.ItemName?.includes("|") && Language === "hebrew"
            //   ? item.ItemName?.split("|").reverse().join("|")
            //   : item.ItemName,
            LastOrderDate: formattedDate?.split("_").join("/"),
            reportPeriod: numberWithCommas(round(reportPeriodSum)),
            compareToPeriod: numberWithCommas(round(compareToPeriodSum)),
            changeBetweenPeriods:
              reportPeriodSum !== 0 && compareToPeriodSum !== 0
                ? ((reportPeriodSum / compareToPeriodSum - 1) * 100).toFixed(
                    1
                  ) + "%"
                : reportPeriodSum === 0 && compareToPeriodSum !== 0
                ? -100.0 + "%"
                : // : reportPeriodSum != 0 && compareToPeriodSum == 0
                  // ? 100.0 + "%"
                  "-",
          };
          return obj;
        })
        ?.filter(
          (item) =>
            parseInt(item.compareToPeriod) !== 0 ||
            parseInt(item.reportPeriod) !== 0
        );
      setProductPurchasedData(dataForAnalyzeTable);
      return;
    }
    if (date.getMonth() === 0 && salesPeriod === "lastMonth") {
      let currentCustomerProducts = salesByItem?.filter(
        (product) =>
          parseInt(product.YearOfData) === currentYear - 1 &&
          (parseInt(product[`Sales_12`]) >= 1 ||
            parseInt(product[`SalesInUnits_12`]) >= 1)
      );
      currentCustomerProducts = currentCustomerProducts?.map((item) => {
        let obj = {
          Code: item.ItemCatalogNumber,
          ProductName: item.ItemName,
          // item.ItemName?.includes("|") && Language === "hebrew"
          //   ? item.ItemName?.split("|").reverse().join("|")
          //   : item.ItemName,
          Value:
            salesPeriod === "lastMonth" &&
            month === 1 &&
            !isNaN(item?.[`Sales_12`])
              ? numberWithCommas(round(item?.[`Sales_12`]))
              : 0,
          OpenOrders:
            salesPeriod === "lastMonth" &&
            month === 1 &&
            !isNaN(item?.[`Orders_12`])
              ? numberWithCommas(round(item?.[`Orders_12`]))
              : 0,
          Sales:
            salesPeriod === "lastMonth" &&
            month === 1 &&
            parseInt(item?.[`Sales_12`]) > 0 &&
            lastMonthTotal > 0
              ? ((parseInt(item?.[`Sales_12`]) / lastMonthTotal) * 100).toFixed(
                  1
                ) + "%"
              : "-",
          Units:
            salesPeriod === "lastMonth" &&
            month === 1 &&
            parseInt(item?.[`Sales_12`]) > 0
              ? numberWithCommas(parseInt(item?.[`SalesInUnits_12`]))
              : "-",
          UpdateDate: item.UpdateDate,
          Probability: item.Probability
            ? dictionary[Language].productPurchasedTable.probabilityOptions[
                item.Probability
              ]
            : dictionary[Language].productPurchasedTable.probabilityOptions.Low,

          LastOrderDate: item.LastOrderDate
            ? item.LastOrderDate?.split("_").join("/")
            : "-",
        };
        return obj;
      });
      // dispatch(setProductPurchasedTableDataInStore(currentCustomerProducts));
      setProductPurchasedData(currentCustomerProducts);
    } else if (salesPeriod !== "lastYear") {
      let currentCustomerProducts = salesByItem?.filter(
        (product) => parseInt(product.YearOfData) === currentYear
      );
      lastYearCustomerProducts?.forEach((product) => {
        if (product.Probability === "Med" || product.Probability === "High") {
          const found = currentCustomerProducts.find(
            (item) => item.ItemCatalogNumber === product.ItemCatalogNumber
          );
          if (!found) currentCustomerProducts.push(product);
        }
      });
      currentCustomerProducts = currentCustomerProducts?.map((item) => {
        // let lastYearProduct = lastYearCustomerProducts.find(
        //   (lastYearItem) =>
        //     lastYearItem.CustID_ItemCatNum_Yr ===
        //     `${item.CustomerID}_${item.ItemCatalogNumber}_${currentYear - 1}`
        // );

        let obj = {
          Code: item.ItemCatalogNumber,
          // ProductName: item.ItemName.replace(/"|'|@/g, ""),
          ProductName: item.ItemName,
          // item.ItemName?.includes("|") && Language === "hebrew"
          //   ? item.ItemName?.split("|").reverse().join("|")
          //   : item.ItemName,
          Value:
            salesPeriod === "thisMonth" &&
            item.YearOfData === currentYear.toString() &&
            !isNaN(item?.[`Sales_${month}`])
              ? isNaN(item?.[`Sales_${month}`])
                ? "0"
                : numberWithCommas(round(item[`Sales_${month}`]))
              : salesPeriod === "lastMonth" &&
                month > 1 &&
                item.YearOfData === currentYear.toString() &&
                !isNaN(item?.[`Sales_${month - 1}`])
              ? isNaN(item?.[`Sales_${month - 1}`])
                ? "0"
                : numberWithCommas(round(item[`Sales_${month - 1}`]))
              : item.YearOfData === currentYear.toString() // : salesPeriod === 'lastYear'
              ? // ? numberWithCommas(lastYearReducer(lastYearProduct).totalYearSales)
                numberWithCommas(ytdReducer(item).ytdSales)
              : "-",

          OpenOrders:
            salesPeriod === "thisMonth" &&
            item.YearOfData === currentYear.toString() &&
            !isNaN(item?.[`Orders_${month}`])
              ? isNaN(item?.[`Orders_${month}`])
                ? "0"
                : numberWithCommas(round(item[`Orders_${month}`]))
              : salesPeriod === "lastMonth" &&
                month > 1 &&
                item.YearOfData === currentYear.toString() &&
                !isNaN(item?.[`Orders_${month - 1}`])
              ? isNaN(item?.[`Orders_${month - 1}`])
                ? "0"
                : numberWithCommas(round(item[`Orders_${month - 1}`]))
              : item.YearOfData === currentYear.toString() // : salesPeriod === 'lastYear'
              ? numberWithCommas(ytdReducer(item).ytdOrders)
              : "-",
          Sales:
            salesPeriod === "thisMonth" &&
            parseInt(item[`Sales_${month}`]) > 0 &&
            thisMonthTotal > 0 &&
            item.YearOfData === currentYear.toString()
              ? (
                  (parseInt(item[`Sales_${month}`]) / thisMonthTotal) *
                  100
                ).toFixed(1) + "%"
              : salesPeriod === "lastMonth" &&
                month > 1 &&
                parseInt(item[`Sales_${month - 1}`]) > 0 &&
                lastMonthTotal > 0 &&
                item.YearOfData === currentYear.toString()
              ? (
                  (parseInt(item[`Sales_${month - 1}`]) / lastMonthTotal) *
                  100
                ).toFixed(1) + "%"
              : salesPeriod === "yearToDate" &&
                ytdReducer(item).ytdSales > 0 &&
                yearToDateTotal > 0 &&
                item.YearOfData === currentYear.toString()
              ? ((ytdReducer(item).ytdSales / yearToDateTotal) * 100).toFixed(
                  1
                ) + "%"
              : "-",
          Units:
            salesPeriod === "thisMonth" &&
            parseInt(item[`SalesInUnits_${month}`]) > 0 &&
            item.YearOfData === currentYear.toString()
              ? numberWithCommas(
                  parseInt(item[`SalesInUnits_${month}`]).toString()
                )
              : salesPeriod === "lastMonth" &&
                month > 1 &&
                parseInt(item[`SalesInUnits_${month - 1}`]) > 0 &&
                item.YearOfData === currentYear.toString()
              ? numberWithCommas(parseInt(item[`SalesInUnits_${month - 1}`]))
              : salesPeriod === "yearToDate" &&
                ytdReducer(item).ytdUnits > 0 &&
                item.YearOfData === currentYear.toString()
              ? numberWithCommas(ytdReducer(item).ytdUnits)
              : "-",
          UpdateDate: item.UpdateDate,
          Probability: item.Probability
            ? dictionary[Language].productPurchasedTable.probabilityOptions[
                item.Probability
              ]
            : dictionary[Language].productPurchasedTable.probabilityOptions.Low,

          LastOrderDate: item.LastOrderDate
            ? item.LastOrderDate?.split("_").join("/")
            : "-",
        };
        return obj;
      });
      // dispatch(setProductPurchasedTableDataInStore(currentCustomerProducts));
      setProductPurchasedData(currentCustomerProducts);
    } else if (salesPeriod === "lastYear") {
      let lastYearProducts = salesByItem?.filter(
        (product) => parseInt(product.YearOfData) === currentYear - 1
      );
      thisYearCustomerProducts?.forEach((product) => {
        if (product.Probability === "Med" || product.Probability === "High") {
          const found = lastYearProducts.find(
            (item) => item.ItemCatalogNumber === product.ItemCatalogNumber
          );
          if (!found) lastYearProducts.push(product);
        }
      });
      lastYearProducts = lastYearProducts?.map((item) => {
        let found = thisYearCustomerProducts.find(
          (currentItem) =>
            currentItem.ItemCatalogNumber === item.ItemCatalogNumber
        );

        let obj = {
          Code: item.ItemCatalogNumber,
          ProductName: item.ItemName,
          // item.ItemName?.includes("|") && Language === "hebrew"
          //   ? item.ItemName?.split("|").reverse().join("|")
          //   : item.ItemName,
          Value:
            item.YearOfData === (currentYear - 1).toString()
              ? numberWithCommas(lastYearReducer(item).totalYearSales)
              : "-",

          OpenOrders:
            item.YearOfData === (currentYear - 1).toString()
              ? numberWithCommas(lastYearReducer(item).totalYearOrders)
              : "-",
          Sales:
            lastYearReducer(item).totalYearSales > 0 &&
            lastYearTotal > 0 &&
            item.YearOfData === (currentYear - 1).toString()
              ? (
                  (lastYearReducer(item).totalYearSales / lastYearTotal) *
                  100
                ).toFixed(1) + "%"
              : "-",
          Units:
            lastYearReducer(item).totalYearUnits > 0 &&
            item.YearOfData === (currentYear - 1).toString()
              ? numberWithCommas(lastYearReducer(item).totalYearUnits)
              : "-",
          UpdateDate: item.UpdateDate,

          LastOrderDate:
            found && found.LastOrderDate
              ? found.LastOrderDate?.split("_").join("/")
              : item.LastOrderDate
              ? item.LastOrderDate?.split("_").join("/")
              : "-",
          Probability: item.Probability
            ? dictionary[Language].productPurchasedTable.probabilityOptions[
                item.Probability
              ]
            : dictionary[Language].productPurchasedTable.probabilityOptions.Low,
        };
        return obj;
      });
      // dispatch(setProductPurchasedTableDataInStore(lastYearProducts));
      setProductPurchasedData(lastYearProducts);
    }
  }, [
    salesPeriod,
    CustomerID,
    salesByItem,
    productsAnalyzeDates,
    productPurchasedTableMode,
    productsAnalyzeDimension,
  ]);

  useEffect(
    function scrollToTop() {
      window.scrollTo(0, 0);
      if (prevCustomerID !== CustomerID) {
        dispatch(setProductPurchasedTableMode("regular"));
        dispatch(setProductsAnalyzeDates([]));
        dispatch(setProductsAnalyzeDimension("value"));
        dispatch(setProductPurchasedTableFilters([]));
        setSalesPeriod("thisMonth");
        dispatch(setPrevCustomerID(CustomerID));
      }
    },
    [CustomerID]
  );

  return (
    <div>
      <Subtitle homeData={[]} customerData={dataForSubtitle} />
      <div
        className="info-section"
        style={{
          direction: `${Language === "hebrew" ? "rtl" : "ltr"}`,
          marginRight: "1.5rem",
        }}
      >
        <h3 className="customer-info">
          {CustomerID}, {CustomerName}
        </h3>
        <h4 className="agent-info">
          {dictionary[Language].agent} : {AgentName}
        </h4>
      </div>
      <CustomerKeyOpportunitiesTable
        customerData={data}
        setCoordinates={setCoordinates}
        setProductName={setProductName}
        salesByItem={salesByItem}
        CustomerID={CustomerID}
        CustomerName={CustomerName}
        AgentName={AgentName}
      />
      <div className="separator-div"></div>
      <div
        className="product-purchased-above-table"
        style={{
          direction: `${Language === "hebrew" ? "rtl" : "ltr"}`,
          marginRight: "1rem",
        }}
      >
        <h4 className="table-header">
          {dictionary[Language]?.productPurchasedTable?.tableTitle}
        </h4>
        {prodPurchasedLastUpdate && (
          <span className="last-update">
            {dictionary[Language].lastUpdate} :{" "}
            {DateFormat === "us"
              ? usDateFormat(prodPurchasedLastUpdate)
              : prodPurchasedLastUpdate}
          </span>
        )}
      </div>
      <div className="bottom-half">
        <div>
          <ProductPurchasedTable
            CustomerID={CustomerID}
            productPurchasedData={productPurchasedData}
            setSalesPeriod={setSalesPeriod}
            salesPeriod={salesPeriod}
            setAnalyzeMode={setAnalyzeMode}
            analyzeMode={analyzeMode}
            setCoordinates={setCoordinates}
            salesByItem={salesByItem}
            setProductName={setProductName}
          />
        </div>
        <div className="product-sales-graph">
          <ProductSalesGraph
            coordinates={coordinates}
            productName={productName}
          />
        </div>
      </div>
      <div className="separator-div"></div>
      <CustomerSalesOverviewGraph
        customerName={CustomerName}
        coordinates={coordinatesOverall}
        productPurchasedData={productPurchasedData}
      />
      <div className="separator-div"></div>
      <div className="white-space" style={{ height: "2rem" }}></div>
      <Footer />
    </div>
  );
};
