import axios from "axios";
import domain from "../config/domain";
import { reqAuth } from "../constants/reqAuth";

async function getCustomersOverview(InsightingCustomerID, reqDate) {
  // reqDate = '2023-03-19T22:00:00.000Z'
  return axios
    .request({
      method: "POST",
      url: `${domain}/CustomersOverview/InsightingCustomerID/${InsightingCustomerID}`,
      headers: { authorization: reqAuth },
      data: { reqDate },
    })
    .then((r) => {
      return r.data;
    })
    .catch(console.error);
}

const CustomersOverviewService = {
  getCustomersOverview,
};

export default CustomersOverviewService;
