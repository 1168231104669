import React from "react";
import { useSelector } from "react-redux";
import { getMonthForHeader } from "../../../utils/helpers";
import { dictionary } from "../../../constants/dictionary";
import numberUtils from "../../../utils/number";
import { currencyMap } from "../../../constants/currency-map";

export default function CustomersOverviewSumsRow(rows) {
  const SalesHeadersMap = {
    english: {
      yearAgo: `Sales ${getMonthForHeader("currentMonthYearAgo")}`,
      lastMonth: `Sales ${getMonthForHeader("lastMonth")}`,
      avgP3M: `Avg ${getMonthForHeader("threeMonthsAgo")}-${getMonthForHeader(
        "lastMonth"
      )}`,
      avgPurchaseP3M: `Avg Purchase ${getMonthForHeader(
        "threeMonthsAgo"
      )}-${getMonthForHeader("lastMonth")}`,
    },
    hebrew: {
      yearAgo: `מכירות ${getMonthForHeader("currentMonthYearAgo")}`,
      lastMonth: `מכירות ${getMonthForHeader("lastMonth")}`,
      avgP3M: `ממוצע ${getMonthForHeader("lastMonth")}-${getMonthForHeader(
        "threeMonthsAgo"
      )}`,
      avgPurchaseP3M: `ממוצע רכישות ${getMonthForHeader(
        "lastMonth"
      )}-${getMonthForHeader("threeMonthsAgo")}`,
    },
  };

  const numberOfCustomers = rows?.rows?.length;
  const currentMonthSum = rows.rows.reduce(
    (sum, row) =>
      sum +
      (typeof row?.original.achieved === "string"
        ? parseInt(row?.original.achieved?.replaceAll(",", ""))
        : typeof row?.original.achieved === "number"
        ? parseInt(row?.original.achieved)
        : 0),
    0
  );
  const comparisonValueSum = rows.rows.reduce(
    (sum, row) =>
      sum +
      (typeof row?.original.comparisonValue === "string"
        ? parseInt(row?.original.comparisonValue?.replaceAll(",", ""))
        : typeof row?.original.comparisonValue === "number"
        ? parseInt(row?.original.comparisonValue)
        : 0),
    0
  );

  const openOrdersSum = rows.rows.reduce(
    (sum, row) =>
      sum +
      (typeof row?.original.openOrders === "string"
        ? parseInt(row?.original.openOrders?.replaceAll(",", ""))
        : typeof row?.original.openOrders === "number"
        ? parseInt(row?.original.openOrders)
        : 0),
    0
  );
  const salesProgressSum = parseInt(
    100 * (currentMonthSum / comparisonValueSum)
  )
    ? parseInt(100 * (currentMonthSum / comparisonValueSum)) + "%"
    : "-";
  const Language = useSelector(
    (state) => state.userSlice.user.metadata.Language
  );
  const Currency = useSelector(
    (state) => state.userSlice.user.metadata.Currency
  );
  const comparisonValue = useSelector(
    (state) => state.homePagePropsSlice.comparisonValue
  );
  return (
    <div
      style={{
        direction: `${Language === "hebrew" ? "rtl" : "ltr"}`,
        margin: "0.5rem",
      }}
    >
      <div className="customers-overview-table-sums-list">
        <div className="customers-overview-table-sums-tag">
          <div className="sum-value">{numberOfCustomers}</div>
          <div className="sum-header">
            {" "}
            {
              dictionary[Language].CustomersOverviewTable.sumsText
                .numberOfCustomers
            }
          </div>
        </div>
        <div className="hierarchy-separator"></div>
        <div className="customers-overview-table-sums-tag">
          <div className="sum-value">
            {currencyMap[Currency]}
            {numberUtils.mFormat(currentMonthSum)}
          </div>
          <div className="sum-header">
            {dictionary[Language].CustomersOverviewTable.sumsText.currentMonth}
          </div>
        </div>
        <div className="hierarchy-separator"></div>
        <div className="customers-overview-table-sums-tag">
          <div className="sum-value">
            {" "}
            {currencyMap[Currency]}
            {numberUtils.mFormat(comparisonValueSum)}
          </div>
          <div className="sum-header">
            {" "}
            {SalesHeadersMap[Language][comparisonValue]}
          </div>
        </div>
        <div className="hierarchy-separator"></div>
        <div className="customers-overview-table-sums-tag">
          <div className="sum-value">{salesProgressSum}</div>
          <div className="sum-header">
            {" "}
            {dictionary[Language].CustomersOverviewTable.sumsText.progress}
          </div>
        </div>
        <div className="hierarchy-separator"></div>
        <div className="customers-overview-table-sums-tag">
          <div className="sum-value">
            {" "}
            {currencyMap[Currency]}
            {numberUtils.mFormat(openOrdersSum)}
          </div>
          <div className="sum-header">
            {" "}
            {dictionary[Language].CustomersOverviewTable.sumsText.openOrders}
          </div>
        </div>
      </div>
    </div>
  );
}
