import TextColumnFilter from "../../../Filters/TextColumnFilter";
import NumberColumnFilter from "../../../Filters/NumberColumnFilter";
import { sortTypes } from "../../../../utils/sort";
import CheckedColumnFilter from "../../../Filters/CheckedColumnFilter";
import DateColumnFilter from "../../../Filters/DateColumnFilter";
import PercentageColumnFilter from "../../../Filters/PercentageColumnFilter";

export const hebCOLUMNS = [
  {
    Header: "שם מוצר",
    accessor: "ProductName",
    filter: "text",
    Filter: TextColumnFilter,
    canFilter: true,
  },
  {
    Header: 'מק"ט',
    accessor: "Code",
    filter: "text",
    Filter: TextColumnFilter,
    canFilter: true,
  },
  {
    Header: "₪",
    accessor: "Value",
    filter: "number",
    Filter: NumberColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: "תקופת ניתוח",
    accessor: "reportPeriod",
    filter: "number",
    Filter: NumberColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: "השוואה ל",
    accessor: "compareToPeriod",
    filter: "number",
    Filter: NumberColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: "% שינוי",
    accessor: "changeBetweenPeriods",
    filter: "number",
    Filter: NumberColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: "מהמכירות %",
    accessor: "Sales",
    filter: "number",
    Filter: PercentageColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: "יחידות",
    accessor: "Units",
    filter: "number",
    Filter: NumberColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: "רכישה אחרונה",
    accessor: "LastOrderDate",
    filter: "date",
    Filter: DateColumnFilter,
    canFilter: true,
    sortType: sortTypes.lastOrderDate,
    // disableSortBy: true
  },
  {
    Header: "הזמנות פתוחות ₪",
    accessor: "OpenOrders",
    filter: "number",
    Filter: NumberColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: "סבירות לרכישה ",
    accessor: "Probability",
    filter: "checked",
    Filter: CheckedColumnFilter,
    canFilter: true,
    sortType: sortTypes.probability,
  },
];
