import React from "react";
import { ReportsTable } from "../../components/Tables/ReportsTable/ReportsTable";


export const ReportsPage = () => {
  return (
    <>
      <ReportsTable />
    </>
  );
};
