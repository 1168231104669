import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import { grey } from "@mui/material/colors";
import Select from "react-select";

import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import CardContent from "@material-ui/core/CardContent";
import { useState, useEffect, useCallback } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { getColumnByAccessor } from "../../utils/filters";
import { dictionary } from "../../constants/dictionary";
import { shallowEqual, useSelector } from "react-redux";

export default function FilterPicker({
  columns = [],
  headers = [],
  visible = false,
  setVisibility = () => {},
}) {
  const [filterByColumn, setFilterByColumn] = useState(columns[0]?.accessor);
  const userSlice = useSelector((state) => state.userSlice, shallowEqual);
  const {
    user: {
      metadata: { Language },
    },
  } = userSlice;
  const useStyles = makeStyles((theme) => ({
    root: {
      position: "relative",
    },
    card: {
      direction: Language === "english" ? "ltr" : "rtl",
      top: -10,
      [`${Language === "hebrew" ? "right" : "left"}`]: 10,
      zIndex: 6,
      width: 210,
      display: "flex",
      position: "absolute",
      flexDirection: "column",
      // color: "rgba(0, 0, 0, 0.54)",
      fontSize: 12,
      boxShadow: `0 0 5px ${grey[500]}`,
      overflow: "visible",
      [theme.breakpoints.down("xs")]: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: "auto",
        height: "fit-content",
      },
    },
    cardcontent: {
      "&:last-child": {
        paddingBottom: 0,
      },
    },
    formControl: {
      width: "100%",
      // margin: theme.spacing(1),
    },
    filtersCloseIcon: {
      opacity: 0.82,
      cursor: "pointer",
      fontSize: "2rem",
      padding: 8,
      transition: "opacity 0.4s",
      "&:hover": {
        opacity: 1,
      },
    },
    font: {
      fontSize: 12,
    },
  }));
  const classes = useStyles();
  const filterColumn = getColumnByAccessor(filterByColumn, headers);
  const clearFilter = useCallback(
    (columnAccessor) => {
      const column = getColumnByAccessor(columnAccessor, headers);
      if (column && column.canFilter && column.setFilter) {
        column.setFilter("");
      }
    },
    [headers]
  );

  const clearAllFilters = useCallback(() => {
    headers?.forEach((column) => {
      clearFilter(column.id);
    });
  }, [headers]);

  useEffect(
    function onClearFilters() {
      if (!filterByColumn) {
        clearAllFilters();
      }
    },
    [filterByColumn]
  );

  const columnOptions = headers
    ?.filter((column) => column.canFilter)
    .map((column) => ({
      value: column.id,
      label: column.Header,
    }));

  return (
    <form name="filter-picker" className={classes.root}>
      {!visible || !filterByColumn ? (
        <Typography
          variant="caption"
          color="textSecondary"
          onClick={() => {
            setVisibility(true);
          }}
        >
          {dictionary[Language]?.filterPicker.addFilter}
        </Typography>
      ) : (
        <Card className={classes.card}>
          <CardContent className={classes.cardcontent}>
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Typography gutterBottom variant="body2">
                {dictionary[Language]?.filterPicker?.filterRows}
              </Typography>
              <CloseIcon
                className={classes.filtersCloseIcon}
                onClick={() => setVisibility(false)}
              />
            </Box>
            <FormControl className={classes.formControl}>
              <div
                style={{
                  right: Language === "hebrew" ? -50 : "initial",
                }}
                id="filter-by-column-select-label"
              >
                {" "}
                {dictionary[Language]?.filterPicker.column}
              </div>
              <div style={{ margin: "5px 0px 15px" }}>
                <Select
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 7,
                      // fontSize: 12,
                      direction: Language === "hebrew" ? "rtl" : "ltr",
                    }),
                  }}
                  value={columnOptions.find(
                    (option) => option.value === filterByColumn
                  )}
                  onChange={(selectedOption) =>
                    setFilterByColumn(selectedOption.value)
                  }
                  options={columnOptions}
                  isSearchable={true}
                />
              </div>
            </FormControl>
            {filterColumn?.canFilter ? filterColumn?.render("Filter") : null}
            <FormControl className={classes.filtersCloseIcon}></FormControl>
          </CardContent>
        </Card>
      )}
    </form>
  );
}
