export const urgencyColorsPalette = {
  text: {
    HIGH: "#b70f0a",
    MED: "#d68227",
    LOW: "#8c8525",
    NO: "transparent",
  },
  background: {
    HIGH: "#ffb7ab",
    MED: "#ffdd9c",
    LOW: "#fef67f",
    NO: "transparent",
  },
};
