import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CloseIcon from "@material-ui/icons/Close";
import { grey } from "@mui/material/colors";
import axios from "axios";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import domain from "../../config/domain";
import { keyOppPriorities } from "../../constants/filtersText-map";
import { setKeyOpportunitiesTablePriority } from "../../store/features/dataSlice";

import closeIcon from "../../images/close.png";

const useStyles = makeStyles(() => ({
  priorityForm: {
    zIndex: 1,
    position: "fixed",
  },
  card: {
    zIndex: 2,
    width: 120,
    display: "flex",
    flexDirection: "column",
    boxShadow: `0 0 5px ${grey[500]}`,
  },
  cardContent: {
    padding: "6px 8px 16px 12px",
    "&:last-child": {
      paddingBottom: 16,
    },
  },
  formControl: {
    margin: 2,
    minWidth: 130,
  },
  filtersCloseIcon: {
    opacity: 0.82,
    cursor: "pointer",
    fontSize: "2rem",
    transition: "opacity 0.4s",
    "&:hover": {
      opacity: 1,
    },
  },
  priorityBtn: {
    width: "1rem",
    height: "1rem",
    margin: "auto",
    borderRadius: "100%",
  },
  inactive: {
    color: "#ffffff",
    border: "1px solid #D1D1D1",
    backgroundColor: "#ffffff",
  },
  amber: {
    color: "#ffffff",
    lineHeight: "16px",
    textAlign: "center",
    backgroundColor: "orange",
  },
  discard: {
    height: 30,
    marginTop: -7,
    marginLeft: -7,
    marginRight: -7,
  },
}));

export const PriorityButton = ({ row, userType, status }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { InsightingCustomerID } = row?.original;
  const tableName = row?.original.CustID_InsightType_Month_ItemCatNum_ItemVar
    ? "KeyOpportunities"
    : "CustomersOverview";
  const [priority, setPriority] = useState(status);
  const [priorityBoxPosition, setPriorityBoxPosition] = useState(false);

  // const onPriorityChange = async () => {
  //   if (userType === "agent" && (status === "1" || priority === "1")) {
  //     return;
  //   }
  //   const [CustomerID] = row.cells.map(({ value }) => value);

  //   setPriorityBoxPosition({});

  //   await axios.request({
  //     method: "POST",
  //     url: `${domain}/${tableName}/priorityRowQuery/`,
  //     data: {
  //       CustomerID,
  //       InsightingCustomerID,
  //       Priority: priority,
  //     },
  //     headers: { authorization: "Basic SXRpbmdfRkU6IVBlcmZlY1Qh" },
  //   });
  //   // }

  //   dispatch(
  //     setKeyOpportunitiesTablePriority({
  //       InsightingCustomerID: row?.original.InsightingCustomerID,
  //       CustID_InsightType_Month_ItemCatNum_ItemVar:
  //         row?.original.CustID_InsightType_Month_ItemCatNum_ItemVar,
  //       Priority: priority,
  //     })
  //   );
  // };

  // useEffect(
  //   function onPriorityChanged() {
  //     if (priority !== status) {
  //       onPriorityChange();
  //     }
  //   },
  //   [priority]
  // );

  return (
    <div>
      <div
        className={classNames(classes.priorityBtn, {
          [classes.inactive]: status === "0",
          [classes.amber]: status === "1",
        })}
      >
        {status !== "-1" ? (
          "!"
        ) : (
          <img src={closeIcon} alt="x" className={classes.discard} />
        )}
      </div>

      {!isEmpty(priorityBoxPosition) && (
        <form
          name="priority-form"
          className={classes.priorityForm}
          onSubmit={(e) => e.preventDefault()}
          style={{
            top: priorityBoxPosition.top,
            left: priorityBoxPosition.left,
          }}
        >
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Box display="flex" justifyContent="flex-end">
                <CloseIcon
                  className={classes.filtersCloseIcon}
                  onClick={() => setPriorityBoxPosition({})}
                />
              </Box>

              <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup>
                  {keyOppPriorities.map(({ value, label }) => {
                    return (
                      <FormControlLabel
                        key={value}
                        size="small"
                        value={value}
                        label={label}
                        style={{ marginBottom: -5 }}
                        control={
                          <Checkbox
                            size="small"
                            color="primary"
                            style={{ padding: 6 }}
                            checked={priority === value}
                            onChange={() => setPriority(value)}
                          />
                        }
                        disabled={
                          userType === "agent" &&
                          (status === "1" || value === "1")
                        }
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </CardContent>
          </Card>
        </form>
      )}
    </div>
  );
};
