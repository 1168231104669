import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import { FILTER_SEPARATOR } from "../../utils/filters";
import { operatorsMapForFilters } from "../../constants/filtersText-map";
import { shallowEqual, useSelector } from "react-redux";
import { dictionary } from "../../constants/dictionary";
import Select from "react-select";

export const operators = [
  { id: "equals", label: "=", textForPicker: "=" },
  {
    id: "greater",
    label: ">",
    textForPicker: "english",
  },
  {
    id: "lower",
    label: "<",
    textForPicker: "english",
  },
];

const DateColumnFilter = ({ column }) => {
  const userSlice = useSelector((state) => state.userSlice, shallowEqual);
  const staticAppDate = useSelector((state) => state.globalSlice.staticAppDate);
  const {
    user: {
      metadata: { Language },
    },
  } = userSlice;
  const { setFilter } = column;

  const [value, setValue] = useState(
    staticAppDate ? new Date(staticAppDate) : new Date()
  );

  const operators = [
    { id: "equals", label: "=", textForPicker: "=" },
    {
      id: "greater",
      label: ">",
      textForPicker: operatorsMapForFilters[Language]?.[">"],
    },
    {
      id: "lower",
      label: "<",
      textForPicker: operatorsMapForFilters[Language]?.["<"],
    },
  ];

  const [operator, setOperator] = useState(operators[0]);

  const applyFilter = (e) => {
    if (value && typeof value === "string" && operator) {
      setFilter(`${operator.id}${FILTER_SEPARATOR}${value}`);
      setValue(new Date());
    }
    e.preventDefault();
  };

  return (
    <>
      <InputLabel
        id="operator-select-label"
        style={{ right: Language === "hebrew" ? -50 : "initial" }}
      >
        {" "}
        {dictionary[Language]?.filterPicker.operator}
      </InputLabel>
      <Select
        value={operator}
        id="operator-select"
        options={operators.map((op) => ({
          value: op.id,
          label: op.textForPicker,
        }))}
        onChange={(selectedOption) => {
          const selectedOperator = operators.find(
            (op) => op.id === selectedOption.value
          );
          setOperator(selectedOperator);
        }}
      />
      <div
        style={{
          margin: "15px 0px",
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <TextField
          fullWidth
          id="value"
          type="date"
          value={value}
          placeholder="Filter"
          inputProps={{
            // className: classes.font,
            style: {
              padding: "7px 0",
              display: "flex",
              color: "rgba(0, 0, 0, 0.54)",
              fontSize: "13px",
              textAlign: Language === "hebrew" ? "right" : "left",
            },
          }}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "0.5rem",
        }}
      >
        {" "}
        <Button
          size="small"
          type="submit"
          color="primary"
          variant="contained"
          onClick={applyFilter}
          className="filter-picker-apply-button"
          disabled={typeof value !== "string"}
        >
          {dictionary[Language].hierarchyBox.apply}
        </Button>
      </div>
    </>
  );
};

export default DateColumnFilter;
