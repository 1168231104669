import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({ 
  palette: {
      primary: {
          main: '#75a9f9',
          light: '#f2faff',
      },
      disabled: {
          main: '#d3d3d3',
          light: '#f2faff',
      },
  },
  breakpoints: {
    values: {
      xs: 450,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});

export default theme;