import React from "react";
import ReportPeriodsFilter from "./ReportFiltersByType/ReportPeriodsFilter";
import ReportAdditionalInfoFilter from "./ReportFiltersByType/ReportAdditionalInfoFilter";
import ReportDisplayOptionsFilter from "./ReportFiltersByType/ReportDisplayOptionsFilter";
import ReportBottomButtons from "./ReportBottomButtons";

function ReportFiltersView() {
  return (
    <>
      <div className="report-filters-container">
        <div>
          <ReportPeriodsFilter />
          <ReportDisplayOptionsFilter />
        </div>
        <div className="report-additional-info-box">
          <ReportAdditionalInfoFilter />
        </div>
      </div>
      <ReportBottomButtons />
    </>
  );
}

export default ReportFiltersView;
