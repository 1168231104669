import React from 'react';
import { CSVLink } from 'react-csv';
import isEmpty from 'lodash/isEmpty';
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import { grey } from '@mui/material/colors';
import MenuItem from '@material-ui/core/MenuItem';
import DownloadIcon from "@material-ui/icons/GetApp";
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

import pdfUtils from '../../utils/pdf';

const useStyles = makeStyles(() => ({
    downloadIcon: {
        cursor: 'pointer',
        color: grey[800],
        marginBottom: -8,
    },
    downloadText: {
        marginTop: -4,
        fontSize: 8,
    },
    menuItem: {
        fontSize: 13,
    },
    exportCsv: {
        color: '#000',
    },
}));

const ExportTableMenu = ({ csv = {}, pdf = {} }) => {
    const classes = useStyles();
    
    return (
      <PopupState variant="popover" popupId="demo-popup-menu">
        {(popupState) => (
          <React.Fragment>
            <Box display="flex" alignItems="center" flexDirection="column" {...bindTrigger(popupState)} className={classes.downloadIcon}>
                <DownloadIcon fontSize="small" />
                <Typography variant="caption" className={classes.downloadText}>Download</Typography>
            </Box>  
            <Menu {...bindMenu(popupState)}>
                {!isEmpty(csv) && (
                    <MenuItem 
                        className={classes.menuItem} 
                        onClick={() => window.setTimeout(popupState.close, 100)}
                    >
                        <CSVLink 
                            target="_blank" 
                            data={csv.data} 
                            filename={`${csv.file}.csv`} className={classes.exportCsv}
                        >
                            Export CSV
                        </CSVLink>
                    </MenuItem>
                )}
                {!isEmpty(pdf) && (
                    <MenuItem 
                        className={classes.menuItem}
                        onClick={() => {
                            pdfUtils.exportPdfFile(pdf.el, pdf.file);
                            popupState.close();
                        }} 
                    >
                        Export PDF
                    </MenuItem>
                )}
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    );
};

export default ExportTableMenu;
