import React, { useEffect, useMemo, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { hebCOLUMNS } from "./ColumnsFiles/hebColumns";
import { engCOLUMNS } from "./ColumnsFiles/engColumns";
import pdfUtils from "../../../utils/pdf";
import {
  getRowsCsv,
  isSorted,
  setCurrencyForTableHeaders,
} from "../../../utils/helpers";
import { useFilters, useSortBy, useTable } from "react-table";
import { filterTypes, getColumnByAccessor } from "../../../utils/filters";
import { setPurchaseHistoryTableSorts } from "../../../store/features/tableSortsSlice";
import { setPurchaseHistoryTableFilters } from "../../../store/features/tableFiltersSlice";
import { isEmpty } from "lodash";
import {dictionary} from "../../../constants/dictionary";
import { Box, Menu, MenuItem, makeStyles } from "@material-ui/core";
import FiltersList from "../../Filters/FiltersList";
import FilterPicker from "../../Filters/FilterPicker";
import NestedMenuItem from "material-ui-nested-menu-item";
import { CSVLink } from "react-csv";
import "./PurchaseHistoryTable.css";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  tableFilters: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: "0.5rem",
    position: "relative",
    direction: "ltr",
    width: "56.8vw",
    // maxWidth: " 42vw",
  },
  filterIcon: {
    marginLeft: 20,
    cursor: "pointer",
  },
  filtersCloseIcon: {
    opacity: 0.82,
    marginBottom: 12,
    cursor: "pointer",
    transition: "opacity 0.4s",
    "&:hover": {
      opacity: 1,
    },
  },
  export: {
    marginLeft: 15,
    marginBottom: 10,
  },
}));

export const PurchaseHistoryTable = ({
  purchasedHistoryData,
  CustomerName,
  ItemVariant,
}) => {
  const purchaseHistoryTableFilters = useSelector(state => state.tableFiltersSlice.purchaseHistoryTableFilters,shallowEqual)
  const purchaseHistoryTableSorts = useSelector(state => state.tableSortsSlice.purchaseHistoryTableSorts,shallowEqual)

  const { Currency, Language } = JSON.parse(
    sessionStorage.getItem("userMetadata")
  );

  const classes = useStyles();
  const [filterPickerVisible, setFilterPickerVisible] = useState(null);
  const [menuPosition, setMenuPosition] = useState(null);
  const [pdfAvailable, setPdfAvailable] = useState(false);
  const tableRef = useRef(null);
  const dispatch = useDispatch();

  const columns = useMemo(
    () =>
      Language === "hebrew"
        ? setCurrencyForTableHeaders(Currency, hebCOLUMNS, Language)
        : Language === "english"
        ? setCurrencyForTableHeaders(Currency, engCOLUMNS, Language)
        : setCurrencyForTableHeaders(Currency, engCOLUMNS, Language),
    []
  );

  const data = useMemo(() => purchasedHistoryData, [purchasedHistoryData]);

  const handlePDFExportToClick = () => {
    pdfUtils.download(
      tableRef.current,
      `${CustomerName}-${ItemVariant}-category-details`
    );
    setMenuPosition(null);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: {
        sortBy: purchaseHistoryTableSorts,
      },
      //   filters: [],
      // },
      autoResetPage: false,
      autoResetExpanded: false,
      autoResetGroupBy: false,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false,
    },
    useFilters,
    useSortBy
  );

  const handleRightClick = (e, row, rowsLength) => {
    setPdfAvailable(rowsLength < 200 ? true : false);
    if (menuPosition) {
      return;
    }
    e.preventDefault();
    setMenuPosition({
      top: e.clientY,
      left: e.clientX,
    });
  };

  useEffect(
    function onSortsChanged() {
      dispatch(setPurchaseHistoryTableSorts(state.sortBy));
    },
    [state.sortBy]
  );

  useEffect(
    function onFiltersChanged() {
      dispatch(setPurchaseHistoryTableFilters(state.filters));
    },
    [state.filters]
  );

  useEffect(
    function applyFilters() {
      if (!isEmpty(purchaseHistoryTableFilters)) {
        purchaseHistoryTableFilters?.forEach(({ id, value }) => {
          const column = getColumnByAccessor(id, headerGroups[1].headers);
          if (column && column.canFilter && column.setFilter) {
            column.setFilter(value);
          }
        });
      }
    },
    [data]
  );


  return (
    <>
      <div
        className="purchase-history-above-table"
        style={{
          direction: `${Language === "hebrew" ? "rtl" : "ltr"}`,
          margin: "1.5rem",
        }}
      >
        <h4>{`${CustomerName}, ${ItemVariant}`}</h4>
        <div>{dictionary[Language]?.purchaseHistoryTable.subtitle}</div>
      </div>

      <Box
        display="flex"
        alignItems="center"
        className={classes.tableFilters}
        style={{ direction: Language === "english" ? "ltr" : "rtl" }}
      >
        <Box display="flex" alignItems="center">
          <FiltersList
            filters={state.filters}
            headers={headerGroups[0].headers}
            setFilterPickerVisible={setFilterPickerVisible}
          />
          <FilterPicker
            columns={columns}
            visible={filterPickerVisible}
            headers={headerGroups[0].headers}
            setVisibility={setFilterPickerVisible}
          />
        </Box>
      </Box>

      <div
        id="purchase-history-table"
        className="purchase-history-table-div"
        ref={tableRef}
      >
        <table
          ref={tableRef}
          {...getTableProps()}
          className="purchase-history-table"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>{isSorted(column)}</span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps}>
            <Menu
              open={!!menuPosition}
              onClose={() => {
                setMenuPosition(null);
              }}
              anchorReference="anchorPosition"
              anchorPosition={menuPosition}
            >
              <NestedMenuItem
                label={
                  dictionary[Language]?.salesReviewTable.menuOptions.exportTo
                }
                parentMenuOpen={!!menuPosition}
              >
                <MenuItem id="csv">
                  <CSVLink
                    target="_blank"
                    data={getRowsCsv(rows, columns)}
                    filename={`${CustomerName}-${ItemVariant}-category-details.csv`}
                    style={{ color: "black" }}
                    onClick={() => {
                      setMenuPosition(null);
                    }}
                  >
                    CSV
                  </CSVLink>
                </MenuItem>
                <MenuItem
                  id="pdf"
                  onClick={pdfAvailable ? handlePDFExportToClick : null}
                  style={{ color: pdfAvailable ? "black" : "grey" }}
                >
                  PDF
                </MenuItem>
              </NestedMenuItem>
            </Menu>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  onContextMenu={(e) => handleRightClick(e, row, rows?.length)}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} style={{ direction: "ltr" }}>
                        {cell.render("Cell")}{" "}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
