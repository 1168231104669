export const engCOLUMNS = [
  {
    Header: "Time Frame",
    accessor: "TimeFrame",
  },
  {
    Header: "Customer",
    accessor: "Customer",
   },
  {
    Header: "Group",
    accessor: "Group",
    }
];
