import axios from "axios";
import domain from "../config/domain";
import { reqAuth } from "../constants/reqAuth";

async function getSalesByAgent(ManagerID, InsightingCustomerID, reqDate) {
  try {
    const promises = [];
    promises.push(
      await axios({
        method: "POST",
        url: `${domain}/SalesByAgent`,
        headers: { authorization: reqAuth },
        data: { ManagerID, InsightingCustomerID, reqDate },
      })
    );
    Promise.all(promises);
    const returnedSalesByAgentData = JSON.stringify(promises[0].data);
    return returnedSalesByAgentData;
  } catch (e) {
    console.error(e);
  }
}

const salesByAgentService = {
  getSalesByAgent,
};

export default salesByAgentService;
