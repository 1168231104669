export const insightsMap = {
  hebrew: {
    Insight1: "פריט לא נרכש חודש קודם",
    Insight1During: "פריט לא נרכש החודש",
    Insight2: "לקוח ללא רכישות חודש קודם",
    Insight2During: "לקוח ללא רכישות החודש",
    Insight3: "פריט מומלץ למכירה",
    Insight4During: "רכישות נמוכות החודש",
    Insight5: "צמצום קטגוריות",
    Insight5During: "צמצום קטגוריות",
    New: "|חדש",
  },
  english: {
    Insight1: "Not ordered last month",
    Insight1During: "Not ordered this month",
    Insight2: "Didn't order last month",
    Insight2During: "Didn't order this month",
    Insight3: "Recommended item",
    Insight4During: "Low sales",
    Insight5: "Reducing category",
    Insight5During: "Reducing category",
    New: "|New",
  },
};

export const aggInsightsMap = {
  hebrew: {
    Insight1_agg: "צמצום פריטים",
    Insight2_agg: "אין הזמנות",
    Insight3_agg: "פריטים מומלצים",
    Insight1During_agg: "צמצום פריטים",
    Insight2During_agg: "אין הזמנות",
    Insight4During_agg: "מכירות נמוכות",
    Insight5_agg: "צמצום קטגוריות",
    Insight5During_agg: "צמצום קטגוריות",
    New: "|חדש",
    None: "ללא הזדמנויות",
  },
  english: {
    Insight1_agg: "Reducing items",
    Insight2_agg: "No orders",
    Insight3_agg: "Upsell",
    Insight1During_agg: "Reducing items",
    Insight2During_agg: "No orders",
    Insight4During_agg: "Low sales",
    Insight5_agg: "Reducing category",
    Insight5During_agg: "Reducing category",
    New: "|New",
    None: "No opportunities",
  },
};
