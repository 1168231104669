import React from "react";
import ReportDetailsBottomButtons from "./ReportDetailsBottomButtons";
import ReportDetailsGeneratedTable from "../../../components/Tables/ReportDetailsGeneratedTable/ReportDetailsGeneratedTable";

function ReportDetailsMainContent() {
  return (
    <div style={{ width: "calc(100% - 310px)" }}>
      <>
        <ReportDetailsGeneratedTable />
        <ReportDetailsBottomButtons />
      </>
    </div>
  );
}

export default ReportDetailsMainContent;
