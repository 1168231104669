import React from "react";
import { dictionary } from "../../../constants/dictionary";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  resetReportFilters,
  setGroups,
  setIsSelectionPending,
  setReportCategory,
  setSelectedCategory,
  setSelectedOption1,
  setSelectedOptions2,
  updateReportFilters,
} from "../../../store/features/reportsGeneratorPageSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function ReportBottomButtons() {
  //   const { Language } = JSON.parse(sessionStorage.getItem("userMetadata"));
  const Language = useSelector(
    (state) => state.userSlice.user.metadata.Language
  );
  const reportFilters = useSelector(
    (state) => state.reportsGeneratorPageSlice.reportFilters,
    shallowEqual
  );
  const comparePeriodCheckbox = useSelector(
    (state) => state.reportsGeneratorPageSlice.comparePeriodCheckbox
  );
  const isSelectionPending = useSelector(
    (state) => state.reportsGeneratorPageSlice.isSelectionPending
  );
  const groups = useSelector(
    (state) => state.reportsGeneratorPageSlice.groups,
    shallowEqual
  );
  const selectedOption1 = useSelector(
    (state) => state.reportsGeneratorPageSlice.selectedOption1
  );
  const selectedCategory = useSelector(
    (state) => state.reportsGeneratorPageSlice.selectedCategory
  );
  const selectedOptions2 = useSelector(
    (state) => state.reportsGeneratorPageSlice.selectedOptions2,
    shallowEqual
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClearAllClick = () => {
    dispatch(setReportCategory(""));
    dispatch(resetReportFilters());
  };
  const handleGenerateClick = () => {
    if (isSelectionPending) {
      const newGroup = {
        selectedOption1,
        selectedCategory, 
        selectedOptions2,
      };

      const updatedGroups = [...groups, newGroup];

      dispatch(setGroups(updatedGroups));
      dispatch(setIsSelectionPending(false));

      dispatch(
        updateReportFilters({
          ...reportFilters,
          groups: updatedGroups,
        })
      );

      dispatch(setSelectedOption1(null));
      dispatch(setSelectedOptions2([]));
      dispatch(setSelectedCategory(null));
    }
    if (areMandatoryFieldsFilled()) {
      history.push("/reportspage/details");
    }
    // dispatch(setReportCategory(""));
    // dispatch(resetReportFilters());
  };

  const areMandatoryFieldsFilled = () => {
    const { Period1, Period2, aggregationType, ValuesToDisplay } =
      reportFilters;

    return (
      Period1[1].length > 0 &&
      (comparePeriodCheckbox ? Period2[1].length > 0 : true) &&
      aggregationType &&
      ValuesToDisplay.length > 0
    );
  };

  return (
    <div className="bottom-buttons-container">
      <button className="clear-all-button" onClick={handleClearAllClick}>
        {dictionary[Language]?.reportsGeneratorPage.clearAll}
      </button>
      <div className="bottom-buttons ">
        {/* <button>{dictionary[Language]?.reportsGeneratorPage.saveReport}</button> */}
        <button
          style={{
            background: !areMandatoryFieldsFilled() ? "#D8D8D8" : "black",
          }}
          onClick={handleGenerateClick}
        >
          {dictionary[Language]?.reportsGeneratorPage.generate}
        </button>
      </div>
    </div>
  );
}

export default ReportBottomButtons;
