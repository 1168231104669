import React, { useEffect, useRef, useState } from "react";
import svgs from "../../../../utils/svgs";
import { dictionary } from "../../../../constants/dictionary";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import tableIndexes from "../../../../services/tableIndexes";
import {
  resetReportFilters,
  setGroups,
  setIsSelectionPending,
  setItemCatNumbers,
  setSelectedCategory,
  setSelectedOption1,
  setSelectedOptions2,
  updateReportFilters,
} from "../../../../store/features/reportsGeneratorPageSlice";
import { setSalesByAgentData } from "../../../../store/features/salesByAgentSlice";
import Select, { components } from "react-select";
import { FixedSizeList as List } from "react-window";

const MAX_SELECTION_LIMIT = 50;

const MenuList = (props) => {
  const height = 35; // Adjust this value based on the height of your option items
  const { options, children, getValue } = props;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;

  return (
    <components.MenuList {...props}>
      <List
        height={Math.min(300, children.length * height)} // Set the maximum height based on the number of items
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
        width="100%"
        style={{ direction: props.selectProps.direction }} // Ensure the list inherits the correct text direction
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    </components.MenuList>
  );
};

function ReportAdditionalInfoFilter() {
  const userSlice = useSelector((state) => state.userSlice, shallowEqual);
  const {
    user: {
      metadata: { Language, InsightingCustomerID, CustHierNames },
    },
  } = userSlice;
  const dispatch = useDispatch();
  const customersForNavbarData = useSelector(
    (state) => state.dataSlice.customersForNavbarData,
    shallowEqual
  );
  const itemCatNumbers = useSelector(
    (state) => state.reportsGeneratorPageSlice.itemCatNumbers,
    shallowEqual
  );
  const salesByAgentData = useSelector(
    (state) => state.salesByAgentSlice.salesByAgentData,
    shallowEqual
  );
  const reportFilters = useSelector(
    (state) => state.reportsGeneratorPageSlice.reportFilters,
    shallowEqual
  );
  const flattenArrayOfHierachyValues = useSelector(
    (state) => state.customersOverviewSlice.flattenArrayOfHierachyValues,
    shallowEqual
  );
  const reportCategory = useSelector(
    (state) => state.reportsGeneratorPageSlice.reportCategory
  );
  const isSelectionPending = useSelector(
    (state) => state.reportsGeneratorPageSlice.isSelectionPending
  );
  const groups = useSelector(
    (state) => state.reportsGeneratorPageSlice.groups,
    shallowEqual
  );
  const selectedOption1 = useSelector(
    (state) => state.reportsGeneratorPageSlice.selectedOption1
  );
  const selectedCategory = useSelector(
    (state) => state.reportsGeneratorPageSlice.selectedCategory
  );
  const selectedOptions2 = useSelector(
    (state) => state.reportsGeneratorPageSlice.selectedOptions2,
    shallowEqual
  );

  const arrayOfHierarchies = CustHierNames?.slice(1, -1).split("~") || [];

  // const [selectedOption1, setSelectedOption1] = useState(null);
  // const [selectedCategory, setSelectedCategory] = useState(null);
  // const [selectedOptions2, setSelectedOptions2] = useState([]);
  // const [groups, setGroups] = useState(reportFilters.groups || []);
  // const [isSelectionPending, setIsSelectionPending] = useState(false);
  const [availableOptions1, setAvailableOptions1] = useState(
    dictionary[Language]?.reportsGeneratorPage.additionalInfoSelector.map(
      (option, index) => ({
        label: option.optionText,
        value: option.optionKey,
        index,
      })
    )
  );

  const [firstOpen, setFirstOpen] = useState(true);
  const prevReportCategoryRef = useRef(reportCategory); // Store the previous category
  const loggedSelectionsRef = useRef(null);

  const handleOption1Change = (option) => {
    dispatch(setSelectedOption1(option));
    dispatch(setIsSelectionPending(true));
    dispatch(setSelectedOptions2([]));
  };

  const handleOption2Change = (options) => {
    if (options.length > MAX_SELECTION_LIMIT) {
      alert(`You can only select up to ${MAX_SELECTION_LIMIT} options.`);
      return;
    }

    dispatch(setSelectedOptions2(options));
    dispatch(setIsSelectionPending(true));

    let updatedReportFilters = { ...reportFilters };

    if (selectedOption1.value === "CustomerHierarchies") {
      const categoryIndex = arrayOfHierarchies
        .indexOf(selectedCategory.value)
        .toString();
      const selectedValues = options.map((option) => option.value);

      updatedReportFilters.CustomersHierarchies = {
        ...updatedReportFilters.CustomersHierarchies,
      };

      updatedReportFilters.CustomersHierarchies[categoryIndex] = selectedValues;
    } else {
      switch (selectedOption1.value) {
        case "CustomerIDs":
          updatedReportFilters.CustomerIDs = options.map((opt) => opt.value);
          updatedReportFilters.CustomerList = options.map((opt) => opt.label);
          break;
        case "AgentIDs":
          updatedReportFilters.AgentIDs = options.map((opt) => opt.value);
          updatedReportFilters.AgentList = options.map((opt) => opt.label);
          break;
        case "ItemCatNumbers":
          updatedReportFilters.ItemCatNumbers = options.map((opt) => opt.value);
          updatedReportFilters.ItemList = options.map((opt) => opt.label);
          break;
        default:
          break;
      }
    }

    dispatch(updateReportFilters(updatedReportFilters));
  };

  const handleCategoryChange = (options) => {
    dispatch(setSelectedCategory(options));
    dispatch(setIsSelectionPending(true));
  };

  const handleCheckmarkClick = () => {
    if (isSelectionPending) {
      const newGroup = {
        selectedOption1,
        selectedCategory,
        selectedOptions2,
      };

      const updatedGroups = [...groups, newGroup];

      dispatch(setGroups(updatedGroups));
      dispatch(setIsSelectionPending(false));

      dispatch(
        updateReportFilters({
          ...reportFilters,
          groups: updatedGroups,
        })
      );

      dispatch(setSelectedOption1(null));
      dispatch(setSelectedOptions2([]));
      dispatch(setSelectedCategory(null));
    }
    setFirstOpen(true);
  };

  const handleDelete = (index) => {
    const updatedGroups = [...groups];
    const removedGroup = updatedGroups.splice(index, 1)[0];

    let updatedReportFilters = { ...reportFilters };

    if (removedGroup?.selectedOption1) {
      switch (removedGroup.selectedOption1.value) {
        case "CustomerIDs":
          updatedReportFilters.CustomerIDs = [];
          updatedReportFilters.CustomerList = [];
          break;
        case "AgentIDs":
          updatedReportFilters.AgentIDs = [];
          updatedReportFilters.AgentList = [];
          break;
        case "ItemCatNumbers":
          updatedReportFilters.ItemCatNumbers = [];
          updatedReportFilters.ItemList = [];
          break;
        default:
          break;
      }

      if (selectedOption1?.value !== "CustomerHierarchies") {
        setAvailableOptions1(
          [...availableOptions1, removedGroup.selectedOption1].sort(
            (a, b) => a.index - b.index
          )
        );
      }
      setSelectedCategory(null);
    }

    updatedReportFilters.groups = updatedGroups;

    dispatch(setGroups(updatedGroups));
    dispatch(updateReportFilters(updatedReportFilters));
  };

  const getSecondSelectOptions = () => {
    if (!selectedOption1) {
      return [];
    }

    switch (selectedOption1.value) {
      case "CustomerIDs":
        return customersForNavbarData.map((custObj) => ({
          label: custObj.label,
          value: custObj.id,
        }));

      case "AgentIDs":
        if (!salesByAgentData?.length) {
          dispatch(
            setSalesByAgentData(
              JSON.parse(sessionStorage.getItem("salesByAgentData"))
            )
          );
        }

        return salesByAgentData.map((agent) => ({
          label: `${agent.SalesAgent} (${agent.AgentID})`,
          value: agent.AgentID,
        }));

      case "ItemCatNumbers":
        return itemCatNumbers.map((item) => ({
          label: item,
          value: item,
        }));

      case "CustomerHierarchies":
        const selectedCategories = groups
          ?.filter(
            (group) => group.selectedOption1.value === "CustomerHierarchies"
          )
          .map((group) => group.selectedCategory?.value);

        return arrayOfHierarchies
          ?.filter((category) => !selectedCategories.includes(category))
          .map((option, index) => ({
            label: option,
            value: option,
            index,
          }));

      default:
        return [];
    }
  };

  const getThirdSelectOptions = () => {
    if (selectedCategory) {
      const selectedOptionIndex = arrayOfHierarchies.indexOf(
        selectedCategory.value
      );

      if (selectedOptionIndex >= 0) {
        const tempFlattenArrayOfHierachyValues =
          flattenArrayOfHierachyValues.length
            ? flattenArrayOfHierachyValues
            : JSON.parse(
                sessionStorage.getItem("flattenArrayOfHierachyValues")
              );

        return tempFlattenArrayOfHierachyValues[selectedOptionIndex]?.map(
          (value) => ({
            label: value,
            value: value,
          })
        );
      }
    }
    return [];
  };

  useEffect(() => {
    const fetchAndSetItemCatNumbers = async () => {
      const indexes = await tableIndexes.getIndexes(
        "RevNavData_ItemsData_ByItemByCust",
        InsightingCustomerID
      );

      dispatch(setItemCatNumbers(indexes));
    };

    fetchAndSetItemCatNumbers();
  }, []);

  useEffect(() => {
    const filterAvailableOptions = () => {
      const selectedOptionKeys = groups.map(
        (group) => group.selectedOption1.value
      );

      let newAvailableOptions1 = dictionary[
        Language
      ]?.reportsGeneratorPage.additionalInfoSelector
        .map((option, index) => ({
          label: option.optionText,
          value: option.optionKey,
          index,
        }))
        ?.filter(
          (option) =>
            !selectedOptionKeys.includes(option.value) ||
            option.value === "CustomerHierarchies"
        );

      const selectedCategories = groups
        ?.filter(
          (group) => group.selectedOption1.value === "CustomerHierarchies"
        )
        .map((group) => group.selectedCategory?.value);

      const remainingCategories = arrayOfHierarchies?.filter(
        (category) => !selectedCategories.includes(category)
      );

      if (remainingCategories.length === 0) {
        newAvailableOptions1 = newAvailableOptions1?.filter(
          (option) => option.value !== "CustomerHierarchies"
        );
      }

      setAvailableOptions1(newAvailableOptions1);
    };

    filterAvailableOptions();
  }, [groups]);

  useEffect(() => {
    if (loggedSelectionsRef.current) {
      loggedSelectionsRef.current.scrollTop =
        loggedSelectionsRef.current.scrollHeight;
    }
  }, [groups]);

  useEffect(() => {
    if (prevReportCategoryRef.current !== reportCategory) {
      // Only reset when the category actually changes
      // Reset state
      // Reset reportFilters
      dispatch(resetReportFilters());
      prevReportCategoryRef.current = reportCategory; // Update the stored category
    }
  }, [reportCategory]);

  return (
    <div>
      <div className="report-filter-header" style={{ padding: "1rem 0 0 0" }}>
        <div className="report-svgs">{svgs.additionalInfoSVG}</div>
        <div>
          {
            dictionary[Language]?.reportsGeneratorPage.reportFiltersHeaders
              .additionalInfo
          }
        </div>
        <div className="report-filter-header-optional">{"(optional)"}</div>
      </div>
      <div className="report-specific-filter-box">
        <>
          <div style={{ maxWidth: "" }}>
            <div
              ref={loggedSelectionsRef}
              className="additional-filter-logged-selections"
            >
              {groups.map((group, groupIndex) => {
                return (
                  <div key={groupIndex}>
                    <div className="hierarchy-display-chosen-selectors">
                      <div className="additional-filter-chosen-hierarchy-div">
                        <span className="hierarchy-chosen-hierarchy-span">
                          {group.selectedOption1
                            ? group.selectedOption1.label
                            : ""}
                        </span>
                      </div>

                      {group.selectedCategory && (
                        <div className="additional-filter-chosen-hierarchy-div">
                          <span className="hierarchy-chosen-hierarchy-span">
                            {group.selectedCategory.label}
                          </span>
                        </div>
                      )}

                      <div
                        className={`additional-filter-chosen-options${
                          group.selectedCategory ? "2" : ""
                        }`}
                      >
                        {group.selectedOptions2.map((option, optionIndex) => (
                          <span
                            key={optionIndex}
                            className="additional-filter-chosen-option"
                          >
                            {option.label}
                          </span>
                        ))}
                      </div>

                      <HighlightOffIcon
                        onClick={() => handleDelete(groupIndex)}
                        style={{ color: "#bababa", cursor: "pointer" }}
                      />
                    </div>

                    {availableOptions1?.length > 0 && (
                      <div
                        style={{ background: "white", height: "11px" }}
                      ></div>
                    )}
                  </div>
                );
              })}
            </div>
            <div
              className="additional-filter-selectors-div"
              style={{
                justifyContent: !selectedOption1
                  ? "flex-start"
                  : "space-around",
              }}
            >
              {(firstOpen || selectedOption1) &&
                availableOptions1.length > 0 && (
                  <div style={{ width: "18%" }}>
                    <Select
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 7,
                          fontSize: 12,
                          direction: Language === "hebrew" ? "rtl" : "ltr",
                        }),
                      }}
                      value={selectedOption1}
                      options={availableOptions1}
                      onChange={(selected) => handleOption1Change(selected)}
                      isSearchable={true}
                      placeholder={
                        dictionary[Language].reportsGeneratorPage
                          .additionalInfoPlaceHolder
                      }
                    />
                  </div>
                )}
              {selectedOption1 &&
              selectedOption1.value === "CustomerHierarchies" ? (
                <>
                  <div style={{ width: "20.5%", margin: "0 1rem" }}>
                    <Select
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 7,
                          fontSize: 12,
                          direction: Language === "hebrew" ? "rtl" : "ltr",
                        }),
                      }}
                      closeMenuOnSelect={true}
                      value={selectedCategory}
                      options={getSecondSelectOptions()}
                      onChange={(selected) => handleCategoryChange(selected)}
                      placeholder={
                        dictionary[Language].reportsGeneratorPage
                          .additionalInfoPlaceHolder
                      }
                    />
                  </div>
                  <div style={{ width: "60.5%" }}>
                    <Select
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 7,
                          fontSize: 12,
                          direction: Language === "hebrew" ? "rtl" : "ltr",
                        }),
                      }}
                      isMulti
                      closeMenuOnSelect={false}
                      value={selectedOptions2}
                      options={getThirdSelectOptions()}
                      onChange={(selected) => handleOption2Change(selected)}
                      placeholder={
                        dictionary[Language].hierarchyBox
                          .hierarchyValuesPlaceHolder
                      }
                    />
                  </div>
                  <CheckCircleOutlineIcon
                    style={{
                      color: selectedOptions2.length > 0 ? "green" : "#BABABA",
                      cursor:
                        selectedOptions2.length > 0 ? "pointer" : "not-allowed",
                    }}
                    onClick={
                      selectedOptions2.length > 0 ? handleCheckmarkClick : null
                    }
                  />
                </>
              ) : selectedOption1 &&
                selectedOption1.value !== "CustomerHierarchies" ? (
                <>
                  <div style={{ width: "81%", margin: "0 1rem" }}>
                    <Select
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 7,
                          fontSize: 12,
                          direction: Language === "hebrew" ? "rtl" : "ltr",
                        }),
                      }}
                      isMulti
                      closeMenuOnSelect={false}
                      value={selectedOptions2}
                      options={getSecondSelectOptions()}
                      onChange={(selected) => handleOption2Change(selected)}
                      placeholder={
                        dictionary[Language].hierarchyBox
                          .hierarchyValuesPlaceHolder
                      }
                      components={
                        getSecondSelectOptions().length > 1000
                          ? { MenuList }
                          : {}
                      }
                    />
                  </div>
                  <CheckCircleOutlineIcon
                    style={{
                      color: selectedOptions2.length > 0 ? "green" : "#BABABA",
                      cursor:
                        selectedOptions2.length > 0 ? "pointer" : "not-allowed",
                    }}
                    onClick={
                      selectedOptions2.length > 0 ? handleCheckmarkClick : null
                    }
                  />
                </>
              ) : null}
            </div>
          </div>
        </>
      </div>
    </div>
  );
}

export default ReportAdditionalInfoFilter;
