import React, { useState } from "react";
import { format } from "date-fns";
import { shallowEqual, useSelector } from "react-redux";
import { dictionary } from "../../../constants/dictionary";
import svgs from "../../../utils/svgs";

function ReportDetailsSideBar() {
  const reportCategory = useSelector(
    (state) => state.reportsGeneratorPageSlice.reportCategory
  );
  const comparePeriodCheckbox = useSelector(
    (state) => state.reportsGeneratorPageSlice.comparePeriodCheckbox
  );
  const Language = useSelector(
    (state) => state.userSlice.user.metadata.Language
  );
  const reportFilters = useSelector(
    (state) => state.reportsGeneratorPageSlice.reportFilters,
    shallowEqual
  );
  const startReportDate = useSelector(
    (state) => state.reportsGeneratorPageSlice.startReportDate
  );
  const endReportDate = useSelector(
    (state) => state.reportsGeneratorPageSlice.endReportDate
  );
  const startComparedDate = useSelector(
    (state) => state.reportsGeneratorPageSlice.startComparedDate
  );
  const endComparedDate = useSelector(
    (state) => state.reportsGeneratorPageSlice.endComparedDate
  );

  // State for dropdowns
  const [isAgentsDropdownOpen, setIsAgentsDropdownOpen] = useState(false);
  const [isCustomersDropdownOpen, setIsCustomersDropdownOpen] = useState(false);
  const [isItemCatNumbersDropdownOpen, setIsItemCatNumbersDropdownOpen] =
    useState(false);
  const [
    isCustomerHierarchiesDropdownOpen,
    setIsCustomerHierarchiesDropdownOpen,
  ] = useState(false); // New state for CustomerHierarchies

  const valuesToDisplayOptions = [
    {
      key: "valueSales",
      text: dictionary[Language]?.reportsGeneratorPage.reportValuesToDisplay
        .valueSales,
    },
    {
      key: "units",
      text: dictionary[Language]?.reportsGeneratorPage.reportValuesToDisplay
        .units,
    },
    {
      key: "uniqueElements",
      text: dictionary[Language]?.reportsGeneratorPage.reportValuesToDisplay
        .uniqueElements,
    },
  ];

  const comparePeriodText =
    dictionary[Language]?.reportsGeneratorPage.comparePeriod;

  return (
    <div className="report-summary-page">
      <div className="report-details-sidebar">
        <div className="sidebar-section">
          <div className="predefined-report-title">
            <span style={{ margin: "0px 3px", fontSize: "14px" }}>
              {dictionary[Language]?.reportsGeneratorPage.reportSettings}
            </span>
          </div>
          <div className="report-filter-header">
            <div className="report-svgs">{svgs.calendarSVG}</div>
            <div>
              {
                dictionary[Language]?.reportsGeneratorPage.reportFiltersHeaders
                  .reportPeriod
              }
            </div>
          </div>
          <div className="report-specific-filter-box">
            <div>
              {startReportDate && endReportDate && (
                <div className="report-specific-filter-box-details">
                  {format(new Date(startReportDate), "MMM yyyy")} -{" "}
                  {format(new Date(endReportDate), "MMM yyyy")}
                </div>
              )}
              {comparePeriodCheckbox && (
                <>
                  <div className="report-specific-filter-box-details">
                    <span>{svgs.checkedCheckmarkSVG}</span> {comparePeriodText}
                  </div>
                  {startComparedDate && endComparedDate && (
                    <div className="report-specific-filter-box-details">
                      {format(new Date(startComparedDate), "MMM yyyy")} -{" "}
                      {format(new Date(endComparedDate), "MMM yyyy")}
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="report-specific-filter-box-details">
              {
                dictionary[Language]?.reportsGeneratorPage.reportPeriodShowBy
                  .showBy
              }{" "}
              {
                dictionary[Language]?.reportsGeneratorPage.reportPeriodShowBy[
                  reportFilters.aggregationType.toLowerCase()
                ]
              }
            </div>
          </div>
        </div>

        <div className="sidebar-section">
          <div className="report-filter-header">
            <div className="report-svgs">{svgs.tableSVG}</div>
            <div>{dictionary[Language]?.reportsGeneratorPage.dataType}</div>
          </div>
          <div className="report-specific-filter-box">
            {valuesToDisplayOptions.map(
              (option) =>
                reportFilters.ValuesToDisplay.includes(option.key) && (
                  <div
                    className="report-specific-filter-box-details"
                    key={option.key}
                  >
                    <span>{svgs.checkedCheckmarkSVG}</span> {option.text}
                  </div>
                )
            )}
          </div>
        </div>

        {(reportFilters.AgentList.length > 0 ||
          reportFilters.CustomerList.length > 0 ||
          reportFilters.ItemList.length > 0 ||
          (reportFilters.CustomersHierarchies &&
            Object.keys(reportFilters.CustomersHierarchies).length > 0)) && (
          <div className="sidebar-section">
            <div className="report-filter-header">
              <div className="report-svgs">{svgs.additionalInfoSVG}</div>
              <div>
                {
                  dictionary[Language]?.reportsGeneratorPage
                    .reportFiltersHeaders.additionalInfo
                }
              </div>
            </div>

            {/* Agents Dropdown */}
            {reportFilters.AgentList.length > 0 && (
              <div className="dropdown-container">
                <div
                  className="dropdown-header"
                  onClick={() => setIsAgentsDropdownOpen(!isAgentsDropdownOpen)}
                >
                  <span>
                    <span className="dropdown-header-count">
                      {reportFilters.AgentList.length}{" "}
                    </span>
                    {
                      dictionary[Language].reportsGeneratorPage
                        .additionalInfoSideBarHeaders.agents
                    }
                  </span>
                  {
                    <span>
                      {isAgentsDropdownOpen ? svgs.arrowUp : svgs.arrowDown}
                    </span>
                  }
                </div>
                {isAgentsDropdownOpen && (
                  <div className="dropdown-content">
                    {reportFilters.AgentList.map((agent) => (
                      <div
                        key={agent}
                        className="dropdown-item additional-filter-chosen-option"
                      >
                        {agent}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}

            {/* Customers Dropdown */}
            {reportFilters.CustomerList.length > 0 && (
              <div className="dropdown-container">
                <div
                  className="dropdown-header"
                  onClick={() =>
                    setIsCustomersDropdownOpen(!isCustomersDropdownOpen)
                  }
                >
                  <span>
                    <span className="dropdown-header-count">
                      {reportFilters.CustomerList.length}{" "}
                    </span>
                    {
                      dictionary[Language].reportsGeneratorPage
                        .additionalInfoSideBarHeaders.customers
                    }
                  </span>
                  {
                    <span>
                      {isCustomersDropdownOpen ? svgs.arrowUp : svgs.arrowDown}
                    </span>
                  }
                </div>
                {isCustomersDropdownOpen && (
                  <div className="dropdown-content">
                    {reportFilters.CustomerList.map((customer) => (
                      <div
                        key={customer}
                        className="dropdown-item additional-filter-chosen-option"
                      >
                        {customer}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}

            {/* Item Catalog Numbers Dropdown */}
            {reportFilters.ItemList.length > 0 && (
              <div className="dropdown-container">
                <div
                  className="dropdown-header"
                  onClick={() =>
                    setIsItemCatNumbersDropdownOpen(
                      !isItemCatNumbersDropdownOpen
                    )
                  }
                >
                  <span>
                    <span className="dropdown-header-count">
                      {reportFilters.ItemList.length}{" "}
                    </span>
                    {
                      dictionary[Language].reportsGeneratorPage
                        .additionalInfoSideBarHeaders.items
                    }
                  </span>
                  {
                    <span>
                      {isItemCatNumbersDropdownOpen
                        ? svgs.arrowUp
                        : svgs.arrowDown}
                    </span>
                  }
                </div>
                {isItemCatNumbersDropdownOpen && (
                  <div className="dropdown-content">
                    {reportFilters.ItemList.map((item) => (
                      <div
                        key={item}
                        className="dropdown-item additional-filter-chosen-option"
                      >
                        {item}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}

            {/* Customer Hierarchies Dropdown */}
            {reportFilters.CustomersHierarchies &&
              Object.keys(reportFilters.CustomersHierarchies).length > 0 && (
                <div className="dropdown-container">
                  <div
                    className="dropdown-header"
                    onClick={() =>
                      setIsCustomerHierarchiesDropdownOpen(
                        !isCustomerHierarchiesDropdownOpen
                      )
                    }
                  >
                    <span>
                      <span className="dropdown-header-count">
                        {Object.keys(reportFilters.CustomersHierarchies).length}{" "}
                      </span>
                      {
                        dictionary[Language].reportsGeneratorPage
                          .additionalInfoSideBarHeaders.customersGroups
                      }{" "}
                    </span>
                    {
                      <span>
                        {isCustomerHierarchiesDropdownOpen
                          ? svgs.arrowUp
                          : svgs.arrowDown}
                      </span>
                    }
                  </div>
                  {isCustomerHierarchiesDropdownOpen && (
                    <div className="dropdown-content">
                      {reportFilters.groups
                        .filter(
                          (group) =>
                            group.selectedOption1.value ===
                            "CustomerHierarchies"
                        )
                        .map((group, index) => (
                          <div key={index}>
                            <div className="">
                              {group.selectedCategory?.label}{" "}
                              {/* Display the selected category label */}
                            </div>
                            {reportFilters.CustomersHierarchies[
                              Object.keys(reportFilters.CustomersHierarchies)[
                                index
                              ]
                            ].map((item) => (
                              <div
                                key={item}
                                className="dropdown-item additional-filter-chosen-option"
                              >
                                {item}
                              </div>
                            ))}
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              )}
          </div>
        )}
      </div>

      <div className="report-summary-content">
        {/* Add your content here */}
      </div>
    </div>
  );
}

export default ReportDetailsSideBar;
