import { configureStore } from "@reduxjs/toolkit";

import userReducer from "./features/userSlice";
import dataReducer from "./features/dataSlice";
import globalReducer from "./features/globalSlice";
import tableFiltersReducer from "./features/tableFiltersSlice";
import tableSortsReducer from "./features/tableSortsSlice";
import tableLatestSortsReducer from "./features/tableLatestSortsSlice";
import tableLatestFiltersReducer from "./features/tableLatestFiltersSlice";
import homePagePropsReducer from "./features/homePagePropsSlice";
import customersOverviewReducer from "./features/customersOverviewSlice";
import testReducer from "./features/testSlice";
import salesByAgentReducer from "./features/salesByAgentSlice";
import revNavigatorReducer from "./features/revNavigatorSlice";
import reportsGeneratorPageReducer from "./features/reportsGeneratorPageSlice";

const store = configureStore({
  reducer: {
    userSlice: userReducer,
    dataSlice: dataReducer,
    homePagePropsSlice: homePagePropsReducer,
    tableFiltersSlice: tableFiltersReducer,
    tableSortsSlice: tableSortsReducer,
    tableLatestSortsSlice: tableLatestSortsReducer,
    tableLatestFiltersSlice: tableLatestFiltersReducer,
    globalSlice: globalReducer,
    customersOverviewSlice: customersOverviewReducer,
    testSlice: testReducer,
    salesByAgentSlice: salesByAgentReducer,
    revNavigatorSlice: revNavigatorReducer,
    reportsGeneratorPageSlice: reportsGeneratorPageReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
});

if (process.env.NODE_ENV === "development") {
  window.store = store;
}

export default store;
