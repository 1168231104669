import React, { useState } from "react";
import Button from "@material-ui/core/Button";

import Select from "react-select";
import { FILTER_SEPARATOR } from "../../utils/filters";
import { operatorsMapForFilters } from "../../constants/filtersText-map";
import { shallowEqual, useSelector } from "react-redux";
import { dictionary } from "../../constants/dictionary";

export const operators = [
  { id: "equals", label: "=", textForPicker: "=" },
  {
    id: "greater",
    label: ">",
    textForPicker: "english",
  },
  {
    id: "lower",
    label: "<",
    textForPicker: "english",
  },
];

const PercentageColumnFilter = ({ column }) => {
  const { setFilter } = column;

  const [value, setValue] = useState("");

  const userSlice = useSelector((state) => state.userSlice, shallowEqual);
  const {
    user: {
      metadata: { Language },
    },
  } = userSlice;
  const operators = [
    { id: "equals", label: "=", textForPicker: "=" },
    {
      id: "greater",
      label: ">",
      textForPicker: operatorsMapForFilters[Language]?.[">"],
    },
    {
      id: "lower",
      label: "<",
      textForPicker: operatorsMapForFilters[Language]?.["<"],
    },
  ];
  const [operator, setOperator] = useState(operators[0]);

  const applyFilter = (e) => {
    if (value && operator) {
      setFilter(`${operator.id}${FILTER_SEPARATOR}${value.replace("%", "")}`);
      setValue("");
    }
    e.preventDefault();
  };

  return (
    <>
      <div
        id="operator-select-label"
        style={{ right: Language === "hebrew" ? -50 : "initial" }}
      >
        {" "}
        {dictionary[Language]?.filterPicker.operator}
      </div>
      <div style={{ margin: "5px 0px 15px" }}>
        <Select
          id="operator-select"
          value={operator}
          onChange={(e) => {
            const operator = operators.find(({ id }) => id === e.value);
            setOperator(operator);
          }}
          options={operators.map((operator) => ({
            value: operator.id,
            label: operator.textForPicker,
          }))}
        />
      </div>
      <input
        style={{ width: "100%", marginTop: "0.5rem" }}
        type="text"
        id="value"
        value={value ? value : "%"}
        onChange={(e) => setValue(e.target.value)}
        placeholder={`${dictionary[Language]?.filterPicker.value}`}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "0.5rem",
        }}
      >
        {" "}
        <Button
          size="small"
          type="submit"
          color="primary"
          variant="contained"
          onClick={applyFilter}
          disabled={!(value && operator)}
          className="filter-picker-apply-button"
        >
          {dictionary[Language].hierarchyBox.apply}
        </Button>
      </div>
    </>
  );
};

export default PercentageColumnFilter;
