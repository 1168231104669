import Box from "@material-ui/core/Box";
import isEmpty from "lodash/isEmpty";
import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useFilters, useSortBy, useTable } from "react-table";
import { filterTypes, getColumnByAccessor } from "../../../utils/filters";

import { setSalesReviewTableFilters } from "../../../store/features/tableFiltersSlice";
import { setSalesReviewTableSorts } from "../../../store/features/tableSortsSlice";
import { setMenuPosition } from "../../../store/features/salesByAgentSlice";
import { engGROUPED_COLUMNS } from "./ColumnsFiles/engColumns";
import { hebGROUPED_COLUMNS } from "./ColumnsFiles/hebColumns";

import { dictionary } from "../../../constants/dictionary";
import {
  setCurrencyForGroupedTableHeaders,
  isSorted,
} from "../../../utils/helpers";
import "./SalesReviewTable.css";
import SalesReviewAboveTable from "./SalesReviewAboveTable";
import SalesReviewFilters from "./SalesReviewFilters";
import SalesReviewMenu from "./SalesReviewMenu";

const noOppsTd = {
  height: "25vh",
  textAlign: "center",
  marginTop: "2rem",
  background: "#EFEDED",
  border: "none",
  width: "30vw",
  margin: "0 1rem",
};

function SalesReviewTable({ setSalesPeriod, salesPeriod }) {
  const dispatch = useDispatch();
  const location = useLocation();

  const tableRef = useRef(null);

  const salesReviewTableFilters = useSelector(
    (state) => state.tableFiltersSlice.salesReviewTableFilters,
    shallowEqual
  );
  const salesReviewTableSorts = useSelector(
    (state) => state.tableSortsSlice.salesReviewTableSorts,
    shallowEqual
  );
  const salesByAgentData = useSelector(
    (state) => state.salesByAgentSlice.salesByAgentData,
    shallowEqual
  );

  const userSlice = useSelector((state) => state.userSlice, shallowEqual);

  const {
    user: {
      metadata: { Currency, Language },
    },
  } = userSlice;

  const columns = useMemo(
    () =>
      Language === "hebrew"
        ? setCurrencyForGroupedTableHeaders(
            Currency,
            hebGROUPED_COLUMNS,
            Language
          )
        : Language === "english"
        ? setCurrencyForGroupedTableHeaders(
            Currency,
            engGROUPED_COLUMNS,
            Language
          )
        : setCurrencyForGroupedTableHeaders(
            Currency,
            engGROUPED_COLUMNS,
            Language
          ),
    []
  );

  const data = useMemo(() => salesByAgentData, [salesByAgentData]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    state,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: {
        sortBy: salesReviewTableSorts,
      },
      autoResetPage: false,
      autoResetExpanded: false,
      autoResetGroupBy: false,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false,
    },
    useFilters,
    useSortBy
  );

  const filterableColumns = [
    columns[0],
    ...columns[1].columns,
    ...columns[2].columns,
    ...columns[3].columns,
  ];

  const handleRightClick = (e, row) => {
    // if (menuPosition) {
    //   return;
    // }
    dispatch(
      setMenuPosition({
        top: e.clientY,
        left: e.clientX,
      })
    );
    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(
    function onSortsChanged() {
      dispatch(setSalesReviewTableSorts(state.sortBy));
    },
    [state.sortBy]
  );

  useEffect(
    function onFiltersChanged() {
      dispatch(setSalesReviewTableFilters(state.filters));
    },
    [state.filters]
  );

  useEffect(
    function applyFilters() {
      sessionStorage.setItem(
        "salesByAgentData",
        JSON.stringify(salesByAgentData)
      );
      if (!isEmpty(salesReviewTableFilters)) {
        salesReviewTableFilters?.forEach(({ id, value }) => {
          const column = getColumnByAccessor(id, headerGroups[1].headers);
          if (column && column.canFilter && column.setFilter) {
            column.setFilter(value);
          }
        });
      }
    },
    [location, data]
  );

  return (
    <>
      <SalesReviewAboveTable data={data} />
      <SalesReviewFilters
        filters={state.filters}
        headers={headerGroups[1].headers}
        columns={filterableColumns}
        disabled={data?.length > 0 ? false : true}
      />
      <div
        style={{ direction: Language === "hebrew" ? "rtl" : "ltr" }}
        ref={tableRef}
        id="sales-review-table"
        className="sales-review-table-div"
        // className={`${
        //   salesByAgentData?.length === 0
        //     ? "no-opps-background"
        //     : "sales-review-table-div"
        // }`}
      >
        <table
          {...getTableProps()}
          className="sales-review-table"
          style={{
            background: salesByAgentData?.length === 0 ? "#EFEDED" : "white",
          }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span> {isSorted(column)}</span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps}>
            <SalesReviewMenu
              tableRef={tableRef}
              salesPeriod={salesPeriod}
              setSalesPeriod={setSalesPeriod}
              rows={rows}
              filterableColumns={filterableColumns}
            />
            {salesByAgentData?.length === 0 ? (
              <tr>
                <td style={noOppsTd}>
                  {dictionary[Language].salesReviewTable.noData}
                </td>
              </tr>
            ) : (
              rows.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    onContextMenu={(e) => handleRightClick(e, row)}
                  >
                    {row.cells.map((cell) => {
                      try {
                        cell.value?.includes("+");
                      } catch (e) {
                        console.log(cell.value);
                        console.log(cell);
                      }

                      return ["YA", "P3M", "P6M", "P9M"].includes(
                        cell.column.id
                      ) &&
                        cell.value?.includes("+") &&
                        parseInt(cell.value?.slice(1)) > 25 ? (
                        <td
                          style={{ color: "rgb(68 206 68)" }}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      ) : ["YA", "P3M", "P6M", "P9M"].includes(
                          cell.column.id
                        ) &&
                        cell.value?.includes("-") &&
                        parseInt(cell.value?.slice(1)) > 25 ? (
                        <td style={{ color: "red" }} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      ) : (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}
export default memo(SalesReviewTable);
