import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartColumn } from "@fortawesome/free-solid-svg-icons";

import "./AdditionalData.css";
import { setProductPurchasedTableMode } from "../../store/features/dataSlice";

export const AdditionalData = ({
  cell,
  salesByItem,
  setCoordinates,
  setProductName,
}) => {
  const dispatch = useDispatch();
  const staticAppDate = useSelector((state) => state.globalSlice.staticAppDate);
  const date = staticAppDate ? new Date(staticAppDate) : new Date();
  const handleKeyDataClick = () => {
    let actualYearProduct = salesByItem.find(
      (item) =>
        item.ItemCatalogNumber === cell.row.values.Code &&
        parseInt(item.YearOfData) === date.getFullYear()
    );
    let yearAgoProduct = salesByItem.find(
      (item) =>
        item.ItemCatalogNumber === cell.row.values.Code &&
        parseInt(item.YearOfData) === date.getFullYear() - 1
    );
    let actualYearCoordinates = [];
    let yearAgoCoordinates = [];

    for (let i = 1; i < 13; i++) {
      if (actualYearProduct)
        actualYearCoordinates.push(actualYearProduct[`Sales_${i}`]);
      if (yearAgoProduct) yearAgoCoordinates.push(yearAgoProduct[`Sales_${i}`]);
    }
    setProductName(cell.row.values.ProductName);
    setCoordinates([actualYearCoordinates, yearAgoCoordinates]);
    dispatch(setProductPurchasedTableMode("regular"));
  };
  return (
    <div className="additional-data-div">
      <span className="additional-data-icon-span" onClick={handleKeyDataClick}>
        <FontAwesomeIcon icon={faChartColumn} size="lg" />
      </span>
      <span className="additional-data-span">{cell.render("Cell")}</span>
    </div>
  );
};
