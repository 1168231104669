import isString from "lodash/isString";

function numberWithCommas(x) {
  return (isString(x) ? x : x?.toString())?.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  );
}

function toNumber(x) {
  // eslint-disable-next-line
  if (typeof x === "string") if (x === "-") return null;
  if (typeof x === "string") return Number(x?.replace(/(,|%)/g, ""));
  return x;
}

function kFormat(x) {
  const absNum = Math.abs(x);
  if (absNum >= 1000) {
    return (x < 0 ? "-" : "") + numberWithCommas(parseInt(absNum / 1000)) + "K";
  } else {
    return x.toString();
  }
}

function mFormat(x) {
  const absNum = Math.abs(x);

  const formatNumber = (num, divisor, suffix) => {
    let result = num / divisor;
    // Convert to string to check the length
    let resultStr = result.toString();
    // Determine the condition for adding decimals based on the length
    if (resultStr.split(".")?.[0]?.length > 3) {
      // Check length without counting the decimal point
      result = Math.round(result);
    } else {
      // Keep one decimal place if the number without the decimal is not longer than 3 digits
      result = parseFloat(result.toFixed(1));
    }

    // Remove trailing ".0" after the rounding adjustment
    resultStr = result.toString().replace(/\.0$/, "");
    return numberWithCommas(resultStr) + suffix;
  };

  if (absNum >= 1000000) {
    return (x < 0 ? "-" : "") + formatNumber(absNum, 1000000, "M");
  } else if (absNum >= 1000) {
    return (x < 0 ? "-" : "") + formatNumber(absNum, 1000, "K");
  } else {
    // For numbers less than 1000, no decimal place to worry about
    return x.toString();
  }
}

const numberUtils = {
  numberWithCommas,
  toNumber,
  kFormat,
  mFormat,
};

export default numberUtils;
