import axios from "axios";
import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Provider, useDispatch, useSelector } from "react-redux";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import store from "./store";
import theme from "./themes";
import dateUtils from "./utils/date";
import Support from "./pages/Support";
import numberUtils from "./utils/number";
import opportunitiesService from "./services/opportunities";
import { setuserMetadata } from "./store/features/userSlice";
import {
  setKeyOpportunitiesTableData,
  setCustomersForNavbarData,
  setAllKeyOpportunitiesForCustomerPage,
  setCustomersOverviewData,
  setKeyOpportunitiesDisplayType,
  setKeyOpportunitiesTableDataCopy,
} from "./store/features/dataSlice";

import NotFound from "./pages/NotFound";
import Navbar from "./components/Navbar/Navbar";
import HomePage from "./pages/HomePage/HomePage";
import LoginPage from "./pages/LoginPage/LoginPage";
import { CustomerPage } from "./pages/CustomerPage/CustomerPage";
import _ from "lodash";
import domain from "./config/domain";
import useResolution from "./hooks/useResolution";
import { aggInsightsMap } from "./constants/insights-map";

import "./App.css";
import "./normalize.css";
import { ReportsPage } from "./pages/ReportsPage/ReportsPage";
import CustomersOverviewService from "./services/customersOverview";
import { isOnGoingInsight } from "./utils/helpers";
import LoadingPage from "./pages/LoadingPage/LoadingPage";
import { setStaticAppDate } from "./store/features/globalSlice";
import { CustomerDetailsPage } from "./pages/CustomerDetailsPage/CustomerDetailsPage";
import RevNavigatorPage from "./pages/RevNavigatorPage/RevNavigatorPage";
import RevNavigatorDetailsPage from "./pages/RevNavigatorDetailsPage/RevNavigatorDetailsPage";
import ReportsGeneratorPage from "./pages/ReportsGeneratorPage/ReportsGeneratorPage";
import ReportsGeneratorPageDetails from "./pages/ReportsGeneratorDetailsPage/ReportsGeneratorDetailsPage";
import { reqAuth } from "./constants/reqAuth";

const isNewMultipleInsights = (data) => {
  if (data?.length > 0) {
    const dates = data.map(({ CreatedDate }) =>
      CreatedDate?.split("_").reverse().join("-")
    );
    return dateUtils.sortDates(dates);
  }
  return null;
};

const calcProgressForMultipleAggs = (arr) => {
  let totalAchievedValues = arr.reduce(
    (prev, curr) => prev + parseInt(curr.AchievedValue),
    0
  );
  let totalAggPotentialValues = arr.reduce(
    (prev, curr) => prev + parseInt(curr.AggPotentialValue?.replace(/,/g, "")),
    0
  );
  return (totalAchievedValues / totalAggPotentialValues) * 100;
};

const AppHooks = ({ children }) => {
  const { dataSlice } = useSelector((state) => state);
  // const staticAppDate = useSelector((state) => state.globalSlice.staticAppDate);
  const staticAppDate =
    process.env.REACT_APP_NODE_ENV === "ir-development"
      ? process.env.REACT_APP_DEMO_DATE + "T15:00:00.000Z"
      : false;
  const date = staticAppDate ? new Date(staticAppDate) : new Date();
  const dispatch = useDispatch();

  const { user, logout } = useAuth0();
  const { keyOpportunitiesTableData } = dataSlice;

  useResolution();
  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV === "ir-development") {
      dispatch(
        setStaticAppDate(process.env.REACT_APP_DEMO_DATE + "T15:00:00.000Z")
      );
    }
    if (user?.email) {
      async function fetchInitialData() {
        try {
          const { data } = await axios.request({
            method: "POST",
            url: `${domain}/UsersMetadata_Main/UserEmail/${user.email?.toLowerCase()}`,
            headers: { authorization: reqAuth },
          });

          const userMetadata = data?.Items[0]; // fails here
          if (userMetadata) {
            const removePrefix = (value) => {
              if (typeof value === "string" && value.startsWith("NO__")) {
                return value?.slice(4); // Remove first 3 characters ("NO__")
              }
              return value;
            };
            // Create a new object with the "NO__" prefix removed from each value
            const modifiedMetadata = Object.entries(userMetadata).reduce(
              (acc, [key, value]) => {
                acc[key] = removePrefix(value);
                return acc;
              },
              {}
            );

            const lowerCaseMetaData = {
              ...modifiedMetadata,
              AgentPermission: modifiedMetadata.AgentPermission?.toLowerCase(),
              Language: modifiedMetadata.Language?.toLowerCase(),
              Currency: modifiedMetadata.Currency?.toLowerCase(),
              DateFormat: modifiedMetadata.DateFormat?.toLowerCase(),
              UserType: modifiedMetadata.UserType?.toLowerCase(),
              ShowCustomersOverview_Flag:
                modifiedMetadata.ShowCustomersOverview_Flag?.toLowerCase(),
              ShowKeyOpportunities_Flag:
                modifiedMetadata.ShowKeyOpportunities_Flag?.toLowerCase(),
              ShowCustomersSales_Flag:
                modifiedMetadata.ShowCustomersSales_Flag?.toLowerCase(),
              ShowGrowthAnalysis_Flag:
                modifiedMetadata.ShowGrowthAnalysis_Flag?.toLowerCase(),
              ApprovedIPsList: modifiedMetadata.ApprovedIPsList?.replace(
                /[\[\]]/g,
                ""
              )
                .split(";")
                .map((ip) => ip.trim()),
            };
            dispatch(setuserMetadata(lowerCaseMetaData));
            sessionStorage.setItem(
              "userMetadata",
              JSON.stringify(lowerCaseMetaData)
            );
          } else logout();

          let {
            ManagerID,
            InsightingCustomerID,
            AgentPermission,
            AgentID,
            Language,
            UserType,
            TeamAgentsIDs,
          } = JSON.parse(sessionStorage.getItem("userMetadata"));

          ManagerID = ManagerID?.split("+");
          let Items = [];
          if (UserType === "teamleader" && AgentPermission === "teamonly") {
            const agents = TeamAgentsIDs?.split("+");
            const response =
              await opportunitiesService.getKeyOpportunitiesTableDataByTeamLeader(
                agents,
                InsightingCustomerID,
                date
              );
            if (response) Items.push(...response);
          } else if (UserType === "agent" && AgentPermission === "agentonly") {
            const response =
              await opportunitiesService.getKeyOpportunitiesTableDataByAgent(
                AgentID,
                InsightingCustomerID,
                date
              );
            if (response) Items.push(...response);
          } else {
            const response =
              await opportunitiesService.getKeyOpportunitiesTableData(
                ManagerID,
                InsightingCustomerID,
                date
              );
            if (response) Items.push(...response);
          }

          const specificKeyOpportunitiesTableData = Items?.filter(
            (item) =>
              item.InsightType === "Insight1_agg" ||
              item.InsightType === "Insight2_agg" ||
              item.InsightType === "Insight3_agg" ||
              item.InsightType === "Insight5_agg" ||
              item.InsightType === "Insight1During_agg" ||
              item.InsightType === "Insight2During_agg" ||
              item.InsightType === "Insight3During_agg" ||
              item.InsightType === "Insight4During_agg" ||
              item.InsightType === "Insight5During_agg"
          ).map((opportunity) => ({
            ...opportunity,
            CustomerName: opportunity.CustomerName.replace(/"|'|@/g, ""),
            OriginalInsightType: [opportunity.InsightType],
            InsightType: isOnGoingInsight(opportunity.CreatedDate)
              ? aggInsightsMap[Language][opportunity.InsightType] +
                aggInsightsMap[Language].New
              : aggInsightsMap[Language][opportunity.InsightType],
            CreatedDate: opportunity.CreatedDate,
            IsNewInsight: isOnGoingInsight(opportunity.CreatedDate),
            // AggPotentialValue: parseInt(opportunity.AggPotentialValue),
            AggPotentialValue: numberUtils.numberWithCommas(
              parseInt(opportunity.AggPotentialValue)
            ),
            CalcProgress: opportunity.CalcProgress * 100,
          }));

          let multipleInsightsKeyOpportunitiesTableData = [];

          const multipleOriginalInsightsConcat = (arr) => {
            let originalInsightsArr = [];
            arr?.forEach((insight) => {
              originalInsightsArr.push(...insight.OriginalInsightType);
            });
            return originalInsightsArr;
          };

          const multipleInsightsConcat = (arr) => {
            if (
              arr.some((opportunity) =>
                opportunity.OriginalInsightType?.includes("Insight2_agg")
              )
            )
              return aggInsightsMap[Language].Insight2_agg;

            if (
              arr.some((opportunity) =>
                opportunity.OriginalInsightType?.includes("Insight2During_agg")
              )
            )
              return aggInsightsMap[Language].Insight2During_agg;

            if (
              arr.some(
                (opportunity) =>
                  (opportunity.OriginalInsightType?.includes(
                    "Insight1During_agg"
                  ) ||
                    opportunity.OriginalInsightType?.includes(
                      "Insight5During_agg"
                    )) &&
                  arr.some((opportunity) =>
                    opportunity.OriginalInsightType?.includes(
                      "Insight4During_agg"
                    )
                  )
              )
            ) {
              return arr
                ?.filter(
                  (opportunity) =>
                    !opportunity.OriginalInsightType?.includes(
                      "Insight4During_agg"
                    )
                )
                .reduce((prev, curr) => {
                  return prev?.length > 0
                    ? prev + "|" + curr.InsightType
                    : prev + curr.InsightType;
                }, "");
            }

            return arr.reduce((prev, curr) => {
              return prev?.length > 0
                ? prev + "|" + curr.InsightType
                : prev + curr.InsightType;
            }, "");
          };

          specificKeyOpportunitiesTableData?.forEach((newKeyOpp) => {
            if (
              !multipleInsightsKeyOpportunitiesTableData.some((item) =>
                item.some(
                  (innerItem) => innerItem.CustomerID === newKeyOpp.CustomerID
                )
              )
            ) {
              multipleInsightsKeyOpportunitiesTableData.push(
                specificKeyOpportunitiesTableData?.filter(
                  (keyOpp) => keyOpp.CustomerID === newKeyOpp.CustomerID
                )
              );
            }
          });

          let finalDataForKeyOpportunitiesTable = [];
          multipleInsightsKeyOpportunitiesTableData?.forEach((arr) => {
            arr?.length > 1
              ? finalDataForKeyOpportunitiesTable.push({
                  ...arr[0],
                  AggPotentialValue: arr.some((opportunity) =>
                    opportunity.OriginalInsightType?.includes("Insight2_agg")
                  )
                    ? numberUtils.numberWithCommas(
                        arr.find((item) =>
                          item.OriginalInsightType?.includes("Insight2_agg")
                        ).AggPotentialValue
                      )
                    : arr.some((opportunity) =>
                        opportunity.OriginalInsightType?.includes(
                          "Insight2During_agg"
                        )
                      )
                    ? numberUtils.numberWithCommas(
                        arr.find((item) =>
                          item.OriginalInsightType?.includes(
                            "Insight2During_agg"
                          )
                        ).AggPotentialValue
                      )
                    : arr.some(
                        (opportunity) =>
                          opportunity.OriginalInsightType?.includes(
                            "Insight1During_agg"
                          ) ||
                          opportunity.OriginalInsightType?.includes(
                            "Insight5During_agg"
                          )
                      ) &&
                      arr.some((opportunity) =>
                        opportunity.OriginalInsightType?.includes(
                          "Insight4During_agg"
                        )
                      )
                    ? numberUtils.numberWithCommas(
                        arr
                          ?.filter(
                            (item) =>
                              !item.OriginalInsightType?.includes(
                                "Insight4During_agg"
                              )
                          )
                          .reduce(
                            (prev, curr) =>
                              prev +
                              parseInt(
                                curr.AggPotentialValue?.includes(",")
                                  ? curr.AggPotentialValue?.replaceAll(",", "")
                                  : curr.AggPotentialValue
                              ),
                            0
                          )
                      )
                    : numberUtils.numberWithCommas(
                        arr.reduce(
                          (prev, curr) =>
                            prev +
                            parseInt(
                              curr.AggPotentialValue?.includes(",")
                                ? curr.AggPotentialValue?.replaceAll(",", "")
                                : curr.AggPotentialValue
                            ),
                          0
                        )
                      ),
                  CalcProgress: arr.some((opportunity) =>
                    opportunity.OriginalInsightType?.includes("Insight2_agg")
                  )
                    ? arr.find((item) =>
                        item.OriginalInsightType?.includes("Insight2_agg")
                      ).CalcProgress
                    : arr.some((opportunity) =>
                        opportunity.OriginalInsightType?.includes(
                          "Insight2During_agg"
                        )
                      )
                    ? arr.find((item) =>
                        item.OriginalInsightType?.includes("Insight2During_agg")
                      ).CalcProgress
                    : arr.some(
                        (opportunity) =>
                          opportunity.OriginalInsightType?.includes(
                            "Insight1During_agg"
                          ) ||
                          opportunity.OriginalInsightType?.includes(
                            "Insight5During_agg"
                          )
                      ) &&
                      arr.some((opportunity) =>
                        opportunity.OriginalInsightType?.includes(
                          "Insight4During_agg"
                        )
                      )
                    ? calcProgressForMultipleAggs(
                        arr?.filter(
                          (item) =>
                            !item.OriginalInsightType?.includes(
                              "Insight4During_agg"
                            )
                        )
                      )
                    : calcProgressForMultipleAggs(arr),
                  InsightType: arr.some((item) =>
                    isOnGoingInsight(item.CreatedDate)
                  )
                    ? multipleInsightsConcat(arr) + aggInsightsMap[Language].New
                    : multipleInsightsConcat(arr),
                  Priority: arr.some((opp) => opp.Priority === "1")
                    ? "1"
                    : arr.some((opp) => opp.Priority === "-1")
                    ? "-1"
                    : "0",
                  CreatedDate: isNewMultipleInsights(arr)?.split("/").join("_"),
                  IsNewInsight: isOnGoingInsight(arr[0].CreatedDate),
                  OriginalInsightType: multipleOriginalInsightsConcat(arr),
                })
              : finalDataForKeyOpportunitiesTable.push({ ...arr[0] });
          });

          dispatch(setAllKeyOpportunitiesForCustomerPage(Items));
          dispatch(
            setKeyOpportunitiesTableData(finalDataForKeyOpportunitiesTable)
          );
          dispatch(
            setKeyOpportunitiesTableDataCopy(finalDataForKeyOpportunitiesTable)
          );
          dispatch(
            setKeyOpportunitiesDisplayType(
              finalDataForKeyOpportunitiesTable?.length > 0
                ? "tableData"
                : "noData"
            )
          );
          // console.time("customersOverview - done fetching trends");

          const customersOverviewDataRes =
            await CustomersOverviewService.getCustomersOverview(
              InsightingCustomerID,
              date
            );
          dispatch(setCustomersOverviewData(customersOverviewDataRes));
          // console.timeEnd("customersOverview - done fetching trends");

          const customersList = customersOverviewDataRes.Items.map((item) => {
            return {
              id: item.CustomerID,
              customerName: item.CustomerName,
              value: `${item.CustomerName} ${item.CustomerID}`,
              label: `${item.CustomerName?.replace(/"|'|@/g, "")} (${
                item.CustomerID
              })`,
              serviceDays: item.ServiceDays,
              AgentID: item.AgentID,
              AgentName: item.AgentName,
            };
          });
          dispatch(setCustomersForNavbarData(customersList));
          // console.log("REACT_VERSION", React.version);
        } catch (error) {
          console.log(error.message);
          console.log(error.stack);
        }
      }
      if (keyOpportunitiesTableData?.length < 1) fetchInitialData();
    }
  }, [user, dispatch]);

  return <>{children}</>;
};

function App() {
  const { isLoading, isAuthenticated } = useAuth0();

  return isLoading ? (
    <LoadingPage />
  ) : (
    <div>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <AppHooks>
            <BrowserRouter scrollRestoration="manual">
              {isAuthenticated ? <Navbar /> : <LoginPage />}
              <Switch>
                <Route
                  exact
                  path="/"
                  component={() => isAuthenticated && <HomePage />}
                />
                <Route
                  exact
                  path="/support"
                  component={() => isAuthenticated && <Support />}
                />
                <Route
                  exact
                  path="/customer"
                  component={() => isAuthenticated && <CustomerPage />}
                />
                <Route
                  exact
                  path="/customer/details"
                  component={() => isAuthenticated && <CustomerDetailsPage />}
                />
                <Route
                  exact
                  path="/opportunities"
                  component={() => isAuthenticated && <ReportsPage />}
                />
                <Route
                  exact
                  path="/reportsPage"
                  component={() => isAuthenticated && <ReportsGeneratorPage />}
                />
                <Route
                  exact
                  path="/reportsPage/details"
                  component={() =>
                    isAuthenticated && <ReportsGeneratorPageDetails />
                  }
                />
                <Route
                  exact
                  path="/revNav"
                  component={() => isAuthenticated && <RevNavigatorPage />}
                />
                <Route
                  exact
                  path="/revNav/details"
                  component={() =>
                    isAuthenticated && <RevNavigatorDetailsPage />
                  }
                />
                <Route component={NotFound} />
                <Redirect from="/customer" to="/" />
              </Switch>
            </BrowserRouter>
          </AppHooks>
        </ThemeProvider>
      </Provider>
    </div>
  );
}

export default App;
