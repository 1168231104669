import Box from "@material-ui/core/Box";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useFilters, useSortBy, useTable } from "react-table";

import isEmpty from "lodash/isEmpty";
import { dictionary } from "../../../constants/dictionary";
import { setProductPurchasedTableSorts } from "../../../store/features/tableLatestSortsSlice";
import {
  setProductPurchasedAnalyzeTableFilters,
  setProductPurchasedTableFilters,
} from "../../../store/features/tableLatestFiltersSlice";
import { filterTypes, getColumnByAccessor } from "../../../utils/filters";
import {
  setCurrencyForTableHeaders,
  usDateFormat,
  getRowsCsv,
  isSorted,
} from "../../../utils/helpers";
import FilterPicker from "../../Filters/FilterPicker";
import FiltersList from "../../Filters/FiltersList";
import { engCOLUMNS } from "./ColumnsFiles/engColumns";
import { hebCOLUMNS } from "./ColumnsFiles/hebColumns";
import { Menu, MenuItem } from "@material-ui/core";
import NestedMenuItem from "material-ui-nested-menu-item";
import { CSVLink } from "react-csv";
import pdfUtils from "../../../utils/pdf";
import AnalyzePicker from "../../Filters/AnalyzePicker";
import {
  setProductPurchasedTableMode,
  setProductsAnalyzeDimension,
} from "../../../store/features/dataSlice";
import { currencyMap } from "../../../constants/currency-map";
import "./ProductPurchasedTable.css";
import { setShowCustomerPageFilters } from "../../../store/features/tableFiltersSlice";

const analyzeColumns = ["ProductName", "Code", "reportPeriod", "LastOrderDate"];
const analyzeColumnsWithComparison = [
  "ProductName",
  "Code",
  "reportPeriod",
  "compareToPeriod",
  "changeBetweenPeriods",
  "LastOrderDate",
];
const productPurchasedColumns = [
  "ProductName",
  "Code",
  "Value",
  "Sales",
  "Units",
  "LastOrderDate",
  "OpenOrders",
  "Probability",
];

export const ProductPurchasedTable = ({
  productPurchasedData,
  setSalesPeriod,
  setCoordinates,
  salesByItem,
  setProductName,
  salesPeriod,
  CustomerID,
  analyzeMode,
  setAnalyzeMode,
}) => {
  const productPurchasedTableFilters = useSelector(
    (state) => state.tableFiltersSlice.productPurchasedTableFilters,
    shallowEqual
  );
  const showCustomerPageFilters = useSelector(
    (state) => state.tableFiltersSlice.showCustomerPageFilters,
    shallowEqual
  );
  const productPurchasedTableSorts = useSelector(
    (state) => state.tableSortsSlice.productPurchasedTableSorts,
    shallowEqual
  );
  const latestProductPurchasedTableSorts = useSelector(
    (state) => state.tableLatestSortsSlice.latestProductPurchasedTableSorts,
    shallowEqual
  );
  const latestProductPurchasedTableFilters = useSelector(
    (state) => state.tableLatestFiltersSlice.latestProductPurchasedTableFilters,
    shallowEqual
  );
  const latestProductPurchasedAnalyzeTableFilters = useSelector(
    (state) =>
      state.tableLatestFiltersSlice.latestProductPurchasedAnalyzeTableFilters,
    shallowEqual
  );
  const staticAppDate = useSelector((state) => state.globalSlice.staticAppDate);
  const productPurchasedTableMode = useSelector(
    (state) => state.dataSlice.productPurchasedTableMode
  );
  const productsAnalyzeDates = useSelector(
    (state) => state.dataSlice.productsAnalyzeDates,
    shallowEqual
  );
  const productsAnalyzeDimension = useSelector(
    (state) => state.dataSlice.productsAnalyzeDimension,
    shallowEqual
  );

  const date = staticAppDate ? new Date(staticAppDate) : new Date();
  // const [lastUpdate, setLastUpdate] = useState(null);
  const [headersForFilterPicker, setHeadersForFilterPicker] = useState(null);
  const [menuPosition, setMenuPosition] = useState(null);
  const [selectedRow, setSelectedRow] = useState({});
  const [prevProductPurchasedTableMode, setPrevProductPurchasedTableMode] =
    useState("regular");
  const [pdfAvailable, setPdfAvailable] = useState(false);

  const groupedHeaderRef = useRef(null);
  const analyzeButtonRef = useRef(null);
  const tableRef = useRef(null);

  const dispatch = useDispatch();

  const { Currency, Language, DateFormat } = JSON.parse(
    sessionStorage.getItem("userMetadata")
  );

  const columns = useMemo(
    () =>
      productPurchasedTableMode === "analyze" &&
      productsAnalyzeDates[1]?.some((arr) => arr === "null")
        ? Language === "hebrew"
          ? setCurrencyForTableHeaders(Currency, hebCOLUMNS, Language)?.filter(
              (column) => analyzeColumns?.includes(column.accessor)
            )
          : Language === "english"
          ? setCurrencyForTableHeaders(Currency, engCOLUMNS, Language)?.filter(
              (column) => analyzeColumns?.includes(column.accessor)
            )
          : setCurrencyForTableHeaders(Currency, engCOLUMNS, Language)?.filter(
              (column) => analyzeColumns?.includes(column.accessor)
            )
        : productPurchasedTableMode === "analyze" &&
          productsAnalyzeDates[1]?.every((arr) => arr !== "null")
        ? Language === "hebrew"
          ? setCurrencyForTableHeaders(Currency, hebCOLUMNS, Language)?.filter(
              (column) => analyzeColumnsWithComparison?.includes(column.accessor)
            )
          : Language === "english"
          ? setCurrencyForTableHeaders(Currency, engCOLUMNS, Language)?.filter(
              (column) => analyzeColumnsWithComparison?.includes(column.accessor)
            )
          : setCurrencyForTableHeaders(Currency, engCOLUMNS, Language)?.filter(
              (column) => analyzeColumnsWithComparison?.includes(column.accessor)
            )
        : Language === "hebrew"
        ? setCurrencyForTableHeaders(Currency, hebCOLUMNS, Language)?.filter(
            (column) => productPurchasedColumns?.includes(column.accessor)
          )
        : Language === "english"
        ? setCurrencyForTableHeaders(Currency, engCOLUMNS, Language)?.filter(
            (column) => productPurchasedColumns?.includes(column.accessor)
          )
        : setCurrencyForTableHeaders(Currency, engCOLUMNS, Language)?.filter(
            (column) => productPurchasedColumns?.includes(column.accessor)
          ),
    [Currency, Language, productPurchasedTableMode, productsAnalyzeDates]
  );

  const [filterPickerVisible, setFilterPickerVisible] = useState(null);
  const [analyzePickerVisible, setAnalyzePickerVisible] = useState(null);
  const data = useMemo(() => productPurchasedData, [productPurchasedData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state,
    prepareRow,
    setAllFilters,
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      // initialState: {
      //   sortBy: productPurchasedTableSorts,
      // },
      autoResetPage: false,
      autoResetExpanded: false,
      autoResetGroupBy: false,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false,
    },
    useFilters,
    useSortBy
  );

  const handlePDFExportToClick = () => {
    pdfUtils.download(tableRef.current, `${CustomerID}-products`);
    setMenuPosition(null);
  };

  const handleRightClick = (e, row, rowsLength) => {
    setPdfAvailable(rowsLength < 200 ? true : false);
    if (menuPosition) {
      return;
    }
    setSelectedRow(row);
    e.preventDefault();
    setMenuPosition({
      top: e.clientY,
      left: e.clientX,
    });
  };

  const handleRowClick = (row, productsAnalyzeDimension = "value") => {
    setProductName(row.values.ProductName);
    let actualYearProduct = salesByItem.find(
      (item) =>
        item.ItemCatalogNumber === row.values.Code &&
        parseInt(item.YearOfData) === date.getFullYear()
    );
    let yearAgoProduct = salesByItem.find(
      (item) =>
        item.ItemCatalogNumber === row.values.Code &&
        parseInt(item.YearOfData) === date.getFullYear() - 1
    );
    let actualYearCoordinates = [];
    let yearAgoCoordinates = [];

    for (let i = 1; i < 13; i++) {
      if (actualYearProduct)
        actualYearCoordinates.push(
          actualYearProduct[
            `${
              productsAnalyzeDimension === "units" ? "SalesInUnits_" : "Sales_"
            }${i}`
          ]
        );
      if (yearAgoProduct)
        yearAgoCoordinates.push(
          yearAgoProduct[
            `${
              productsAnalyzeDimension === "units" ? "SalesInUnits_" : "Sales_"
            }${i}`
          ]
        );
    }
    setCoordinates([actualYearCoordinates, yearAgoCoordinates]);
  };

  useEffect(() => {
    setHeadersForFilterPicker(
      headerGroups[0].headers.map((item) => {
        return item.Header === "Product Name"
          ? { ...item, Header: "Name" }
          : item.id === "reportPeriod"
          ? { ...item, Header: groupedHeaderRef.current.children[2].innerText }
          : item.id === "compareToPeriod"
          ? { ...item, Header: groupedHeaderRef.current.children[3].innerText }
          : item;
      })
    );
  }, [headerGroups, productPurchasedTableMode]);

  useEffect(
    function onSortsChanged() {
      dispatch(setProductPurchasedTableSorts(state.sortBy));
    },
    [state.sortBy, dispatch]
  );

  useEffect(
    function applySorts() {
      if (!showCustomerPageFilters && !isEmpty(productPurchasedTableSorts)) {
        productPurchasedTableSorts?.forEach(({ id, value }) => {
          const column = getColumnByAccessor(id, headerGroups[0].headers);
          if (column && column.canSort && column.toggleSortBy) {
            column.toggleSortBy(id);
          }
        });
      } else if (
        showCustomerPageFilters &&
        !isEmpty(latestProductPurchasedTableSorts)
      ) {
        latestProductPurchasedTableSorts?.forEach(({ id, value }) => {
          const column = getColumnByAccessor(id, headerGroups[0].headers);
          if (column && column.canSort && column.toggleSortBy) {
            column.toggleSortBy(id);
          }
        });
      }
    },
    [CustomerID]
  );

  useEffect(
    function closeAnalyzePickerOnCustomerChange() {
      setAnalyzePickerVisible(false);
    },
    [CustomerID]
  );

  useEffect(
    function onFiltersChanged() {
      if (productPurchasedTableMode === "analyze") {
        if (!isEmpty(state.filters))
          dispatch(setProductPurchasedAnalyzeTableFilters(state.filters));
      } else {
        dispatch(setProductPurchasedTableFilters(state.filters));
      }
    },
    [state.filters, dispatch]
  );

  useEffect(
    function applyFilters() {
      if (productPurchasedTableMode !== "analyze") {
        if (
          !showCustomerPageFilters &&
          !isEmpty(productPurchasedTableFilters)
        ) {
          productPurchasedTableFilters?.forEach(({ id, value }) => {
            const column = getColumnByAccessor(id, headerGroups[0]?.headers);
            if (column && column.canFilter && column.setFilter) {
              column.setFilter(value);
            }
          });
        } else if (
          // showCustomerPageFilters &&
          !isEmpty(latestProductPurchasedTableFilters)
        ) {
          latestProductPurchasedTableFilters?.forEach(({ id, value }) => {
            const column = getColumnByAccessor(id, headerGroups[0]?.headers);
            if (column && column.canFilter && column.setFilter) {
              column.setFilter(value);
            }
          });
        } else if (
          !showCustomerPageFilters &&
          isEmpty(productPurchasedTableFilters)
        ) {
          setAllFilters([]);
        }
      } else {
        if (!isEmpty(data)) {
          if (
            // prevProductPurchasedTableMode === productPurchasedTableMode &&
            !isEmpty(latestProductPurchasedAnalyzeTableFilters)
          ) {
            setAllFilters([]);
            if (productsAnalyzeDates[1][1] === "null") {
              latestProductPurchasedAnalyzeTableFilters
                ?.filter((i) =>
                  [
                    "Code",
                    "ProductName",
                    "LastOrderDate",
                    "reportPeriod",
                  ]?.includes(i.id)
                )
                ?.forEach(({ id, value }) => {
                  const column = getColumnByAccessor(
                    id,
                    headerGroups[0]?.headers
                  );
                  if (column && column.canFilter && column.setFilter) {
                    column.setFilter(value);
                  }
                });
            } else {
              latestProductPurchasedAnalyzeTableFilters?.forEach(
                ({ id, value }) => {
                  const column = getColumnByAccessor(
                    id,
                    headerGroups[0]?.headers
                  );
                  if (column && column.canFilter && column.setFilter) {
                    column.setFilter(value);
                  }
                }
              );
            }
          } else {
            setAllFilters([]);
          }
        }
      }
    },
    [data]
  );

  useEffect(
    function changeHeadersOnAnalyzeMode() {
      if (productPurchasedTableMode === "analyze") {
        const startReportDate = new Date(
          JSON.parse(productsAnalyzeDates[0][0])
        );
        const endReportDate = new Date(JSON.parse(productsAnalyzeDates[0][1]));
        const startCompareToDate = new Date(
          JSON.parse(productsAnalyzeDates[1][0])
        );
        const endCompareToDate = new Date(
          JSON.parse(productsAnalyzeDates[1][1])
        );

        const startReportDateStr = `${
          startReportDate.getMonth() + 1
        }/${startReportDate.getFullYear().toString()?.slice(2)}`;
        const endReportDateStr = `${
          endReportDate.getMonth() + 1
        }/${endReportDate.getFullYear().toString()?.slice(2)}`;
        const startCompareToDateStr = `${
          startCompareToDate.getMonth() + 1
        }/${startCompareToDate.getFullYear().toString()?.slice(2)}`;
        const endCompareToDateStr = `${
          endCompareToDate.getMonth() + 1
        }/${endCompareToDate.getFullYear().toString()?.slice(2)}`;

        if (productsAnalyzeDimension === "value") {
          if (Language === "hebrew") {
            groupedHeaderRef.current.children[2].innerText = `${currencyMap[Currency]} מכירות ${endReportDateStr} - ${startReportDateStr}`;
            if (
              startCompareToDate.getFullYear() !== 1970 &&
              endCompareToDate.getFullYear() !== 1970
            ) {
              groupedHeaderRef.current.children[3].innerText = `${currencyMap[Currency]} מכירות ${endCompareToDateStr} - ${startCompareToDateStr}`;
            }
          } else if (Language === "english") {
            groupedHeaderRef.current.children[2].innerText = `Sales ${startReportDateStr} - ${endReportDateStr} ${currencyMap[Currency]}`;
            if (
              startCompareToDate.getFullYear() !== 1970 &&
              endCompareToDate.getFullYear() !== 1970
            ) {
              groupedHeaderRef.current.children[3].innerText = `Sales ${startCompareToDateStr} - ${endCompareToDateStr} ${currencyMap[Currency]}`;
            }
          }
        } else if (productsAnalyzeDimension === "units") {
          if (Language === "hebrew") {
            groupedHeaderRef.current.children[2].innerText = `מכירות ${endReportDateStr} - ${startReportDateStr} (יחידות)`;
            if (
              startCompareToDate.getFullYear() !== 1970 &&
              endCompareToDate.getFullYear() !== 1970
            ) {
              groupedHeaderRef.current.children[3].innerText = `מכירות ${endCompareToDateStr} - ${startCompareToDateStr} (יחידות)`;
            }
          } else if (Language === "english") {
            groupedHeaderRef.current.children[2].innerText = `Sales ${startReportDateStr} - ${endReportDateStr} (units)`;
            if (
              startCompareToDate.getFullYear() !== 1970 &&
              endCompareToDate.getFullYear() !== 1970
            ) {
              groupedHeaderRef.current.children[3].innerText = `Sales ${startCompareToDateStr} - ${endCompareToDateStr} (units)`;
            }
          }
        }
      }
    },
    [productPurchasedTableMode, productsAnalyzeDates, productsAnalyzeDimension]
  );

  useEffect(() => {
    analyzeButtonRef.current.style.backgroundColor =
      productPurchasedTableMode === "analyze" ? "#00FF00" : "#B3FFB3";
  }, [productPurchasedTableMode]);

  useEffect(() => {
    if (
      !isEmpty(rows) &&
      (prevProductPurchasedTableMode !== productPurchasedTableMode ||
        (prevProductPurchasedTableMode === productPurchasedTableMode &&
          productPurchasedTableMode === "analyze"))
    ) {
      handleRowClick(rows[0], productsAnalyzeDimension);
      setPrevProductPurchasedTableMode(productPurchasedTableMode);
    } else if (isEmpty(rows)) {
      handleRowClick({ values: { ProductName: null, Code: null } });
    } else {
      handleRowClick(rows[0], productsAnalyzeDimension);
    }
  }, [data]);

  return (
    <>
      <div
        className="product-purchased-clickables"
        style={{
          justifyContent: `${
            Language === "hebrew" ? "space-between" : "normal"
          }`,
        }}
      >
        <div style={{ display: "flex" }}>
          <Box
            className="product-purchased-buttons"
            onClick={(e) => {
              dispatch(setProductPurchasedTableMode("regular"));
              dispatch(setProductsAnalyzeDimension("value"));
              dispatch(setShowCustomerPageFilters(false));
              dispatch(setProductPurchasedAnalyzeTableFilters([]));
              dispatch(setShowCustomerPageFilters(false));
              setSalesPeriod(e.target.id);
            }}
          >
            <span
              className="period"
              id="thisMonth"
              style={{
                backgroundColor:
                  productPurchasedTableMode === "analyze"
                    ? "#f2faff"
                    : salesPeriod === "thisMonth"
                    ? "#a8c6fa"
                    : "#f2faff",
                borderTopLeftRadius: 8,
                borderBottomLeftRadius: 8,
              }}
            >
              {
                dictionary[Language].productPurchasedTable.periodButtons
                  .thisMonth
              }
            </span>
            <span
              className="period"
              id="lastMonth"
              style={{
                backgroundColor:
                  productPurchasedTableMode === "analyze"
                    ? "#f2faff"
                    : salesPeriod === "lastMonth"
                    ? "#a8c6fa"
                    : "#f2faff",
              }}
            >
              {
                dictionary[Language].productPurchasedTable.periodButtons
                  .lastMonth
              }
            </span>
            <span
              className="period"
              id="yearToDate"
              style={{
                backgroundColor:
                  productPurchasedTableMode === "analyze"
                    ? "#f2faff"
                    : salesPeriod === "yearToDate"
                    ? "#a8c6fa"
                    : "#f2faff",
              }}
            >
              {
                dictionary[Language].productPurchasedTable.periodButtons
                  .yearToDate
              }
            </span>
            <span
              className="period"
              id="lastYear"
              style={{
                backgroundColor:
                  productPurchasedTableMode === "analyze"
                    ? "#f2faff"
                    : salesPeriod === "lastYear"
                    ? "#a8c6fa"
                    : "#f2faff",
              }}
            >
              {date.getFullYear() - 1}
            </span>
          </Box>
          <Box
            onClick={(e) => {
              setAnalyzeMode(!analyzeMode);
              setAnalyzePickerVisible(true);
            }}
          >
            <span
              ref={analyzeButtonRef}
              className="analyze-button"
              id="analyze"
              style={{
                backgroundColor: "#B3FFB3",
              }}
            >
              {dictionary[Language].productPurchasedTable.analyze}
            </span>
          </Box>
          <Box>
            <AnalyzePicker
              columns={columns}
              visible={analyzePickerVisible}
              headers={headerGroups[0].headers}
              setVisibility={setAnalyzePickerVisible}
            />
          </Box>
        </div>

        <Box
          style={{
            marginLeft: 10,
            display: "flex",
            alignitems: "center",
            marginTop: "0.2rem",
            direction: `${Language === "hebrew" ? "rtl" : "ltr"}`,
          }}
        >
          <FiltersList
            filters={state.filters}
            headers={headersForFilterPicker}
            setFilterPickerVisible={setFilterPickerVisible}
          />
          <FilterPicker
            columns={columns}
            visible={filterPickerVisible}
            headers={headersForFilterPicker}
            setVisibility={setFilterPickerVisible}
          />
        </Box>
      </div>

      <div
        id="product-purchased-table"
        className="product-purchased-table-div"
        ref={tableRef}
      >
        <table
          {...getTableProps()}
          className="product-purchased-table"
          style={{ direction: Language === "hebrew" ? "rtl" : "ltr" }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} ref={groupedHeaderRef}>
                {headerGroup.headers.map((column) => {
                  if (["Probability", "OpenOrders"]?.includes(column.id)) {
                    return (
                      <th
                        className="smaller-width-header"
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        <span> {isSorted(column)}</span>
                      </th>
                    );
                  } else
                    return (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        <span> {isSorted(column)}</span>
                      </th>
                    );
                })}
              </tr>
            ))}
          </thead>
          {!productPurchasedData?.length > 0 ? (
            // <LoadingAnimation />
            <tbody style={{ marginLeft: "33%", marginTop: "14%" }}>
              <tr>
                <td style={{ border: "none" }}>
                  {" "}
                  {Language === "hebrew"
                    ? "אין מידע זמין"
                    : "No Data Available"}
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody {...getTableBodyProps}>
              <Menu
                open={!!menuPosition}
                onClose={() => {
                  setMenuPosition(null);
                }}
                anchorReference="anchorPosition"
                anchorPosition={menuPosition}
              >
                <MenuItem
                  onClick={(e) => {
                    setMenuPosition(null);
                    handleRowClick(selectedRow, productsAnalyzeDimension);
                  }}
                >
                  {
                    dictionary[Language]?.CustomersOverviewTable.menuOptions
                      .showGraph
                  }
                </MenuItem>
                <NestedMenuItem
                  label={
                    dictionary[Language]?.salesReviewTable.menuOptions.exportTo
                  }
                  parentMenuOpen={!!menuPosition}
                >
                  <MenuItem id="csv">
                    <CSVLink
                      target="_blank"
                      data={getRowsCsv(rows, columns)}
                      filename={`product_purchased.csv`}
                      style={{ color: "black" }}
                      onClick={() => {
                        setMenuPosition(null);
                      }}
                    >
                      CSV
                    </CSVLink>
                  </MenuItem>
                  <MenuItem
                    id="pdf"
                    onClick={pdfAvailable ? handlePDFExportToClick : null}
                    style={{ color: pdfAvailable ? "black" : "grey" }}
                  >
                    PDF
                  </MenuItem>
                </NestedMenuItem>
              </Menu>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    onClick={(e) =>
                      handleRowClick(row, productsAnalyzeDimension)
                    }
                    onContextMenu={(e) =>
                      handleRightClick(e, row, rows?.length)
                    }
                  >
                    {row.cells.map((cell) => {
                      if (cell.column.id === "ProductName") {
                        return (
                          <td
                            {...cell.getCellProps()}
                            style={{ direction: "ltr" }}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      }
                      if (
                        typeof cell.value === "string" &&
                        cell.value?.includes("/")
                      ) {
                        return (
                          <td {...cell.getCellProps()}>
                            {DateFormat === "us"
                              ? usDateFormat(cell.value)
                              : cell.render("Cell")}
                          </td>
                        );
                      }
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
    </>
  );
};
