import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import classNames from "classnames";
import React, { useState } from "react";
import { useEffect } from "react";

const useStyles = makeStyles(() => ({
  container: {
    height: "1rem",
    // minWidth: "4.5rem",
    width: "calc(60%)",
    borderRadius: 50,
    border: "1px solid #D1D1D1",
    // margin: "auto",
  },
  containerFullWidth: {
    width: "100%",
    position: "relative",
  },
  filter: {
    height: "100%",
    textAlign: "right",
    borderRadius: "inherit",
    backgroundColor: "#73aafc",
  },
  label: {
    paddingLeft: "0.3rem",
    paddingTop: "0.15rem",
  },
  percentLabel: {
    bottom: 18,
    fontSize: 12,
    fontStyle: "italic",
    position: "absolute",
  },
}));

export default function ProgressBar({ row, fullWidth = false }) {
  const classes = useStyles();
  const [percentage, setPercentage] = useState(0);
  const [backgroundColor, setBackgroundColor] = useState("transparent");
  const [actualPercent, setActualPercent] = useState("0%");

  if (row.values.progressExpectation)
    row.values.CalcProgress = row.values.progressExpectation;

  useEffect(() => {
    if (row.values.CalcProgress) {
      setPercentage(
        parseInt(row.values.CalcProgress) >= 0
          ? parseInt(row.values.CalcProgress)
          : 0
      );
      setBackgroundColor(
        percentage > 70
          ? "#24b474"
          : percentage > 25
          ? "#ffc408"
          : percentage > 0
          ? "#fd6767"
          : "transparent"
      );
      setActualPercent(`${Math.min(percentage, 100)}%`);
      return parseInt(row.values.CalcProgress) >= 0
        ? parseInt(row.values.CalcProgress)
        : 0;
    }
    if (row.values.Progress) {
      setPercentage(
        parseInt(row.values.Progress) >= 0 ? parseInt(row.values.Progress) : 0
      );
      setBackgroundColor(
        percentage > 70
          ? "green"
          : percentage > 25
          ? "orange"
          : percentage > 0
          ? "red"
          : "transparent"
      );
      setActualPercent(`${Math.min(percentage, 100)}%`);
      return parseInt(row.values.Progress) >= 0
        ? parseInt(row.values.Progress)
        : 0;
    }
    return 0;
  }, [row.values.CalcProgress, row.values.Progress, percentage]);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        style={{
          width: fullWidth ? "100%" : "initial",
          marginLeft: "calc(5%)",
          marginRight: "calc(5%)",
        }}
      >
        <div
          className={classNames(classes.container, {
            [classes.containerFullWidth]: fullWidth,
          })}
        >
          {fullWidth && (
            <span
              className={classes.percentLabel}
              style={{ left: `calc(${actualPercent} - 10px)` }}
            >
              {percentage}%
            </span>
          )}
          <div
            className={classes?.filter}
            style={{ width: actualPercent, backgroundColor: backgroundColor }}
          />
        </div>
        {!fullWidth && <span className={classes.label}>{percentage}%</span>}
      </Box>
    </>
  );
}
