import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import numberUtils from "../../utils/number";
import { currencyMap } from "../../constants/currency-map";
import { dictionary } from "../../constants/dictionary";

const useStyles = makeStyles((theme) => ({
  subtitle: {
    fontWeight: 700,
    paddingLeft: "1rem",
    paddingRight: "1rem",
    paddingTop: "0.5rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
      textAlign: "center",
      paddingTop: "1rem",
      paddingBottom: "1rem",
    },
  },
}));

export const Subtitle = ({
  customerData = [],
  homeData = [],
  dayData = [],
}) => {
  const classes = useStyles();
  const userSlice = useSelector((state) => state.userSlice, shallowEqual);
  const resolution = useSelector(
    (state) => state.globalSlice.resolution,
    shallowEqual
  );
  const isMobile = resolution.device === "mobile";
  const { user } = userSlice;
  const { Currency, UserType, AgentID, Language } = user.metadata;

  if (UserType === "agent" && homeData?.length > 0 && !isMobile) {
    return (
      <div
        className="green-div"
        style={{
          height: "initial",
          direction: Language === "hebrew" ? "rtl" : "ltr",
        }}
      >
        <div className={classes.subtitle}>
          {homeData?.filter((item) => item.AgentID === AgentID)?.length}{" "}
          {`${dictionary[Language]?.greenDiv.customersWith}`}{" "}
          {currencyMap[Currency]}
          {numberUtils.numberWithCommas(
            homeData
              ?.filter((item) => item.AgentID === AgentID)
              .reduce(
                (prev, curr) =>
                  prev + parseInt(curr.AggPotentialValue?.replaceAll(",", "")),
                0
              )
          )}{" "}
          {`${dictionary[Language]?.greenDiv.salesPotential}`}
        </div>
      </div>
    );
  }
  if (UserType === "agent" && isMobile && dayData) {
    return (
      <div
        className="green-div"
        style={{ direction: Language === "hebrew" ? "rtl" : "ltr" }}
      >
        <div className={classes.subtitle}>
          {dayData?.length}{" "}
          {`${dictionary[Language]?.greenDiv.activeInsightsWorth}`}{" "}
          {currencyMap[Currency]}
          {numberUtils.numberWithCommas(
            dayData?.reduce(
              (prev, curr) =>
                prev + parseInt(curr.AggPotentialValue?.replaceAll(",", "")),
              0
            )
          )}
        </div>
      </div>
    );
  }
  if (UserType === "agent" && customerData?.length > 0) {
    return (
      <div
        className="green-div"
        style={{ direction: Language === "hebrew" ? "rtl" : "ltr" }}
      >
        <div className={classes.subtitle}>
          {customerData?.length}{" "}
          {`${dictionary[Language]?.greenDiv.activeInsightsWorth}`}{" "}
          {currencyMap[Currency]}
          {numberUtils.numberWithCommas(
            customerData.reduce(
              (prev, curr) =>
                prev + parseInt(curr.AggPotentialValue?.replaceAll(",", "")),
              0
            )
          )}
        </div>
      </div>
    );
  }
  if (homeData?.length > 0) {
    return (
      <div
        className="green-div"
        style={{ direction: Language === "hebrew" ? "rtl" : "ltr" }}
      >
        <div className={classes.subtitle}>
          {`${dictionary[Language]?.greenDiv.activeInsightsWorth}`}{" "}
          {currencyMap[Currency]}
          {numberUtils.numberWithCommas(
            homeData.reduce(
              (prev, curr) =>
                prev + parseInt(curr.AggPotentialValue?.replaceAll(",", "")),
              0
            )
          )}
        </div>
      </div>
    );
  } else if (customerData?.length > 0) {
    return (
      <div
        className="green-div"
        style={{ direction: Language === "hebrew" ? "rtl" : "ltr" }}
      >
        <div className={classes.subtitle}>
          {customerData?.length}{" "}
          {`${dictionary[Language]?.greenDiv.activeInsightsWorth}`}{" "}
          {currencyMap[Currency]}
          {numberUtils.numberWithCommas(
            customerData.reduce(
              (prev, curr) =>
                prev + parseInt(curr.AggPotentialValue?.replaceAll(",", "")),
              0
            )
          )}
        </div>
      </div>
    );
  } else {
    return <div className="green-div"></div>;
  }
};
