import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import Pagination from "@material-ui/lab/Pagination";
import makeStyles from "@material-ui/styles/makeStyles";
import axios from "axios";
import classNames from "classnames";
import _ from "lodash";
import flattenDeep from "lodash/flattenDeep";
import isEmpty from "lodash/isEmpty";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useFilters, useSortBy, useTable } from "react-table";
import domain from "../../../config/domain";
import { insightsMap } from "../../../constants/insights-map";
import opportunitiesService from "../../../services/opportunities";
import dateUtils from "../../../utils/date";
import { filterTypes, getColumnByAccessor } from "../../../utils/filters";
import numberUtils from "../../../utils/number";

import { setReportsTableFilters } from "../../../store/features/tableFiltersSlice";
import FilterPicker from "../../Filters/FilterPicker";
import FiltersList from "../../Filters/FiltersList";
import ProgressBar from "../../ProgressBar/ProgressBar";
import { engGROUPED_COLUMNS } from "./ColumnsFiles/engColumns";
import { hebGROUPED_COLUMNS } from "./ColumnsFiles/hebColumns";

import {
  setCurrencyForGroupedTableHeaders,
  usDateFormat,
  getLastUpdate,
  getRowsCsv,
  isSorted,
} from "../../../utils/helpers";
import "./ReportsTable.css";
import { dictionary } from "../../../constants/dictionary";
import { Menu } from "@material-ui/core";
import NestedMenuItem from "material-ui-nested-menu-item";
import { CSVLink } from "react-csv";
import pdfUtils from "../../../utils/pdf";
import { reqAuth } from "../../../constants/reqAuth";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: 20,
    marginBottom: 20,
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
      width: "100%",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  tableFilters: {
    marginLeft: 20,
    marginBottom: 12,
    position: "relative",
  },
  filterIcon: {
    marginLeft: 20,
    cursor: "pointer",
  },
  filtersCloseIcon: {
    opacity: 0.82,
    marginBottom: 12,
    cursor: "pointer",
    transition: "opacity 0.4s",
    "&:hover": {
      opacity: 1,
    },
  },
  pagination: {
    minWidth: 350,
    marginTop: 25,
  },
  spaceRight: {
    marginRight: 25,
  },
  bold: {
    fontWeight: 700,
  },
}));

export const ReportsTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);
  const [showRows, setShowRows] = useState(10);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [filterPickerVisible, setFilterPickerVisible] = useState(null);
  const [menuPosition, setMenuPosition] = useState(null);
  const tableRef = useRef(null);

  const {
    dataSlice,
    userSlice,
    tableFiltersSlice: { reportsTableFilters },
  } = useSelector((state) => state);
  const staticAppDate = useSelector((state) => state.globalSlice.staticAppDate);
  const date = staticAppDate ? new Date(staticAppDate) : new Date();

  const { keyOpportunitiesTableData: specificKeyOpportunitiesTableData } =
    dataSlice;
  let {
    user: {
      metadata: {
        InsightingCustomerID,
        UserType,
        ManagerID,
        AgentID,
        DateFormat,
        AgentPermission,
        TeamAgentsIDs,
      },
    },
  } = userSlice;
  const { Currency, Language } = JSON.parse(
    sessionStorage.getItem("userMetadata")
  );
  ManagerID = ManagerID?.split("+");

  const columns = useMemo(
    () =>
      Language === "hebrew"
        ? setCurrencyForGroupedTableHeaders(
            Currency,
            hebGROUPED_COLUMNS,
            Language
          )
        : Language === "english"
        ? setCurrencyForGroupedTableHeaders(
            Currency,
            engGROUPED_COLUMNS,
            Language
          )
        : setCurrencyForGroupedTableHeaders(
            Currency,
            engGROUPED_COLUMNS,
            Language
          ),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    state,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: {
        sortBy: [
          { id: "AgentName", desc: true },
          { id: "Value", desc: true },
        ],
      },
      autoResetPage: false,
      autoResetExpanded: false,
      autoResetGroupBy: false,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false,
    },
    useFilters,
    useSortBy
  );

  const filterableColumns = [...columns[0].columns, ...columns[1].columns];

  const handleRightClick = (e, row) => {
    if (menuPosition) {
      return;
    }
    e.preventDefault();
    setMenuPosition({
      top: e.clientY,
      left: e.clientX,
    });
  };

  const handlePDFExportToClick = () => {
    pdfUtils.download(tableRef.current, "opportunities-report");
    setMenuPosition(null);
  };

  const getCustomerOpportunities = async () => {
    try {
      const responseItems = [];
      let res;
      if (UserType === "teamleader" && AgentPermission !== "agentonly") {
        let agents = TeamAgentsIDs?.split("+");
        for (let agent of agents) {
          const response =
            await opportunitiesService.getKeyOpportunitiesTableDataByAgent(
              agent,
              InsightingCustomerID,
              date
            );
          if (response) responseItems.push(...response);
        }
      }
      if (
        (UserType === "manager" && AgentPermission !== "agentonly") ||
        (UserType === "agent" && AgentPermission !== "agentonly")
      ) {
        const response =
          await opportunitiesService.getKeyOpportunitiesTableData(
            ManagerID,
            InsightingCustomerID,
            date
          );
        if (response) responseItems.push(...response);
      } else if (AgentPermission === "agentonly") {
        const response =
          await opportunitiesService.getKeyOpportunitiesTableDataByAgent(
            AgentID,
            InsightingCustomerID,
            date
          );
        if (response) responseItems.push(...response);
      }
      const data = responseItems
        ?.filter(({ InsightType }) =>
          [
            "Insight1",
            "Insight1During",
            "Insight2",
            "Insight2During",
            "Insight3",
            "Insight4During",
            "Insight5",
            "Insight5During",
          ]?.includes(InsightType)
        )
        ?.filter((item) => item.MonthOfInsight === dateUtils.currentMonth())
        .map((item) => {
          return {
            Code: [
              "Insight3",
              "Insight3During",
              "Insight5",
              "Insight5During",
            ]?.includes(item.InsightType)
              ? item.ItemName &&
                item.ItemName?.replace(/[^ -~]+/g, "").toLowerCase() !== "na"
                ? item.ItemCatalogNumber
                : "-"
              : // ? item.ItemVariant.replace(/"|'|@/g, "")
              item.InsightType === "Insight1" ||
                item.InsightType === "Insight1During"
              ? item.ItemCatalogNumber
              : "-",
            CustomerID: item.CustomerID,
            ProductName: [
              "Insight3",
              "Insight3During",
              "Insight5",
              "Insight5During",
            ]?.includes(item.InsightType)
              ? // ? item.ItemVariant.replace(/"|'|@/g, "")
                item.ItemName &&
                item.ItemName?.replace(/[^ -~]+/g, "").toLowerCase() !== "na"
                ? item.ItemName
                : item.ItemVariant
              : item.InsightType === "Insight1" ||
                item.InsightType === "Insight1During"
              ? // ? item.ItemName.replace(/"|'|@/g, "")
                item.ItemName
              : item.InsightType === "Insight2" ||
                item.InsightType === "Insight2During"
              ? "-"
              : "-",
            Value: numberUtils.numberWithCommas(
              parseInt(item.AggPotentialValue)
            ),
            Progress: parseInt(item.CalcProgress * 100) + "%",
            InsightType: insightsMap[Language][item.InsightType],
            PotentialValueInUnits: numberUtils.numberWithCommas(
              isNaN(item.PotentialValueInUnits) ||
                parseInt(item.PotentialValueInUnits) === 0
                ? null
                : parseInt(item.PotentialValueInUnits)
            ),
            KeyData:
              Language === "english" &&
              (item.InsightType?.includes("Insight1") ||
                item.InsightType?.includes("Insight2") ||
                item.InsightType?.includes("Insight5"))
                ? item.Param3 === "1"
                  ? `Sold once, on ${item.Param2?.replace("_", "/")}${
                      item.Param4 > 0 && !isNaN(parseFloat(item.Param4))
                        ? `, number of available units is ${parseFloat(
                            item.Param4
                          ).toFixed(1)}`
                        : ""
                    }`
                  : `Last month of purchase ${item.Param2?.replace(
                      "_",
                      "/"
                    )}, typically purchased every ${parseFloat(
                      item.Param1
                    ).toFixed(1)} months${
                      item.Param4 > 0 && !isNaN(parseFloat(item.Param4)) // || insightType !== 5
                        ? `, number of available units is ${parseFloat(
                            item.Param4
                          ).toFixed(1)}`
                        : ""
                    }`
                : Language === "english" && item.InsightType === "Insight2"
                ? `Has not purchased since ${item.Param2?.replace(
                    "_",
                    "/"
                  )}, typically purchases every ${parseFloat(
                    item.Param1
                  ).toFixed(1)} months.`
                : Language === "english" && item.InsightType === "Insight3"
                ? `Bought by ${item.Param1} similar customers `
                : // Contribute ${parseFloat(
                //   item.Param2?.replace('_','/')
                // ).toFixed(1)} % of total sales`
                Language === "english" && item.InsightType === "Insight4During"
                ? `Customer purchased only ${parseInt(
                    parseFloat(item?.Param1) * 100
                  )}% of average purchase`
                : Language === "english" &&
                  item.InsightType === "Insight2During"
                ? `No orders this month, typically purchases every ${parseFloat(
                    item.Param1
                  ).toFixed(1)} months`
                : Language === "english" &&
                  item.InsightType === "Insight4During"
                ? `customer purchased only ${parseInt(
                    parseFloat(item?.Param1) * 100
                  )}% of average purchase`
                : // Language === "hebrew" && item.InsightType === "Insight2"
                // ? `רכש לאחרונה ב ${item.Param2?.replace('_','/')}, בד״כ רוכש כל ${parseFloat(
                //     item.Param1
                //   ).toFixed(1)} חודשים`
                // :
                Language === "hebrew" &&
                  (item.InsightType?.includes("Insight1") ||
                    item.InsightType?.includes("Insight2") ||
                    item.InsightType?.includes("Insight5"))
                ? item.Param3 === "1"
                  ? `רכישה בודדת, בוצעה ב: ${item.Param2?.replace("_", "/")}${
                      item.Param4 > 0 && !isNaN(parseFloat(item.Param4))
                        ? `, כמות זמינה במלאי: ${parseFloat(
                            item.Param4
                          ).toFixed(1)}`
                        : ""
                    }`
                  : `רכש לאחרונה ב ${item.Param2?.replace(
                      "_",
                      "/"
                    )}, בד״כ רוכש כל ${parseFloat(item.Param1).toFixed(
                      1
                    )} חודשים${
                      item.Param4 > 0 && !isNaN(parseFloat(item.Param4))
                        ? `, כמות זמינה במלאי: ${parseFloat(
                            item.Param4
                          ).toFixed(1)}`
                        : ""
                    }`
                : Language === "hebrew" && item.InsightType === "Insight2"
                ? `לא רכש מאז ${item.Param2?.replace(
                    "_",
                    "/"
                  )}, בד״כ רוכש כל ${parseFloat(item.Param1).toFixed(1)} חודשים`
                : Language === "hebrew" && item.InsightType === "Insight3"
                ? `נרכש על ידי ${item.Param1} לקוחות דומים`
                : //  מהווה ${parseFloat(
                //     item.Param2?.replace('_','/')
                //   ).toFixed(1)} % מסך הרכישות של לקוחות אלו`
                Language === "hebrew" && item.InsightType === "Insight4During"
                ? `הלקוח רכש ${parseInt(
                    parseFloat(item?.Param1) * 100
                  )}% מהממוצע החודשי עד כה החודש`
                : Language === "hebrew" && item.InsightType === "Insight2During"
                ? `הלקוח לא רכש עד כה החודש, בד״כ רוכש כל ${parseFloat(
                    item.Param1
                  ).toFixed(1)} חודשים`
                : "-",
            CreatedDate: item.CreatedDate,
          };
        });
      let groupedCustomersArr = _.groupBy(data, "CustomerID");
      let listOfCustomers = Object.keys(groupedCustomersArr);
      let finalData = [];
      listOfCustomers?.forEach((customer) =>
        groupedCustomersArr[customer].some(
          (opportunity) =>
            opportunity.InsightType === insightsMap[Language].Insight2During
        )
          ? finalData.push(
              groupedCustomersArr[customer].find(
                (opportunity) =>
                  opportunity.InsightType ===
                  insightsMap[Language].Insight2During
              )
            )
          : groupedCustomersArr[customer].some(
              (opportunity) =>
                opportunity.InsightType?.includes(
                  insightsMap[Language].Insight1During
                ) ||
                opportunity.InsightType?.includes(
                  insightsMap[Language].Insight5During
                )
            ) &&
            groupedCustomersArr[customer].some((opportunity) =>
              opportunity.InsightType?.includes(
                insightsMap[Language].Insight4During
              )
            )
          ? finalData.push(
              ...groupedCustomersArr[customer]?.filter(
                (opportunity) =>
                  opportunity.InsightType !==
                  insightsMap[Language].Insight2During
              )
            )
          : finalData.push(...groupedCustomersArr[customer])
      );
      return finalData;
    } catch (error) {
      console.log(error.message);
      console.log(error.stack);
    }
  };

  useEffect(
    function onFiltersChanged() {
      dispatch(setReportsTableFilters(state.filters));
    },
    [state.filters]
  );

  useEffect(
    function onLastUpdate() {
      if (data?.length > 0) {
        setLastUpdate(getLastUpdate(data));
      }
    },
    [data]
  );

  useEffect(
    function applyFilters() {
      if (!isEmpty(reportsTableFilters)) {
        reportsTableFilters?.forEach(({ id, value }) => {
          const column = getColumnByAccessor(id, headerGroups[1].headers);
          if (column && column.canFilter && column.setFilter) {
            column.setFilter(value);
          }
        });
      }
    },
    [location, data]
  );
  useEffect(
    function getCustomerOpportunitiesData() {
      if (!isEmpty(specificKeyOpportunitiesTableData)) {
        (async () => {
          const customersData = await getCustomerOpportunities();
          let allData = flattenDeep(
            customersData?.map((item) => {
              const found = specificKeyOpportunitiesTableData.find(
                (data) => data.CustomerID === item.CustomerID
              );
              return found
                ? {
                    ...item,
                    CustomerID: found.CustomerID,
                    CustomerName: found.CustomerName,
                    UpdateDate: found.UpdateDate,
                    AgentName: found.AgentName,
                    AggPotentialValue: found.AggPotentialValue,
                  }
                : null;
            })
          )?.filter((item) => item);
          setData(allData);
        })();
      }
    },
    [specificKeyOpportunitiesTableData]
  );

  return (
    <div className={classes.root}>
      <div
        className="reports-above-table"
        style={{
          direction: `${Language === "hebrew" ? "rtl" : "ltr"}`,
        }}
      >
        <h4 className="table-header">
          {dictionary[Language]?.reportsTable.tableTitle}
        </h4>
        <span className="last-update">
          {dictionary[Language]?.lastUpdate} :{" "}
          {DateFormat === "us" ? usDateFormat(lastUpdate) : lastUpdate}
        </span>
      </div>

      <div className={classes.tableFilters}>
        <Box
          display="flex"
          alignItems="center"
          style={{
            marginLeft: 10,
            marginRight: 18,
            direction: `${Language === "hebrew" ? "rtl" : "ltr"}`,
          }}
        >
          <FiltersList
            filters={state.filters}
            headers={headerGroups[1].headers}
            style={{ maxWidth: "calc(100% - 170px)" }}
            setFilterPickerVisible={setFilterPickerVisible}
          />
          <FilterPicker
            columns={filterableColumns}
            visible={filterPickerVisible}
            headers={headerGroups[1].headers}
            setVisibility={setFilterPickerVisible}
          />
        </Box>
      </div>

      <div
        id="reports-table"
        className="reports-table-div"
        ref={tableRef}
        style={{ direction: Language === "hebrew" ? "rtl" : "ltr" }}
      >
        <table {...getTableProps()} className="reports-table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    className="reports-headers"
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    <span>{isSorted(column)}</span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            <Menu
              open={!!menuPosition}
              onClose={() => {
                setMenuPosition(null);
              }}
              anchorReference="anchorPosition"
              anchorPosition={menuPosition}
            >
              <NestedMenuItem
                label={
                  dictionary[Language]?.salesReviewTable.menuOptions.exportTo
                }
                parentMenuOpen={!!menuPosition}
              >
                <MenuItem id="csv">
                  <CSVLink
                    target="_blank"
                    data={getRowsCsv(rows, filterableColumns)}
                    filename={`opportunities-report.csv`}
                    style={{ color: "black" }}
                    onClick={() => {
                      setMenuPosition(null);
                    }}
                  >
                    CSV
                  </CSVLink>
                </MenuItem>
                <MenuItem id="pdf" onClick={handlePDFExportToClick}>
                  PDF
                </MenuItem>
              </NestedMenuItem>
            </Menu>
            {rows?.slice(page * showRows, (page + 1) * showRows).map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  onContextMenu={(e) => handleRightClick(e, row)}
                >
                  {row.cells.map((cell) => {
                    if (cell.column.Header === "Progress") {
                      if (isNaN(parseFloat(cell.value))) {
                        return (
                          <td key={cell.column.id} {...cell.getCellProps()}>
                            -
                          </td>
                        );
                      } else {
                        let progressValue = parseInt(cell.value);
                        let percentage = Math.min(
                          Math.max(progressValue, 0),
                          999
                        );
                        const displayPercentage = Math.min(percentage, 100); // Cap displayed percentage at 100

                        const progressBarWidth = `${displayPercentage}%`;

                        let backgroundColor =
                          displayPercentage > 70
                            ? "#24b474"
                            : displayPercentage > 25
                            ? "#ffc408"
                            : displayPercentage > 0
                            ? "#fd6767"
                            : "transparent";

                        return (
                          <td
                            key={cell.column.id}
                            {...cell.getCellProps()}
                            className="overview-progress-cell"
                            style={{
                              width: "7rem",
                              border: "1px solid #ddd",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  border: "1px solid #D1D1D1",
                                  height: "1rem",
                                  borderRadius: "50px",
                                  width: "100%",
                                  marginLeft: "5px",
                                  position: "relative",
                                }}
                              >
                                <div
                                  style={{
                                    width: progressBarWidth,
                                    height: "100%",
                                    backgroundColor: backgroundColor,
                                    borderRadius: "50px",
                                    position: "absolute",
                                    left: 0,
                                    top: 0,
                                  }}
                                />
                              </div>
                              <span style={{ margin: "0 5px" }}>
                                {percentage}%
                              </span>
                            </div>
                          </td>
                        );
                      }
                    }

                    return (
                      <td {...cell.getCellProps()} onClick={() => {}}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        flexWrap="wrap"
        className={classes.pagination}
      >
        <Typography
          variant="body2"
          color="textSecondary"
          className={classNames(classes.spaceRight, classes.bold)}
        >
          {rows?.length} Results
        </Typography>

        <FormControl
          size="small"
          className={classes.spaceRight}
          style={{ minWidth: 100 }}
        >
          <Box display="flex" alignItems="center">
            <Typography
              variant="body2"
              color="textSecondary"
              className={classes.bold}
              style={{ marginRight: 10 }}
            >
              Show rows
            </Typography>
            <Select
              size="small"
              id="show-rows"
              value={showRows}
              label="Show rows"
              labelId="show-rows-label"
              onChange={(e) => {
                setPage(0);
                setShowRows(Number(e.target.value));
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </Box>
        </FormControl>

        <Pagination
          size="small"
          color="primary"
          className={classes.spaceRight}
          inputlabelprops={{ shrink: false }}
          count={Math.ceil(rows?.length / showRows)}
          onChange={(_, pageIdx) => setPage(pageIdx - 1)}
        />

        <FormControl
          size="small"
          className={classes.spaceRight}
          style={{ minWidth: 100 }}
        >
          <Box display="flex" alignItems="center">
            <Typography
              variant="body2"
              color="textSecondary"
              className={classes.bold}
              style={{ marginRight: 10 }}
            >
              Go to
            </Typography>
            <Select
              id="go-to"
              size="small"
              value={page}
              label="Go to"
              labelId="go-to-label"
              onChange={(e) => setPage(Number(e.target.value))}
            >
              {[...Array(Math.ceil(rows?.length / showRows)).keys()].map(
                (page) => (
                  <MenuItem key={page} value={page}>
                    {page + 1}
                  </MenuItem>
                )
              )}
            </Select>
          </Box>
        </FormControl>
      </Box>
    </div>
  );
};
