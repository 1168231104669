import React from "react";
import { useSelector } from "react-redux";
import { currencyMap } from "../../constants/currency-map";
import { dictionary } from "../../constants/dictionary";
import numberUtils from "../../utils/number";
import "./CustomerOverviewSalesForecast.css";

export const CustomerOverviewSalesForecast = () => {
  const Language = useSelector(
    (state) => state.userSlice.user.metadata.Language
  );
  const Currency = useSelector(
    (state) => state.userSlice.user.metadata.Currency
  );
  const selectedCustomer = useSelector(
    (state) => state.homePagePropsSlice.selectedCustomer
  );

  const probability = selectedCustomer?.CustomerPurchaseProb;
  const expectedValueMin = selectedCustomer?.ExpectedValueMin;
  const expectedValueMax = selectedCustomer?.ExpectedValueMax;

  return (
    <>
      <div className="bold-header" style={{ fontSize: "13px" }}>
        {dictionary[Language].customerOverviewSalesForecast.title}
      </div>
      <div className="forecast-probability-div">
        <div className="purchase-probability-div">
          <div className="bold-header" style={{ fontSize: "13px" }}>
            {
              dictionary[Language].customerOverviewSalesForecast
                .purchaseProbabilityTitle
            }
          </div>
          <div className="forecast-box">
            {probability ? (
              <>
                {
                  dictionary[Language].productPurchasedTable.probabilityOptions[
                    probability
                  ]
                }
              </>
            ) : (
              "-"
            )}
          </div>
        </div>
        <div className="forecast-div">
          <div className="bold-header" style={{ fontSize: "13px" }}>
            {dictionary[Language].customerOverviewSalesForecast.forecastTitle}{" "}
            {currencyMap[Currency]}
          </div>
          <div className="forecast-box">
            {expectedValueMin &&
            expectedValueMax &&
            !isNaN(expectedValueMin) &&
            !isNaN(expectedValueMax) ? (
              <>
                {numberUtils.kFormat(parseInt(expectedValueMin))} -{" "}
                {numberUtils.kFormat(parseInt(expectedValueMax))}
              </>
            ) : (
              "-"
            )}
          </div>
        </div>
      </div>
    </>
  );
};
