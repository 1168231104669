import { Box } from "@material-ui/core";
import React, { useState } from "react";
import FiltersList from "../../Filters/FiltersList";
import FilterPicker from "../../Filters/FilterPicker";
import { useSelector } from "react-redux";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

export default function SalesReviewFilters({
  filters,
  headers,
  columns,
  disabled,
}) {
  const Language = useSelector(
    (state) => state.userSlice.user.metadata.Language
  );
  const [filterPickerVisible, setFilterPickerVisible] = useState(null);

  return (
    <>
      <Box>
        <Box
          display="flex"
          alignItems="center"
          style={{
            marginLeft: 10,
            direction: `${Language === "hebrew" ? "rtl" : "ltr"}`,
            marginRight: "3.2rem",
          }}
        >
          {disabled ? (
            <FilterAltIcon color="disabled" />
          ) : (
            <>
              <FiltersList
                filters={filters}
                headers={headers}
                style={{ maxWidth: "calc(100% - 170px)" }}
                setFilterPickerVisible={setFilterPickerVisible}
              />
              <FilterPicker
                columns={columns}
                visible={filterPickerVisible}
                headers={headers}
                setVisibility={setFilterPickerVisible}
              />
            </>
          )}
        </Box>
      </Box>
    </>
  );
}
