import TextColumnFilter from "../../../Filters/TextColumnFilter";
import NumberColumnFilter from "../../../Filters/NumberColumnFilter";
import { sortTypes } from "../../../../utils/sort";
import CheckedColumnFilter from "../../../Filters/CheckedColumnFilter";
import DateColumnFilter from "../../../Filters/DateColumnFilter";

export const engCOLUMNS = [
  {
    Header: "Customer Code",
    accessor: "CustomerID",
    filter: "text",
    Filter: TextColumnFilter,
    canFilter: true,
  },
  {
    Header: "Customer Name",
    accessor: "customerName",
    filter: "text",
    Filter: TextColumnFilter,
    canFilter: true,
  },
  {
    Header: "Agent",
    accessor: "salesAgent",
    filter: "checked",
    Filter: CheckedColumnFilter,
    canFilter: true,
  },
  {
    Header: "$",
    accessor: "periodValue",
    filter: "number",
    Filter: NumberColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: "Units",
    accessor: "Units",
    filter: "number",
    Filter: NumberColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: "Last Purchase",
    accessor: "LastOrderDate",
    filter: "date",
    Filter: DateColumnFilter,
    canFilter: true,
    sortType: sortTypes.lastOrderDate,
  },
  //   {
  //     Header: "Opportunities",
  //     accessor: "opportunities",
  //     filter: "checked",
  //     Filter: CheckedColumnFilter,
  //     canFilter: true,
  //     // sortType: sortTypes.new,
  //     disableSortBy: true,
  //   },
];
