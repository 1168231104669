import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import Button from "@material-ui/core/Button";
import { dictionary } from "../../constants/dictionary";
import "./HierarchySelectors.css";
import {
  setCustomersGroupFilter,
  setHierarchyFilterLogicOperators,
  setHierFilterData,
  setShowHierFilteredData,
} from "../../store/features/tableFiltersSlice";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const HierarchySelectors = ({
  setVisibility,
}) => {
  const flattenArrayOfHierachyValues = useSelector(
    (state) => state.customersOverviewSlice.flattenArrayOfHierachyValues,
    shallowEqual
  );
  const userSlice = useSelector((state) => state.userSlice, shallowEqual);
  const {
    user: {
      metadata: { Language, CustHierNames },
    },
  } = userSlice;
  const dispatch = useDispatch();

  const arrayOfHierarchies = CustHierNames?.slice(1, -1).split("~");

  const [selectedOption1, setSelectedOption1] = useState(null);
  const [selectedOptions2, setSelectedOptions2] = useState([]);
  const [groups, setGroups] = useState([]);
  const [isSelectionPending, setIsSelectionPending] = useState(false);
  const [logicMode, setLogicMode] = useState("");
  const [availableOptions1, setAvailableOptions1] = useState(
    arrayOfHierarchies.map((option, index) => ({
      label: option,
      value: option,
      index,
    }))
  );
  const [logRow, setLogRow] = useState(false);
  const [selectedLogicOptionID, setSelectedLogicOptionID] = useState("");
  const [newLogicMode, setNewLogicMode] = useState("");
  const [firstOpen, setFirstOpen] = useState(true);
  const [logicOperatorsArray, setLogicOperatorsArray] = useState([]);
  const loggedSelectionsRef = useRef(null);

  const isAndOptionDisabled = () => {
    for (let i = 0; i < logicOperatorsArray?.length - 1; i++) {
      if (
        logicOperatorsArray[i][1] === "AND" &&
        logicOperatorsArray[i + 1][1] === "OR"
      ) {
        return true; // Disable the "AND" option if "AND" is followed by "OR"
      }
    }
    return false; // Enable the "AND" option otherwise
  };

  const handleOption1Change = (option) => {
    setSelectedOption1(option);
    setIsSelectionPending(true);
    setSelectedOptions2([])
  };

  const handleOption2Change = (options) => {
    setSelectedOptions2(options);
    setIsSelectionPending(true);
  };

  const handleLogicSelection = (e) => {
    const logicValue = e.target.value;
    const selectedOptionIndex = e.target.selectedIndex;
    const logicID = e.target.options[selectedOptionIndex].id;
    setNewLogicMode(logicValue);
    setSelectedLogicOptionID(logicID);
    setLogicOperatorsArray([...logicOperatorsArray, [logicValue, logicID]]);

    setLogRow(false);
  };

  const handleCheckmarkClick = () => {
    setLogRow(true);
    setFirstOpen(false);

    if (isSelectionPending) {
      setIsSelectionPending(false);
      setGroups([
        ...groups,
        {
          selectedOption1,
          selectedOptions2,
        },
      ]);
      setSelectedOption1(null);
      setSelectedOptions2([]);
      setLogicMode(null); // Reset logicMode
    } else {
      setLogicMode(newLogicMode);
    }
    setSelectedLogicOptionID("");
  };

  const handleDelete = (index) => {
    const updatedGroups = [...groups];
    updatedGroups.splice(index, 1);
    const logicOperatorsArrayCopy = [...logicOperatorsArray];
    logicOperatorsArrayCopy.splice(index - 1, 1);

    setLogicOperatorsArray(logicOperatorsArrayCopy);
    setGroups(updatedGroups);
  };

  const getSecondSelectOptions = () => {
    if (selectedOption1) {
      const selectedOptionIndex = arrayOfHierarchies.indexOf(
        selectedOption1.value
      );
      if (selectedOptionIndex >= 0) {
        return flattenArrayOfHierachyValues[selectedOptionIndex]?.map((value) => ({
          label: value,
          value: value,
        }));
      }
    }
    return [];
  };

  const handleApplyClick = () => {
    const newGroup = {
      selectedOption1,
      selectedOptions2,
    };

    setGroups([...groups, newGroup]);

    const newGroups = [...groups, newGroup];

    const hierarchyFilterData = [
      newGroups[0], // Include the first item as is
      ...newGroups?.slice(1).map((item, index) => {
        if (index < logicOperatorsArray?.length) {
          const [logicValue, logicID] = logicOperatorsArray[index];
          return { ...item, logicValue, logicID };
        }
        return item;
      }),
    ]?.filter((i) => i?.selectedOption1 !== null);

    dispatch(setHierarchyFilterLogicOperators(logicOperatorsArray));
    dispatch(setHierFilterData(hierarchyFilterData));
    dispatch(setCustomersGroupFilter([...groups, newGroup]));
    dispatch(setShowHierFilteredData(true));
    setVisibility(false);
  };

  useEffect(() => {
    if (loggedSelectionsRef.current) {
      // Scroll to the bottom when the content overflows
      loggedSelectionsRef.current.scrollTop =
        loggedSelectionsRef.current.scrollHeight;
    }
  }, [groups]);

  return (
    <>
      <div>
        <div ref={loggedSelectionsRef} className="hierarchy-logged-selections">
          {groups.map((group, groupIndex) => (
            <div key={groupIndex}>
              <div className="hierarchy-display-chosen-selectors">
                <div className="hierarchy-chosen-hierarchy-div">
                  <span className="hierarchy-chosen-hierarchy-span">
                    {group.selectedOption1 ? group.selectedOption1.label : ""}
                  </span>
                </div>
                <div className="hierarchy-chosen-options">
                  {group.selectedOptions2.map((option, optionIndex) => (
                    <span key={optionIndex} className="hierarchy-chosen-option">
                      {option.label}
                    </span>
                  ))}
                </div>
                <HighlightOffIcon
                  onClick={() => handleDelete(groupIndex)}
                  style={{ color: "#bababa", cursor: "pointer" }}
                />
              </div>
              {logicOperatorsArray[groupIndex] && (
                <div
                  className="hierarchy-filter-chosen-logic"
                  style={
                    logicOperatorsArray[groupIndex]?.[0] ===
                    dictionary[Language].hierarchyBox.and
                      ? { color: "#d36efb", background: "#f6deff" }
                      : { color: "#FBA16E", background: "#FFF2DE" }
                  }
                >
                  {logicOperatorsArray[groupIndex]?.[0]}
                </div>
              )}
            </div>
          ))}
        </div>
        <div
          className="hierarchy-selectors-div"
          style={{
            justifyContent: !selectedOption1 ? "flex-start" : "space-around",
          }}
        >
          {(firstOpen || selectedLogicOptionID) && (
            <div style={{ width: "25%" }}>
              <Select
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 7,
                    fontSize: 12,
                    direction: Language === "hebrew" ? "rtl" : "ltr",
                  }),
                }}
                value={selectedOption1}
                options={availableOptions1}
                onChange={(selected) => handleOption1Change(selected)}
                isSearchable={true}
                placeholder={
                  dictionary[Language].hierarchyBox.hierarchyPlaceHolder
                }
              />
            </div>
          )}
          {selectedOption1 && (
            <>
              <div style={{ width: "62%" }}>
                <Select
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 7,
                      fontSize: 12,
                      direction: Language === "hebrew" ? "rtl" : "ltr",
                    }),
                  }}
                  isMulti
                  closeMenuOnSelect={false}
                  value={selectedOptions2}
                  // hideSelectedOptions={false}
                  options={getSecondSelectOptions()}
                  onChange={(selected) => handleOption2Change(selected)}
                  placeholder={
                    dictionary[Language].hierarchyBox.hierarchyValuesPlaceHolder
                  }
                />
              </div>
              <CheckCircleOutlineIcon
                style={{
                  color: selectedOptions2?.length > 0 ? "green" : "#BABABA",
                }}
                onClick={handleCheckmarkClick}
              />
            </>
          )}
        </div>
        {logRow && (
          <>
            {/* <div style={{ width: "15%" }}> */}
            <select
              className={`hierarchy-filter-logic-selector`}
              value={logicMode}
              onChange={(e) => handleLogicSelection(e)}
              // style={{ color: "#D36EFB", background: "#F6DEFF", border: "none", fontWeight:'9px' }}
            >
              <option value="AND" id="AND" disabled selected hidden>
                {dictionary[Language].hierarchyBox.and}
              </option>

              <option
                id="AND"
                value={dictionary[Language].hierarchyBox.and}
                disabled={isAndOptionDisabled()}
              >
                {dictionary[Language].hierarchyBox.and}
              </option>
              <option id="OR" value={dictionary[Language].hierarchyBox.or}>
                {dictionary[Language].hierarchyBox.or}
              </option>
            </select>
          </>
        )}
      </div>
      <div
        className="hierarchy-filter-apply-button"
        style={{ [Language === "hebrew" ? "left" : "right"]: 16 }}
      >
        <Button
          size="small"
          type="submit"
          color="primary"
          variant="contained"
          onClick={handleApplyClick}
          disabled={
            !(
              (logRow && !selectedOption1?.label) ||
              (selectedOption1?.label && selectedOptions2?.length > 0)
            )
          }
        >
          {dictionary[Language].hierarchyBox.apply}
        </Button>
      </div>
    </>
  );
};

export default HierarchySelectors;
