import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import momentBusinessDays from "moment-business-days";
import first from "lodash/first";
import groupBy from "lodash/groupBy";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import sumBy from "lodash/sumBy";
import { parseInt, cloneDeep } from "lodash";

import CustomersOverviewTable from "../../components/Tables/CustomersOverviewTable/CustomersOverviewTable";
import KeyOpportunitiesTable from "../../components/Tables/KeyOpportunitiesTable/KeyOpportunitiesTable";
import SalesReviewTable from "../../components/Tables/SalesReviewTable/SalesReviewTable";
import { Subtitle } from "../../components/Subtitle/Subtitle";
import salesByAgentService from "../../services/salesByAgent";
import salesService from "../../services/salesByCustomerByItem";
import {
  setSalesByAgent,
  setSalesByCustomer_MainData,
} from "../../store/features/dataSlice";
import numberUtils from "../../utils/number";
import GrowthAnalysis from "../../components/GrowthAnalysis/GrowthAnalysis";
import CustomerOverviewDetails from "../../components/CustomerOverviewDetails/CustomerOverviewDetails";
import "./HomePage.css";
import Footer from "../../components/Footer/Footer";
import { setSalesByAgentData } from "../../store/features/salesByAgentSlice";

const staticAppDate =
  process.env.REACT_APP_NODE_ENV === "ir-development"
    ? process.env.REACT_APP_DEMO_DATE
    : false;

const date = staticAppDate ? new Date(staticAppDate) : new Date();
const month = date.getMonth() + 1;
const year = date.getFullYear();

const { numberWithCommas } = numberUtils;
const extCalc = (currentSales) =>
  parseInt(
    (currentSales * momentBusinessDays().monthBusinessDays()?.length) /
      momentBusinessDays().businessDaysIntoMonth()
  );

const calcSalesPlusOrders = (sales, orders) => {
  if (!isNaN(sales) && !isNaN(orders)) return numberWithCommas(sales + orders);
  if (!isNaN(sales) && isNaN(orders)) return numberWithCommas(sales + 0);
  if (isNaN(sales) && !isNaN(orders)) return numberWithCommas(0 + orders);
  if (isNaN(sales) && isNaN(orders)) return "-";
};

const calcPercentage = (number) => {
  if (number === "-" || isNaN(number)) return "-";
  return number > 1
    ? "+" + parseInt(number * 100 - 100) + "%"
    : "-" + parseInt(100 - number * 100) + "%";
};

let ytdReducer = (saleReview) => {
  let ytdOrders = 0;
  let ytdTarget = 0;
  let ytdSales = 0;
  for (const [key, value] of Object.entries(saleReview)) {
    for (let i = 1; i <= month; i++) {
      if (key === `Orders_${i}`) ytdOrders += parseInt(value);
      if (key === `Sales_${i}`) ytdSales += parseInt(value);
      if (key === `Target_${i}`) ytdTarget += parseInt(value);
    }
  }
  return { ytdOrders, ytdSales, ytdTarget };
};

export default function HomePage() {
  const keyOpportunitiesTableDataCopy = useSelector(
    (state) => state.dataSlice.keyOpportunitiesTableDataCopy,
    shallowEqual
  );
  const salesByCustomer_MainData = useSelector(
    (state) => state.dataSlice.salesByCustomer_MainData,
    shallowEqual
  );
  const salesByAgent = useSelector(
    (state) => state.dataSlice.salesByAgent,
    shallowEqual
  );
  const metadata = useSelector(
    (state) => state.userSlice.user.metadata,
    shallowEqual
  );
  const resolution = useSelector((state) => state.globalSlice.resolution);
  const isMobile = resolution.device === "mobile";

  let {
    InsightingCustomerID,
    ManagerID,
    UserType,
    ShowCustomersOverview_Flag,
    AgentPermission,
    AgentID,
    TeamAgentsIDs,
    ShowGrowthAnalysis_Flag,
    ShowKeyOpportunities_Flag,
  } = metadata;

  let ManagersID = ManagerID?.split("+");
  const [salesPeriod, setSalesPeriod] = useState("thisMonth");
  const dispatch = useDispatch();

  const [currentYearItems, setCurrentYearItems] = useState([]);
  const [lastYearData, setLastYearData] = useState([]);
  const [twoYearsAgoData, setTwoYearsAgoData] = useState([]);
  const [agentDayData, setAgentDayData] = useState([]);
  // const Items = [];
  const { round } = Math;

  const dataByAgentId = groupBy(keyOpportunitiesTableDataCopy, "AgentID");
  const agentValues = orderBy(
    map(dataByAgentId, (data) => {
      return {
        agentID: data[0].AgentID,
        agentName: first(data)?.AgentName,
        agentAggValue: sumBy(data, ({ AggPotentialValue }) =>
          numberUtils.toNumber(AggPotentialValue)
        ),
        agentAchievedValue: sumBy(data, ({ AchievedValue }) =>
          numberUtils.toNumber(AchievedValue)
        ).toFixed(0),
        agentNotAchievedValue:
          sumBy(data, ({ AggPotentialValue }) =>
            numberUtils.toNumber(AggPotentialValue)
          ) -
          sumBy(data, ({ AchievedValue }) =>
            numberUtils.toNumber(AchievedValue)
          ),
      };
    }),
    ["agentAggValue"],
    ["desc"]
  );

  async function getSalesAndDispatch() {
    dispatch(
      setSalesByCustomer_MainData(
        await salesService.getSalesByCustomerData(InsightingCustomerID, date)
      )
    );
  }

  async function getSalesByAgentData(ManagersID, InsightingCustomerID) {
    try {
      if (salesByAgent?.length < 1) {
        const responseNew = JSON.parse(
          await salesByAgentService.getSalesByAgent(
            ManagersID,
            InsightingCustomerID,
            date
          )
        );
        if (AgentPermission === "agentOnly" && UserType === "agent")
          dispatch(
            setSalesByAgent(
              responseNew?.Items?.filter((item) => item.AgentID === AgentID)
            )
          );
        else {
          dispatch(setSalesByAgent(responseNew?.Items));
        }
      }
    } catch (e) {
      console.log(e.message);
      console.log(e.stack);
    }
  }

  useEffect(() => {
    if (InsightingCustomerID && !salesByCustomer_MainData.Items) {
      getSalesAndDispatch();
    }
  }, [InsightingCustomerID, dispatch]);

  useEffect(() => {
    if (InsightingCustomerID && ManagerID) {
      getSalesByAgentData(ManagersID, InsightingCustomerID);
    }
  }, [ManagerID, InsightingCustomerID]);

  useEffect(() => {
    if (salesByAgent?.length > 0) {
      setCurrentYearItems(
        salesByAgent?.filter((item) => item.YearOfData === JSON.stringify(year))
      );
      setLastYearData(
        salesByAgent?.filter(
          (item) => item.YearOfData === JSON.stringify(year - 1)
        )
      );
      setTwoYearsAgoData(
        salesByAgent?.filter(
          (item) => item.YearOfData === JSON.stringify(year - 2)
        )
      );
    }
  }, [salesByAgent]);

  useEffect(() => {
    function lastMonthTrends(
      month,
      saleReview,
      lastYearItems,
      twoYearsAgoItems,
      trend
    ) {
      const salesReviewAgentID = saleReview.AgentID;
      let lastYearSaleReview;
      lastYearItems?.forEach((element) => {
        if (element.AgentID === salesReviewAgentID)
          lastYearSaleReview = cloneDeep(element);
      });
      let twoYearsAgoSaleReview;
      twoYearsAgoItems?.forEach((element) => {
        if (element.AgentID === salesReviewAgentID)
          twoYearsAgoSaleReview = cloneDeep(element);
      });
      const getPeriodResult = (months) => {
        let count = 0;
        let sum = 0;
        const lastMonthResult =
          month !== 1
            ? saleReview?.[`Sales_${month - 1}`]
            : lastYearSaleReview?.[`Sales_12`];
        if (
          parseInt(lastMonthResult) === 0 ||
          isNaN(parseInt(lastMonthResult)) === true
        ) {
          return "-";
        }
        if (month === 1) {
          if (months === 12) {
            while (count < months - 1) {
              if (
                parseInt(lastYearSaleReview[`Sales_${12 - (count + 1)}`]) ===
                  0 ||
                isNaN(
                  parseInt(lastYearSaleReview[`Sales_${12 - (count + 1)}`]) ===
                    true
                )
              )
                return "-";
              sum += parseInt(lastYearSaleReview[`Sales_${12 - (count + 1)}`]);
              count++;
            }
            if (count === months - 1) {
              if (
                parseInt(twoYearsAgoSaleReview?.[`Sales_${months}`]) === 0 ||
                isNaN(parseInt(twoYearsAgoSaleReview?.[`Sales_${months}`])) ===
                  true
              )
                return "-";
              else sum += parseInt(twoYearsAgoSaleReview?.[`Sales_${months}`]);
            }
            return lastMonthResult / (sum / months);
          } else {
            while (count < months) {
              if (
                parseInt(lastYearSaleReview[`Sales_${12 - (count + 1)}`]) ===
                  0 ||
                isNaN(
                  parseInt(lastYearSaleReview[`Sales_${12 - (count + 1)}`]) ===
                    true
                )
              )
                return "-";
              sum += parseInt(lastYearSaleReview[`Sales_${12 - (count + 1)}`]);
              count++;
            }
            return lastMonthResult / (sum / months);
          }
        } else {
          while (month - (2 + count) > 0 && count < months) {
            if (
              parseInt(saleReview[`Sales_${month - (2 + count)}`]) === 0 ||
              isNaN(
                parseInt(saleReview[`Sales_${month - (2 + count)}`]) === true
              )
            )
              return "-";
            sum += parseInt(saleReview[`Sales_${month - (2 + count)}`]);
            count++;
          }
          if (count === months) {
            return lastMonthResult / (sum / months);
          } else {
            if (months === 12) {
              sum += parseInt(twoYearsAgoSaleReview?.[`Sales_${months}`]);
              months--;
            }
            count = months - count;
            while (count > 0 && count < 12) {
              if (
                !lastYearSaleReview ||
                parseInt(lastYearSaleReview[`Sales_${12 - count}`]) === 0 ||
                isNaN(parseInt(lastYearSaleReview[`Sales_${12 - count}`])) ===
                  true
              ) {
                return "-";
              }
              sum += parseInt(lastYearSaleReview[`Sales_${12 - count}`]);
              count--;
            }
            return lastMonthResult / (sum / months);
          }
        }
      };

      // console.log(trend);
      switch (trend) {
        case "YA":
          if (month > 1) {
            let lastYearSaleReview;
            lastYearItems?.forEach((element) => {
              if (element.AgentID === salesReviewAgentID)
                lastYearSaleReview = cloneDeep(element);
            });
            if (!lastYearSaleReview) return "-";
            if (
              parseInt(saleReview[`Sales_${month - 1}`]) === 0 ||
              (lastYearSaleReview &&
                parseInt(lastYearSaleReview[`Sales_${month - 1}`]) === 0)
            )
              return "0%";

            return calcPercentage(
              saleReview[`Sales_${month - 1}`] /
                lastYearSaleReview[`Sales_${month - 1}`]
            );
          } else {
            let lastYearSaleReview;
            lastYearItems?.forEach((element) => {
              if (element.AgentID === salesReviewAgentID)
                lastYearSaleReview = cloneDeep(element);
            });
            let twoYearsAgoSaleReview;
            twoYearsAgoData?.forEach((element) => {
              if (element.AgentID === salesReviewAgentID)
                twoYearsAgoSaleReview = cloneDeep(element);
            });
            if (
              parseInt(twoYearsAgoSaleReview?.[`Sales_${12}`]) === 0 ||
              parseInt(lastYearSaleReview?.[`Sales_${12}`]) === 0 ||
              isNaN(parseInt(twoYearsAgoSaleReview?.[`Sales_${12}`])) ===
                true ||
              isNaN(parseInt(lastYearSaleReview?.[`Sales_${12}`])) === true
            )
              return "-";
            return calcPercentage(
              lastYearSaleReview?.[`Sales_${12}`] /
                twoYearsAgoSaleReview?.[`Sales_${12}`]
            );
          }
        case "P3M":
          return calcPercentage(getPeriodResult(3));
        case "P6M":
          return calcPercentage(getPeriodResult(6));
        case "P12M":
          return calcPercentage(getPeriodResult(12));
        default:
          return null;
      }
    }

    function specificSalesByAgentData() {
      return currentYearItems.map((saleReview) => {
        let lastYearSaleReviewItem = lastYearData.find(
          (item) =>
            item.AgentID_Yr ===
            `${saleReview.AgentID}_${saleReview.YearOfData - 1}`
        );
        let foundInAgentsAnalysis = agentValues.find(
          (agent) => agent.agentID === saleReview.AgentID
        );
        let { ytdOrders, ytdSales, ytdTarget } = ytdReducer(saleReview);
        const lastYearItems = lastYearData;
        const twoYearsAgoItems = twoYearsAgoData;

        let obj = {
          SalesAgent: saleReview.AgentName,
          AgentID: saleReview.AgentID,
          Target:
            salesPeriod === "thisMonth" &&
            !isNaN(parseInt(saleReview[`Target_${month}`]))
              ? numberWithCommas(parseInt(saleReview[`Target_${month}`]))
              : salesPeriod === "lastMonth" &&
                month > 1 &&
                !isNaN(parseInt(saleReview[`Target_${month - 1}`]))
              ? numberWithCommas(parseInt(saleReview[`Target_${month - 1}`]))
              : salesPeriod === "lastMonth" &&
                month === 1 &&
                !isNaN(parseInt(lastYearSaleReviewItem[`Target_12`]))
              ? numberWithCommas(parseInt(lastYearSaleReviewItem[`Target_12`]))
              : ytdTarget > 0 && !isNaN(ytdTarget)
              ? numberWithCommas(ytdTarget)
              : "-",
          Sales:
            salesPeriod === "thisMonth" &&
            !isNaN(round(saleReview[`Sales_${month}`]))
              ? numberWithCommas(round(saleReview[`Sales_${month}`]))
              : salesPeriod === "lastMonth" &&
                month > 1 &&
                !isNaN(round(saleReview[`Sales_${month - 1}`]))
              ? numberWithCommas(round(saleReview[`Sales_${month - 1}`]))
              : salesPeriod === "lastMonth" &&
                month === 1 &&
                !isNaN(round(lastYearSaleReviewItem[`Sales_12`]))
              ? numberWithCommas(round(lastYearSaleReviewItem[`Sales_12`]))
              : ytdSales > 0 && !isNaN(ytdSales)
              ? numberWithCommas(ytdSales)
              : "-",
          SalesIx:
            salesPeriod === "thisMonth" &&
            !isNaN(parseInt(saleReview[`Sales_${month}`])) &&
            parseInt(saleReview[`Target_${month}`]) !== 0 &&
            !isNaN(parseInt(saleReview[`Target_${month}`]))
              ? parseInt(
                  (saleReview[`Sales_${month}`] /
                    saleReview[`Target_${month}`]) *
                    100
                ) + "%"
              : salesPeriod === "lastMonth" &&
                month > 1 &&
                !isNaN(parseInt(saleReview[`Sales_${month - 1}`])) &&
                parseInt(saleReview[`Target_${month - 1}`]) !== 0 &&
                !isNaN(parseInt(saleReview[`Target_${month - 1}`]))
              ? parseInt(
                  (saleReview[`Sales_${month - 1}`] /
                    saleReview[`Target_${month - 1}`]) *
                    100
                ) + "%"
              : salesPeriod === "lastMonth" &&
                month === 1 &&
                !isNaN(parseInt(lastYearSaleReviewItem[`Sales_12`])) &&
                parseInt(lastYearSaleReviewItem[`Target_12`]) !== 0 &&
                !isNaN(parseInt(lastYearSaleReviewItem[`Target_12`]))
              ? parseInt(
                  (lastYearSaleReviewItem[`Sales_12`] /
                    lastYearSaleReviewItem[`Target_12`]) *
                    100
                ) + "%"
              : salesPeriod === "yearToDate" &&
                parseInt((ytdSales / ytdTarget) * 100) > 0 &&
                parseInt((ytdSales / ytdTarget) * 100) < Infinity
              ? parseInt((ytdSales / ytdTarget) * 100) + "%"
              : "-",
          SalesExt:
            salesPeriod === "thisMonth"
              ? numberWithCommas(
                  extCalc(parseInt(saleReview[`Sales_${month}`]))
                )
              : "-",
          SalesExtIx:
            salesPeriod === "thisMonth" &&
            !isNaN(parseInt(saleReview[`Sales_${month}`])) &&
            parseInt(saleReview[`Target_${month}`]) !== 0 &&
            !isNaN(parseInt(saleReview[`Target_${month}`]))
              ? parseInt(
                  (extCalc(parseInt(saleReview[`Sales_${month}`])) /
                    saleReview[`Target_${month}`]) *
                    100
                ) + "%"
              : "-",
          OpenOrders:
            salesPeriod === "thisMonth" &&
            !isNaN(parseInt(saleReview[`Orders_${month}`]))
              ? numberWithCommas(parseInt(saleReview[`Orders_${month}`]))
              : salesPeriod === "lastMonth" &&
                month > 1 &&
                !isNaN(parseInt(saleReview[`Orders_${month - 1}`]))
              ? numberWithCommas(parseInt(saleReview[`Orders_${month - 1}`]))
              : salesPeriod === "lastMonth" &&
                month === 1 &&
                !isNaN(parseInt(lastYearSaleReviewItem[`Orders_12`]))
              ? numberWithCommas(parseInt(lastYearSaleReviewItem[`Orders_12`]))
              : ytdOrders > 0
              ? numberWithCommas(ytdOrders)
              : 0,
          SalesPlusOrders:
            salesPeriod === "thisMonth"
              ? calcSalesPlusOrders(
                  parseInt(saleReview[`Sales_${month}`]),
                  parseInt(saleReview[`Orders_${month}`])
                )
              : salesPeriod === "lastMonth" && month > 1
              ? calcSalesPlusOrders(
                  parseInt(saleReview[`Sales_${month - 1}`]),
                  parseInt(saleReview[`Orders_${month - 1}`])
                )
              : salesPeriod === "lastMonth" && month === 1
              ? calcSalesPlusOrders(
                  parseInt(lastYearSaleReviewItem[`Sales_12`]),
                  parseInt(lastYearSaleReviewItem[`Orders_12`])
                )
              : salesPeriod === "yearToDate"
              ? calcSalesPlusOrders(parseInt(ytdSales), parseInt(ytdOrders))
              : "-",
          Achieved:
            foundInAgentsAnalysis &&
            !isNaN(foundInAgentsAnalysis.agentAchievedValue)
              ? numberWithCommas(foundInAgentsAnalysis.agentAchievedValue)
              : "-",
          NotAchieved:
            foundInAgentsAnalysis &&
            !isNaN(foundInAgentsAnalysis.agentNotAchievedValue)
              ? numberWithCommas(foundInAgentsAnalysis.agentNotAchievedValue)
              : "-",
          YA: lastMonthTrends(
            month,
            saleReview,
            lastYearItems,
            twoYearsAgoItems,
            "YA"
          ),
          P3M: lastMonthTrends(
            month,
            saleReview,
            lastYearItems,
            twoYearsAgoItems,
            "P3M"
          ),
          P6M: lastMonthTrends(
            month,
            saleReview,
            lastYearItems,
            twoYearsAgoItems,
            "P6M"
          ),
          P12M: lastMonthTrends(
            month,
            saleReview,
            lastYearItems,
            twoYearsAgoItems,
            "P12M"
          ),
          UpdateDate: saleReview.UpdateDate,
        };
        return obj;
      });
    }

    if (currentYearItems?.length > 0 || lastYearData?.length > 0) {
      if (AgentPermission === "agentonly" && UserType === "agent")
        dispatch(
          setSalesByAgentData(
            specificSalesByAgentData()?.filter(
              (item) => item.AgentID === AgentID
            )
          )
        );
      else if (AgentPermission === "teamonly" && UserType === "teamleader") {
        dispatch(
          setSalesByAgentData(
            specificSalesByAgentData()?.filter((item) =>
              [...TeamAgentsIDs?.split("+"), AgentID]?.includes(item.AgentID)
            )
          )
        );
      } else dispatch(setSalesByAgentData(specificSalesByAgentData()));
    }
  }, [
    salesPeriod,
    currentYearItems,
    lastYearData,
    twoYearsAgoData,
    keyOpportunitiesTableDataCopy,
    AgentID,
    AgentPermission,
    UserType,
    TeamAgentsIDs,
  ]);

  return (
    <div className="home">
      <Subtitle
        homeData={keyOpportunitiesTableDataCopy}
        dayData={agentDayData}
      />
      {UserType === "agent" && isMobile && (
        <KeyOpportunitiesTable setAgentDayData={setAgentDayData} />
      )}
      {/* Web platform: */}
      {(UserType === "manager" ||
        UserType === "teamleader" ||
        (UserType === "agent" && !isMobile)) && (
        <>
          {ShowCustomersOverview_Flag === "true" && (
            <>
              <>
                <CustomersOverviewTable />
                <CustomerOverviewDetails />
              </>
            </>
          )}
          {/* {ShowKeyOpportunities_Flag === "true" && (
            <>
              <div className="home-page-separator-div"></div>
              <KeyOpportunitiesTable setAgentDayData={setAgentDayData} />
            </>
          )} */}
          {ShowGrowthAnalysis_Flag === "true" && (
            <>
              <div className="home-page-separator-div"></div>
              <GrowthAnalysis />
            </>
          )}
          <div className="sales-review">
            <div className="home-page-separator-div"></div>
            <SalesReviewTable
              setSalesPeriod={setSalesPeriod}
              salesPeriod={salesPeriod}
            />
          </div>
          <div className="home-page-separator-div"></div>
        </>
      )}
      <Footer />
    </div>
  );
}
