import TextColumnFilter from "../../../Filters/TextColumnFilter";
import NumberColumnFilter from "../../../Filters/NumberColumnFilter";
import CheckedColumnFilter from "../../../Filters/CheckedColumnFilter";

import { sortTypes } from "../../../../utils/sort";
import PercentageColumnFilter from "../../../Filters/PercentageColumnFilter";
import DateColumnFilter from "../../../Filters/DateColumnFilter";
import { getMonthForHeader } from "../../../../utils/helpers";

const staticAppDate =
  process.env.REACT_APP_NODE_ENV === "ir-development"
    ? process.env.REACT_APP_DEMO_DATE
    : false;

const date = staticAppDate ? new Date(staticAppDate) : new Date();

const currentMonth = `${date.getMonth() + 1}/${date
  .getFullYear()
  .toString()
  ?.slice(2)}`;

export const engCOLUMNS = [
  {
    Header: "Product Name",
    accessor: "productName",
    filter: "text",
    Filter: TextColumnFilter,
    canFilter: true,
  },
  {
    Header: "Code",
    accessor: "code",
    filter: "text",
    Filter: TextColumnFilter,
    canFilter: true,
  },
  {
    Header: "Sales " + currentMonth + " $",
    accessor: "currentMonth",
    filter: "number",
    Filter: NumberColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: `Sales ${getMonthForHeader("threeMonthsAgo")}-${getMonthForHeader(
      "lastMonth"
    )} $`,
    accessor: "p3m",
    filter: "number",
    Filter: NumberColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: `Sales ${getMonthForHeader("sixMonthsAgo")}-${getMonthForHeader(
      "fourMonthsAgo"
    )} $`,
    accessor: "p6mToP3m",
    filter: "number",
    Filter: NumberColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: "% change",
    accessor: "changeBetweenPeriods",
    filter: "number",
    Filter: PercentageColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: "Last Purchase",
    accessor: "LastOrderDate",
    filter: "date",
    Filter: DateColumnFilter,
    canFilter: true,
    sortType: sortTypes.lastOrderDate,
  },
  {
    Header: "Purchase Probability",
    accessor: "Probability",
    filter: "checked",
    Filter: CheckedColumnFilter,
    canFilter: true,
    sortType: sortTypes.probability,
  },
];
