import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import RevNavigator from "../../components/RevNavigator/RevNavigator";
import "./RevNavigatorPage.css";
import RevNavigatorTable from "../../components/Tables/RevNavigatorTable/RevNavigatorTable";
import { setFetchedRevNav_CustomersData } from "../../store/features/dataSlice";
import revNavigatorService from "../../services/revNavigator";
import {
  setRevNavAgentsMap,
  setRevNavAgentsMapByAgentID,
  setRevNavCheckedAgents,
  setRevNavGraphData,
  setRevNavItemsByItemData,
  setRevNavResData,
  setUniqueAgentIDs,
} from "../../store/features/revNavigatorSlice";
import { getRevNavCoordinates } from "../../utils/helpers";

export default function RevNavigatorPage() {
  // const InsightingCustomerID = useSelector(
  //   (state) => state.userSlice.user.metadata.InsightingCustomerID
  // );
  // const UserType = useSelector(
  //   (state) => state.userSlice.user.metadata.UserType
  // );
  let {
    UserType,
    InsightingCustomerID,
    Language,
    ManagerID,
    AgentPermission,
    TeamAgentsIDs,
    AgentID,
  } = JSON.parse(sessionStorage.getItem("userMetadata"));

  const revNavResData = useSelector(
    (state) => state.revNavigatorSlice.revNavResData,
    shallowEqual
  );
  const revNavSelectedPeriod = useSelector(
    (state) => state.revNavigatorSlice.revNavSelectedPeriod
  );
  const revNavCheckedAgents = useSelector(
    (state) => state.revNavigatorSlice.revNavCheckedAgents,
    shallowEqual
  );
  const uniqueAgentIDs = useSelector(
    (state) => state.revNavigatorSlice.uniqueAgentIDs,
    shallowEqual
  );
  const revNavTableMode = useSelector(
    (state) => state.revNavigatorSlice.revNavTableMode
  );
  const staticAppDate = useSelector((state) => state.globalSlice.staticAppDate);
  const date = staticAppDate ? new Date(staticAppDate) : new Date();

  // const [processedPeriods, setProcessedPeriods] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    async function getRevNavResData() {
      try {
        let Items = [];
        if (UserType === "teamleader" && AgentPermission === "teamonly") {
          const agents = TeamAgentsIDs?.split("+");
          const response =
            await revNavigatorService.getRevNavigator_Main_DataByTeamLeader(
              agents,
              InsightingCustomerID,
              date
            );
          if (response) Items.push(...response);
        } else if (UserType === "agent" && AgentPermission === "agentonly") {
          const response =
            await revNavigatorService.getRevNavigator_Main_DataByAgent(
              AgentID,
              InsightingCustomerID,
              date
            );
          if (response) {
            Items.push(...response);
          }
        } else {
          //manager or agent or team leader with no restrictions
          const response =
            await revNavigatorService.getRevNavigator_Main_DataByManager(
              InsightingCustomerID,
              date
            );

          if (response) Items.push(...response);
        }

        if (Items?.length > 0) {
          let agentsMap = [];
          let uniqueAgentsList = Items.reduce((acc, item) => {
            if (
              item.AgentID &&
              !acc?.includes(item.AgentID) &&
              item.AgentID !== "Total"
            ) {
              acc.push(item.AgentID);
              agentsMap.push({
                agentID: item.AgentID,
                agentName: item.AgentName,
              });
            }
            return acc;
          }, []);

          dispatch(setRevNavAgentsMap(agentsMap));
          dispatch(
            setRevNavAgentsMapByAgentID(
              agentsMap.reduce((acc, agent) => {
                acc[agent.agentID] = agent.agentName;
                return acc;
              }, {})
            )
          );
          dispatch(setRevNavResData(Items));
          dispatch(
            setUniqueAgentIDs(
              uniqueAgentsList
              // .map((agent) => ({
              //   name: agent,
              //   code: agent,
              //   value: agent,
              //   label: agent,
              //   isSelected: false,
              // }))
            )
          );
          if (UserType === "agent" && AgentPermission !== "agentonly") {
            dispatch(
              setRevNavCheckedAgents(
                uniqueAgentsList.reduce((acc, current) => {
                  acc[current] = current === AgentID ? true : false;
                  return acc;
                }, {})
              )
            );
          } else {
            dispatch(
              setRevNavCheckedAgents(
                uniqueAgentsList.reduce((acc, current) => {
                  acc[current] = true;
                  return acc;
                }, {})
              )
            );
          }
        }
      } catch (error) {
        console.error("Error fetching RevNavigator_Main data:", error);
      }
    }
    if (!Object.keys(revNavCheckedAgents).length) getRevNavResData();
  }, []);

  useEffect(async () => {
    async function fetchRevNavCustomersData() {
      let Items = [];
      let itemsByitemRes = [];
      if (UserType === "teamleader" && AgentPermission === "teamonly") {
        const agents = TeamAgentsIDs?.split("+");
        const response =
          await revNavigatorService.getRevNavigator_CustomersData_DataByTeamLeader(
            agents,
            InsightingCustomerID,
            date
          );
        itemsByitemRes =
          await revNavigatorService.getRevNavigator_ItemsData_ByItem_DataByTeamLeader(
            agents,
            InsightingCustomerID,
            date
          );
        if (response) Items.push(...response);
      } else if (UserType === "agent" && AgentPermission === "agentonly") {
        const response =
          await revNavigatorService.getRevNavigator_CustomersData_DataByAgent(
            AgentID,
            InsightingCustomerID,
            date
          );
        itemsByitemRes =
          await revNavigatorService.getRevNavigator_ItemsData_ByItem_DataByAgent(
            AgentID,
            InsightingCustomerID,
            date
          );
        if (response) {
          Items.push(...response);
        }
      } else {
        //manager or agent or team leader with no restrictions
        const response =
          await revNavigatorService.getRevNavigator_CustomersData_DataByManager(
            InsightingCustomerID,
            date
          );
        itemsByitemRes =
          await revNavigatorService.getRevNavigator_ItemsData_ByItem_DataByManager(
            InsightingCustomerID,
            date
          );
        if (response) Items.push(...response);
        // set the values for periods after data returned
      }
      const keysToConvert = [
        "P2M_YA",
        "P2M",
        "P3M_YA",
        "P3M",
        "LastMonth_YA",
        "LastMonth",
      ];
      Items?.forEach((item) => {
        keysToConvert?.forEach((key) => {
          if (item[key] === "False") {
            item[key] = false;
          } else if (typeof item[key] === "string") {
            item[key] = item[key]
              .replace(/[\[\]]/g, "")
              .split(",")
              .map((item) => (parseFloat(item) ? parseFloat(item) : item));
          }
        });
      });
      
      itemsByitemRes?.forEach((item) => {
        keysToConvert?.forEach((key) => {
          if (item[key] === "False") {
            item[key] = false;
          } else if (typeof item[key] === "string") {
            item[key] = item[key]
            .replace(/[\[\]]/g, "")
            .split(",")
            .map((item) => (parseFloat(item) ? parseFloat(item) : item));
          }
        });
      });
      
      dispatch(setRevNavItemsByItemData(itemsByitemRes));
      dispatch(setFetchedRevNav_CustomersData(Items));
    }

    fetchRevNavCustomersData();
  }, []);

  useEffect(
    function createGraphDataPoints() {
      if (revNavResData?.length > 0 && uniqueAgentIDs?.length > 0) {
        const processedPeriodsMap = {};
        const spacesAndBrackets = /[\[\]\s]/g;

        revNavResData?.forEach((item) => {
          const period = item.Period;
          const firstPeriod = item.FirstPeriod?.replace(
            spacesAndBrackets,
            ""
          ).replace(",", "-");
          const secondPeriod = item.SecondPeriod?.replace(
            spacesAndBrackets,
            ""
          ).replace(",", "-");
          if (
            period &&
            !processedPeriodsMap[period] &&
            [
              "LastMonth",
              "LastMonth_YA",
              "P2M",
              "P2M_YA",
              "P3M",
              "P3M_YA",
            ]?.includes(period)
          ) {
            processedPeriodsMap[period] = {
              Period: period,
              FirstPeriod: firstPeriod,
              SecondPeriod: secondPeriod,
            };
          }
        });
        // setProcessedPeriods(Object.values(processedPeriodsMap));
        dispatch(
          setRevNavGraphData(
            getRevNavCoordinates(
              UserType,
              revNavResData,
              revNavSelectedPeriod,
              revNavCheckedAgents,
              uniqueAgentIDs,
              Language,
              revNavTableMode
            )
          )
        );
        // }
      }
    },
    [revNavSelectedPeriod, uniqueAgentIDs]
  );

  return (
    <>
      <RevNavigator />
      <RevNavigatorTable />
    </>
  );
}
