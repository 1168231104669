import map from "lodash/map";

export const prioritiesMapForKeyOpp = {
  1: "High",
  0: "Regular",
  "-1": "Discard",
};
export const prioritiesMapForTrends = { 1: "High", 0: "Regular", "-1": "Low" };
export const urgencyMapForTrends = {
  hebrew: { 9: "גבוהה", 6: "בינונית", 3: "נמוכה", 1: "ללא" },
  english: { 9: "High", 6: "Medium", 3: "Low", 1: "No" },
};

// export const opportunitiesMapForCustomersOverview = {
//   hebrew: {
//     Insight1_agg: "הזדמנות 1",
//     Insight2_agg: "הזדמנות 2",
//     Insight3_agg: "הזדמנות 3",
//     Insight1During_agg: "הזדמנות 1",
//     Insight2During_agg: "הזדמנות 2",
//     Insight3During_agg: "הזדמנות 3",
//     Insight4During_agg: "הזדמנות 4",
//     undefined: "ללא הזדמנויות",
//   },
//   english: {
//     Insight1_agg: "reducing items",
//     Insight2_agg: "no orders",
//     Insight3_agg: "upsell",
//     Insight1During_agg: "reducing items",
//     Insight2During_agg: "no orders",
//     Insight3During_agg: "upsell",
//     Insight4During_agg: "low sales",
//     undefined: "no opportunities",
//   },
// };

export const keyOppPriorities = map(prioritiesMapForKeyOpp, (label, value) => ({
  label,
  value,
}));

export const trendsPriorities = map(prioritiesMapForTrends, (label, value) => ({
  label,
  value,
}));

export const operatorsMapForFilters = {
  hebrew: {
    ">": "גדול",
    "<": "קטן",
  },
  english: {
    ">": "greater",
    "<": "lower",
  },
};
