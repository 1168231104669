export const hebCOLUMNS = [
    {
      Header: "פרק זמן",
      accessor: "TimeFrame",
    },
    {
      Header: "לקוח",
      accessor: "Customer",
     },
    {
      Header: "קבוצה",
      accessor: "Group",
      }
  ];
  