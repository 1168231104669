import { insightsMap } from "../constants/insights-map";
import dateUtils from "./date";
import numberUtils from "./number";
import moment from "moment";
import keys from "lodash/keys";
import values from "lodash/values";
import { urgencyMapForTrends } from "../constants/filtersText-map";
import { dictionary } from "../constants/dictionary";
import { labelTextMap } from "../constants/revNavLabelsDesc-map";

const { kFormat } = numberUtils;
const staticAppDate =
  process.env.REACT_APP_NODE_ENV === "ir-development"
    ? process.env.REACT_APP_DEMO_DATE
    : false;

const CurrencyMap = {
  ils: "₪",
  usd: "$",
  eur: "€",
  gbp: "£",
};

const { round } = Math;
const date = staticAppDate ? new Date(staticAppDate) : new Date();
const currentMonth = staticAppDate ? new Date(staticAppDate) : new Date();
const lastMonth = staticAppDate ? new Date(staticAppDate) : new Date();
const twoMonthsAgo = staticAppDate ? new Date(staticAppDate) : new Date();
const threeMonthsAgo = staticAppDate ? new Date(staticAppDate) : new Date();
const fourMonthsAgo = staticAppDate ? new Date(staticAppDate) : new Date();
const sixMonthsAgo = staticAppDate ? new Date(staticAppDate) : new Date();
const sevenMonthsAgo = staticAppDate ? new Date(staticAppDate) : new Date();
const currentMonthYearAgo = staticAppDate
  ? new Date(staticAppDate)
  : new Date();
const lastMonthYearAgo = staticAppDate ? new Date(staticAppDate) : new Date();
const twoMonthsAgoYearAgo = staticAppDate
  ? new Date(staticAppDate)
  : new Date();

currentMonth.setDate(15);
lastMonth.setDate(15);
twoMonthsAgo.setDate(15);
threeMonthsAgo.setDate(15);
fourMonthsAgo.setDate(15);
sevenMonthsAgo.setDate(15);
currentMonthYearAgo.setDate(15);
lastMonthYearAgo.setDate(15);
twoMonthsAgoYearAgo.setDate(15);

currentMonth.setMonth(date.getMonth());
lastMonth.setMonth(date.getMonth() - 1);
twoMonthsAgo.setMonth(date.getMonth() - 2);
threeMonthsAgo.setMonth(date.getMonth() - 3);
fourMonthsAgo.setMonth(date.getMonth() - 4);
sixMonthsAgo.setMonth(date.getMonth() - 6);
sevenMonthsAgo.setMonth(date.getMonth() - 7);
currentMonthYearAgo.setMonth(date.getMonth() - 12);
lastMonthYearAgo.setMonth(date.getMonth() - 13);
twoMonthsAgoYearAgo.setMonth(date.getMonth() - 14);

export function dateObjToStr(date) {
  const strArr = date.toString()?.split(" ");
  return `${date.getMonth() + 1}/${strArr[3]?.slice(2)}`;
}

export function getMonthForHeader(month) {
  switch (month) {
    case "currentMonth":
      return dateObjToStr(currentMonth);
    case "lastMonth":
      return dateObjToStr(lastMonth);
    case "twoMonthsAgo":
      return dateObjToStr(twoMonthsAgo);
    case "threeMonthsAgo":
      return dateObjToStr(threeMonthsAgo);
    case "fourMonthsAgo":
      return dateObjToStr(fourMonthsAgo);
    case "sixMonthsAgo":
      return dateObjToStr(sixMonthsAgo);
    case "sevenMonthsAgo":
      return dateObjToStr(sevenMonthsAgo);
    case "currentMonthYearAgo":
      return dateObjToStr(currentMonthYearAgo);
    case "lastMonthYearAgo":
      return dateObjToStr(lastMonthYearAgo);
    case "twoMonthsAgoYearAgo":
      return dateObjToStr(twoMonthsAgoYearAgo);
    default:
      break;
  }
}

export function setCurrencyForTableHeaders(Currency, columns, Language) {
  const currentCurrency =
    Language === "hebrew" ? "₪" : Language === "english" ? "$" : "$";
  const newColumns = columns?.map((column) =>
    column.Header?.includes(currentCurrency)
      ? {
          ...column,
          Header: column.Header.replace(currentCurrency, CurrencyMap[Currency]),
        }
      : column
  );
  return newColumns;
}

export function isSorted(column) {
  return column.isSorted ? (
    !column.isSortedDesc ? (
      <span>&#11014;</span>
    ) : (
      <span>&#11015;</span>
    )
  ) : (
    ""
  );
}

export function setCurrencyForGroupedTableHeaders(
  Currency,
  columns,
  Language,
  newHeader
) {
  const newGroupedColumns = setCurrencyForTableHeaders(
    Currency,
    columns,
    Language,
    newHeader
  ).map((column) =>
    column.columns
      ? {
          ...column,
          columns: setCurrencyForTableHeaders(
            Currency,
            column.columns,
            Language
          ),
        }
      : { ...column }
  );
  return newGroupedColumns;
}

export function setProgressVsHeaderForOverviewTable(newHeader, columns) {
  const newColumns = columns?.map((column) =>
    column.accessor === "progressExpectation"
      ? {
          ...column,
          Header: newHeader,
        }
      : column
  );
  return newColumns;
}

export function removePrefix(value) {
  if (typeof value === "string" && value.startsWith("NO__")) {
    return value?.slice(4); // Remove first 3 characters ("NO__")
  }
  return value;
}

export function usDateFormat(date) {
  if (date?.includes("/")) {
    date = date?.split("/");
    if (parseInt(date[1]) < 10 && date[1]?.includes("0"))
      date[1] = date[1].replace("0", "");
    if (parseInt(date[0]) < 10 && date[0]?.includes("0"))
      date[0] = date[0].replace("0", "");
    const usDate = [date[1], date[0], date[2]];
    return usDate.join("/");
  }
}

export function goToCustomerPage(
  CustomerID,
  InsightingCustomerID,
  CustomerName,
  AgentName,
  Language,
  allKeyOpportunitiesForCustomerPage,
  history
) {
  const specificData = allKeyOpportunitiesForCustomerPage
    ?.filter((item) => item.CustomerID === CustomerID)
    ?.filter(
      (item) =>
        item.InsightType === "Insight1" ||
        item.InsightType === "Insight1During" ||
        item.InsightType === "Insight2" ||
        item.InsightType === "Insight2During" ||
        item.InsightType === "Insight3" ||
        item.InsightType === "Insight3During" ||
        item.InsightType === "Insight4During" ||
        item.InsightType === "Insight5" ||
        item.InsightType === "Insight5During"
    );

  let dataForSubtitle = specificData?.filter(
    (item) => item.MonthOfInsight === dateUtils.currentMonth()
  );
  let data = specificData
    ?.filter((item) => item.MonthOfInsight === dateUtils.currentMonth())
    .map((item) => {
      return {
        Code:
          item.InsightType === "Insight3" ||
          item.InsightType === "Insight3During" ||
          item.InsightType === "Insight5" ||
          item.InsightType === "Insight5During"
            ? item.ItemName &&
              item.ItemName?.replace(/[^ -~]+/g, "").toLowerCase() !== "na"
              ? item.ItemCatalogNumber
              : "-"
            : // ? item.ItemVariant.replace(/"|'|@/g, "")
            item.InsightType === "Insight1" ||
              item.InsightType === "Insight1During"
            ? item.ItemCatalogNumber
            : "-",
        ProductName:
          item.InsightType === "Insight3" ||
          item.InsightType === "Insight3During" ||
          item.InsightType === "Insight5" ||
          item.InsightType === "Insight5During"
            ? // ? item.ItemVariant.replace(/"|'|@/g, "")
              item.ItemName &&
              item.ItemName?.replace(/[^ -~]+/g, "").toLowerCase() !== "na"
              ? item.ItemName
              : item.ItemVariant
            : item.InsightType === "Insight1" ||
              item.InsightType === "Insight1During"
            ? // ? item.ItemName.replace(/"|'|@/g, "")
              item.ItemName
            : item.InsightType === "Insight2" ||
              item.InsightType === "Insight2During"
            ? "-"
            : "-",
        Value: numberUtils.numberWithCommas(round(item.AggPotentialValue)),
        Progress: parseInt(item.CalcProgress * 100) + "%",
        InsightType: isOnGoingInsight(item.CreatedDate)
          ? insightsMap[Language][item.InsightType] + insightsMap[Language].New
          : insightsMap[Language][item.InsightType],
        UpdateDate: item.UpdateDate,
        PotentialValueInUnits: numberUtils.numberWithCommas(
          !item.PotentialValueInUnits ||
            isNaN(item.PotentialValueInUnits) ||
            parseInt(item.PotentialValueInUnits) === 0 ||
            item.InsightType === "Insight5" ||
            item.InsightType === "Insight5During"
            ? "-"
            : parseInt(item.PotentialValueInUnits)
        ),
        KeyData:
          // Language === "english" && item.InsightType === "Insight2"
          //   ? `Last purchase ${item.Param2?.replace('_','/')}, purchases every ${parseFloat(
          //       item.Param1
          //     ).toFixed(1)} months`
          //   :
          Language === "english" &&
          (item.InsightType?.includes("Insight1") ||
            item.InsightType?.includes("Insight2") ||
            item.InsightType?.includes("Insight5"))
            ? item.Param3 === "1"
              ? `Sold once, on ${item.Param2?.replace("_", "/")}${
                  item.Param4 > 0 && !isNaN(parseFloat(item.Param4))
                    ? `, number of available units is ${parseFloat(
                        item.Param4
                      ).toFixed(1)}`
                    : ""
                }`
              : `Last month of purchase ${item.Param2?.replace(
                  "_",
                  "/"
                )}, typically purchased every ${parseFloat(item.Param1).toFixed(
                  1
                )} months${
                  item.Param4 > 0 && !isNaN(parseFloat(item.Param4)) // || insightType !== 5
                    ? `, number of available units is ${parseFloat(
                        item.Param4
                      ).toFixed(1)}`
                    : ""
                }`
            : Language === "english" && item.InsightType === "Insight2"
            ? `Has not purchased since ${item.Param2?.replace(
                "_",
                "/"
              )}, typically purchases every ${parseFloat(item.Param1).toFixed(
                1
              )} months.`
            : Language === "english" && item.InsightType === "Insight3"
            ? `Bought by ${item.Param1} similar customers `
            : // Contribute ${parseFloat(
            //   item.Param2?.replace('_','/')
            // ).toFixed(1)} % of total sales`
            Language === "english" && item.InsightType === "Insight4During"
            ? `Customer purchased only ${parseInt(
                parseFloat(item?.Param1) * 100
              )}% of average purchase`
            : Language === "english" && item.InsightType === "Insight2During"
            ? `No orders this month, typically purchases every ${parseFloat(
                item.Param1
              ).toFixed(1)} months`
            : Language === "english" && item.InsightType === "Insight4During"
            ? `customer purchased only ${parseInt(
                parseFloat(item?.Param1) * 100
              )}% of average purchase`
            : // Language === "hebrew" && item.InsightType === "Insight2"
            // ? `רכש לאחרונה ב ${item.Param2?.replace('_','/')}, בד״כ רוכש כל ${parseFloat(
            //     item.Param1
            //   ).toFixed(1)} חודשים`
            // :
            Language === "hebrew" &&
              (item.InsightType?.includes("Insight1") ||
                item.InsightType?.includes("Insight2") ||
                item.InsightType?.includes("Insight5"))
            ? item.Param3 === "1"
              ? `רכישה בודדת, בוצעה ב: ${item.Param2?.replace("_", "/")}${
                  item.Param4 > 0 && !isNaN(parseFloat(item.Param4))
                    ? `, כמות זמינה במלאי: ${parseFloat(item.Param4).toFixed(
                        1
                      )}`
                    : ""
                }`
              : `רכש לאחרונה ב ${item.Param2?.replace(
                  "_",
                  "/"
                )}, בד״כ רוכש כל ${parseFloat(item.Param1).toFixed(1)} חודשים${
                  item.Param4 > 0 && !isNaN(parseFloat(item.Param4))
                    ? `, כמות זמינה במלאי: ${parseFloat(item.Param4).toFixed(
                        1
                      )}`
                    : ""
                }`
            : Language === "hebrew" && item.InsightType === "Insight2"
            ? `לא רכש מאז ${item.Param2?.replace(
                "_",
                "/"
              )}, בד״כ רוכש כל ${parseFloat(item.Param1).toFixed(1)} חודשים`
            : Language === "hebrew" && item.InsightType === "Insight3"
            ? `נרכש על ידי ${item.Param1} לקוחות דומים`
            : //  מהווה ${parseFloat(
            //     item.Param2?.replace('_','/')
            //   ).toFixed(1)} % מסך הרכישות של לקוחות אלו`
            Language === "hebrew" && item.InsightType === "Insight4During"
            ? `הלקוח רכש ${parseInt(
                parseFloat(item?.Param1) * 100
              )}% מהממוצע החודשי עד כה החודש`
            : Language === "hebrew" && item.InsightType === "Insight2During"
            ? `הלקוח לא רכש עד כה החודש, בד״כ רוכש כל ${parseFloat(
                item.Param1
              ).toFixed(1)} חודשים`
            : "-",
        CreatedDate: item.CreatedDate,
        Param3: item.Param3,
        Param5: item.Param5,
        Param4:
          item.Param4?.length > 0
            ? item.Param4?.replace(/[\[\]']+/g, "")
                ?.split(",")
                ?.map((item) => removePrefix(item?.trim()))
                : false,
        OriginalInsightType: item.InsightType,
        ItemVariant: item.ItemVariant,
      };
    });

  data = data.some((opportunity) =>
    opportunity.InsightType?.split("|")[0]?.includes(
      insightsMap[Language].Insight2During
    )
  )
    ? data?.filter(
        (opportunity) =>
          opportunity.InsightType?.split("|")[0] ===
          insightsMap[Language].Insight2During
      )
    : data;

  data =
    data.some((opportunity) =>
      opportunity.InsightType?.split("|")[0]?.includes(
        insightsMap[Language].Insight1During
      )
    ) &&
    data.some((opportunity) =>
      opportunity.InsightType?.split("|")[0]?.includes(
        insightsMap[Language].Insight4During
      )
    )
      ? data?.filter(
          (opportunity) =>
            opportunity.InsightType?.split("|")[0] !==
            insightsMap[Language].Insight4During
        )
      : data;

  dataForSubtitle = dataForSubtitle.some((opportunity) =>
    opportunity.InsightType?.split("|")[0]?.includes("Insight2During")
  )
    ? dataForSubtitle?.filter(
        (opportunity) =>
          opportunity.InsightType?.split("|")[0] === "Insight2During"
      )
    : dataForSubtitle;

  dataForSubtitle =
    dataForSubtitle.some(
      (opportunity) =>
        opportunity.InsightType?.split("|")[0]?.includes("Insight1During") ||
        opportunity.InsightType?.split("|")[0]?.includes("Insight5During")
    ) &&
    dataForSubtitle.some((opportunity) =>
      opportunity.InsightType?.split("|")[0]?.includes("Insight4During")
    )
      ? dataForSubtitle?.filter(
          (opportunity) =>
            opportunity.InsightType?.split("|")[0] !== "Insight4During"
        )
      : dataForSubtitle;

  history.push("/customer", {
    CustomerID,
    InsightingCustomerID,
    CustomerName,
    AgentName,
    data,
    dataForSubtitle,
  });
  return;
}

export const isOnGoingInsight = (createdDate) => {
  const todaysDate = moment(moment().format("DD/MM/YYYY"), "D/M/YYYY");
  const dayToCompare = moment(createdDate?.split("_").join("/"), "D/M/YYYY");
  return todaysDate.diff(dayToCompare, "days") < 3 ? true : false;
};

export const getLastUpdate = (data) => {
  const dates = data
    .filter((item) => item.UpdateDate)
    .map(({ UpdateDate }) => UpdateDate?.split("_").reverse().join("-"));
  return dateUtils.sortDates(dates);
};

export const getRowsCsv = (
  rows,
  filterableColumns,
  language = "english",
  includeSums = false
) => {
  if (filterableColumns?.length > 0) {
    const headerByAccessor = filterableColumns?.reduce(
      (acc, { accessor, Header }) => ({ ...acc, [accessor]: Header }),
      {}
    );
    const headers = values(headerByAccessor);
    const body = rows.map((row) => {
      const valuesByAccessor = row.values.urgency
        ? {
            ...row.values,
            urgency: urgencyMapForTrends[language][row.values.urgency],
            // customerCode: `${row.values.customerCode}`,
            // customerCode: `"${row.values.customerCode}"`,
          }
        : row.values;

      return keys(headerByAccessor).map((accessor) => {
        // Check if the value is a string before using replace
        const value = valuesByAccessor[accessor];

        if (typeof value === "string") {
          // Remove [u+2067] and [u+2069] characters from string values
          const cleanedValue = value.replace(/\u2067|\u2069/g, "");
          return cleanedValue;
        } else {
          // Return the value as is for non-string values
          return value;
        }
      });
    });
    if (includeSums) {
      if (
        typeof includeSums === "string" &&
        includeSums.toLowerCase() === "customersoverview"
      ) {
        const currentMonthSum = rows.reduce(
          (sum, row) =>
            sum +
            (typeof row?.original.achieved === "string"
              ? parseInt(row?.original.achieved?.replaceAll(",", ""))
              : typeof row?.original.achieved === "number"
              ? parseInt(row?.original.achieved)
              : 0),
          0
        );
        const comparisonValueSum = rows.reduce(
          (sum, row) =>
            sum +
            (typeof row?.original.comparisonValue === "string"
              ? parseInt(row?.original.comparisonValue?.replaceAll(",", ""))
              : typeof row?.original.comparisonValue === "number"
              ? parseInt(row?.original.comparisonValue)
              : 0),
          0
        );

        const openOrdersSum = rows.reduce(
          (sum, row) =>
            sum +
            (typeof row?.original.openOrders === "string"
              ? parseInt(row?.original.openOrders?.replaceAll(",", ""))
              : typeof row?.original.openOrders === "number"
              ? parseInt(row?.original.openOrders)
              : 0),
          0
        );
        const salesProgressSum = parseInt(
          100 * (currentMonthSum / comparisonValueSum)
        )
          ? parseInt(100 * (currentMonthSum / comparisonValueSum)) + "%"
          : "-";

        body.push([
          "Total",
          "",
          "",
          "",
          numberUtils.numberWithCommas(currentMonthSum),
          numberUtils.numberWithCommas(comparisonValueSum),
          salesProgressSum,
          numberUtils.numberWithCommas(openOrdersSum),
          "",
          "",
          "",
        ]);
      }
    }
    return [headers, ...body];
  }
};

export function getMonthName(monthNumber, language) {
  const monthNames = {
    english: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    hebrew: [
      "ינואר",
      "פברואר",
      "מרץ",
      "אפריל",
      "מאי",
      "יוני",
      "יולי",
      "אוגוסט",
      "ספטמבר",
      "אוקטובר",
      "נובמבר",
      "דצמבר",
    ],
  };

  const monthIndex = parseInt(monthNumber, 10) - 1;
  if (isNaN(monthIndex) || monthIndex < 0 || monthIndex > 11) {
    return null; // Invalid month number
  }

  return monthNames[language][monthIndex];
}

export function monthsBetween(date1, date2) {
  const months = {};

  while (date1 <= date2) {
    const year = date1.getFullYear();
    const month = date1.getMonth() + 1;
    const key = month;

    if (year !== 1970) {
      if (!months[year]) {
        months[year] = [];
      }

      months[year].push(key);
    }
    date1.setMonth(date1.getMonth() + 1);
  }
  return months;
}

export function getRevNavCoordinates(
  UserType,
  revNavResData,
  revNavSelectedPeriod,
  revNavCheckedAgents,
  uniqueAgentIDs,
  Language,
  revNavTableMode
) {
  if (!Object.keys(revNavCheckedAgents)?.length > 0)
    revNavCheckedAgents = uniqueAgentIDs?.reduce((acc, current) => {
      acc[current] = true;
      return acc;
    }, {});

  let relevantUserCoordinates;
  let relevantPeriodCoordinates;

  if (UserType === "manager") {
    relevantUserCoordinates = revNavResData?.filter(
      (i) => i.AgentID === "Total"
    );
    relevantPeriodCoordinates = relevantUserCoordinates.find(
      (i) => i.Period === revNavSelectedPeriod
    );
  }

  if (
    UserType === "manager" &&
    Object.keys(revNavCheckedAgents)?.length === uniqueAgentIDs?.length &&
    Object.values(revNavCheckedAgents).every((value) => value === true)
  ) {
    let relevantUserCoordinates = revNavResData?.filter(
      (i) => i.AgentID === "Total"
    );
    let relevantPeriodCoordinates = relevantUserCoordinates.find(
      (i) => i.Period === revNavSelectedPeriod
    );

    let {
      BaseSales,
      DistributionGains,
      DistributionLosses,
      LostCustomers,
      NewCustomers,
      Total,
      FirstPeriod,
      SecondPeriod,
    } = relevantPeriodCoordinates;

    BaseSales = Math.round(BaseSales) || 0;
    DistributionGains = Math.round(DistributionGains) || 0;
    DistributionLosses = Math.round(DistributionLosses) || 0;
    LostCustomers = Math.round(LostCustomers) || 0;
    NewCustomers = Math.round(NewCustomers) || 0;
    Total = Math.round(Total) || 0;

    return [
      {
        barIndex: 0,
        labelText: labelTextMap[Language]["BaseSales"],
        name: dictionary[Language].growthAnalysisGraph?.legend.BaseSales,
        value: [0, BaseSales],
        label: kFormat(BaseSales),
        tableMode: "B",
        selected: revNavTableMode === "B",
        firstPeriod: FirstPeriod,
        secondPeriod: SecondPeriod,
      },
      {
        barIndex: 1,
        labelText: labelTextMap[Language]["DistributionGains"],
        name: dictionary[Language].growthAnalysisGraph?.legend
          .DistributionGains,
        value: [BaseSales, BaseSales + DistributionGains],
        label: kFormat(DistributionGains),
        tableMode: "DG",
        selected: revNavTableMode === "DG",
      },
      {
        barIndex: 2,
        labelText: labelTextMap[Language]["DistributionLosses"],
        name: dictionary[Language].growthAnalysisGraph?.legend
          .DistributionLosses,
        value: [
          BaseSales + DistributionGains,
          BaseSales + DistributionGains + DistributionLosses,
        ],
        label: kFormat(DistributionLosses),
        tableMode: "DL",
        selected: revNavTableMode === "DL",
      },
      {
        barIndex: 3,
        labelText: labelTextMap[Language]["NewCustomers"],
        name: dictionary[Language].growthAnalysisGraph?.legend.NewCustomers,
        value: [
          BaseSales + DistributionGains + DistributionLosses,
          BaseSales + DistributionGains + DistributionLosses + NewCustomers,
        ],
        label: kFormat(NewCustomers),
        tableMode: "N",
        selected: revNavTableMode === "N",
      },
      {
        barIndex: 4,
        labelText: labelTextMap[Language]["LostCustomers"],
        name: dictionary[Language].growthAnalysisGraph?.legend.LostCustomers,
        value: [
          BaseSales + DistributionGains + DistributionLosses + NewCustomers,
          BaseSales +
            DistributionGains +
            DistributionLosses +
            NewCustomers +
            LostCustomers,
        ],
        label: kFormat(LostCustomers),
        tableMode: "L",
        selected: revNavTableMode === "L",
      },
      {
        barIndex: 5,
        labelText: labelTextMap[Language]["Total"],
        name: dictionary[Language].growthAnalysisGraph?.legend.Total,
        value: [
          0,
          BaseSales +
            DistributionGains +
            DistributionLosses +
            NewCustomers +
            LostCustomers,
        ],
        label: kFormat(
          BaseSales +
            DistributionGains +
            DistributionLosses +
            NewCustomers +
            LostCustomers
        ),
        selected: false,
      },
    ];
    //   )
    // );
  } else {
    const agentsToSumList = Object.keys(revNavCheckedAgents)?.filter(
      (key) => revNavCheckedAgents[key] === true
    );

    const keysToSum = [
      "DistributionLosses",
      "DistributionGains",
      "Total",
      "LostCustomers_Num",
      "DistributionLosses_Num",
      "DistributionGains_Num",
      "NewCustomers_Num",
      "NewCustomers",
      "LostCustomers",
      "BaseSales",
    ];

    const relevantDataListToSum = revNavResData?.filter(
      (i) =>
        i.Period === revNavSelectedPeriod &&
        agentsToSumList?.includes(i.AgentID)
    );
    const agentsSumsForGraph = relevantDataListToSum.reduce(
      (accumulator, currentItem) => {
        keysToSum?.forEach((key) => {
          const value = parseFloat(currentItem[key]) || 0;
          if (accumulator[key]) {
            accumulator[key] += value;
          } else {
            accumulator[key] = value;
          }
        });
        return accumulator;
      },
      {
        DistributionLosses: 0,
        DistributionGains: 0,
        Total: 0,
        LostCustomers_Num: 0,
        DistributionLosses_Num: 0,
        DistributionGains_Num: 0,
        NewCustomers_Num: 0,
        NewCustomers: 0,
        LostCustomers: 0,
        BaseSales: 0,
      }
    );

    const BaseSales = Math.round(agentsSumsForGraph.BaseSales);
    const DistributionGains = Math.round(agentsSumsForGraph.DistributionGains);
    const DistributionLosses = Math.round(
      agentsSumsForGraph.DistributionLosses
    );
    const LostCustomers = Math.round(agentsSumsForGraph.LostCustomers);
    const NewCustomers = Math.round(agentsSumsForGraph.NewCustomers);
    const Total = Math.round(agentsSumsForGraph.Total);

    // dispatch(
    //   setRevNavGraphData(
    return [
      {
        barIndex: 0,
        labelText: labelTextMap[Language]?.["BaseSales"],
        name: dictionary[Language]?.growthAnalysisGraph?.legend.BaseSales,
        value: [0, BaseSales],
        label: kFormat(BaseSales),
        tableMode: "B",
        selected: revNavTableMode === "B",
        // firstPeriod: FirstPeriod,
        // secondPeriod: SecondPeriod,
      },
      {
        barIndex: 1,
        labelText: labelTextMap[Language]?.["DistributionGains"],
        name: dictionary[Language]?.growthAnalysisGraph?.legend
          .DistributionGains,
        value: [BaseSales, BaseSales + DistributionGains],
        label: kFormat(DistributionGains),
        tableMode: "DG",
        selected: revNavTableMode === "DG",
      },
      {
        barIndex: 2,
        labelText: labelTextMap[Language]?.["DistributionLosses"],
        name: dictionary[Language]?.growthAnalysisGraph?.legend
          .DistributionLosses,
        value: [
          BaseSales + DistributionGains,
          BaseSales + DistributionGains + DistributionLosses,
        ],
        label: kFormat(DistributionLosses),
        tableMode: "DL",
        selected: revNavTableMode === "DL",
      },
      {
        barIndex: 3,
        labelText: labelTextMap[Language]?.["NewCustomers"],
        name: dictionary[Language]?.growthAnalysisGraph?.legend.NewCustomers,
        value: [
          BaseSales + DistributionGains + DistributionLosses,
          BaseSales + DistributionGains + DistributionLosses + NewCustomers,
        ],
        label: kFormat(NewCustomers),
        tableMode: "N",
        selected: revNavTableMode === "N",
      },
      {
        barIndex: 4,
        labelText: labelTextMap[Language]?.["LostCustomers"],
        name: dictionary[Language]?.growthAnalysisGraph?.legend.LostCustomers,
        value: [
          BaseSales + DistributionGains + DistributionLosses + NewCustomers,
          BaseSales +
            DistributionGains +
            DistributionLosses +
            NewCustomers +
            LostCustomers,
        ],
        label: kFormat(LostCustomers),
        tableMode: "L",
        selected: revNavTableMode === "L",
      },
      {
        barIndex: 5,
        labelText: labelTextMap[Language]?.["Total"],
        name: dictionary[Language]?.growthAnalysisGraph?.legend.Total,
        value: [
          0,
          BaseSales +
            DistributionGains +
            DistributionLosses +
            NewCustomers +
            LostCustomers,
        ],
        label: kFormat(
          BaseSales +
            DistributionGains +
            DistributionLosses +
            NewCustomers +
            LostCustomers
        ),
        selected: false,
      },
    ];
    //   )
    // );
  }
}

export function getUrgencyValue(cellValue) {
  if (cellValue === 6) return "MED";
  if (cellValue === 9) return "HIGH";
  if (cellValue === 3) return "LOW";
  if (cellValue === 1) return "NO";
  else return "NO";
}

export function deepEqualObjects(obj1, obj2) {
  if (obj1 === obj2) return true;

  if (
    typeof obj1 !== "object" ||
    obj1 === null ||
    typeof obj2 !== "object" ||
    obj2 === null
  ) {
    return false;
  }

  let keys1 = Object.keys(obj1);
  let keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (let key of keys1) {
    if (!keys2?.includes(key) || !deepEqualObjects(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

const helpers = {
  setCurrencyForTableHeaders,
  setCurrencyForGroupedTableHeaders,
  setProgressVsHeaderForOverviewTable,
  getMonthForHeader,
  usDateFormat,
  isOnGoingInsight,
  getLastUpdate,
  goToCustomerPage,
  getRowsCsv,
  isSorted,
  getMonthName,
  getRevNavCoordinates,
  getUrgencyValue,
  deepEqualObjects,
  removePrefix,
};

export default helpers;
