import React from "react";
import ReactDOM from "react-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import App from "./App";

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_AUTH0_CLIENT_ID_LOCAL
    : process.env.REACT_APP_NODE_ENV === "production"
    ? process.env.REACT_APP_AUTH0_CLIENT_ID_PRODUCTION
    : process.env.REACT_APP_NODE_ENV === "ir-development"
    ? process.env.REACT_APP_AUTH0_CLIENT_ID_IR_DEVELOPMENT
    : process.env.REACT_APP_AUTH0_CLIENT_ID_DEVELOPMENT;

//process.env.REACT_APP_NODE_ENV === "ir-development"
ReactDOM.render(
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    redirectUri={window.location.origin}
    audience="https://dev-yjz19wwu.us.auth0.com/api/v2/"
    scope="read:current_user update:current_user_metadata"
  >
    <App />
  </Auth0Provider>,
  document.getElementById("root")
);
