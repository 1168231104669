import React from "react";
import { Bar } from "react-chartjs-2";
import { dictionary } from "../../../constants/dictionary";
import { currencyMap } from "../../../constants/currency-map";
import textDirection from "../../../utils/textDirection";
import "./ProductSalesGraph.css";
import { shallowEqual, useSelector } from "react-redux";

export const ProductSalesGraph = ({ coordinates, productName }) => {
  const userSlice = useSelector((state) => state.userSlice, shallowEqual);
  const staticAppDate = useSelector((state) => state.globalSlice.staticAppDate);
  const productsAnalyzeDimension = useSelector(
    (state) => state.dataSlice.productsAnalyzeDimension,
    shallowEqual
  );

  const {
    user: {
      metadata: { Language, Currency },
    },
  } = userSlice;

  const date = staticAppDate ? new Date(staticAppDate) : new Date();

  var data = {
    labels:
      Language === "hebrewwwq"
        ? [
            "ינו׳",
            "פבר׳",
            "מרץ",
            "אפר׳",
            "מאי",
            "יוני",
            "יולי",
            "אוג׳",
            "ספט׳",
            "אוק׳",
            "נוב׳",
            "דצמ׳",
          ]
        : [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
    datasets: [
      {
        label: date.getFullYear() - 1,
        type: "bar",
        backgroundColor: "rgba(248, 191, 19, 1)",
        borderColor: "rgba(248, 191, 19, 1)",
        data: coordinates[1],
        lineTension: 0.6,
      },
      {
        label: date.getFullYear(),
        type: "bar",
        borderColor: "rgba(68, 115, 195, 1)",
        backgroundColor: "rgba(68, 115, 195, 1)",
        data: coordinates[0],
      },
    ],
  };
  let options = {
    animation: {
      duration: 400,
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  };
  return (
    <div>
      <h4 className="product-name" style={textDirection(Language)}>
        {productName} {dictionary[Language]?.productSalesGraph?.graphTitle}{" "}
        {productsAnalyzeDimension === "value"
          ? currencyMap[Currency]
          : `(${dictionary[Language].analyzeBox.units})`}
      </h4>
      <Bar data={data} options={options} className="product-sales-scatter" />
    </div>
  );
};
