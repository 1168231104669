import React from "react";
import { useSelector } from "react-redux";
import { dictionary } from "../../../constants/dictionary";
import { usDateFormat } from "../../../utils/helpers";
import moment from "moment";

function ReportDetailsTitleBar() {
  const Language = useSelector(
    (state) => state.userSlice.user.metadata.Language
  );
  const DateFormat = useSelector(
    (state) => state.userSlice.user.metadata.DateFormat
  );
  const reportCategory = useSelector(
    (state) => state.reportsGeneratorPageSlice.reportCategory
  );
  const todaysDate = moment(moment().format("DD/MM/YYYY"), "D/M/YYYY");

  const formattedDate = DateFormat === "us" ? usDateFormat(todaysDate._i) : todaysDate.format("DD/MM/YYYY");

  return (
    <div className="title-bar reports-page-title">
      {dictionary[Language]?.reportsGeneratorPage.report}{" "}
      {dictionary[Language]?.reportsGeneratorPage.reportTypes[reportCategory]}
      <div className="hierarchy-separator"></div>
      <div className="last-update">
        {formattedDate}
      </div>
    </div>
  );
}

export default ReportDetailsTitleBar;
