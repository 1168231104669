import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import makeStyles from "@material-ui/core/styles/makeStyles";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { subMonths } from "date-fns";
import {
  setProductPurchasedTableMode,
  setProductsAnalyzeDates,
  setProductsAnalyzeDimension,
} from "../../store/features/dataSlice";
import { dictionary } from "../../constants/dictionary";
import { setProductPurchasedTableFilters } from "../../store/features/tableLatestFiltersSlice";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: 140,
    // marginBottom: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    margin: " 13px 0px",
  },
  font: {
    fontSize: 13,
  },
}));

const DateMonthsColumnFilter = ({ setVisibility, analyzeDimension }) => {
  const classes = useStyles();

  const userSlice = useSelector((state) => state.userSlice, shallowEqual);
  const {
    user: {
      metadata: { Language },
    },
  } = userSlice;

  const [startReportDate, setStartReportDate] = useState(null);
  const [endReportDate, setEndReportDate] = useState(null);
  const [startComparedDate, setStartComparedDate] = useState(null);
  const [endComparedDate, setEndComparedDate] = useState(null);
  const [checkbox, setCheckbox] = useState(false);

  const dispatch = useDispatch();

  const applyAnalyze = (e) => {
    if (startReportDate && endReportDate) {
      dispatch(
        setProductsAnalyzeDates([
          [JSON.stringify(startReportDate), JSON.stringify(endReportDate)],
          [JSON.stringify(startComparedDate), JSON.stringify(endComparedDate)],
        ])
      );
      dispatch(setProductsAnalyzeDimension(analyzeDimension));
      dispatch(setProductPurchasedTableMode("analyze"));
      dispatch(setProductPurchasedTableFilters([]));
      setVisibility(false);
    } else if (startReportDate && !endReportDate && !endComparedDate) {
      dispatch(
        setProductsAnalyzeDates([
          [JSON.stringify(startReportDate), JSON.stringify(startReportDate)],
          [
            JSON.stringify(startComparedDate),
            JSON.stringify(startComparedDate),
          ],
        ])
      );
      dispatch(setProductsAnalyzeDimension(analyzeDimension));
      dispatch(setProductPurchasedTableMode("analyze"));
      dispatch(setProductPurchasedTableFilters([]));
      setVisibility(false);
    } else if (startReportDate && !endReportDate && endComparedDate) {
      dispatch(
        setProductsAnalyzeDates([
          [JSON.stringify(startReportDate), JSON.stringify(startReportDate)],
          [JSON.stringify(startComparedDate), JSON.stringify(endComparedDate)],
        ])
      );
      dispatch(setProductsAnalyzeDimension(analyzeDimension));
      dispatch(setProductPurchasedTableMode("analyze"));
      dispatch(setProductPurchasedTableFilters([]));
      setVisibility(false);
    } else {
      e.preventDefault();
    }
  };

  const changeCheckbox = () => {
    setCheckbox(!checkbox);
  };
  const changeReportDate = (dates) => {
    const [start, end] = dates;
    setStartReportDate(start);
    setEndReportDate(end);
  };

  const changeComparedDate = (dates) => {
    const [start, end] = dates;
    setStartComparedDate(start);
    setEndComparedDate(end);
  };

  return (
    <>
      <FormControl className={classes.formControl} style={{ marginTop: 6 }}>
        {dictionary[Language].analyzeBox.reportingPeriod}{" "}
        <DatePicker
          className={classes.formControl}
          calendarClassName="calendar-control"
          selected={startReportDate}
          onChange={changeReportDate}
          selectsRange
          startDate={startReportDate}
          endDate={endReportDate}
          dateFormat="M/yyyy"
          showMonthYearPicker
          minDate={subMonths(new Date(), 23)}
          maxDate={subMonths(new Date(), 1)}
          placeholderText={dictionary[Language].analyzeBox.clickHere}
        />
        <div
          style={{
            display: "flex",
            color: checkbox ? "black" : "grey",
            margin: " 4px 0px",
          }}
        >
          <input
            type="checkbox"
            name="compare-to"
            id="compare-to"
            onChange={changeCheckbox}
            style={{ cursor: "pointer" }}
          />
          {dictionary[Language].analyzeBox.compareTo}{" "}
        </div>
        {checkbox && (
          <DatePicker
            className={classes.formControl}
            calendarClassName="calendar-control"
            selected={startComparedDate}
            onChange={changeComparedDate}
            selectsRange
            startDate={startComparedDate}
            endDate={endComparedDate}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            placeholderText={dictionary[Language].analyzeBox.clickHere}
            minDate={subMonths(new Date(), 23)}
            maxDate={subMonths(new Date(), 1)}
          />
        )}
      </FormControl>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "0.5rem",
        }}
      >
        {" "}
        <Button
          size="small"
          type="submit"
          color="primary"
          variant="contained"
          onClick={applyAnalyze}
        >
          {dictionary[Language].hierarchyBox.apply}
        </Button>
      </div >
    </>
  );
};

export default DateMonthsColumnFilter;
