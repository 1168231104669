import React from "react";
import PreGeneratingView from "./PreGeneratingView";
import { useSelector } from "react-redux";
import ReportFiltersView from "./ReportFiltersView";

const MainContent = () => {
  const reportCategory = useSelector(
    (state) => state.reportsGeneratorPageSlice.reportCategory
  );

  return (
    <div className={reportCategory ? "main-content" : "initial-main-content"}>
      {reportCategory ? <ReportFiltersView /> : <PreGeneratingView />}
    </div>
  );
};

export default MainContent;
