import TextColumnFilter from "../../../Filters/TextColumnFilter";
import NumberColumnFilter from "../../../Filters/NumberColumnFilter";
import CheckedColumnFilter from "../../../Filters/CheckedColumnFilter";

import { sortTypes } from "../../../../utils/sort";
import PercentageColumnFilter from "../../../Filters/PercentageColumnFilter";

export const hebGROUPED_COLUMNS = [
  {
    Header: "פרטי לקוח",
    columns: [
      {
        Header: "מספר לקוח",
        Footer: "Code",
        accessor: "CustomerID",
        filter: "text",
        Filter: TextColumnFilter,
        canFilter: true,
      },
      {
        Header: "שם לקוח",
        Footer: "Customer Name",
        accessor: "CustomerName",
        filter: "text",
        Filter: TextColumnFilter,
        canFilter: true,
      },
      {
        Header: "סוכן",
        Footer: "Sales Person",
        accessor: "AgentName",
        filter: "checked",
        Filter: CheckedColumnFilter,
        canFilter: true,
      },
      {
        Header: "₪ סה״כ הזדמנויות",
        Footer: "Value",
        accessor: "AggPotentialValue",
        filter: "number",
        Filter: NumberColumnFilter,
        canFilter: true,
        sortType: sortTypes.number,
      },
    ],
  },
  {
    Header: "פרטי הזדמנות",
    columns: [
      {
        Header: "מק״ט",
        accessor: "Code",
        filter: "text",
        Filter: TextColumnFilter,
        canFilter: true,
      },
      {
        Header: "מוצר / וריאנט",
        accessor: "ProductName",
        filter: "text",
        Filter: TextColumnFilter,
        canFilter: true,
      },
      {
        Header: "סוג הזדמנות",
        accessor: "InsightType",
        filter: "checked",
        Filter: CheckedColumnFilter,
        canFilter: true,
      },
      {
        Header: "₪",
        accessor: "Value",
        filter: "number",
        Filter: NumberColumnFilter,
        canFilter: true,
        sortType: sortTypes.number,
      },
      {
        Header: "מידע מרכזי",
        accessor: "KeyData",
        filter: "text",
        Filter: TextColumnFilter,
        canFilter: true,
      },
      {
        Header: "התקדמות",
        accessor: "Progress",
        filter: "number",
        Filter: PercentageColumnFilter,
        canFilter: true,
        sortType: sortTypes.number,
      },
    ],
  },
];
