import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { dictionary } from "../../constants/dictionary";
import { FILTER_SEPARATOR } from "../../utils/filters";
import Button from "@material-ui/core/Button";

import Select from "react-select";

export const operators = [
  { id: "contains", label: "Contains", shortLabel: "co." },
  { id: "notContains", label: "Not Contains", shortLabel: "not co." },
  { id: "equals", label: "Equals", shortLabel: "eq." },
  { id: "startsWith", label: "Starts With", shortLabel: "start." },
  { id: "endsWith", label: "Ends With", shortLabel: "end." },
];
export const engOperators = [
  { id: "contains", label: "Contains", shortLabel: "co." },
  { id: "notContains", label: "Not Contains", shortLabel: "not co." },
  { id: "equals", label: "Equals", shortLabel: "eq." },
  { id: "startsWith", label: "Starts With", shortLabel: "start." },
  { id: "endsWith", label: "Ends With", shortLabel: "end." },
];
export const hebOperators = [
  { id: "contains", label: "מכיל", shortLabel: "מכיל" },
  { id: "notContains", label: "לא מכיל", shortLabel: "לא מכיל" },
  { id: "equals", label: "שווה ל", shortLabel: "שווה" },
  { id: "startsWith", label: "מתחיל ב", shortLabel: "מתחיל" },
  { id: "endsWith", label: "מסתיים ב", shortLabel: "מסתיים" },
];

const TextColumnFilter = ({ column }) => {
  const userSlice = useSelector((state) => state.userSlice, shallowEqual);
  const {
    user: {
      metadata: { Language },
    },
  } = userSlice;
  const { setFilter } = column;
  const [value, setValue] = useState("");
  const [languageOperators, setLanguageOperators] = useState(operators);
  const [operator, setOperator] = useState(
    Language === "hebrew"
      ? hebOperators[0]
      : Language === "english"
      ? engOperators[0]
      : operators[0]
  );

  const applyFilter = (e) => {
    if (value && operator) {
      setFilter(`${operator.value || operator.id}${FILTER_SEPARATOR}${value}`);
      setValue("");
    }
    e.preventDefault();
  };

  useEffect(() => {
    setLanguageOperators(
      Language === "hebrew"
        ? hebOperators
        : Language === "english"
        ? engOperators
        : operators
    );
  }, [Language]);

  return (
    <>
      <div>
        <label
          htmlFor="operator-select"
          id="operator-select-label"
          style={{ right: Language === "hebrew" ? -50 : "initial" }}
        >
          {dictionary[Language]?.filterPicker.operator}
          <div style={{ margin: "5px 0px 15px" }}>
            <Select
              value={operator}
              id="operator-select"
              onChange={(selectedOption) => {
                setOperator(selectedOption);
              }}
              options={languageOperators.map((operator) => ({
                value: operator.id,
                label: operator.label,
              }))}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 7,
                  fontSize: 12,
                  direction: Language === "hebrew" ? "rtl" : "ltr",
                }),
              }}
            />
          </div>
        </label>
      </div>
      <div>
        <input
          style={{ width: "100%", marginTop: "0.5rem" }}
          id="value"
          placeholder={`${dictionary[Language]?.filterPicker.value}`}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "0.5rem",
        }}
      >
        {" "}
        <Button
          size="small"
          type="submit"
          color="primary"
          variant="contained"
          onClick={applyFilter}
          disabled={!(value && operator)}
          className="filter-picker-apply-button"
        >
          {dictionary[Language].hierarchyBox.apply}
        </Button>
      </div>
    </>
  );
};

export default TextColumnFilter;
