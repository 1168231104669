import {
  Box,
  FormControl,
  makeStyles,
  Menu,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import React, { useMemo, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getRowsCsv,
  isSorted,
  setCurrencyForGroupedTableHeaders,
} from "../../../utils/helpers";
import { useFilters, useSortBy, useTable } from "react-table";
import { dictionary } from "../../../constants/dictionary";
import FiltersList from "../../Filters/FiltersList";
import FilterPicker from "../../Filters/FilterPicker";
import NestedMenuItem from "material-ui-nested-menu-item";
import { CSVLink } from "react-csv";
import pdfUtils from "../../../utils/pdf";
import { Pagination } from "@material-ui/lab";
import classNames from "classnames";
import { LoadingAnimation } from "../../LoadingAnimation/LoadingAnimation";
import "./ReportDetailsGeneratedTable.css";
import numberUtils from "../../../utils/number";

const useStyles = makeStyles((theme) => ({
  root: {
    // marginRight: 20,
    // marginBottom: 20,
    height: "90%",
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
      width: "100%",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  tableFilters: {
    marginLeft: 20,
    marginBottom: 12,
    position: "relative",
  },
  filterIcon: {
    marginLeft: 20,
    cursor: "pointer",
  },
  filtersCloseIcon: {
    opacity: 0.82,
    marginBottom: 12,
    cursor: "pointer",
    transition: "opacity 0.4s",
    "&:hover": {
      opacity: 1,
    },
  },
  pagination: {
    minWidth: 350,
    marginTop: 25,
  },
  spaceRight: {
    marginRight: 25,
  },
  bold: {
    fontWeight: 700,
  },
}));

const getMYYDateStr = (dateString) => {
  const date = new Date(dateString);
  const options = { month: "numeric", year: "2-digit" };
  const formattedDate = date.toLocaleDateString("en-US", options);
  return formattedDate;
};

const generateMonthArray = (startDateStr, endDateStr) => {
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);
  const monthArray = [];

  while (startDate <= endDate) {
    monthArray.push(getMYYDateStr(startDate));
    startDate.setMonth(startDate.getMonth() + 1);
  }

  return monthArray;
};

const generateYearArray = (startDateStr, endDateStr) => {
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);
  const yearArray = new Set();

  while (startDate <= endDate) {
    yearArray.add(startDate.getFullYear());
    startDate.setFullYear(startDate.getFullYear() + 1);
  }

  return Array.from(yearArray);
};

const translations = {
  CustomerID: {
    english: "Customer ID",
    hebrew: "מספר לקוח",
  },
  CustomerName: {
    english: "Customer Name",
    hebrew: "שם לקוח",
  },
  AgentName: {
    english: "Agent Name",
    hebrew: "שם סוכן",
  },
  ItemCatNum: {
    english: "Item Cat Num",
    hebrew: "מק״ט",
  },
  ItemName: {
    english: "Item Name",
    hebrew: "שם פריט",
  },
  Amount: {
    english: "Monetary",
    hebrew: "כספי",
  },
  Units: {
    english: "Units",
    hebrew: "יחידות",
  },
  Count: {
    english: "Count",
    hebrew: "#",
  },
  Period1: {
    english: "Period 1",
    hebrew: "תקופה 1",
  },
  Period2: {
    english: "Period 2",
    hebrew: "תקופה 2",
  },
  Details: {
    english: "Details",
    hebrew: "פרטים",
  },
};

const translateHeader = (accessor, language) => {
  return translations[accessor]?.[language] || accessor;
};

const generateGroupedColumns = (
  aggregationType,
  comparePeriodCheckbox,
  reportFilters,
  reportCategory,
  Language
) => {
  const getDetailsHeaders = () => {
    switch (reportCategory) {
      case "byCustomer":
        return [
          {
            Header: translateHeader("CustomerID", Language),
            accessor: "CustomerID",
            className: "details-column sticky-column",
          },
          {
            Header: translateHeader("CustomerName", Language),
            accessor: "CustomerName",
            className: "details-column sticky-column",
          },
          {
            Header: translateHeader("AgentName", Language),
            accessor: "AgentName",
            className: "details-column sticky-column",
          },
        ];
      case "byCustomerItem":
        return [
          {
            Header: translateHeader("CustomerID", Language),
            accessor: "CustomerID",
            className: "details-column sticky-column",
          },
          {
            Header: translateHeader("CustomerName", Language),
            accessor: "CustomerName",
            className: "details-column sticky-column",
          },
          {
            Header: translateHeader("AgentName", Language),
            accessor: "AgentName",
            className: "details-column sticky-column",
          },
          {
            Header: translateHeader("ItemCatNum", Language),
            accessor: "ItemCatNum",
            className: "details-column sticky-column",
          },
          {
            Header: translateHeader("ItemName", Language),
            accessor: "ItemName",
            className: "details-column sticky-column",
          },
        ];
      case "byItem":
        return [
          {
            Header: translateHeader("ItemCatNum", Language),
            accessor: "ItemCatNum",
            className: "details-column sticky-column",
          },
          {
            Header: translateHeader("ItemName", Language),
            accessor: "ItemName",
            className: "details-column sticky-column",
          },
        ];
      case "byItemAgent":
        return [
          {
            Header: translateHeader("AgentName", Language),
            accessor: "AgentName",
            className: "details-column sticky-column",
          },
          {
            Header: translateHeader("ItemCatNum", Language),
            accessor: "ItemCatNum",
            className: "details-column sticky-column",
          },
          {
            Header: translateHeader("ItemName", Language),
            accessor: "ItemName",
            className: "details-column sticky-column",
          },
        ];
      case "byAgent":
        return [
          {
            Header: translateHeader("AgentName", Language),
            accessor: "AgentName",
            className: "details-column sticky-column",
          },
        ];
      default:
        return [];
    }
  };

  const baseColumns = [
    {
      Header: translateHeader("Details", Language),
      columns: getDetailsHeaders(),
    },
  ];

  const getHeaders = (index) => {
    const headers = [];
    if (reportFilters.ValuesToDisplay.includes("valueSales")) {
      headers.push({
        Header: translateHeader("Amount", Language),
        accessor: `A${index}`,
      });
    }
    if (reportFilters.ValuesToDisplay.includes("units")) {
      headers.push({
        Header: translateHeader("Units", Language),
        accessor: `U${index}`,
      });
    }
    if (reportFilters.ValuesToDisplay.includes("uniqueElements")) {
      headers.push({
        Header: translateHeader("Count", Language),
        accessor: `C${index}`,
      });
    }
    return headers;
  };

  let currentIndex = 1;

  if (aggregationType === "Total") {
    const period1Header = {
      Header: translateHeader("Period1", Language),
      columns: [
        {
          Header: `${getMYYDateStr(
            reportFilters.Period1[1][0]
          )}-${getMYYDateStr(reportFilters.Period1[1][1])}`,
          columns: getHeaders(currentIndex++),
        },
      ],
    };

    baseColumns.push(period1Header);

    if (comparePeriodCheckbox) {
      const period2Header = {
        Header: translateHeader("Period2", Language),
        columns: [
          {
            Header: `${getMYYDateStr(
              reportFilters.Period2[1][0]
            )}-${getMYYDateStr(reportFilters.Period2[1][1])}`,
            columns: getHeaders(currentIndex++),
          },
        ],
      };
      baseColumns.push(period2Header);
    }
  } else if (aggregationType === "Month") {
    const period1Months = generateMonthArray(
      reportFilters.Period1[1][0],
      reportFilters.Period1[1][1]
    ).map((item, i) => ({
      Header: item,
      columns: getHeaders(currentIndex++),
    }));

    const period1Header = {
      Header: translateHeader("Period1", Language),
      columns: period1Months,
    };

    baseColumns.push(period1Header);

    if (comparePeriodCheckbox) {
      const period2Months = generateMonthArray(
        reportFilters.Period2[1][0],
        reportFilters.Period2[1][1]
      ).map((item, i) => ({
        Header: item,
        columns: getHeaders(currentIndex++),
      }));

      const period2Header = {
        Header: translateHeader("Period2", Language),
        columns: period2Months,
      };

      baseColumns.push(period2Header);
    }
  } else if (aggregationType === "Year") {
    const getYearsFromPeriod = (period) =>
      generateMonthArray(period[0], period[1]).reduce((acc, month) => {
        const year = "20" + month.split("/")[1];

        if (!acc[year]) {
          acc[year] = [];
        }
        acc[year].push(month);
        return acc;
      }, {});

    const period1Years = getYearsFromPeriod(reportFilters.Period1[1]);
    const period1YearHeaders = Object.keys(period1Years).map((year, index) => ({
      Header: year,
      columns: getHeaders(index + 1), // Only include A, U, C based on ValuesToDisplay, no months
    }));

    const period1Header = {
      Header: translateHeader("Period1", Language),
      columns: period1YearHeaders,
    };

    baseColumns.push(period1Header);

    if (comparePeriodCheckbox) {
      const period2Years = getYearsFromPeriod(reportFilters.Period2[1]);
      const period2YearHeaders = Object.keys(period2Years).map(
        (year, index) => ({
          Header: year,
          columns: getHeaders(period1YearHeaders.length + index + 1), // Continue incrementing the index
        })
      );

      const period2Header = {
        Header: translateHeader("Period2", Language),
        columns: period2YearHeaders,
      };

      baseColumns.push(period2Header);
    }
  }

  return baseColumns;
};

function ReportDetailsGeneratedTable() {
  const classes = useStyles();
  const dispatch = useDispatch();
  //   const location = useLocation();
  const staticAppDate = useSelector((state) => state.globalSlice.staticAppDate);
  const Language = useSelector(
    (state) => state.userSlice.user.metadata.Language
  );
  const Currency = useSelector(
    (state) => state.userSlice.user.metadata.Currency
  );
  const DateFormat = useSelector(
    (state) => state.userSlice.user.metadata.DateFormat
  );
  const fetchingSpinner = useSelector(
    (state) => state.reportsGeneratorPageSlice.fetchingSpinner
  );
  const reportDetailsResData = useSelector(
    (state) => state.reportsGeneratorPageSlice.reportDetailsResData
  );
  const reportFilters = useSelector(
    (state) => state.reportsGeneratorPageSlice.reportFilters,
    shallowEqual
  );
  const comparePeriodCheckbox = useSelector(
    (state) => state.reportsGeneratorPageSlice.comparePeriodCheckbox
  );
  const reportCategory = useSelector(
    (state) => state.reportsGeneratorPageSlice.reportCategory,
    shallowEqual
  );

  const date = staticAppDate ? new Date(staticAppDate) : new Date();

  const [page, setPage] = useState(0);
  // const [data, setData] = useState([]);
  const [showRows, setShowRows] = useState(25);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [filterPickerVisible, setFilterPickerVisible] = useState(null);
  const [menuPosition, setMenuPosition] = useState(null);
  const tableRef = useRef(null);

  const handleRightClick = (e, row) => {
    if (menuPosition) {
      return;
    }
    e.preventDefault();
    setMenuPosition({
      top: e.clientY,
      left: e.clientX,
    });
  };

  const columns = useMemo(
    () =>
      generateGroupedColumns(
        reportFilters.aggregationType,
        comparePeriodCheckbox,
        reportFilters,
        reportCategory,
        Language
      ),
    [reportFilters.aggregationType, reportDetailsResData]
  );
  const data = useMemo(() => {
    return reportDetailsResData;
  }, [reportDetailsResData]);
  // const columns = useMemo(
  //   () =>
  //     Language === "hebrew"
  //       ? setCurrencyForGroupedTableHeaders(
  //           Currency,
  //           hebGROUPED_COLUMNS,
  //           Language
  //         )
  //       : Language === "english"
  //       ? setCurrencyForGroupedTableHeaders(
  //           Currency,
  //           engGROUPED_COLUMNS,
  //           Language
  //         )
  //       : setCurrencyForGroupedTableHeaders(
  //           Currency,
  //           engGROUPED_COLUMNS,
  //           Language
  //         ),
  //   []
  // );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    state,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: columns[0]?.columns[0]?.accessor || columns[0]?.accessor,
            desc: false,
          },
        ],
      },
      autoResetPage: false,
      autoResetExpanded: false,
      autoResetGroupBy: false,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false,
    },
    useFilters,
    useSortBy
  );

  function extractSubColumns(columns, depth = 0) {
    let subColumns = [];

    columns.forEach((column) => {
      if (column.columns) {
        if (depth === 1) {
          // If we're at the 2nd level and there are further nested columns, combine the header with its child headers
          column.columns.forEach((subColumn) => {
            subColumns.push({
              ...subColumn,
              Header: `${subColumn.Header}: ${column.Header}`, // Combine the 2nd level header with its child headers
            });
          });
        } else {
          // Recursively extract sub-columns if nested columns are found
          subColumns = subColumns.concat(
            extractSubColumns(column.columns, depth + 1)
          );
        }
      } else {
        // Add the current column to the list
        subColumns.push(column);
      }
    });
    return subColumns;
  }

  const filterableColumns = extractSubColumns(columns);

  // return fetchingSpinner ? (
  //   <LoadingAnimation />
  // ) : (
  return (
    <>
      {fetchingSpinner ? (
        <div
          style={{
            height: "80vh",
          }}
        >
          <div
            style={{
              paddingTop:"30vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {dictionary[Language]?.reportsGeneratorPage.loadingMessageText}
            <LoadingAnimation />
          </div>
        </div>
      ) : (
        <div className={classes.root}>
          {/* <div className={classes.tableFilters}>
              <Box
                display="flex"
                alignItems="center"
                style={{
                  marginLeft: 10,
                  marginRight: 18,
                  direction: `${Language === "hebrew" ? "rtl" : "ltr"}`,
                }}
              >
                <FiltersList
                  filters={state.filters}
                  headers={headerGroups[1].headers}
                  style={{ maxWidth: "calc(100% - 170px)" }}
                  setFilterPickerVisible={setFilterPickerVisible}
                />
                <FilterPicker
                  columns={filterableColumns}
                  visible={filterPickerVisible}
                  headers={headerGroups[1].headers}
                  setVisibility={setFilterPickerVisible}
                />
              </Box>
            </div> */}

          <div
            id="report-details-generated-table"
            className="report-details-generated-table-div"
            ref={tableRef}
            style={{ direction: Language === "hebrew" ? "rtl" : "ltr" }}
          >
            <table
              {...getTableProps()}
              className="report-details-generated-table"
              style={{ width: "98%" }}
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                      return (
                        <th
                          className={
                            [
                              "AgentName",
                              "CustomerID",
                              "CustomerName",
                              "ItemCatNum",
                              "ItemName",
                            ].includes(column.id)
                              ? "details-column"
                              : ""
                          }
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          <span>{isSorted(column)}</span>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>

              {!rows.length ? (
                <div>{dictionary[Language]?.keyOpportunitiesTable.noData2}</div>
              ) : (
                <tbody {...getTableBodyProps()}>
                  <Menu
                    open={!!menuPosition}
                    onClose={() => {
                      setMenuPosition(null);
                    }}
                    anchorReference="anchorPosition"
                    anchorPosition={menuPosition}
                  >
                    <NestedMenuItem
                      label={
                        dictionary[Language]?.salesReviewTable.menuOptions
                          .exportTo
                      }
                      parentMenuOpen={!!menuPosition}
                    >
                      <MenuItem id="csv">
                        <CSVLink
                          target="_blank"
                          data={getRowsCsv(rows, filterableColumns)}
                          filename={`report-${reportCategory}.csv`}
                          style={{ color: "black" }}
                          onClick={() => {
                            setMenuPosition(null);
                          }}
                        >
                          CSV
                        </CSVLink>
                      </MenuItem>
                      {/* <MenuItem id="pdf" onClick={handlePDFExportToClick}>
                        PDF
                      </MenuItem> */}
                    </NestedMenuItem>
                  </Menu>
                  {rows
                    ?.slice(page * showRows, (page + 1) * showRows)
                    .map((row) => {
                      prepareRow(row);
                      return (
                        <tr
                          {...row.getRowProps()}
                          onContextMenu={(e) => handleRightClick(e, row)}
                        >
                          {row.cells.map((cell) => {
                            if (cell.column.id.length > 7) {
                              return (
                                <td
                                  style={{ minWidth: "7.5rem" }}
                                  {...cell.getCellProps()}
                                >
                                  {cell.render("Cell")}
                                </td>
                              );
                            } else {
                              return (
                                <td
                                  style={{ minWidth: "4.5rem" }}
                                  {...cell.getCellProps()}
                                >
                                  {numberUtils.numberWithCommas(cell.value)}
                                </td>
                              );
                            }
                          })}
                        </tr>
                      );
                    })}
                </tbody>
              )}
            </table>
          </div>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            flexWrap="wrap"
            className={classes.pagination}
          >
            <Typography
              variant="body2"
              color="textSecondary"
              className={classNames(classes.spaceRight, classes.bold)}
            >
              {rows?.length} Results
            </Typography>

            <FormControl
              size="small"
              className={classes.spaceRight}
              style={{ minWidth: 100 }}
            >
              <Box display="flex" alignItems="center">
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={classes.bold}
                  style={{ marginRight: 10 }}
                >
                  Show rows
                </Typography>
                <Select
                  size="small"
                  id="show-rows"
                  value={showRows}
                  label="Show rows"
                  labelId="show-rows-label"
                  onChange={(e) => {
                    setPage(0);
                    setShowRows(Number(e.target.value));
                  }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </Box>
            </FormControl>

            <Pagination
              size="small"
              color="primary"
              className={classes.spaceRight}
              inputlabelprops={{ shrink: false }}
              count={Math.ceil(rows?.length / showRows)}
              onChange={(_, pageIdx) => setPage(pageIdx - 1)}
            />

            <FormControl
              size="small"
              className={classes.spaceRight}
              style={{ minWidth: 100 }}
            >
              <Box display="flex" alignItems="center">
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={classes.bold}
                  style={{ marginRight: 10 }}
                >
                  Go to
                </Typography>
                <Select
                  id="go-to"
                  size="small"
                  value={page}
                  label="Go to"
                  labelId="go-to-label"
                  onChange={(e) => setPage(Number(e.target.value))}
                >
                  {[...Array(Math.ceil(rows?.length / showRows)).keys()].map(
                    (page) => (
                      <MenuItem key={page} value={page}>
                        {page + 1}
                      </MenuItem>
                    )
                  )}
                </Select>
              </Box>
            </FormControl>
          </Box>
        </div>
      )}
    </>
  );
}

export default ReportDetailsGeneratedTable;
