import React from "react";
import "./TrendArrow.css";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
export const TrendArrow = ({ trend }) => {
  return (
    <div className="trend-div">
      {parseFloat(trend) > 0 ? (
        <TrendingUpIcon className="trending-up-icon trending-icon" />
      ) : parseFloat(trend) < 0 ? (
        <TrendingDownIcon className="trending-down-icon trending-icon" />
      ) : null}
      <span className="trend-percentage">{parseInt(trend * 100)}%</span>
    </div>
  );
};
