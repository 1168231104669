import React, { useEffect, useState } from "react";
import FiltersList from "../../Filters/FiltersList";
import FilterPicker from "../../Filters/FilterPicker";
import { useDispatch } from "react-redux";
import { setRevNavigatorTableFilters } from "../../../store/features/tableFiltersSlice";

export default function RevNavigatorFilters({ filters, headers, columns }) {
  const [filterPickerVisible, setFilterPickerVisible] = useState(null);
  // const mappedHeaders = headers.map((header) => ({
  //   id: header.id,
  //   canFilter: header.canFilter,
  //   Header: header.Header,
  //   setFilter: header.setFilter,
  //   render: header.render,
  // }));
  const dispatch = useDispatch();
  
  useEffect(
    function updateFiltersState() {
      dispatch(setRevNavigatorTableFilters(filters));
    },
    [filters]
  );

  return (
    <>
      <FiltersList
        filters={filters}
        headers={headers}
        setFilterPickerVisible={setFilterPickerVisible}
      />
      <FilterPicker
        columns={columns}
        visible={filterPickerVisible}
        headers={headers}
        setVisibility={setFilterPickerVisible}
      />
    </>
  );
}
