import React, { useEffect, useState } from "react";
import { dictionary } from "../../../constants/dictionary";
import { shallowEqual, useSelector } from "react-redux";
import { getLastUpdate, usDateFormat } from "../../../utils/helpers";
import RevNavigatorSumsRow from "./RevNavigatorSumsRow";

export default function RevNavigatorAboveTable({ rows }) {
  const Language = useSelector(
    (state) => state.userSlice.user.metadata.Language
  );
  const DateFormat = useSelector(
    (state) => state.userSlice.user.metadata.DateFormat
  );
  const trendsData = useSelector(
    (state) => state.homePagePropsSlice.trendsData,
    shallowEqual
  );
  const revNavTableMode = useSelector(
    (state) => state.revNavigatorSlice.revNavTableMode
  );
  const [overviewLastUpdate, setOverviewLastUpdate] = useState("");

  useEffect(
    function getOverviewLastUpdate() {
      if (trendsData?.length > 0) {
        setOverviewLastUpdate(getLastUpdate(trendsData));
      }
    },
    [trendsData]
  );

  return (
    <div
      style={{
        direction: `${Language === "hebrew" ? "rtl" : "ltr"}`,
        display: "flex",
        alignItems: "baseline",
      }}
    >
      <div
        className="customers-trends-above-table"
        style={{
          marginRight: "3rem",
        }}
      >
        <h4 className="table-header">
          {dictionary[Language]?.RevNavigatorTable.tableTitle2[revNavTableMode]}
        </h4>
        {overviewLastUpdate && (
          <div className="last-update">
            {dictionary[Language]?.lastUpdate} :{" "}
            {DateFormat === "us"
              ? usDateFormat(overviewLastUpdate)
              : overviewLastUpdate}
          </div>
        )}
      </div>
      <RevNavigatorSumsRow rows={rows} />
    </div>
  );
}
