import classNames from "classnames";
import Box from "@material-ui/core/Box";
import { grey } from "@mui/material/colors";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";

import numberUtils from "../../utils/number";
import ProgressBar from "../ProgressBar/ProgressBar";
import { PriorityButton } from "../PriorityButton/PriorityButton";
import InsightTypesLogos from "../InsightTypesLogos/InsightTypesLogos";
import OnGoingLight from "../OnGoingLight/OnGoingLight";

const useStyles = makeStyles(() => ({
  root: {
    padding: "15px 12px",
    background: "#ffffff",
    borderTop: `10px solid ${grey[100]}`,
  },
  box: {
    marginBottom: 15,
  },
  text: {
    marginLeft: 5,
    marginRight: 5,
  },
  boldText: {
    fontWeight: 600,
  },
  caption: {
    fontSize: 14,
  },
  captionValue: {
    color: "#8fc072",
  },
}));

const month_names_short = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export default function AgentCard({ row, onCardClick = () => {} }) {
  const classes = useStyles();

  const {
    Priority,
    CustomerName,
    CustomerID,
    AggPotentialValue,
    Param2,
  } = row?.original;


  let updateDate = Param2?.split("_");

  updateDate = `${month_names_short[updateDate[0] - 1]} ${updateDate[1]}`;

  return (
    <div className={classes.root}>
      <Box display="flex" alignItems="baseline" className={classes.box}>
        <Typography
          variant="body1"
          className={classes.text}
          onClick={() => onCardClick(CustomerID)}
        >
          {CustomerName} #{CustomerID}
        </Typography>
        <PriorityButton row={row} status={Priority} userType="Agent" />
      </Box>

      <Box display="flex" alignItems="center" className={classes.box}>
        <Typography
          variant="h6"
          className={classNames(
            classes.text,
            classes.boldText,
            classes.captionValue
          )}
        >
          0
        </Typography>
        <ProgressBar row={row} fullWidth />
        <Typography
          variant="h6"
          className={classNames(
            classes.text,
            classes.boldText,
            classes.captionValue
          )}
        >
          {numberUtils.kFormat(AggPotentialValue)}
        </Typography>
      </Box>

      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Typography
            variant="caption"
            className={classNames(classes.text, classes.caption)}
          >
            Last order:
          </Typography>
          <Typography
            variant="caption"
            className={classNames(classes.caption, classes.captionValue)}
          >
            {Param2 != 0 && updateDate}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography
            variant="caption"
            className={classNames(classes.text, classes.caption)}
          >
            Opportunities:
          </Typography>
          <InsightTypesLogos row={row} />
          <OnGoingLight row={row} />
        </Box>
      </Box>
    </div>
  );
}
