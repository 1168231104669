import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loadingSpinner: true,
  selectedCustomer: {},
  customerNameForGraph: "",
  trendsCoordinates: {},
  customerOverviewTrendsData: [],
  trendsData: [],
  comparisonValue: "lastMonth",
  displayLoadingPage: true,
  isOverviewGraphready: false,
};

export const homePagePropsSlice = createSlice({
  name: "homePageProps",
  initialState,
  reducers: {
    setLoadingSpinner: (state, action) => {
      state.loadingSpinner = action.payload;
    },
    setSelectedCustomer: (state, action) => {
      state.selectedCustomer = action.payload;
    },
    setCustomerNameForGraph: (state, action) => {
      state.customerNameForGraph = action.payload;
    },
    setTrendsCoordinates: (state, action) => {
      state.trendsCoordinates = action.payload;
    },
    setCustomerOverviewTrendsData: (state, action) => {
      state.customerOverviewTrendsData = action.payload;
    },
    setTrendsData: (state, action) => {
      state.trendsData = action.payload;
    },
    setComparisonValue: (state, action) => {
      state.comparisonValue = action.payload;
    },
    setDisplayLoadingPage: (state, action) => {
      state.displayLoadingPage = action.payload;
    },
    setIsOverviewGraphReady: (state, action) => {
      state.isOverviewGraphready = action.payload;
    },
  },
});

export const {
  setLoadingSpinner,
  setSelectedCustomer,
  setCustomerNameForGraph,
  setTrendsCoordinates,
  setCustomerOverviewTrendsData,
  setTrendsData,
  setComparisonValue,
  setDisplayLoadingPage,
  setIsOverviewGraphReady,
} = homePagePropsSlice.actions;

export default homePagePropsSlice.reducer;
