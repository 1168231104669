import TextColumnFilter from "../../../Filters/TextColumnFilter";
import NumberColumnFilter from "../../../Filters/NumberColumnFilter";
import CheckedColumnFilter from "../../../Filters/CheckedColumnFilter";

import { sortTypes } from "../../../../utils/sort";
// import { getMonthForHeader } from "../../../utils/helpers";
import {
  prioritiesMapForTrends,
  urgencyMapForTrends,
} from "../../../../constants/filtersText-map";
import DateColumnFilter from "../../../Filters/DateColumnFilter";
import PercentageColumnFilter from "../../../Filters/PercentageColumnFilter";

const staticAppDate =
  process.env.REACT_APP_NODE_ENV === "ir-development"
    ? process.env.REACT_APP_DEMO_DATE
    : false;

const date = staticAppDate ? new Date(staticAppDate) : new Date();
const lastMonth =
  date.getMonth() !== 0
    ? `${date.getMonth()}/${date.getFullYear().toString()?.slice(2)}`
    : `12/${(date.getFullYear() - 1).toString()?.slice(2)}`;
const currentMonth = `${date.getMonth() + 1}/${date
  .getFullYear()
  .toString()
  ?.slice(2)}`;

export const engCOLUMNS = [
  {
    Header: "Customer Name",
    accessor: "customerName",
    filter: "text",
    Filter: TextColumnFilter,
    canFilter: true,
  },
  {
    Header: "Customer Code",
    accessor: "customerCode",
    filter: "text",
    Filter: TextColumnFilter,
    canFilter: true,
  },
  {
    Header: "Sales Agent",
    accessor: "salesAgent",
    filter: "checked",
    Filter: CheckedColumnFilter,
    canFilter: true,
  },
  {
    Header: "Last Purchase",
    accessor: "lastOrder",
    filter: "date",
    Filter: DateColumnFilter,
    canFilter: true,
    sortType: sortTypes.lastOrder,
  },
  // {
  //   Header: `Trend : ${getMonthForHeader("twoMonthsAgo")}-${getMonthForHeader(
  //     "lastMonth"
  //   )} vs. ${getMonthForHeader("fourMonthsAgo")}-${getMonthForHeader(
  //     "threeMonthsAgo"
  //   )}`,
  //   columns: [
  // {
  //   Header: "Customer",
  //   accessor: "trend",
  //   filter: "number", // filter: "checked"
  //   Filter: NumberColumnFilter,
  //   canFilter: true,
  //   sortType: sortTypes.number,
  // },
  // {
  //   Header: "Group",
  //   accessor: "gix",
  //   filter: "number", // filter: "checked"
  //   Filter: NumberColumnFilter,
  //   canFilter: true,
  //   sortType: sortTypes.number,
  // },
  //   ],
  // },
  // {
  //   Header: `Sales ($): ${getMonthForHeader(
  //     "currentMonth"
  //   )} vs.  ${getMonthForHeader("lastMonth")}`,
  //   columns: [
  {
    Header: "Sales " + currentMonth + " $",
    accessor: "achieved",
    filter: "number", // filter: "checked"
    Filter: NumberColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: "Sales " + lastMonth + " $",
    accessor: "comparisonValue",
    filter: "number", // filter: "checked"
    Filter: NumberColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: "Sales Progress",
    accessor: "progressExpectation",
    filter: "number", // filter: "checked"
    Filter: PercentageColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  //   ],
  // },
  {
    Header: "Open Orders $",
    accessor: "openOrders",
    filter: "number",
    Filter: NumberColumnFilter,
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: "Opportunities",
    accessor: "InsightType",
    filter: "checked",
    // Filter: ({ column }) => {
    //   return(
    //   <CheckedColumnFilter
    //     column={column}
    //     labelsMap={opportunitiesMapForCustomersOverview.english}
    //   />
    // )},
    Filter: CheckedColumnFilter,
    canFilter: true,
    // sortType: sortTypes.new,
    disableSortBy: true,
  },
  {
    Header: "Urgency",
    accessor: "urgency",
    // filter: "number",
    filter: "checked",
    Filter: ({ column }) => (
      <CheckedColumnFilter
        column={column}
        labelsMap={urgencyMapForTrends.english}
      />
    ),
    canFilter: true,
    sortType: sortTypes.number,
  },
  {
    Header: "Priority",
    accessor: "priority",
    filter: "checked", // filter: "checked"
    Filter: ({ column }) => (
      <CheckedColumnFilter column={column} labelsMap={prioritiesMapForTrends} />
    ),
    canFilter: true,
  },
];
