import React, { useEffect, useState } from "react";
import FiltersList from "../../Filters/FiltersList";
import FilterPicker from "../../Filters/FilterPicker";
import { useDispatch } from "react-redux";
import { setRevNavigatorDetailsTableFilters } from "../../../store/features/tableFiltersSlice";

export default function RevNavigatorDetailsFilters({ filters, headers, columns }) {
  const [filterPickerVisible, setFilterPickerVisible] = useState(null);
  const dispatch = useDispatch();
  
  useEffect(
    function updateFiltersState() {
      dispatch(setRevNavigatorDetailsTableFilters(filters));
    },
    [filters]
  );

  return (
    <>
      <FiltersList
        filters={filters}
        headers={headers}
        setFilterPickerVisible={setFilterPickerVisible}
      />
      <FilterPicker
        columns={columns}
        visible={filterPickerVisible}
        headers={headers}
        setVisibility={setFilterPickerVisible}
      />
    </>
  );
}
