import React, { memo } from "react";
import { Bar } from "react-chartjs-2";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { dictionary } from "../../../constants/dictionary";
import textDirection from "../../../utils/textDirection";
import { LoadingAnimation } from "../../LoadingAnimation/LoadingAnimation";
import { useEffect } from "react";
import { currencyMap } from "../../../constants/currency-map";
import "./CustomersOverviewGraph.css";
import { setLoadingSpinner } from "../../../store/features/homePagePropsSlice";

function CustomersOverviewGraph() {
  const userSlice = useSelector((state) => state.userSlice, shallowEqual);
  const staticAppDate = useSelector((state) => state.globalSlice.staticAppDate);
  const loadingSpinner = useSelector(
    (state) => state.homePagePropsSlice.loadingSpinner
  );
  const trendsCoordinates = useSelector(
    (state) => state.homePagePropsSlice.trendsCoordinates,
    shallowEqual
  );

  const {
    user: {
      metadata: { Currency, Language },
    },
  } = userSlice;
  const dispatch = useDispatch();

  let months = [];
  let monthsRequired = 11;

  const baseDate = staticAppDate ? new Date(staticAppDate) : new Date();
  baseDate.setDate(15);

  for (let i = monthsRequired; i >= 0; i--) {
    const newDate = new Date(baseDate);

    // Calculate the target month and year considering year change
    let targetMonth = baseDate.getMonth() - i;
    let targetYear = baseDate.getFullYear();

    // Adjust the year if the month subtraction went beyond the current year
    while (targetMonth < 0) {
      targetMonth += 12; // Move to the previous year
      targetYear--;
    }

    newDate.setMonth(targetMonth);
    newDate.setFullYear(targetYear);

    const month = (newDate.getMonth() + 1).toString(); //.padStart(2, "0");
    const year = newDate.getFullYear().toString()?.slice(-2);
    months.push(`${month}/${year}`);
  }

  const data = {
    labels: months,
    datasets: [
      {
        label: "Group",
        type: "line",
        // backgroundColor: "rgb(30, 206, 30)",
        borderColor: "rgb(30, 206, 30)",
        data: trendsCoordinates.group, //trendsCoordinates[1],
        lineTension: 0.6,
        // borderWidth: 3.5,
        // pointStyle: "dash",
        borderDash: [9, 9],
      },
      {
        label: "Year Ago",
        type: "bar",
        backgroundColor: "rgba(248, 191, 19, 1)",
        borderColor: "rgba(248, 191, 19, 1)",
        data: trendsCoordinates.yearAgo, //trendsCoordinates[1],
        lineTension: 0.6,
      },
      {
        label: "Actual",
        type: "bar",
        borderColor: "rgba(68, 115, 195, 1)",
        backgroundColor: "rgba(68, 115, 195, 1)",
        data: trendsCoordinates.actual, //trendsCoordinates[0],
        // barPercentage:0.5
      },
      // {
      //   label: "Expected",
      //   type: "bar",
      //   data: trendsCoordinates.expected,
      //   backgroundColor: "lightBlue",
      // },
    ],
  };

  let options = {
    animation: {
      duration: 400,
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: true,
        },
        stacked: false,
      },
      y: {
        grid: {
          display: false,
        },
        stacked: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  useEffect(() => {
    trendsCoordinates && // 👈 null and undefined check
    Object.keys(trendsCoordinates)?.length === 0 &&
    Object.getPrototypeOf(trendsCoordinates) === Object.prototype
      ? dispatch(setLoadingSpinner(true))
      : dispatch(setLoadingSpinner(false));
  }, [trendsCoordinates]);

  return (
    <div className="customers-trends-graph-box">
      <div
        className="product-name bold-header"
        style={{ ...textDirection(Language), fontSize: "13px" }}
      >
        {dictionary[Language]?.CustomersOverviewGraph.graphTitle}{" "}
        {currencyMap[Currency]}
      </div>
      {loadingSpinner ? (
        <LoadingAnimation />
      ) : (
        <Bar data={data} options={options} className="customers-trends-bar" />
      )}
    </div>
  );
}
export default memo(CustomersOverviewGraph);
